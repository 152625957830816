import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Intro = ({ lang, ruta, sobrenosotros }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if (sobrenosotros.imagen_cabecera) {
        imagen = ruta + "/" + sobrenosotros.imagen_cabecera;
    }

    return (
        <section className="intro">
            <div className="intro__bg-wrap">
                <div
                    className="overlay intro__bg js-image js-parallax js-scale-down"
                    data-image={imagen}
                    style={{ backgroundImage: "url('" + imagen + "')" }}
                    data-jarallax-original-styles={`background-image: url("${imagen});`}
                >
                    <div
                        id="jarallax-container-0"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            pointerEvents: "none",
                            zIndex: -100,
                            transform: "matrix(1, 0, 0, 1, 0, 0)",
                            transformOrigin: "50% 50% 0px"
                        }}
                    >
                        <div
                            style={{
                                backgroundPosition: "50% 50%",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundImage: `url("${imagen}")`,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: 1497,
                                height: 922,
                                overflow: "hidden",
                                pointerEvents: "none",
                                marginTop: 0,
                                transform: "translate3d(0px, -0.0328124px, 0px)",
                                transformOrigin: "50% 50% 0px"
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="container intro__container">
                <div className="row h-100 align-items-center">
                    <div className="col-12 col-md-12 col-xl-8">
                        <span
                            className="text-white title title--overhead js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    {t("Bienvenidos a Holiday Homes Canarias")}
                                </div>
                            </div>
                        </span>
                        <h1
                            className="title title--display-1 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    {t("Sobre Nosotros")}
                                </div>
                            </div>
                        </h1>
                    </div>
                </div>
            </div>
            {/* Scroll To */}
            <a className="scroll-to" href="!#about">
                {t("Scroll")}
                <span className="scroll-to__line" />
            </a>
        </section>

    );
};
export default Intro;
