import MenuWrapper from '../Compartido/menu.jsx';  
import { useTranslation } from 'react-i18next';

function Header({lang, noticia, ruta, config, meta_titulo, meta_descripcion, meta_keywords}) {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (noticia.imagen)
	{
		imagen = ruta+"/blog/"+noticia.id+"/"+noticia.imagen;
	}

    return (
        <div id="Header_wrapper" style={{ backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}}>
            <header id="Header">
                <MenuWrapper
                    preidioma = {preidioma} 
                    lang = {lang} 
                    config = {config}
                    ruta = {ruta}
                    meta_titulo = {noticia.eltitulo+" - "+meta_titulo}
                    meta_descripcion = {meta_descripcion} 
                    meta_keywords = {meta_keywords}
                />          
            </header>  
            <div id="Subheader">
                <div className="container">
                    <div className="column one">
                        <h1 className="title">{  noticia.eltitulo }</h1>
                        <div className="intro-meta">
                            <div className="date" style={{ color:"white" }}>
                                <i className="fa-solid fa-clock"></i><span>{new Date(noticia.fecha_publica).toDateString()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header; 
