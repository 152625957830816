import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Ventajas = ({ lang, ventajas, ruta }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section">
            <div className="row align-items-end">
                <div className="col-12 col-md-12 col-lg-8">
                    <span className="title title--overhead js-lines">{t("Tus ventajas")}</span>
                    <h1 className="title title--h1 js-lines">{t("Te damos más por menos")}.</h1>
                </div>
            </div>
            <div className="row ventajas-container">
                {ventajas.map((ventaja) => (
                    <div className="col-6 col-lg-3" key={ventaja.idv}>
                        <img src={`${ruta}/ventajas/${ventaja.idv}/${ventaja.imagen}`} style={{ padding: "20px" }} />
                        <h4 className="mb-20">{ventaja.ventaja_nombre}</h4>
                    </div>
                ))}
            </div>
        </section>


    );
};
export default Ventajas;
