import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const PanelSeparador = ({ lang, config }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="bottom-panel height-md-auto">
            <div className="bottom-panel__wrap">
                <div className="row h-100 align-items-center">
                    <div className="col-12">
                        <div className="row ">
                            <div className="mb-2 col-12 col-md-6 col-lg-4 mb-sm-4 mb-md-0">
                                <div className="bottom-panel-title">{t("¿Tienes dudas?")}</div>
                                <span className="bottom-panel-info">{config.telefono_fiscal}</span>
                            </div>
                            <div className="mb-2 col-12 col-md-6 col-lg-4 mb-sm4 mb-md-0">
                                <div className="bottom-panel-title">{t("Escríbenos")}</div>
                                <span className="bottom-panel-info">
                                    {config.email_fiscal}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default PanelSeparador;
