import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextoNoticia = ({noticia, populares, ruta}) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png"; 

    if(noticia.imagen)
    {
        imagen = ruta+"/blog/"+noticia.id+"/"+noticia.imagen;
    }

    const results = [];
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={t("Blog")} style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}} >
                    </div>
                    <h1 className="noticia-individual mt-5 mb-5">{ noticia.eltitulo}</h1>
                        <div className="noticia-individual meta mb-4 gris">
                            <i className="fa fa-calendar"></i>
                            <a className="gris" href="#"> {noticia.fecha_publica} </a>
                        </div> 
                    <p className="noticia-individual-descripcio-curta fw-bolder mb-4" dangerouslySetInnerHTML={{__html: noticia.ladesccorta}}></p>
                    <p className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: noticia.ladesclarga}}></p>
                    </div>
                </div>
            </div>
    );
}

export default TextoNoticia;
