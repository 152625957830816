import {React } from "react";

import { useTranslation } from 'react-i18next';

const PropiedadesBuscar = ({lang, ruta, propiedades, elementosweb, adultos, ninos, bebes, llegada, salida, config }) => {

    const { t } = useTranslation();

    {/* ************ */}

    return (
      <div className="pt-5" >
        <div className="destacats">
            {
              propiedades.length>0 ?
                propiedades.map((propiedad, index) => (
                  <div className="destacats-propietat" key={"d"+index}>
                      <img className="destacats-imatge-propietat" src={propiedad.foto_principal.length!=0 ? ruta+"/propiedades/"+propiedad.id+"/fotos/web/thumb/"+propiedad.foto_principal[0].nombre : "/images/web/bedloop-nofoto.png" }/>
                      <div className="destacats-text">
                          <span className="destacats-icones-propietat" style={{ color: ""+config.color_corporativo_web+"" }}>
                          <i className="fa-solid fa-bed"></i> {propiedad.num_habitaciones} | <i className="fa-solid fa-users"></i> {propiedad.capacidad_maxima} | <i className="fa-solid fa-bath"></i> {propiedad.num_banyos}
                        </span>
                        <span className="destacats-titol-propietat">
                          <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica}>
                            { propiedad.nombre}
                          </a>
                        </span>
                    
                        <span className="destacats-ciutat-propietat"> { propiedad.ciudad}</span>
                        { adultos && llegada && salida && propiedad.by_request==0
                          ?
                            <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica+"?adultos="+adultos+"&ninos="+ninos+"&bebes="+bebes+"&llegada="+llegada+"&salida="+salida} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                              <i class="fa-duotone fa-calendar-check"></i> {t("Desde")+" "+(propiedad.precio/100).toFixed(2)+" €"}
                            </a>
                          :
                            adultos && llegada && salida && propiedad.by_request==1
                            ?
                              <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica+"?adultos="+adultos+"&ninos="+ninos+"&bebes="+bebes+"&llegada="+llegada+"&salida="+salida} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                <i class="fa-duotone fa-calendar-check"></i> {t("Petición") }
                              </a>
                            :
                              propiedad.by_request==1
                              ?
                                <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                { t("Petición")}
                               </a>
                              : 
                                <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica} className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                  {t("Reservar")}
                                </a>
                        }
                      </div>
                  </div>
                ))
              :
                <div className="centra">
                  { elementosweb.etiqueta_calificacion ? elementosweb.etiqueta_calificacion[lang] : "" }
                </div>
            }
         </div>
      </div>
    );
};

export default PropiedadesBuscar;

