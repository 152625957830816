import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const PanelInferior = ({ lang, propiedad }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="bottom-panel bottom-panelRoom">
            <div className="bottom-panel__wrap">
                <div className="row h-100 align-items-center">
                    <div className="col-12 col-md-12 col-xl-8">
                        <div className="row room-details">
                            <div className="col-3 room-details__item">
                                <i className="fa-regular fa-users" style={{ paddingRight: "10px" }} />{propiedad.capacidad_maxima} {t("Huéspedes")}{" "}
                            </div>
                            <div className="col-3 room-details__item">
                                <i className="fa-regular fa-bed" style={{ paddingRight: "10px" }} />{propiedad.num_habitaciones} {t("Habitaciones")}
                            </div>
                            <div className="col-3 room-details__item">
                                <i className="fa-regular fa-bath" style={{ paddingRight: "10px" }} />{propiedad.num_banyos} {t("Baños")}{" "}
                            </div>
                            <div className="col-3 room-details__item">
                                <i className="fa-regular fa-ruler" style={{ paddingRight: "10px" }} />{propiedad.metros_cuadrados}{t("m2")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default PanelInferior;
