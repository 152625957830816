import {React} from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import "./../../../../../css/magno/conocenos.css";

const Conocenos = ({lang, config, websgrupo, sobrenosotros }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div  className="desktiopymobilecono" style={{ backgroundColor: "#000000", background: "url('/images/web/magno/conocenos-5.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center'}}>
            <section className="container">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b798f20" data-id="b798f20" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-f7835e9 elementor-section-boxed elementor-section-height-default" data-id="f7835e9" data-element_type="section">
                                <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-9ab551c" data-id="9ab551c" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated capsaconocenos" style={{ color:"#FFFFFF", textAlign: "center" }}>

                                            <div className="solodesktopcono elementor-element elementor-element-4ef2bcd elementor-widget elementor-widget-heading" data-id="4ef2bcd" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign:"center" }}>
                                                <div className="elementor-widget-container">
                                                    <h3 className="elementor-heading-title elementor-size-default" style={{ fontFamily: "'Roboto', Sans-serif", fontSize: "12px", fontWeight: "400", lineHeight: "1em", letterSpacing: "4px", marginBottom: "20px" }}>
                                                    { websgrupo.pie_pagina[lang] }
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="solodesktopcono elementor-element elementor-element-c6677e5 elementor-widget elementor-widget-heading" data-id="c6677e5" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign:"center" }}>
                                                <div className="elementor-widget-container">
                                                    <h3 className="mb-4 elementor-heading-title elementor-size-medium" style={{ fontFamily: "'Lato', Sans-serif", fontSize: "50px", fontWeight: "300", lineHeight: "1.2em", letterSpacing: "1px", marginBotton: "20px" }}>
                                                        { websgrupo.subtitulo_descripcion[lang] }
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="solomobilecono elementor-element elementor-element-4ef2bcd elementor-widget elementor-widget-heading" data-id="4ef2bcd" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                                                <div className="elementor-widget-container" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                                    <h3 className="elementor-heading-title elementor-size-medium" style={{fontFamily: 'Roboto, Sans-serif', fontWeight: "400", lineHeight: "1.5em" , letterSpacing: "4px",  marginBottom: "20px", fontSize:"12px", textTransform: "uppercase"}}>
                                                    { t("¿Sabes como podemos conseguir nuestras metas juntos?")}
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="solomobilecono elementor-element elementor-element-c6677e5 elementor-widget elementor-widget-heading" data-id="c6677e5" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                                                <div className="elementor-widget-container" style={{ marginLeft: "auto", marginRight: "auto" }}>
                                                    <h3 className="elementor-heading-title elementor-size-default" style={{ fontFamily: "Lato, Sans-serif", fontSize: "30px", fontWeight: "300", lineHeight: "1.2em", letterSpacing: "1px", marginBottom:"20px" }}>
                                                    { t("Gestionamos tu inmueble")}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="solodesktopcono elementor-element elementor-element-fe6a524 elementor-widget elementor-widget-text-editor" data-id="fe6a524" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div className="elementor-widget-container">
                                                    { ReactHtmlParser(sobrenosotros.ladesc) }
                                                </div>
                                            </div>

                                            <div className="elementor-element elementor-element-4403c7e elementor-align-center elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="4403c7e" data-element_type="widget" data-widget_type="button.default" style={{ textAlign:"center" }}>
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-button-wrapper">
                                                        <a href={"/"+preidioma+t("propietarios")} className="elementor-button-link elementor-button elementor-size-lg elementor-animation-shrink" role="button" style={{ fontFamily: "'Roboto', Sans-serif", fontSize: "12px", fontWeight: "normal", lineHeight: "1px", letterSpacing: "2px", fill: "#FFFFFF" , color: "#FFFFFF", borderRadius: "0px 0px 0px 0px", padding: "19px 19px 19px 19px" }}>
                                                            <span className="elementor-button-content-wrapper" style={{margin: "0px 15% 0px 15%"}}>
                                                                <span className="serpropietario solodesktop">
                                                                    { websgrupo.texto_a_buscar[lang] }
                                                                </span>
                                                                <span className="serpropietario solomobilecono m-auto-mb" style={{ textTransform:"uppercase" }}>
                                                                    {t("Ser propietario")}
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Conocenos;


