import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const PDCookieConsent = ({lang}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    useEffect(() => {
        // Importación del CSS
        const cssLink = document.createElement("link");
        cssLink.href = "https://pdcc.gdpr.es/pdcc.min.css";
        cssLink.rel = "stylesheet";
        document.head.appendChild(cssLink);

        // Importación del JavaScript
        const jsScript = document.createElement("script");
        jsScript.src = "https://pdcc.gdpr.es/pdcc.min.js";
        jsScript.charset = "utf-8";
        document.body.appendChild(jsScript);

         // Polyfill para PDCookieConsent.config
        if (typeof PDCookieConsent === "undefined" || typeof PDCookieConsent.config !== "function") {
            window.PDCookieConsent = {
                config: function() {}
            };
        }

        window.PDCookieConsent.config({
            "defaultLang": {lang}, 
            "brand": {
                "dev": false, // **Modo de desarrollo (opcional)**
                "name": "ASF CONSULTORS", // **Nombre de la marca (opcional)**
                "url": "https://asfconsultors.cat", // **URL de la marca (opcional)**
                "websiteOwner": "" // **Propietario del sitio web (opcional)**
            },
            "showRejectButton": true, // **Mostrar botón de rechazo (opcional)**
            "cookiePolicyLink": "/"+preidioma+t("cookies") , // **Enlace a la política de cookies (opcional)**
            "hideModalIn": ["/"+preidioma+t("cookies") ], // **Ocultar modal en páginas específicas (opcional)**
            "styles": {
                "primaryButton": {
                "bgColor": "#FFE06A", // **Color de fondo del botón principal (opcional)**
                "txtColor": "#024100" // **Color del texto del botón principal (opcional)**
                },
                "secondaryButton": {
                "bgColor": "#EEEEEE", // **Color de fondo del botón secundario (opcional)**
                "txtColor": "#333333" // **Color del texto del botón secundario (opcional)**
                }
            }
        });

        // Limpieza
        return () => {
        document.head.removeChild(cssLink);
        document.body.removeChild(jsScript);
        };
    }, []);

    return null;
}

export default PDCookieConsent;