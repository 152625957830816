import React from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';

const Content = ({preidioma, textocookies}) => {

    const { t } = useTranslation();

    return (
        <div id="Content">
            <div className="content_wrapper clearfix">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma = {preidioma}
                        />

                        <div className="section" style={{paddingTop:"60px" }}>
                            <div className="section_wrapper clearfix">
                                <div className="column_attr clearfix align_center">
                                    <h6 className="landing4-heading grey" style={{paddingBottom: "3px", borderBottom: "1px solid #E87C0C",  display: "inline-block" , borderColor: "#E87C0C" }}>{ t("POLÍTICA DE COOKIES") }</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto"}}/>
                                </div>
                                <div className="column one column_blog">
                                    <div className="column_attr clearfix">
                                        <div className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: textocookies.ladesc}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;