import React from "react";
import { useTranslation } from 'react-i18next';

const Content = ({ lang, datostransferencia }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <main className="mt-5 main mb80">
            <section className="container section section-first">
                <div className="row justify-content-center">
                    {/* Content */}
                    <div className="col-12 col-lg-10 col-xl-8">
                        <h3 className="centra">{t("Gracias por su reserva")}</h3>
                        <h4 className="centra">{t("Recuerde completar el pago en la siguente cuenta")}:</h4>
                        <p className="centra" dangerouslySetInnerHTML={{ __html: datostransferencia }}></p>
                    </div>
                </div>
            </section>
        </main>

    );
};
export default Content;
