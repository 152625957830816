import { React } from "react";
import { Tab, Tabs, Badge } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';


import { useTranslation } from 'react-i18next';

const ListaReservas = () => {
    const { t } = useTranslation();
    return (
        <section className="mt-5 mb-5">
            <div className="p-5 m-5 bg-white shadow br15">
                <div className="row noticia-individual noticia-individual-descripcio-llarga mx-auto">
                    <h2 className="noticia-individual mb-5 mt-5 border-bottom pb-3">{t("Mis Reservas")}</h2>
                    <Tabs
                        defaultActiveKey="0"
                        id="uncontrolled-tab-example"
                        className="mb-5 shadow-sm mt-4"
                    >

                        <Tab eventKey="0" title="Actuales" key="0">
                            <div className="portlet-body">
                            <Table responsive>
                               <thead>
                                <tr>
                                    <th>Id. Reserva</th>
                                    <th>Entrada</th>
                                    <th>Salida</th>
                                    <th>Propiedad</th>
                                    <th>Ciudad</th>
                                    <th>Adultos</th>
                                    <th>Total</th>
                                    <th>Prepago</th>
                                    <th>Pendiente</th>
                                    <th>Estado</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <Badge bg="light" text="dark">12345</Badge>
                                        </td>
                                        <td>
                                        <Badge bg="warning">Inicio_Reserva</Badge>
                                        </td>
                                        <td>
                                         <Badge bg="info">Final_Reserva</Badge>
                                        </td>
                                        <td>
                                         <p>Nombre_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Ciudad_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Adultos_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Total_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Pagado</p>
                                        </td>
                                        <td>
                                        <p>Pendiente</p>
                                        </td>
                                        <td>
                                        <Badge bg="success">Confirmada</Badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <Badge bg="light" text="dark">12345</Badge>
                                        </td>
                                        <td>
                                        <Badge bg="warning">Inicio_Reserva</Badge>
                                        </td>
                                        <td>
                                         <Badge bg="info">Final_Reserva</Badge>
                                        </td>
                                        <td>
                                         <p>Nombre_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Ciudad_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Adultos_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Total_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Pagado</p>
                                        </td>
                                        <td>
                                        <p>Pendiente</p>
                                        </td>
                                        <td>
                                        <Badge bg="danger">Cancelada</Badge>
                                        </td>
                                    </tr>
                                </tbody>
                                </Table>
                            </div>
                        </Tab>

                        <Tab eventKey="1" title="Pasadas" key="1">
                            <div className="portlet-body">
                            <Table responsive>
                               <thead>
                                <tr>
                                    <th>Id. Reserva</th>
                                    <th>Entrada</th>
                                    <th>Salida</th>
                                    <th>Propiedad</th>
                                    <th>Ciudad</th>
                                    <th>Adultos</th>
                                    <th>Total</th>
                                    <th>Prepago</th>
                                    <th>Pendiente</th>
                                    <th>Estado</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        <Badge bg="light" text="dark">12345</Badge>
                                        </td>
                                        <td>
                                        <Badge bg="warning">Inicio_Reserva</Badge>
                                        </td>
                                        <td>
                                         <Badge bg="info">Final_Reserva</Badge>
                                        </td>
                                        <td>
                                         <p>Nombre_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Ciudad_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Adultos_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Total_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Pagado</p>
                                        </td>
                                        <td>
                                        <p>Pendiente</p>
                                        </td>
                                        <td>
                                        <Badge bg="success">Confirmada</Badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <Badge bg="light" text="dark">12345</Badge>
                                        </td>
                                        <td>
                                        <Badge bg="warning">Inicio_Reserva</Badge>
                                        </td>
                                        <td>
                                         <Badge bg="info">Final_Reserva</Badge>
                                        </td>
                                        <td>
                                         <p>Nombre_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Ciudad_Propiedad</p>
                                        </td>
                                        <td>
                                            <p>Adultos_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Total_Reserva</p>
                                        </td>
                                        <td>
                                         <p>Pagado</p>
                                        </td>
                                        <td>
                                        <p>Pendiente</p>
                                        </td>
                                        <td>
                                        <Badge bg="danger">Cancelada</Badge>
                                        </td>
                                    </tr>
                                </tbody>
                                </Table>
                            </div>
                        </Tab>

                    </Tabs>
                </div>
            </div>
        </section>
    );
}
export default ListaReservas;
