import React from "react";
import { useTranslation } from 'react-i18next';

const NoticiasPortada = ({ noticias, ruta, preidioma }) => {

    const { t } = useTranslation();

    return (
        <div className="section mcb-section p80centredContainer">
            <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix align_center">
                                <a href="blogen" className="button button_size_2 button_theme button_js">
                                    <span className="button_label">{t("Información")}</span>
                                </a>
                                <h2>{t("Últimas noticias")}</h2>
                            </div>
                        </div>

                        <div className="column mcb-column one column_blog_slider">
                            <div className="blog_slider clearfix flat" data-count="6">
                                <div className="blog_slider_header"></div>
                                <div>
                                    <ul className="blog_slider_ul" >

                                        {noticias.map((noticia, index) => (
                                            <li className="post format-standard has-post-thumbnail hentry category-events w346" key={index} style={{ width: "320px" }}>
                                                <div className="item_wrapper" id="event0" style={{ height: "470px" }}>
                                                    <div className="image_frame scale-with-grid">
                                                        <div className="image_wrapper">
                                                            <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl}>
                                                                {
                                                                    noticia.imagen != ""
                                                                        ? <img src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} className="scale-with-grid wp-post-image h230 border-radius-imatge" alt="" />
                                                                        : <img src="images/web/bedloop-nofoto.png" className="scale-with-grid wp-post-image h230 border-radius-imatge" alt="" />
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="date_label">{new Date(noticia.fecha_publica).toDateString()}</div>
                                                    <div className="desc">
                                                        <h4 className="pLeft20pRight20">
                                                            <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl}>
                                                                {noticia.eltitulo}
                                                            </a>
                                                        </h4>
                                                        <hr className="hr_color" />
                                                        <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl} className="button button_left button_js kill_the_icon">
                                                            <span className="button_icon">
                                                                <i className="icon-layout"></i>
                                                            </span>

                                                            <span className="button_label">{t("Más informacion")}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>

                                        ))}
                                    </ul>
                                </div>

                                <div className="slider_pager slider_pagination hidden" >
                                    <a href="#" className="selected">
                                        <span>1</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NoticiasPortada;
