import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Apartamentos = ({ lang, destacadas, ruta }) => {

    const { t } = useTranslation();

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="p-0 m-0 container-fluid">
            {/* separador */}
            <div className="d-flex align-items-center sep-exp">
                <hr className="bg-dark" />
                <p className="p-0">{t("EXPERIENCIAS Y COMODIDADES ÚNICAS")}</p>
            </div>

            {/* apartamentos */}
            <div className="container-fluid justify-content-center box-apart">
                <div>
                    <h2 className="m-0 noto">{t("Nuestros apartamentos")}</h2>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <p className="">{t("Exclusivamente decorados cuidando al máximo cada detalle.")}</p>
                </div>
                {/* grid */}
                <div className="grid-custom">
                    {
                        destacadas.map((propiedad, index) => (
                            <div className="card-grid-custom" key={index}>
                                <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica} className="h-100 text-decoration-none w-100">
                                    {
                                        propiedad.foto_principal.length != 0
                                            ?
                                            <div className="w-100" style={{ backgroundImage: "url('" + ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre + "')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} />

                                            :
                                            <div className="w-100" style={{ backgroundImage: "url('/images/web/bedloop-nofoto.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} />
                                    }
                                    <div className="w-100 text-grid-custom">
                                        <h4>{propiedad.nombre}</h4>
                                        <p className="m-0" style={{ fontSize: "17px", fontWeight: "normal" }}>{propiedad.ladesccorta}</p>
                                    </div>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    );
};
export default Apartamentos;
