import Menu from './Compartido/Menu.jsx';
import TextosFaqs from './Faqs/TextosFaqs.jsx';
import Footer from './Compartido/Footer.jsx';
import { useTranslation } from 'react-i18next';

const Faqs = (props) => {
    
    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas = {props.idiomas}
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                ruta = {props.ruta}
                meta_titulo = {"Magno Apartments - "+t("Faqs")}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />
            <TextosFaqs 
                categorias = {props.categoriasfaq}
                faqs = {props.faqs}
                lang = {props.lang}
            />
            <Footer 
                 lang = {props.lang}
                 config = {props.websgrupo}
                 config2 = {props.config}
                 idiomas = {props.idiomas}
                 ruta = {props.ruta}
            />
        </main>
    );
}

export default Faqs;