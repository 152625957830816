import MenuWrapper from '../Compartido/menu.jsx';  
import { useTranslation } from 'react-i18next';

function Header({lang, servicios, ruta, config,  meta_titulo, meta_descripcion, meta_keywords}) {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (servicios.imagen_cabecera)
	{
		imagen = ruta+"/"+servicios.imagen_cabecera;
	}

    return (
        <div id="Header_wrapper" style={{ backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}}>
            <header id="Header">
                <MenuWrapper
                    preidioma = {preidioma} 
                    lang = {lang} 
                    config = {config}
                    ruta = {ruta}
                    meta_titulo = {t("Servicios")+" - "+meta_titulo}
                    meta_descripcion = {meta_descripcion} 
                    meta_keywords = {meta_keywords}
                />      
            </header>  
            <div id="Subheader">
                <div className="container">
                    <div className="column one">
                        <h1 className="title text-uppercase">{t("Servicios") }</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header; 
