import {React} from "react";
import { useTranslation } from 'react-i18next';
import codigoImagen from './CajaImagen.jsx';

const Destinos = ({destinos, ruta, config, lang}) => {

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    const { t } = useTranslation();

    // let resultsdreta = [];
    // let resultsesquerra = [];
    // let resultsmig = [];

    //destinos.length = 3;

    const parseArray6 = (num) => {
        const chunks = []
        while (num > 6) chunks.push(6), num -= 6;
        chunks.push(num);
        return chunks;
    }

    const estructuraNumPar = num => {
        let primerosDestinos = destinosCopy.slice(0, num);
        destinosCopy.splice(0, num);
        primerosDestinos.forEach((undestino, index) => {
            tempResult = tempResult.concat(
                    codigoImagen(undestino, preidioma, ruta, t, "destacats-imatge-desti", index)
            );
        });
        result = result.concat(
            <div className={`destacats-desti desti-grid${num}`}>
                {tempResult}
            </div>
        );
        tempResult = [];
    }

    let result = [];
    let tempResult = []
    let array = parseArray6(destinos.length)
    let destinosCopy = destinos.slice();

    {
        for (let i = 0; i < array.length; i++) {
            if(array[i] === 1){
                let primerosDestinos = destinosCopy.slice(0, 1);
                destinosCopy.splice(0, 1);
                primerosDestinos.forEach((undestino, index) => {
                    tempResult = tempResult.concat(
                            codigoImagen(undestino, preidioma, ruta, t, "destacats-imatge-desti", "", index)
                    );
                });
                result = result.concat(
                    <div className="destacats-desti">
                        {tempResult}
                    </div>
                );
                tempResult = [];
            }
            else if(array[i] === 2)
                estructuraNumPar(2);
            else if(array[i] === 3){
                let primerosDestinos = destinosCopy.slice(0, 3);
                destinosCopy.splice(0, 3);
                primerosDestinos.forEach((undestino, index) => {
                    tempResult = tempResult.concat(
                            codigoImagen(undestino, preidioma, ruta, t, (index == 2) ? "destacats-imatge-desti3" : "destacats-imatge-desti", (index == 2) ? "mt-2" : "", index)
                    );
                });
                result = result.concat(
                    <div className="destacats-desti desti-grid3">
                        {tempResult}
                    </div>
                );
                tempResult = [];
            }
            else if (array[i] === 4)
                estructuraNumPar(4)
            else if(array[i] === 5){
                let primerosDestinos = destinosCopy.slice(0, 5);
                destinosCopy.splice(0, 5);
                primerosDestinos.forEach((undestino, index) => {
                    tempResult = tempResult.concat(
                            codigoImagen(undestino, preidioma, ruta, t, (index == 3 || index == 4) ? "destacats-imatge-desti5" : "destacats-imatge-desti", (index != 3 || index != 4) ? "d-block" : "", index)
                    );
                });
                result = result.concat(
                    <div className="destacats-desti desti-grid5">
                        {tempResult}
                    </div>
                );
                tempResult = [];
            }
            else if(array[i] === 6)
                estructuraNumPar(6)
            else
                null
        }
    }

    // {
    //     (destinos.length==2)?
    //         destinos.forEach((undestino, index) => {
    //             (index%2==0) ?
    //                 resultsesquerra = resultsesquerra.concat(
    //                     <div className="destacats-desti" key={index}>
    //                         <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                             {
    //                                 undestino.imagen_principal.length>0 ?
    //                                     <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt={ undestino.elnombre} />
    //                                 :
    //                                     <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt={ undestino.elnombre} />

    //                             }
    //                             <span className="sobreimagen">{ undestino.elnombre }</span>
    //                         </a>
    //                     </div>
    //                 )
    //             :
    //                 resultsdreta = resultsdreta.concat(
    //                     <div className="destacats-desti" key={index}>
    //                         <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                             {
    //                                 undestino.imagen_principal.length>0 ?
    //                                     <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                 :
    //                                     <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                             }
    //                             <span className="sobreimagen">{ undestino.elnombre }</span>
    //                         </a>
    //                     </div>
    //                 )
    //         })
    //     :
    //         (destinos.length%3==0)?
    //             destinos.forEach((undestino, index) => {
    //                 (index%3==0) ?
    //                     resultsesquerra = resultsesquerra.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 : (index%3==1) ?
    //                     resultsesquerra = resultsesquerra.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )

    //                 :
    //                     resultsdreta = resultsdreta.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti3" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti3" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //             })
    //         :
    //         (destinos.length%3==1) ?
    //             destinos.forEach((undestino, index) => {
    //                 (index%3==0 && index!=destinos.length-1) ?
    //                     resultsesquerra = resultsesquerra.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 : (index%3==0 && index==destinos.length-1) ?
    //                     resultsmig = resultsmig.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 : (index%3==1) ?
    //                     resultsesquerra = resultsesquerra.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 :
    //                     resultsdreta = resultsdreta.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti3" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti3" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //             })
    //         :
    //             destinos.forEach((undestino, index) => {
    //                 (index%3==0 && destinos.length-2!=index) ?
    //                     resultsesquerra = resultsesquerra.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 : (index%3==0 && destinos.length-2==index) ?
    //                     resultsmig = resultsmig.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 : (index%3==1 && destinos.length-1!=index) ?
    //                     resultsesquerra = resultsesquerra.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 : (index%3==1 && destinos.length-1==index) ?
    //                     resultsmig = resultsmig.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //                 :
    //                     resultsdreta = resultsdreta.concat(
    //                         <div className="destacats-desti" key={index}>
    //                             <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
    //                                 {
    //                                     undestino.imagen_principal.length>0 ?
    //                                         <img className="destacats-imatge-desti3" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
    //                                     :
    //                                         <img className="destacats-imatge-desti3" src="/images/web/bedloop-nofoto.png"  alt="" />

    //                                 }
    //                                 <span className="sobreimagen">{ undestino.elnombre }</span>
    //                             </a>
    //                         </div>
    //                     )
    //             })
    // }

    {/* ************* */}

     return (
        <>
            <h2 className="text-center">{ t("Nuestros destinos") }</h2>
            <div className="destacats flex-dColumn" style={{gap: '25px'}}>
                {result}
            </div>
            {/* {
                (destinos.length==2)?
                    <div className="destacats">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                { resultsesquerra }
                            </div>
                            <div className="col-12 col-lg-6">
                                { resultsdreta }
                            </div>
                        </div>
                    </div>
                :
                    <div className="destacats">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                { resultsesquerra }
                            </div>
                            <div className="col-12 col-lg-6">
                                { resultsdreta }
                            </div>
                        </div>
                        <div className="row ml_50">
                            <div className="col-12 col-lg-6">
                                { resultsmig }
                            </div>
                        </div>
                    </div>
            } */}
        </>
     )
}
export default Destinos;
