import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import BtnFloating from './Compartido/BtnFloating.jsx';
import Slider from './Inicio/Slider.jsx';
import Ventajas from './Inicio/Ventajas.jsx';
import AboutUs from './Inicio/AboutUs.jsx';
import Villas from './Inicio/Villas.jsx';
import Servicios from './Inicio/Servicios.jsx';
import CajaSeparacion from './Inicio/CajaSeparacion.jsx';
import DescubreTnf from './Inicio/DescubreTnf.jsx';
import Newsletter from './Inicio/Newsletter.jsx';
import Recomendaciones from './Inicio/Recomendaciones.jsx';
import PanelInferior from './Inicio/PanelInferior.jsx';
import Scripts from './Compartido/Scripts.jsx';

import '../../../../../resources/css/web/holidayhomescanarias/header.css'
import '../../../../../resources/css/web/holidayhomescanarias/general.css';
import '../../../../../resources/css/web/holidayhomescanarias/about-us.css'
import '../../../../../resources/css/web/holidayhomescanarias/slider.css'
import '../../../../../resources/css/web/holidayhomescanarias/bottompannel-inicio.css'
import '../../../../../resources/css/web/holidayhomescanarias/rooms.css'
import '../../../../../resources/css/web/holidayhomescanarias/servicios-ventajas.css'
import '../../../../../resources/css/web/holidayhomescanarias/recomendaciones.css'
import '../../../../../resources/css/web/holidayhomescanarias/cta.css'
import '../../../../../resources/css/web/holidayhomescanarias/descubre-tnf.css'
import '../../../../../resources/css/web/holidayhomescanarias/footer.css'
import '../../../../../resources/css/web/holidayhomescanarias/buttons.css'

const Inicio = (props) => {
    return (
        <main>
            <Header
                datos={props.websgrupo}
                lang={props.lang}
                idiomas={props.idiomas}
                tipo="inicio"
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
            />
            <Slider
                lang={props.lang}
                destinos={props.destinos}
                d_ale={props.aleatori}
                ruta={props.ruta}
            />
            <PanelInferior
                lang={props.lang}
                capacidad_maxima={props.capacidad_maxima}
            />
            <AboutUs
                lang={props.lang}
            />
            <Villas
                ruta={props.ruta}
                destacadas={props.destacadas}
                lang={props.lang}
            />
            <Servicios
                lang={props.lang}
            />
            <Ventajas
                lang={props.lang}
                ventajas={props.ventajasweb}
                ruta={props.ruta}
            />
            <Recomendaciones
                lang={props.lang}
            />
            <CajaSeparacion
                lang={props.lang}
            />
            <DescubreTnf
                lang={props.lang}
                noticias={props.noticias}
                ruta={props.ruta}

            />
            <Newsletter
                lang={props.lang}
            />
            <Footer
                lang={props.lang}
                config={props.config}
            />
            <BtnFloating />

            <Scripts />
        </main>
    );
}

export default Inicio;

