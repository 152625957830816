
import Menu from './Compartido/Menu.jsx';
// import Banner from './Inicio/Banner.jsx';
// import Destacados from './Inicio/Destacados.jsx';
import Destinos from './Inicio/Destinos.jsx';
// import Mapa from './Inicio/Mapa.jsx';
// import Caracteristicas from './Compartido/Caracteristicas.jsx';
// import Comentarios from './Inicio/Comentarios.jsx';
// import NoticiasHome from './Inicio/NoticiasHome.jsx';
import Footer from './Compartido/Footer.jsx';
import TextoPiePgina from './Inicio/TextoPiePagina.jsx';
//import { ProvidePlugin } from 'webpack';

const Inicio = (props) => {
  return (
    <main>
      { <Menu
        destinos = {props.destinos}
        sobrenosotros = {props.textosobrenosotros}
        clientes = {props.textoclientes}
        propietarios = {props.textopropietarios}
        servicios = {props.textoservicios}
        experiencias = {props.experiencias}
        idiomas = {props.idiomas}
        lang = {props.lang}
        tiposcasas = {props.tiposcasas}
        config = {props.config}
        textopropiedades = {props.textopropiedades}
        edificios = {props.edificios}
        marcas = {props.marcas}
        ruta = {props.ruta}
      /> }
      {/* <Banner
        destinos={props.destinos}
        lang = {props.lang}
        ruta = {props.ruta}
        d_ale = {props.aleatori}
        config = {props.websgrupo}
      /> */}
      {/* <Destacados
        destinos = {props.destinos}
        ruta = {props.ruta}
        destacadas = {props.destacadas}
        elementosweb = {props.elementosweb}
        config = {props.websgrupo}
        lang = {props.lang}
      /> */}
      <Destinos
         destinos = {props.destinos}
         ruta = {props.ruta}
         config = {props.websgrupo}
      />
      {/* <Mapa 
        destinos={props.destinos}
        elementosweb = {props.elementosweb}
        config = {props.websgrupo}
        lang = {props.lang}
      /> */}
      {/* <Caracteristicas 
        caracteristicas={props.caracteristicas}
        config = {props.config}
      /> */}
      {/* <Comentarios
        comentarios = {props.comentaris}
        lang = {props.lang}
        config = {props.websgrupo}
      /> */}
      {/* <NoticiasHome
        noticias={props.noticias}
        ruta = {props.ruta}
        lang = {props.lang}
        config = {props.websgrupo}
      /> */}
       <TextoPiePgina
        elementosweb = {props.elementosweb}
        lang = {props.lang}
        config = {props.websgrupo}
      />
      <Footer
        datos = {props.websgrupo}
        terminos = {props.textoterminos}
        protocolos = {props.textoprotocolos}
        avisolegal = {props.textoavisolegal}
        config = {props.config}
        elementosweb = {props.elementosweb}
        politicaprivacidad = {props.textopolitica}
        lang = {props.lang}
        ruta = {props.ruta}
      />
    </main>
  );
 }

 export default Inicio;
