import { React } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from 'react-i18next';

const PropiedadesBuscar = ({ lang, ruta, propiedades, elementosweb, adultos, ninos, bebes, llegada, salida, config, moneda }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    {/* ************ */ }

    return (
        <div className="pt-5" >
            <div className="destacats">
                {
                    propiedades.length > 0 ?
                        propiedades.map((propiedad, index) => (
                            <div className="destacats-propietat" key={"d" + index}>
                                {
                                    propiedad.foto_principal && propiedad.foto_principal.length != 0
                                        ? <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre} alt={propiedad.foto_principal[0].texto_alt ? propiedad.foto_principal[0].texto_alt[lang] : ""} title={propiedad.foto_principal[0].texto_title ? propiedad.foto_principal[0].texto_title[lang] : ""} />
                                        : <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-propietat" src="/images/web/bedloop-nofoto.png" alt="" title="" />
                                }
                                <div className="destacats-text">
                                    <span className="destacats-icones-propietat" style={{ color: "" + config.color_corporativo_web + "" }}>
                                        <i className="fa-solid fa-bed"></i> {propiedad.num_habitaciones} | <i className="fa-solid fa-users"></i> {propiedad.capacidad_maxima} | <i className="fa-solid fa-bath"></i> {propiedad.num_banyos}
                                    </span>
                                    <span className="destacats-titol-propietat">
                                        <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica}>
                                            {propiedad.nombre}
                                        </a>
                                    </span>

                                    <span className="destacats-ciutat-propietat"> {propiedad.ciudad}</span>
                                    {adultos && llegada && salida && propiedad.by_request == 0
                                        ?
                                        <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica + "?adultos=" + adultos + "&ninos=" + ninos + "&bebes=" + bebes + "&llegada=" + llegada + "&salida=" + salida} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                            <i className="fa-duotone fa-calendar-check"></i> {t("Desde") + " " + (propiedad.precio / 100).toFixed(2) + moneda}
                                        </a>
                                        :
                                        adultos && llegada && salida && propiedad.by_request == 1
                                            ?
                                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica + "?adultos=" + adultos + "&ninos=" + ninos + "&bebes=" + bebes + "&llegada=" + llegada + "&salida=" + salida} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                                <i className="fa-duotone fa-calendar-check"></i> {t("Petición")}
                                            </a>
                                            :
                                            propiedad.by_request == 1
                                                ?
                                                <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                                    {t("Petición")}
                                                </a>
                                                :
                                                <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className="btn btn-primary" style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }}>
                                                    {t("Reservar")}
                                                </a>
                                    }
                                </div>
                            </div>
                        ))
                        :
                        <div className="centra">
                            {t("No se han encontrado resultados con las fechas suministradas. Vuelva a hacer la consulta con otras fechas")}
                        </div>
                }
            </div>
        </div>
    );
};

export default PropiedadesBuscar;

