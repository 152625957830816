import { React } from "react";
import { useTranslation } from 'react-i18next';

import "./../../../../../css/magno/cabecera.css";

const ListaNoticias = ({ noticias, ruta, lang, config, preidioma }) => {

    const { t } = useTranslation();

    return (
        <div className="destacats row" style={{ alignItems: "stretch" }}>
            {noticias.map((noticia, index) => (
                <article className="mb-3 col-md-3 blog elementor-post elementor-grid-item post-6459 post type-post status-publish format-standard has-post-thumbnail hentry category-blog" key={index}>
                    <div className="elementor-post__card">
                        <a className="elementor-post__thumbnail__link" href={"/" + preidioma + t("blog") + "/" + noticia.laurl} target="_blank">
                            <div className="elementor-post__thumbnail">
                                {
                                    noticia.imagen != ""
                                        ? <img height="175" src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} className="attachment-medium size-medium wp-image-9968" alt="" decoding="async" loading="lazy" style={{ width: "100%" }} />

                                        : <img height="175" src="/images/web/bedloop-nofoto.png" className="attachment-medium size-medium wp-image-9968" alt="" decoding="async" loading="lazy" style={{ width: "100%" }} />
                                }
                            </div>
                        </a>
                        <div className="elementor-post__text" style={{ paddingLeft: '12.5%' }}>
                            <h3 className="elementor-post__title" style={{ marginTop: "45px", marginBottom: "26px", marginRight: "30px" }}>
                                <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl} target="_blank" style={{ fontFamily: "Lato, Sans-serif" }}>{noticia.eltitulo}</a>
                            </h3>
                            <a className="elementor-post__read-more colorcorporativo" href={"/" + preidioma + t("blog") + "/" + noticia.laurl} target="_blank" aria-label={t("Leer más") + " " + t('sobre') + " " + noticia.eltitulo} style={{ fontFamily: "'Roboto', Sans-serif", fontWeight: "normal", fontSize: "12px" }}>{t('Ver más').toUpperCase() + " »"}</a>
                        </div>
                    </div>
                </article>
            ))}
        </div>
    );
}



const Blog = ({ lang, config, websgrupo, noticias, ruta }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container solodesktopblock" style={{ marginTop: "27px", marginBottom: "17px", padding: "33px 0px 17px 0px" }}>
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8f1574e" data-id="8f1574e" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-0aa6136 elementor-widget elementor-widget-heading" data-id="0aa6136" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                            <div className="elementor-widget-container">
                                <h3 className="elementor-heading-title elementor-size-medium" style={{ color: "#2E2E2E", fontFamily: "Lato, Sans-serif", fontSize: "50px", fontWeight: "300", lineHeight: "1.2em", letterSpacing: "1px" }}>{t("Blog")}</h3>
                            </div>

                            <div className="elementor-element elementor-element-b4c55b3 elementor-grid-4 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-posts--thumbnail-top elementor-posts--show-avatar elementor-posts__hover-gradient elementor-widget elementor-widget-posts" data-id="b4c55b3" data-element_type="widget">
                                <div className="elementor-widget-container">
                                    <ListaNoticias
                                        noticias={noticias}
                                        ruta={ruta}
                                        lang={lang}
                                        config={config}
                                        preidioma={preidioma}
                                    />
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-814ec09 elementor-align-center elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="814ec09" data-element_type="widget" data-widget_type="button.default" style={{ alignText: "center" }}>
                                <div className="elementor-widget-container">
                                    <div className="elementor-button-wrapper">
                                        <a href={"/" + preidioma + t("blog")} className="elementor-button-link elementor-button elementor-size-lg elementor-animation-shrink" role="button" style={{ fontFamily: "Roboto, Sans-serif", fontSize: "12px", fontWeight: "normal", lineHeight: "1px", letterSpacing: "2px", fill: "#FFFFFF", color: "#FFFFFF", borderRadius: "0px 0px 0px 0px", padding: "19px 19px 19px 19px" }}>
                                            <span className="elementor-button-content-wrapper" style={{ margin: "0px 28%", marginTop: '1.2rem!important' }}>
                                                <span className="boto-blog mayusculacss">{t('Ver más')}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-ac33f00 elementor-widget elementor-widget-spacer" data-id="ac33f00" data-element_type="widget" data-widget_type="spacer.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                        <div className="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Blog;
