import { useEffect, useRef, useState } from "react";
import { NavDropdown, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const MyLangDropdown = ({ idiomas, langactual }) => {
    const [isOpenL, setIsOpenL] = useState(false);
    const dropdownRefL = useRef(null);

    const [langu, setLangu] = useState({ langactual });

    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const getUserBrowserLanguage = (langu) => {
        let lang = "";

        if (langu != "")
            lang = langu.langactual.toLowerCase();
        else
            lang = window.navigator.language;

        if (lang.includes("es")) return "es";
        if (lang.includes("en")) return "en";
        if (lang.includes("fr")) return "fr";
        if (lang.includes("it")) return "it";
        if (lang.includes("pt")) return "pt";
        if (lang.includes("ru")) return "ru";
        if (lang.includes("de")) return "de";
        if (lang.includes("sv")) return "sv";
        if (lang.includes("ca")) return "ca";

        return "es";
    };

    const handleMouseEnterL = () => {
        setIsOpenL(true);
    };

    const handleMouseLeaveL = (e) => {
        // Cierra el dropdown solo si el cursor no está sobre el contenido abierto

        if ((!dropdownRefL.current.contains(e.relatedTarget) && !e.relatedTarget.contains(dropdownRefL.current)) || e.relatedTarget.innerHTML.includes("main")) {
            setIsOpenL(false);
        }
    };

    useEffect(() => {

        const userBrowserLang = getUserBrowserLanguage(langu);

        i18n.changeLanguage(userBrowserLang);
        setLangu(userBrowserLang);

        const dropdownElement = dropdownRefL.current;
        dropdownElement.addEventListener("mouseenter", handleMouseEnterL);
        dropdownElement.addEventListener("mouseleave", handleMouseLeaveL);

        // Limpia los eventos del oyente al desmontar el componente
        return () => {
            dropdownElement.removeEventListener("mouseenter", handleMouseEnterL);
            dropdownElement.removeEventListener("mouseleave", handleMouseLeaveL);
        };
    }, []);

    {/* ************** */ }

    let Nombreidioma = "";
    var results = [];

    idiomas.forEach((unidioma, index) => {
        if (unidioma.codigo === langactual.toString().toLowerCase()) {
            Nombreidioma = unidioma.nombre;
        }
    })

    const handleLangSelect = (currentLang) => {

        i18n.changeLanguage(currentLang);

        setLangu(currentLang);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: currentLang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }

    {/* ************** */ }

    return (
        <NavDropdown
            className="navegacio letrablanca ubermenu ubermenu-nojs ubermenu-main ubermenu-menu-27 ubermenu-responsive ubermenu-responsive-default ubermenu-responsive-collapse ubermenu-horizontal ubermenu-transition-fade ubermenu-trigger-hover_intent ubermenu-skin-minimal ubermenu-bar-align-right ubermenu-items-align-right ubermenu-bound-inner ubermenu-disable-submenu-scroll ubermenu-sub-indicators ubermenu-sub-indicators-align-text ubermenu-retractors-responsive ubermenu-submenu-indicator-closes" title={Nombreidioma} id="collasible-nav-dropdown.ayuda ubermenu-main-28s" style={{ marginTop: "2px" }}
            show={isOpenL}
            onMouseEnter={handleMouseEnterL}
            onMouseLeave={handleMouseLeaveL}
            ref={dropdownRefL}
        >
            <Container className="mt-1 mb-1">
                <Row className="ml-5 ubermenu-nav" data-title="MAGNO" id="ubermenu-nav-main-28">
                    {idiomas.forEach((unidioma, index) => (
                        (unidioma.codigo !== langactual.toLowerCase()) ?
                            results = results.concat(
                                <span className='wpml-ls-slot-footer wpml-ls-item wpml-ls-item-{ unidioma.codigo } wpml-ls-item-legacy-list-horizontal' key={"i" + index} style={{ display: "inline-block", verticalAlign: "middle", padding: "0", margin: "5px 0", listStyleType: "none" }}>
                                    <a className='wpml-ls-link' style={{ fontFamily: '"Lato", Sans-serif', marginRight: "10px", cursor: "pointer" }} onClick={() => handleLangSelect(unidioma.codigo)}>
                                        <span className='wpml-ls-native' style={{ fontWeight: "300", fontFamily: '"Roboto", sans-serif', color: "inherit", textTransform: "uppercase", boxSizing: "border-box", fontSize: "13px", paddingTop: "5px", letterSpacing: "1px" }}>{unidioma.nombre}</span>
                                    </a>
                                </span>
                            )
                            :
                            ""
                    ))}
                    {results}
                </Row>
            </Container>
        </NavDropdown>
    );
};

export default MyLangDropdown;
