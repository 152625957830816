import {React} from "react";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const Destinos = ({destinos, ruta, config}) => {

    const { t } = useTranslation();
    let resultats = [];

    {/* ************* */}

     return (
        <>
            <h2 className="text-center pt-5">{ t("Nuestros destinos") }</h2>
            { 
                <div className="destacats">
                    <div className="d-flex justify-content-center flex-wrap">
                        {  
                             destinos.forEach((undestino, index) => {
                                resultats = resultats.concat(
                                    <div className="p-4">
                                        <div className="destacats-desti" key={index}>
                                            <a href={"/apartamentos-"+undestino.laurl } className="caixa-destins">
                                                {
                                                    undestino.imagen_principal.length>0 ? 
                                                        <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                                    :
                                                        <LazyLoadImage effect='blur' threshold={10} className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png" />
                                                    
                                                }
                                                <span className="sobreimagen">{ undestino.elnombre }</span>
                                            </a>
                                        </div>
                                    </div> 
                                )
                            })
                        }
                        { resultats }
                    </div>
                </div>
            }
        </>
     )
}
export default Destinos;