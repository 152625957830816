import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const NuestrasVillas = ({ lang, ruta, propiedades, moneda, adultos, ninos, bebes, mascotas, llegada, salida }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section section-first">
            <div className="row mb80">
                <div className="col-12">
                    <h1 className="title title--h1 js-lines">{t("Nuestras villas")}.</h1>
                </div>
                {propiedades.map((propiedad) => (
                    <div className="col-12 col-md-6 col-lg-4 js-scroll-show" key={propiedad.id}>
                        {/* ItemRoom extended */}
                        <div className="itemRoom itemRoom__extended">
                            <figure className="itemRoom__img-wrap">
                                {adultos && llegada && salida && propiedad.by_request == 0 ?
                                    <a className="itemRoom__link" href={"/" + preidioma + "villas-tenerife/" + propiedad.url_dinamica + "?llegada=" + llegada + "&salida=" + salida + "&adultos=" + adultos + "&ninos=" + ninos + "&bebes=" + bebes + "&mascotas=" + mascotas}>
                                        {" "}
                                        {
                                            propiedad.foto_principal.length != 0 ?
                                                <img className="cover lazyload" src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre} alt="room" />
                                                :
                                                <img className="cover lazyload" src="/images/web/bedloop-nofoto.png" />
                                        }
                                        {" "}
                                    </a>
                                    :
                                    <a className="itemRoom__link" href={"/" + preidioma + "villas-tenerife/" + propiedad.url_dinamica}>
                                        {" "}
                                        {
                                            propiedad.foto_principal.length != 0 ?
                                                <img className="cover lazyload" src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre} alt="room" />
                                                :
                                                <img className="cover lazyload" src="/images/web/bedloop-nofoto.png" />
                                        }
                                        {" "}
                                    </a>
                                }
                                <div className="itemRoom__details">
                                    <h3 className="title title--h3">{propiedad.nombre}</h3>
                                    {adultos && llegada && salida && propiedad.by_request == 0 ?
                                        <div className="itemRoom__price">
                                            <span>{t("Desde")}:</span> {(propiedad.precio / 100).toFixed(2)}{moneda}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </figure>
                            <div className="itemRoom__details-extended">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="item-extended black-text">
                                            {" "}
                                            <i className="fa-regular fa-users black-text" style={{ paddingRight: "10px" }} />{" "} {propiedad.capacidad_maxima} {t("Huéspedes")}
                                            {" "}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="mr-4 item-extended black-text">
                                            {" "}
                                            <i className="fa-regular fa-ruler black-text" style={{ paddingRight: "10px" }} />{" "} {propiedad.metros_cuadrados} {t("m2")}
                                            {" "}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="item-extended black-text">
                                            {" "}
                                            <i className="fa-regular fa-bath black-text" style={{ paddingRight: "10px" }} /> {" "} {propiedad.num_banyos} {t("Baños")}
                                            {" "}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="item-extended black-text">
                                            {" "}
                                            <i className="fa-regular fa-bed black-text" style={{ paddingRight: "10px" }} /> {" "} {propiedad.num_habitaciones} {t("Habitaciones")}
                                            {" "}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                {/*
                <div className="col-12 col-md-6 col-lg-4 js-scroll-show">
                    <div className="itemRoom itemRoom__extended">
                        <figure className="itemRoom__img-wrap">
                            <a className="itemRoom__link" href="/villas-tenerife/casa-higo">
                                {" "}
                                <img className="cover lazyload" src="casa.jpg" alt="room" />{" "}
                            </a>
                            <div className="itemRoom__details">
                                <h3 className="title title--h3">Casa Higo</h3>
                                <div className="itemRoom__price">
                                    <span>Desde:</span> 138.60€
                                </div>
                            </div>
                        </figure>
                        <div className="itemRoom__details-extended">
                            <div className="row">
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-users black-text" />6 Huéspedes{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mr-4 item-extended black-text">
                                        {" "}
                                        <i className="icon-maximize black-text" />
                                        100m2
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bath black-text" />2 Baños{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bed black-text" />3 Habitaciones{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 js-scroll-show">

                    <div className="itemRoom itemRoom__extended">
                        <figure className="itemRoom__img-wrap">
                            <a className="itemRoom__link" href="/villas-tenerife/casa-naranja">
                                {" "}
                                <img
                                    className="cover lazyload"
                                    src="/assets/images/apartments/7/slider/porís_de_abona-bahia_d_beautiful_house_with_3_bedrooms_and_5_minutes_from_the_beach_4509DD06-BD17-037A-86E3-F9D2152E3ABE-39.jpg"
                                    alt="room"
                                />{" "}
                            </a>
                            <div className="itemRoom__details">
                                <h3 className="title title--h3">Casa Naranja</h3>
                                <div className="itemRoom__price">
                                    <span>Desde:</span> 112.00€
                                </div>
                            </div>
                        </figure>
                        <div className="itemRoom__details-extended">
                            <div className="row">
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-users black-text" />6 Huéspedes{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mr-4 item-extended black-text">
                                        {" "}
                                        <i className="icon-maximize black-text" />
                                        100m2
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bath black-text" />2 Baños{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bed black-text" />3 Habitaciones{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 js-scroll-show">

                    <div className="itemRoom itemRoom__extended">
                        <figure className="itemRoom__img-wrap">
                            <a className="itemRoom__link" href="/villas-tenerife/casa-almendra">
                                {" "}
                                <img
                                    className="cover lazyload"
                                    src="/assets/images/apartments/28/slider/arico-casa_almendra_84654361-66C9-1758-063E-92653CFF41D3-0.jpg"
                                    alt="room"
                                />{" "}
                            </a>
                            <div className="itemRoom__details">
                                <h3 className="title title--h3">Casa Almendra</h3>
                                <div className="itemRoom__price">
                                    <span>Desde:</span> 142.00€
                                </div>
                            </div>
                        </figure>
                        <div className="itemRoom__details-extended">
                            <div className="row">
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-users black-text" />8 Huéspedes{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mr-4 item-extended black-text">
                                        {" "}
                                        <i className="icon-maximize black-text" />
                                        150m2
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bath black-text" />2 Baños{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bed black-text" />4 Habitaciones{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 js-scroll-show">

                    <div className="itemRoom itemRoom__extended">
                        <figure className="itemRoom__img-wrap">
                            <a className="itemRoom__link" href="/villas-tenerife/casa-platano">
                                {" "}
                                <img
                                    className="cover lazyload"
                                    src="/assets/images/apartments/4/slider/porís_de_abona-casa_platano_FF566595-ECF9-839A-F67F-F0F3FE8DC0ED-0.jpg"
                                    alt="room"
                                />{" "}
                            </a>
                            <div className="itemRoom__details">
                                <h3 className="title title--h3">Casa Platano</h3>
                                <div className="itemRoom__price">
                                    <span>Desde:</span> 164.50€
                                </div>
                            </div>
                        </figure>
                        <div className="itemRoom__details-extended">
                            <div className="row">
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-users black-text" />8 Huéspedes{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mr-4 item-extended black-text">
                                        {" "}
                                        <i className="icon-maximize black-text" />
                                        120m2
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bath black-text" />2 Baños{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bed black-text" />4 Habitaciones{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 js-scroll-show">

                    <div className="itemRoom itemRoom__extended">
                        <figure className="itemRoom__img-wrap">
                            <a className="itemRoom__link" href="/villas-tenerife/casa-melocoton">
                                {" "}
                                <img
                                    className="cover lazyload"
                                    src="/assets/images/apartments/34/slider/la_listada-casa_melocoton_82350275-8570-1AE5-A474-650C06E7A02E-0.jpg"
                                    alt="room"
                                />{" "}
                            </a>
                            <div className="itemRoom__details">
                                <h3 className="title title--h3">Casa Melocoton</h3>
                                <div className="itemRoom__price">
                                    <span>Desde:</span> 142.00€
                                </div>
                            </div>
                        </figure>
                        <div className="itemRoom__details-extended">
                            <div className="row">
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-users black-text" />8 Huéspedes{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mr-4 item-extended black-text">
                                        {" "}
                                        <i className="icon-maximize black-text" />
                                        120m2
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bath black-text" />3 Baños{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bed black-text" />4 Habitaciones{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 js-scroll-show">

                    <div className="itemRoom itemRoom__extended">
                        <figure className="itemRoom__img-wrap">
                            <a className="itemRoom__link" href="/villas-tenerife/casa-limon">
                                {" "}
                                <img
                                    className="cover lazyload"
                                    src="/assets/images/apartments/10/slider/porís_de_abona-casa_limon_80B7DFBB-6A03-3E87-5641-9BE73ED6D16B-33.jpg"
                                    alt="room"
                                />{" "}
                            </a>
                            <div className="itemRoom__details">
                                <h3 className="title title--h3">Casa Limon</h3>
                                <div className="itemRoom__price">
                                    <span>Desde:</span> 71.40€
                                </div>
                            </div>
                        </figure>
                        <div className="itemRoom__details-extended">
                            <div className="row">
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-users black-text" />6 Huéspedes{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mr-4 item-extended black-text">
                                        {" "}
                                        <i className="icon-maximize black-text" />
                                        90m2
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bath black-text" />1 Baño{" "}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="item-extended black-text">
                                        {" "}
                                        <i className="icon-bed black-text" />2 Habitaciones{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                */}
            </div>
        </section>

    );
};
export default NuestrasVillas;
