import { React } from "react";
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import "./../../../../../css/magno/legal.css";



const TextosLegal = ({ ruta, legal, cookies }) => {

    const { t } = useTranslation();

    return (
        <>
            <section className="elementor-section" style={{ background: "url('/images/web/magno/cabecera-politica.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center', height: "300px" }}>
                <div className="elementor-background-overlay"></div>
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5b48ef6" data-id="5b48ef6" data-element_type="column">
                        <div className="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <div className="container" style={{ margin: "auto", padding: "40px" }}>
                <h2 className="textolegal" style={{ fontSize: "45px", fontFamily: " 'Lato',sans-serif", lineHeight: "initial", margin: "10px 0px 30px 0", fontWeight: "300" }}>{t("Aviso Legal")}</h2>

                <div className="textolegal" style={{ marginBottom: "30px" }}>
                    {ReactHtmlParser(legal.ladesc)}
                </div>

                <hr className="colorcorporativo" style={{ borderTop: "2px solid", marginLeft: "38px", marginTop: "40px" }} />

                <h2 className="textolegal" style={{ fontSize: "45px", fontFamily: "'Lato',sans-serif", lineHeight: "initial", margin: "30px 0px", fontWeight: "300" }}>{t("Privacidad, Política de Cookies")}</h2>

                <div className="textolegal" style={{ marginBottom: "30px" }}>
                    {ReactHtmlParser(cookies.ladesc)}
                </div>

            </div>
        </>
    );
}

export default TextosLegal;
