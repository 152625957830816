import { React } from "react";
import { Col, Nav, Row, Tab, Accordion } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TextosFaqs = ({categorias, faqs, config, lang, webconfig}) => {

    const { t } = useTranslation();

    return (
            <div className="container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <Row>
                        <h2 className="mb-4 text-center">{ t("Hola, ¿cómo podemos ayudarle?") }</h2>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column pillsfaqs shadow-lg rounded">
                                {categorias.map((categoria, index) => (
                                    <Nav.Item key={"cat"+index}>
                                        <Nav.Link eventKey={index+1}>{categoria.nombre[lang]}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={9} className="mt-2">
                            <Tab.Content>
                                {categorias.map((categoria, index) => (
                                    <Tab.Pane eventKey={index+1} key={"cf"+index}>
                                        <Accordion>
                                            {faqs.filter(faq => faq.categoria_id == categoria.id).map((faq, index2) => (
                                                <Accordion.Item eventKey={index+""+index2} key={"f"+index2}>
                                                    <Accordion.Header >{faq.pregunta[lang]}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div dangerouslySetInnerHTML={{__html: faq.respuesta[lang]}}></div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <div className="linia mt-5"></div>
                <div className="row noticia-individual-descripcio-llarga mb-5">
                    <h3 className="text-center mt-5"> { t("¿No encuentra lo que busca? Aquí tiene algunas opciones") }</h3>

                    <div className="featured-box shadow-lg">
                        <div className="icon bg-primary">
                            <i className="fa fa-comments"></i>
                        </div>
                        <div className="content">
                            <h4 className="uppercase">{ t("Contacto") }</h4>
                            <p>
                                { config.nombre_comercial }<br/>
                                { config.direccion_fiscal }<br/>
                                { config.email_fiscal }<br/>
                                { config.telefono_fiscal }
                            </p>
                        </div>
                    </div>
                </div>

            </div>
    );
}

export default TextosFaqs;
