import React from "react";
import { Container } from "react-bootstrap";
var imagePath = "../../../../../img/holidayhomescanarias/casa.jpg";
import { useTranslation } from 'react-i18next';

const DescubreTnf = ({ lang, noticias, ruta }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section mb80">
            <div className="row align-items-end">
                <div className="col-12 col-md-12 col-lg-9">
                    <span className="title title--overhead js-lines">{t("Descubre Tenerife")}</span>
                    <h1 className="title title--h1 js-lines">
                        {t("Vive una experiencia única en Tenerife")}.
                    </h1>
                </div>
                <div className="col-12 col-md-12 col-lg-3 text-lg-right d-none d-md-block">
                    <a className="btn-link header-btn-more black-text" href={preidioma + t("blog")}>
                        {t("Ver todas las experiencias")}
                    </a>
                </div>
            </div>
            <div className="row">
                {noticias.map((noticia) => (
                    <div className="col-lg-4 col-md-4 wow fadeInLeft delay-04s" key={noticia.id}>
                        <div className="blog-2">
                            <div className="blog-photo">
                                {
                                    noticia.imagen != ""
                                        ? <img className="img-fluid" src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen} />
                                        : <img className="img-fluid" src="/images/web/bedloop-nofoto.png" />
                                }
                            </div>
                            <div className="detail">
                                <span className="article-tag">
                                    <a href="#">{noticia.tags.toUpperCase()}</a>
                                </span>
                                <p className="blog-title">
                                    <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl}>{noticia.eltitulo}</a>
                                </p>
                                <div className="post-meta">
                                    <span>
                                        <a href="#">
                                            <i className="fa fa-user" />
                                        </a>
                                        <p dangerouslySetInnerHTML={{ __html: noticia.ladesc }}></p>
                                    </span>
                                </div>
                                <p className="read-more-blog">
                                    <a href={"/" + preidioma + t("blog") + "/" + noticia.laurl}>{t("Leer más")}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div className="col-lg-4 col-md-4 wow fadeInLeft delay-04s">
                    <div className="blog-2">
                        <div className="blog-photo">
                            <img loading="lazy" src={imagePath} alt="blog" className="img-fluid" />
                        </div>
                        <div className="detail">
                            <span className="article-tag">
                                <a href="#">CULTURA</a>
                            </span>
                            <p className="blog-title">
                                <a href="/bloges/titulo'">Titulo</a>
                            </p>
                            <div className="post-meta">
                                <span>
                                    <a href="#">
                                        <i className="fa fa-user" />
                                    </a>
                                    <p>
                                        &nbsp;Descripcion corta&nbsp; Descripcion corta&nbsp;
                                        Descripcion corta&nbsp; Descripcion corta&nbsp; Descripcion
                                        corta&nbsp; Descripcion corta&nbsp; Descripcion corta&nbsp;
                                    </p>
                                </span>
                            </div>
                            <p className="read-more-blog">
                                <a href="/bloges/titulo">Leer más</a>
                            </p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-4 wow fadeInLeft delay-04s">
                        <div className="blog-2">
                            <div className="blog-photo">
                                <img loading="lazy" src="../../../../../img/holidayhomescanarias/casa.jpg" alt="blog" className="img-fluid" />
                            </div>
                            <div className="detail">
                                <span className="article-tag">
                                    <a href="#">GASTRONOMÍA</a>
                                </span>
                                <p className="blog-title">
                                    <a href="/bloges/streik-bei-ryanair'">Streik bei Ryanair</a>
                                </p>
                                <div className="post-meta">
                                    <span>
                                        <a href="#">
                                            <i className="fa fa-user" />
                                        </a>
                                    </span>
                                </div>
                                <p className="read-more-blog">
                                    <a href="/bloges/streik-bei-ryanair">Leer más</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 wow fadeInLeft delay-04s">
                        <div className="blog-2">
                            <div className="blog-photo">
                                <img loading="lazy" src="../../../../../img/holidayhomescanarias/casa.jpg" alt="blog" className="img-fluid" />
                            </div>
                            <div className="detail">
                                <span className="article-tag">
                                    <a href="#">NATURALEZA</a>
                                </span>
                                <p className="blog-title">
                                    <a href="/bloges/autofahrer-aufgepaßt-enten-auf-der-straße'">
                                        Autofahrer aufgepaßt: Enten auf der Straße
                                    </a>
                                </p>
                                <div className="post-meta">
                                    <span>
                                        <a href="#">
                                            <i className="fa fa-user" />
                                        </a>
                                    </span>
                                </div>
                                <p className="read-more-blog">
                                    <a href="/bloges/autofahrer-aufgepaßt-enten-auf-der-straße">
                                        Leer más
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>*/}
            </div>
        </section>

    );
};
export default DescubreTnf;
