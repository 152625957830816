import { React } from "react";
import { useTranslation } from 'react-i18next';

const TextosCookies = ({ruta,cookies}) => {

	var imagen = "/images/web/bedloop-nofoto.png"; 

	if (cookies.imagen_cabecera)
	{
		imagen = ruta+"/"+cookies.imagen_cabecera;
	}

    const { t } = useTranslation();

    return (
        <>
             <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={t("Política de cookies")} style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center', backgroundSize: "cover"}} >
                        </div>
                        <h1 className="noticia-individual mt-5 mb-5">{ t("Política de cookies") }</h1>
                        <p className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: cookies.ladesc}}></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextosCookies;