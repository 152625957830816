import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Villas = ({ ruta, destacadas, lang }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    var results = [];

    return (
        <section className="container section">
            <div className="row align-items-end">
                <div className="col-12 col-md-12 col-lg-9">
                    <span
                        className="title title--overhead js-lines"
                        style={{ overflow: "inherit" }}
                    >
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Villas espléndidas y con carácter")}
                            </div>
                        </div>
                    </span>
                    <h1 className="title title--h1 js-lines" style={{ overflow: "inherit" }}>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Tu estancia en Tenerife")},
                            </div>
                        </div>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("mejor que en tu propia casa")}.
                            </div>
                        </div>
                    </h1>
                </div>
                <div className="col-12 col-md-12 col-lg-3 text-lg-right d-none d-md-block">
                    <a
                        className="btn-link header-btn-more black-text"
                        href={"/" + preidioma + t("villas-tenerife")}
                    >
                        {t("Ver todas las Villas")}
                    </a>
                </div>
            </div>
            {/* Grid */}
            <div className="row flexed-rooms">
                {destacadas.map((propiedad) => (
                    <div className="col-12 col-md-6 col-lg-4" key={propiedad.id}>
                        {/* ItemRoom */}
                        <div className="itemRoom">
                            <figure className="itemRoom__img-wrap">
                                <a className="itemRoom__link" href={"/" + preidioma + t("villas-tenerife") + "/" + propiedad.url_dinamica}>
                                    {
                                        propiedad.foto_principal.length != 0 ?
                                            <img
                                                className="cover"
                                                src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + propiedad.foto_principal[0].nombre}
                                                alt="room"
                                            />
                                            :
                                            <img
                                                className="cover"
                                                src="/images/web/bedloop-nofoto.png"
                                                alt="room"
                                            />
                                    }
                                </a>
                            </figure>
                            <div className="itemRoom__details">
                                <h4 className="title title--h4">{propiedad.nombre}</h4>
                                <button
                                    className={"intro__btn-play js-lines " + propiedad.nombre + "1"}
                                    id="play"
                                    style={{ overflow: "inherit" }}
                                >
                                    <span className="btn-play btn-play__popup" style={{}}>
                                        <i className="fa-solid fa-play" style={{ color: "black", paddingTop: "8px" }}></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
        </section>

    );
};
export default Villas;
