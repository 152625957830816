import { React} from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'


const Content = ({lang, datospropietario, elpropietario, ruta }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";
    
    
    const mostracontrasenya1 = () => {
        if(document.getElementById("lapassword").getAttribute('type') == "password"){
            document.getElementById("lapassword").setAttribute('type',"text");
        }
        else {
            document.getElementById("lapassword").setAttribute('type',"password");
        }
    };

    const mostracontrasenya2 = () => {
        if(document.getElementById("lapasswordrepe").getAttribute('type') == "password"){
            document.getElementById("lapasswordrepe").setAttribute('type',"text");
        }
        else {
            document.getElementById("lapasswordrepe").setAttribute('type',"password");
        }
    };

    const handleSubmit = (e) => {
        console.log("click envio")
        let token = document.getElementById('meta_token').getAttribute('content');

        let laweb = document.getElementById("laweb").value;
        let lapassword = document.getElementById("lapassword").value;
        let username = document.getElementById("username").value;
        let company = document.getElementById("company").value;
        let idasociado = document.getElementById("idasociado").value;
        let idpropietario = document.getElementById("idpropietario").value;

        const formData = new FormData();
        formData.append("web", laweb);
        formData.append("lapassword", lapassword);
        formData.append("username", username);
        formData.append("company", company);
        formData.append("idasociado", idasociado);
        formData.append("idpropietario", idpropietario);

        const result = axios.post('/modificadatos', formData,
        {
            headers: {
                'X-CSRF-TOKEN': token,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            document.getElementsByClassName("resultado")[0].innerHTML = t("Datos actualizados"); 
            location.reload();  
        });
    }

    // specify upload params and url for your files
    const getUploadParams = ({ file, meta }) => { 
        const body = new FormData()
        body.append('fileField', file);
        return { url: 'https://httpbin.org/post', body } 
    }
  
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {}
  
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmitFoto = (files, allFiles) => {

        const formData = new FormData();
        formData.append('file', files[0]['file']);

        let token = document.getElementById('meta_token').getAttribute('content');
        const result = axios.post('/uploadLogo/'+elpropietario.id, formData,
        {
            headers: {
                'X-CSRF-TOKEN': token,
                'Content-Type': 'multipart/form-data'
            }
        })
        allFiles.forEach(f => f.remove())
        location.reload();
    }

    return (
        <div id="Content">
            <div className="content_wrapper clearfix">
                <div className="sections_group">
                    <div className="entry-content">

                        
                        <div className="section" style={{paddingTop:"60px"}}>
                            <div className="section_wrapper clearfix">

                                {/* **** */}

                                <div className="entry_content">
                                    <div className="section mcb-section" style={{paddingTop:"60px", paddingBottom:"80px", backgroundColor:"#f9f9f9"}}>
                                        <div className="section_wrapper mcb-section-inner">
                                            <div className="wrap mcb-wrap one valign-top clearfix">
                                                <div className="mcb-wrap-inner">
                                                    <div className="column mcb-column one column_column">
                                                        <div className="column_attr clearfix">
                                                            <h3>{ t("Modifique sus datos")}</h3>
                                                            <hr className="no_line" style={{ margin:"0 auto 15px"}}/>
                                                            <div id="contactWrapper">
                                                                <form id="modificaForm">
                                                                    <input type="hidden" name="idasociado" id="idasociado" value={ datospropietario.id} />
                                                                    <input type="hidden" name="idpropietario"  id="idpropietario" value={ elpropietario.id} />

                                                                    {/* -- One Second (1/2) Column --*/}
                                                                    <div className="column one-second">
                                                                        <span>{ t("Empresa") }</span>
                                                                        <input placeholder={ t("Empresa") } type="text" name="company" id="company" size="100" aria-required="true" aria-invalid="false" defaultValue={ elpropietario.nombre }/>
                                                                    </div>
                                                                    { /*<!-- One Second (1/2) Column --*/}
                                                                    <div className="column one-second">
                                                                        <span>{ t("Usuario") } *</span>
                                                                        <input placeholder={ t("Usuario")+"*"} type="text" name="username" id="username" size="100" aria-required="true" aria-invalid="false" defaultValue={ datospropietario.username}/>
                                                                    </div>
                                                                    {/*-- One Second (1/2) Column --*/}
                                                                    <div className="column one-second">
                                                                        <span>{ t("Contraseña")} *</span>
                                                                        <input placeholder={ t("Contraseña")+"*"} type="password" name="lapassword" id="lapassword" size="40" aria-required="true" aria-invalid="false" defaultValue={ datospropietario.password }/>
                                                                        <i className="fa-light fa-eye verContrasenya pointer" style={{ top: "0", cursor:"pointer", left: "auto", right: "8px", float: "right"}} onClick={ () => mostracontrasenya1()}></i>
                                                                    </div>
                                                                    {/*-- One Second (1/2) Column --*/}
                                                                    <div className="column one-second">
                                                                        <span>{ t("Confirmar contraseña") } *</span>
                                                                        <input placeholder={ t("Confirmar contraseña")+" *"} type="password" name="lapasswordrepe" id="lapasswordrepe" size="40" aria-required="true" aria-invalid="false" defaultValue={ datospropietario.password } />
                                                                        <i className="fa-light fa-eye  verContrasenya pointer" style={{ top: "0", cursor:"pointer", left: "auto", right: "8px", float:"right"}} onClick={ () => mostracontrasenya2()}></i>
                                                                    </div>
                                                                    {/*-- One Second (1/2) Column --*/}
                                                                    <div className="column one-second">
                                                                        <span>{ t("Web") }</span>
                                                                        <input placeholder="Web" type="text" name="laweb" id="laweb" size="200" aria-invalid="false" defaultValue={ elpropietario.web }/>
                                                                    </div>
                                                                    <div className="column one-second">
                                                                        <span>{ t("Logo") }</span>
                                                                        {
                                                                            (elpropietario.logo) ? 
                                                                                <div className="logofiscal">
                                                                                    <img src ={ruta+"/propietarios/"+elpropietario.id+"/logo/"+elpropietario.logo} style = {{ width: "100px"}}/>
                                                                                    <input type="button" className = 'eliminarLogo' style ={{ marginLeft : "20px"}} value={ t("Eliminar logo")}/>
                                                                                </div>
                                                                            :
                                                                            <div className="logofiscal">
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </form>
                                                                <div className="column one">
                                                                    <Dropzone
                                                                        getUploadParams={getUploadParams}
                                                                        onChangeStatus={handleChangeStatus}
                                                                        onSubmit={handleSubmitFoto}
                                                                        accept="image/*"
                                                                        inputContent ={t("Arrastre archivos o presione para subirlos")}
                                                                        submitButtonContent = {t("Subir")}
                                                                        maxFiles = "1"
                                                                    />
                                                                </div>
                                                                <div className="column one">
                                                                    <input type="button" value={ t("Enviar")} id="submit" onClick={ handleSubmit}/>
                                                                </div>
                                                               
                                                                <div className="resultado"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              {/* **** */}

                            </div>
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;