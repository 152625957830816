import { React } from "react";

import { useTranslation } from 'react-i18next';

const DatosCliente = ({ lang, config }) => {
    const { t } = useTranslation();
    return (
        <section className="mt-5 mb-5">
            <div className="container bg-white shadow mt-5 mb-5 br15 col-sm-5">
                <div className="row noticia-individual noticia-individual-descripcio-llarga mx-auto col-sm-6">
                    <h2 className="noticia-individual mb-5 mt-5 border-bottom pb-3">{t("Actualiza tus datos")}</h2>
                    <form className="noticia-individual" id="contactForm" action="/guardarCliente">
                        <input type="hidden" name="lang" value={lang.toString().toUpperCase()} />
                        <input type="hidden" name="idusuario" value="" />
                        <input type="hidden" name="lang" value="" />
                        <div className="row">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Nombre")} name="nombreusuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Apellidos")} name="apellidosusuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Dirección")} name="direccionusuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Ciudad")} name="ciudadusuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Provincia")} name="provinciausuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Provincia")} name="provinciausuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Teléfono")} name="telefonousuario" />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Email")} name="email" readonly />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Documento")} name="docidentidad" readonly />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Nacionalidad")} name="nacionality" readonly />
                            </div>


                            <div className="pull-right mt-4 mb-5">
                                <input type="submit" className="btn btn-danger" value={t("Guardar")} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
export default DatosCliente;
