import Menu from './Compartido/Menu.jsx';
import TextosPagoResto from './PagoResto/TextosPagoResto.jsx';
import Footer from './Compartido/Footer.jsx';

import { useTranslation } from 'react-i18next';

const PagoResto = (props) => {
    
    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas = {props.idiomas}
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                ruta = {props.ruta}
                meta_titulo = {"Magno Apartments - "+t("Pago resto")}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />
            <TextosPagoResto 
                datoscliente = {props.datoscliente}
                datospropiedad = {props.datospropiedad}
                reserva = {props.reserva}
                totalesreserva = {props.totalesreserva}
                restoapagar = {props.restoapagar}
                config = {props.config}
                tpvs = {props.tpvs}
                tipopago = {props.tipopago}
                lang = {props.lang}
                ruta = {props.ruta}
            />
            <Footer
                 lang = {props.lang}
                 config = {props.websgrupo}
                 config2 = {props.config}
                 idiomas = {props.idiomas}
                 ruta = {props.ruta}
            />
        </main>
    );
}
export default PagoResto;