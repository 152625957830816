import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import axios from 'axios';

import { useTranslation } from 'react-i18next';
import { toUpper } from "lodash";

// $('.hamburger').on('click', function() {
//     $(this).toggleClass('is-active');
//     $('html').toggleClass('is-scroll-disabled');
// });



var isActive = false;
if (window.innerWidth < 1500 && window.scrollY > 0) {
    isActive = true;
}
const Header = ({ datos, lang, idiomas, tipo, meta_titulo, meta_descripcion, meta_keywords, ruta }) => {

    const { t } = useTranslation();

    const { i18n } = useTranslation();

    const handleLangSelect = (currentLang) => {

        i18n.changeLanguage(currentLang);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: currentLang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                console.log(response.data);
                console.log("cambiado voy a recargar");
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }

    function toggleHamburger() {
        document.querySelector('.hamburger').classList.toggle('is-active');
        document.querySelector('html').classList.toggle('is-scroll-disabled');

        // added
        document.querySelector('.nav').classList.toggle('nav-add-menu')
        document.querySelectorAll('.lineaButton').forEach(function (linea) {
            linea.classList.toggle('bg-black');
        });
        document.querySelectorAll('.last_item').forEach(function (item) {
            item.classList.toggle('nav-add-menu');
        });
        document.querySelectorAll('.nav__link').forEach(function (navLink) {
            navLink.classList.toggle('black-text');
        });
    }

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    if (tipo !== "inicio") isActive = true;

    const resultsmobile = idiomas.filter((unidioma) => unidioma.codigo !== lang).map((unidioma, index) => (
        <a
            className="mobile-nav-rrss"
            key={"im" + index}
            onClick={() => handleLangSelect(unidioma.codigo)}
            href="#"
        >
            {unidioma.codigo.toUpperCase()}
        </a>
    ));

    const resultspc = idiomas.filter((unidioma) => unidioma.codigo !== lang).map((unidioma, index) => (
        <a
            className={`${isActive ? 'black-text' : 'white-text'}`}
            key={"ip" + index}
            onClick={() => handleLangSelect(unidioma.codigo)}
            href="#"
            style={{ marginRight: 10 }}
        >
            {unidioma.codigo.toUpperCase()}
        </a>
    ));

    const resultspcblack = idiomas.filter((unidioma) => unidioma.codigo !== lang).map((unidioma, index) => (
        <a
            className='black-text'
            key={"ip" + index}
            onClick={() => handleLangSelect(unidioma.codigo)}
            href="#"
            style={{ marginRight: 10 }}
        >
            {unidioma.codigo.toUpperCase()}
        </a>
    ));



    useEffect(() => {
        document.title = meta_titulo
        let titleMeta = document.querySelector("meta[name='title']")
        titleMeta.setAttribute("content", meta_titulo)

        let descMeta = document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", meta_descripcion)

        let keyMeta = document.querySelector("meta[name='keywords']")
        keyMeta.setAttribute("content", meta_keywords)

        if (datos.id === 1) {
            let favicon = ruta + "/web/favicon/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }
        else {
            let favicon = ruta + "/web/favicon/" + datos.id + "/favicon.ico"
            let fav = document.querySelector("link[rel='icon']")
            fav.setAttribute("href", favicon)
        }

        let favicon = ruta + "/web/favicon/favicon.ico"
        let fav = document.querySelector("link[rel='icon']")
        fav.setAttribute("href", favicon)

        if (datos.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = datos.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

        i18n.changeLanguage(lang);

    }, []);

    return (
        <>
            <header className="header">
                <nav className="navbar navbar-white navbar-overlay">
                    <a className="logo-link" href="/">

                        {datos.logo_fiscal != "" ?
                            <img className="logotype" alt="Holiday Homes Canarias" src={ruta + "/web/logo/" + datos.logo_fiscal} />
                            :
                            <img className="logotype" alt="Holiday Homes Canarias" src="https://www.bedloop.com/imatges/logo.webp" />
                        }
                    </a>
                    <div className="navbar__menu">
                        <button className="hamburger" type="button" onClick={toggleHamburger}>
                            <span className="lineaButton" />
                            <span className="lineaButton" />
                        </button>
                        <ul className="nav">
                            <li
                                className="nav__item _is-current"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                <a className={`nav__link ${isActive ? 'black-text' : 'white-text'}`} href={"/" + preidioma + t("villas-tenerife")}>
                                    <span data-hover={t("Nuestras villas")}>{t("Nuestras villas")}</span>
                                </a>
                            </li>
                            <li
                                className="nav__item"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                <a className={`nav__link ${isActive ? 'black-text' : 'white-text'}`} href={"/" + preidioma + t("blog")}>
                                    <span data-hover={t("Descubre Tenerife")}>{t("Descubre Tenerife")}</span>
                                </a>
                            </li>
                            <li
                                className="nav__item"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                <a className={`nav__link ${isActive ? 'black-text' : 'white-text'}`} href={"/" + preidioma + t("experiencias")}>
                                    <span data-hover={t("Experiencias")}>{t("Experiencias")}</span>
                                </a>
                            </li>
                            <li
                                className="nav__item"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                <a className={`nav__link ${isActive ? 'black-text' : 'white-text'}`} href={"/" + preidioma + t("recomendaciones")}>
                                    <span data-hover={t("Recomendaciones")}>{t("Recomendaciones")}</span>
                                </a>
                            </li>
                            <li
                                className="nav__item"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                <a className={`nav__link ${isActive ? 'black-text' : 'white-text'}`} href={"/" + preidioma + t("sobre-nosotros")}>
                                    <span data-hover={t("Sobre Nosotros")}>{t("Sobre Nosotros")}</span>
                                </a>
                            </li>
                            <li
                                className="nav__item last_item"
                                style={{ opacity: "1", transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                <a className="btn btn__medium black-text" href={preidioma + t("buscar")}>
                                    <i className="btn-icon-left icon-bookmark" />
                                    {t("¡Reserva ya!")}
                                </a>
                            </li>
                            <li
                                className="nav__item last_item"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >
                                {datos.url_facebook && datos.url_facebook != "" ?
                                    <a
                                        className="mobile-nav-rrss"
                                        href="https://www.facebook.com/HolidayHomesCanarias"
                                    >
                                        <img src="/images/web/hhc/facebook.png" />
                                    </a>
                                    :
                                    ""
                                }
                                {datos.url_instagram && datos.url_instagram != "" ?
                                    <a
                                        className="mobile-nav-rrss"
                                        href="https://www.instagram.com/holidayhomescanarias/"
                                    >
                                        <img src="/images/web/hhc/instagram.png" />
                                    </a>
                                    :
                                    ""
                                }
                                <a
                                    className="mobile-nav-rrss"
                                    href="https://www.youtube.com/channel/UCknzY3LyNvGHQCffNIzgEVw"
                                >
                                    <img src="/images/web/hhc/youtube.png" />
                                </a>
                                <a className="mobile-nav-rrss" href="https://wa.me/+34689302854">
                                    <img src="/images/web/hhc/whatsapp-bubble.png" />
                                </a>
                            </li>
                            <li
                                className="nav__item last_item"
                                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                            >

                                {resultsmobile}

                            </li>
                        </ul>
                    </div>
                    <div className="navbar__btn">
                        {resultspc}

                        <a className="btn btn__medium black-text" href={"/" + preidioma + t("buscar")}>
                            {t("¡Reserva ya!")}
                        </a>
                    </div>
                </nav>
            </header>

            <header className="hidden header sticky-header">
                <nav className="navbar">
                    <a className="logo-link" href="/">
                        {datos.logo_fiscal != "" ?
                            <img className="logotype" alt="Holiday Homes Canarias" src={ruta + "/web/logo/" + datos.logo_fiscal} />
                            :
                            <img className="logotype" alt="Holiday Homes Canarias" src="https://www.bedloop.com/imatges/logo.webp" />
                        }
                    </a>
                    <div className="navbar__menu">
                        <button className="hamburger" type="button">
                            <span></span>
                            <span></span>
                        </button>
                        <ul className="nav">
                            <li
                                className="nav__item _is-current"
                            >
                                <a className="nav__link black-text" href={"/" + preidioma + t("villas-tenerife")}>
                                    <span data-hover={t("Nuestras villas")}>{t("Nuestras villas")}</span>
                                </a>
                            </li>

                            <li
                                className="nav__item"
                            >
                                <a className="nav__link black-text" href={"/" + preidioma + t("blog")}>
                                    <span data-hover={t("Descubre Tenerife")}>{t("Descubre Tenerife")}</span>
                                </a>
                            </li>

                            <li
                                className="nav__item"
                            >
                                <a className="nav__link black-text" href={"/" + preidioma + t("experiencias")}>
                                    <span data-hover={t("Experiencias")}>{t("Experiencias")}</span>
                                </a>
                            </li>

                            <li
                                className="nav__item"
                            >
                                <a className="nav__link black-text" href={"/" + preidioma + t("recomendaciones")}>
                                    <span data-hover={t("Recomendaciones")}>{t("Recomendaciones")}</span>
                                </a>
                            </li>

                            <li
                                className="nav__item"
                            >
                                <a className="nav__link black-text" href={"/" + preidioma + t("sobre-nosotros")}>
                                    <span data-hover={t("Sobre Nosotros")}>{t("Sobre Nosotros")}</span>
                                </a>
                            </li>

                            <li
                                className="nav__item"
                            >
                                <a className="btn btn__medium black-text" href={preidioma + t("buscar")}>
                                    <i className="btn-icon-left icon-bookmark" />
                                    {t("¡Reserva ya!")}
                                </a>
                            </li>

                            <li
                                className="nav__item last_item"
                            >
                                {datos.url_facebook && datos.url_facebook != "" ?
                                    <a
                                        className="mobile-nav-rrss"
                                        href="https://www.facebook.com/HolidayHomesCanarias"
                                    >
                                        <img src="/images/web/hhc/facebook.png" />
                                    </a>
                                    :
                                    ""
                                }
                                {datos.url_instagram && datos.url_instagram != "" ?
                                    <a
                                        className="mobile-nav-rrss"
                                        href="https://www.instagram.com/holidayhomescanarias/"
                                    >
                                        <img src="/images/web/hhc/instagram.png" />
                                    </a>
                                    :
                                    ""
                                }
                                <a
                                    className="mobile-nav-rrss"
                                    href="https://www.youtube.com/channel/UCknzY3LyNvGHQCffNIzgEVw"
                                >
                                    <img src="/images/web/hhc/youtube.png" />
                                </a>
                                <a className="mobile-nav-rrss" href="https://wa.me/+34689302854">
                                    <img src="/images/web/hhc/whatsapp-bubble.png" />
                                </a>
                            </li>

                            <li
                                className="nav__item l"
                            >

                                {resultsmobile}

                            </li>
                        </ul>
                    </div>

                    <div className="navbar__btn">
                        {resultspcblack}

                        <a className="btn btn__medium black-text" href={"/" + preidioma + t("buscar")}>
                            {t("¡Reserva ya!")}
                        </a>
                    </div>
                </nav>
            </header>
        </>
    );
};
export default Header;
