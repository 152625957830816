import { React, useState} from "react";
import { useTranslation } from 'react-i18next';

const Content = ({lang, newsletters, contadores}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if(show=='none') setShow('block');
        else setShow('none');
    };

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <div className="section section-filters">
                            <div className="clearfix section_wrapper">
                                <div id="Filters" className="column one">
                                    <ul className="filters_buttons">
                                        <li className="label">
                                            { t("Filtros de búsqueda") }
                                        </li>
                                        <li className="anyos">
                                            <a className="open" onClick={handleShow}><i className="fa-solid fa-page"></i>{ t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                        </li>
                                        <li className="reset">
                                            <a className="close" data-rel="*" href={"/"+preidioma+t("newsletters")+"/"+t("total") }><i className="fa-solid fa-xmark"></i>{ t("Ver todos") }</a>
                                        </li>
                                    </ul>
                                    <div className="filters_wrapper" style={{ display: show }}>
                                        <ul className="anyos" style={{ display: show }}>
                                            <li className="reset-inner">
                                                <a data-rel="*" href={"/"+preidioma+t("newsletters")+"/"+t("total") }>{ t("Ver todos") }</a>
                                            </li>
                                            {
                                                contadores.map((uncontador, index) => (
                                                    <li key={index}>
                                                        <a data-rel={"."+uncontador.anyo} href={"/"+preidioma+t("newsletters")+"/anyo/"+uncontador.anyo}>{uncontador.anyo}({uncontador.total})</a>
                                                    </li>
                                                ))
                                            }
                                            <li className="close">
                                                <a onClick={handleShow} className="curpointer"><i className="fa-solid fa-xmark"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{paddingTop:"60px"}}>
                            <div className="clearfix section_wrapper">

                               {/* **** NEWSLETTERS ***** */}
                               <div className="sections_group normas">
                                    <div className="section">
                                        <div className="clearfix section_wrapper">

                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C"}}>{t("NEWSLETTERS")}</h6>
                                                <hr className="no_line" style={{margin: "0 auto 15px auto"}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="column one column_blog">
                                                    {
                                                        newsletters.map((unnewsletter, index) =>(
                                                            <div className="column mcb-column one-third column_column minh-200" style={{borderBottom: "1px solid  #E87C0C"}}  key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/apartur/corporation2-relations-pic2.png" width="30" height="36" className="imgnewsletters"/>
                                                                    {unnewsletter.nombre}
                                                                </h3>
                                                                <h6 style={{fontWeight:"400"}}> {new Date(unnewsletter.created_at).toLocaleDateString()}</h6>
                                                                <div style={{ width:"100%", paddingBottom: "3px", display: "inline-block", marginBottom:"20px", lineHeight:"21px"}} className="fs-13" dangerouslySetInnerHTML={{__html: unnewsletter.respuesta}}>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ****FIN NEWSLETTERS ***** */}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
