import { useTranslation } from 'react-i18next';

const Team = () => {
    const { t } = useTranslation();

    return (
        <div className="section mcb-section no-margin-h positionContrast">
            <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one-sixth column_placeholder">
                            <div className="placeholder"></div>
                        </div>
                        
                        <div className="column mcb-column two-third column_column">
                            <div className="column_attr clearfix align_center">
                                <h6 className="cityhall-heading">{t("Quiénes somos en Apartur")}</h6>
                                
                                <div className="column_attr clearfix align_center p08">
                                    <h6 className="landing4-heading grey positionContainer">{t("NUESTRO EQUIPO")}</h6>
                                    <hr className="no_line margin15Auto" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper border-radius foto-equip">
                                    <img className="scale-with-grid" src="/images/apartur/team/persona1.jpg" width="135" height="203"/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_column column-margin-20px">
                            <div className="column_attr clearfix">
                                <h3 className="centratexto equip">Judith Gómez de la Lastra</h3>
                                <p className="alignCenter">{t("Gerente")}</p>                                                                    
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                                <div className="image_wrapper">
                                    <a href="#">
                                        <div className="mask"></div>
                                    </a>
                                    
                                    <div className="image_links">
                                        <a href="#" className="link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                                
                <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper border-radius foto-equip">
                                    <img className="scale-with-grid" src="/images/apartur/team/persona2.jpg" width="135" height="203"/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_column column-margin-20px">
                            <div className="column_attr clearfix">
                                <h3 className="centratexto equip">Silvia Pastor</h3>
                                <p className="alignCenter">{t("Relaciones institucionales")}</p>                                                                    
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                                <div className="image_wrapper">
                                    <a href="#">
                                        <div className="mask"></div>
                                    </a>
                                    
                                    <div className="image_links">
                                        <a href="#" className="link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                                
                <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper border-radius foto-equip">
                                    <img className="scale-with-grid" src="/images/apartur/team/persona3.jpg" width="135" height="203"/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_column column-margin-20px">
                            <div className="column_attr clearfix">
                                <h3 className="centratexto equip">Mercè Moreno</h3>
                                <p className="alignCenter">{t("Departamento Jurídico")}</p>                                                                    
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                                <div className="image_wrapper">
                                    <a href="#">
                                        <div className="mask"></div>
                                    </a>
                                    
                                    <div className="image_links">
                                        <a href="#" className="link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                                
                <div className="wrap mcb-wrap one-fourth valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper border-radius foto-equip">
                                    <img className="scale-with-grid" src="/images/apartur/team/persona4.jpg" width="135" height="203"/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_column column-margin-20px">
                            <div className="column_attr clearfix">
                                <h3 className="centratexto equip">Sandra García</h3>
                                <p className="alignCenter">{t("Departamento de Comunicación")}</p>
                            </div>
                        </div>

                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item scale-with-grid no_border hover-disable">
                                <div className="image_wrapper">
                                    <a href="#">
                                        <div className="mask"></div>
                                    </a>
                                    
                                    <div className="image_links">
                                        <a href="#" className="link">
                                            <i className="icon-link"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Team;