import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextoPropiedads = ({propiedades, config, ruta}) => {
	
	const { t } = useTranslation();

	var imagen = "/images/web/bedloop-nofoto.png"; 

	if (propiedades.imagen_cabecera)
	{
		imagen = ruta+"/"+propiedades.imagen_cabecera;
	}

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={t("Propiedades")} style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center', backgroundSize: "cover"}} >
                        </div>
                        <h1 className="noticia-individual mt-5 mb-5">{ t("Propiedades") }</h1>
                        <p className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: propiedades.ladesc}}></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextoPropiedads;