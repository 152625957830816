import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import BtnFloating from './Compartido/BtnFloating.jsx';
import Buscador from './Buscar/Buscador.jsx';
import NuestrasVillas from './Buscar/NuestrasVillas.jsx';
import Scripts from './Compartido/Scripts.jsx';

import '../../../../../resources/css/web/holidayhomescanarias/header.css'
import '../../../../../resources/css/web/holidayhomescanarias/buscador.css'
import '../../../../../resources/css/web/holidayhomescanarias/footer.css'
import '../../../../../resources/css/web/holidayhomescanarias/general.css'
import '../../../../../resources/css/web/holidayhomescanarias/nuestrasvillas.css'
import '../../../../../resources/css/web/holidayhomescanarias/rooms.css'
import '../../../../../resources/css/web/holidayhomescanarias/buttons.css'

const Buscar = (props) => {
    return (
        <main>
            <Header
                datos={props.websgrupo}
                lang={props.lang}
                idiomas={props.idiomas}
                tipo="buscar"
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
            />
            <Buscador
                lang={props.lang}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                mascotas={props.mascotas}
                llegada={props.llegada}
                salida={props.salida}
                capacidad_maxima={props.capacidad_maxima}
            />
            <NuestrasVillas
                lang={props.lang}
                ruta={props.ruta}
                propiedades={props.propiedades}
                moneda={props.moneda}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                mascotas={props.mascotas}
                llegada={props.llegada}
                salida={props.salida}
            />
            <Footer
                lang={props.lang}
                config={props.config}
            />
            <BtnFloating />

            <Scripts />
        </main>
    );
}

export default Buscar;

