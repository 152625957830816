import Menu from './Compartido/Menu.jsx';
import TextoNoticia from './FichaNoticia/TextoNoticia.jsx';
import Footer from './Compartido/Footer.jsx';

const FichaNoticia = (props) => {
    return (
        <main>
            <Menu
                idiomas = {props.idiomas}
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                ruta = {props.ruta}
                meta_titulo = {"Magno Apartments - "+ props.lanoticia.eltitulo}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />
            <TextoNoticia 
                noticia = { props.lanoticia}
                populares = {props.populares}
                ruta = {props.ruta}
                lang = {props.lang}
            />
            <Footer
               lang = {props.lang}
               config = {props.websgrupo}
               config2 = {props.config}
               idiomas = {props.idiomas}
               ruta = {props.ruta}
            />
        </main>
    );
}

export default FichaNoticia;