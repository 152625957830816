import { React } from "react";

import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Carousel } from 'react-bootstrap';

import logo from "/images/magno/LOGO-MAGNO.png";

import "./../../../../../css/magno/buscador.css";

const ListaPropiedades = ({ ruta, propiedades, config, lang, preidioma, llegada, salida, adultos, ninos, bebes, moneda, totalapartamentos, pagina_actual, palabra }) => {

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const { t } = useTranslation();

    let results = [];
    let caracs = [];
    let contador = 0;

    let paginacion = [];
    let tamanyo_pagina = 1;
    let color_boton = "";

    let enlacenombre = [];
    let botonreserva = [];

    let lasfotos = [];

    var paginas = Math.floor(totalapartamentos / 12);

    if (totalapartamentos % 12 != 0) paginas++;

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    if (paginas > 1) {
        var actual = pagina_actual;

        if (paginas <= 4) {
            tamanyo_pagina = 1;
            for (let i = 0; i < paginas; i++) {
                if (i == actual) {
                    color_boton = "#000000";
                }
                else {
                    color_boton = "#c19b77";
                }
                paginacion = paginacion.concat(
                    <div className={"col-1 col-md-" + tamanyo_pagina} data-pag={"pag" + i} key={"pag-" + i}>
                        <div className="vc_column-inner vc_custom_1676977697036">
                            <div className="wpb_wrapper">
                                <div className=" vc_custom_1676977959985 ubtn-ctn-center" style={{ marginTop: "5px", marginRight: "15px", marginBottom: "0px", marginLeft: "0px", paddingTop: "5px", paddingRight: "15px", paddingBottom: "0px", paddingLeft: "0px" }}>
                                    <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small pasapagina" data-pagina={i} onClick={() => handleChangePagina(i)}>
                                        <button type="button" id={"ubtn-" + (i + 1)} className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ulta-shrink ubtn-center tooltip-641048fba662f" data-hover="" data-border-color="" data-bg={color_boton} data-hover-bg="#000000" data-border-hover="" data-shadow-hover="" data-shadow-click="none" data-shadow="" data-shd-shadow="" data-ultimate-target={"#ubtn-" + (i + 1)} style={{ fontWeight: "normal", border: "none", backgroundColor: "" + color_boton + "", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit" }}>
                                            <span className="ubtn-hover"></span>
                                            <span className="ubtn-data ubtn-text ">{i + 1}</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        else {
            tamanyo_pagina = 1
            var last = paginas;
            var start = ((Number(actual) - 1) > 0) ? Number(actual) - 1 : 1;
            var end = ((Number(actual) + 2) < last) ? Number(actual) + 2 : last;

            if (start == 2) {
                color_boton = "#c19b77";
                paginacion = paginacion.concat(
                    <div className={"col-1 col-md-" + tamanyo_pagina + " pag" + start} data-pag={"pag" + start} key={"pag2" + start}>
                        <div className="vc_column-inner vc_custom_1676977697036">
                            <div className="wpb_wrapper">
                                <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                    <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small pasapagina" data-pagina="0" onClick={() => handleChangePagina(0)}>
                                        <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ulta-shrink ubtn-center tooltip-641048fba662f" data-hover="" data-border-color="" data-bg={color_boton} data-hover-bg="#000000" data-border-hover="" data-shadow-hover="" data-shadow-click="none" data-shadow="" data-shd-shadow="" data-ultimate-target="#ubtn-1" style={{ fontWeight: "normal", border: "none", background: "" + color_boton + "", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit" }}>
                                            <span className="ubtn-hover"></span>
                                            <span className="ubtn-data ubtn-text ">1</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if (start > 2) {
                color_boton = "#c19b77";
                paginacion = paginacion.concat(
                    <>
                        <div className={"col-1 col-md-" + tamanyo_pagina + " pag0"} data-pag={"pag0"} key={"pag2-0"}>
                            <div className="vc_column-inner vc_custom_1676977697036">
                                <div className="wpb_wrapper">
                                    <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                        <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small pasapagina " data-pagina="0" onClick={() => handleChangePagina(0)}>
                                            <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ulta-shrink ubtn-center tooltip-641048fba662f" data-hover="" data-border-color="" data-bg={color_boton} data-hover-bg="#000000" data-border-hover="" data-shadow-hover="" data-shadow-click="none" data-shadow="" data-shd-shadow="" data-ultimate-target="#ubtn-1" style={{ fontWeight: "normal", border: "none", background: "" + color_boton + "", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit" }}>
                                                <span className="ubtn-hover"></span>
                                                <span className="ubtn-data ubtn-text ">1</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-1 col-md-" + tamanyo_pagina + " pag1"} data-pag={"pag1"} key={"pag2-1"}>
                            <div className="vc_column-inner vc_custom_1676977697036">
                                <div className="wpb_wrapper">
                                    <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                        <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small">
                                            <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ubtn-center tooltip-641048fba662f" disabled style={{ fontWeight: "normal", border: "none", backgroundColor: "#c19b77", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit", cursor: "none", paddingBottom: "7px" }}>
                                                <span className="ubtn-hover"></span>
                                                <span className="ubtn-data ubtn-text ">...</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            for (let i = start; i <= end; i++) {
                if ((i - 1) == actual)
                    color_boton = "#000000";
                else
                    color_boton = "#c19b77";

                paginacion = paginacion.concat(
                    <div className={"col-1 col-md-" + tamanyo_pagina + " pag" + (i - 1)} data-pag={"pag" + (i - 1)} key={"pag3-" + (i - 1)}>
                        <div className="vc_column-inner vc_custom_1676977697036">
                            <div className="wpb_wrapper">
                                <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                    <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small pasapagina" data-pagina={i - 1} onClick={() => handleChangePagina(i - 1)}>
                                        <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ulta-shrink ubtn-center tooltip-641048fba662f" data-hover="" data-border-color="" data-bg={color_boton} data-hover-bg="#000000" data-border-hover="" data-shadow-hover="" data-shadow-click="none" data-shadow="" data-shd-shadow="" data-ultimate-target="#ubtn-1" style={{ fontWeight: "normal", border: "none", backgroundColor: "" + color_boton + "", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit" }}>
                                            <span className="ubtn-hover"></span>
                                            <span className="ubtn-data ubtn-text ">{i}</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            if (end == (last - 1)) {
                if (last == actual) {
                    color_boton = "#000000";
                }
                else {
                    color_boton = "#c19b77";
                }

                paginacion = paginacion.concat(
                    <div className={"col-1 col-md-" + tamanyo_pagina + " pag" + (last - 1)} data-pag={"pag" + (last - 1)} key={"pag4-" + (last - 1)}>
                        <div className="vc_column-inner vc_custom_1676977697036">
                            <div className="wpb_wrapper">
                                <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                    <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small pasapagina" data-pagina={last - 1} onClick={() => handleChangePagina(last - 1)}>
                                        <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ulta-shrink ubtn-center tooltip-641048fba662f" data-hover="" data-border-color="" data-bg={color_boton} data-hover-bg="#000000" data-border-hover="" data-shadow-hover="" data-shadow-click="none" data-shadow="" data-shd-shadow="" data-ultimate-target="#ubtn-1" style={{ fontWeight: "normal", border: "none", backgroundColor: "" + color_boton + "", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit" }}>
                                            <span className="ubtn-hover"></span>
                                            <span className="ubtn-data ubtn-text ">{last}</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if (end < last) {
                if ((last - 1) == actual)
                    color_boton = "#000000";
                else
                    color_boton = "#c19b77";

                paginacion = paginacion.concat(
                    <>
                        <div className={"col-1 col-md-" + tamanyo_pagina + " pag99"} data-pag={"pag99"} key={"pag5-99"}>
                            <div className="vc_column-inner vc_custom_1676977697036">
                                <div className="wpb_wrapper">
                                    <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                        <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small">
                                            <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ubtn-center tooltip-641048fba662f" disabled style={{ fontWeight: "normal", border: "none", backgroundColor: "#c19b77", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit", paddingBottom: "7px" }}>
                                                <span className="ubtn-hover"></span>
                                                <span className="ubtn-data ubtn-text ">...</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"col-1 col-md-" + tamanyo_pagina + " pag" + (last - 1)} data-pag={"pag" + (last - 1)} key={"pag6-" + (last - 1)}>
                            <div className="vc_column-inner vc_custom_1676977697036">
                                <div className="wpb_wrapper">
                                    <div className=" vc_custom_1676977959985 ubtn-ctn-center">
                                        <a className="ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small pasapagina" data-pagina={last - 1} onClick={() => handleChangePagina(last - 1)}>
                                            <button type="button" id="ubtn-1" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-small ubtn-no-hover-bg ulta-shrink ubtn-center tooltip-641048fba662f" data-hover="" data-border-color="" data-bg={color_boton} data-hover-bg="#000000" data-border-hover="" data-shadow-hover="" data-shadow-click="none" data-shadow="" data-shd-shadow="" data-ultimate-target="#ubtn-1" style={{ fontWeight: "normal", border: "none", backgroundColor: "" + color_boton + "", color: "#ffffff", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "13px", paddingRight: "13px", fontFamily: "Lato", letterSpacing: "1.3px", fontWeight: "inherit", fontSize: "13px", fontStyle: "inherit", lineHeight: "inherit" }}>
                                                <span className="ubtn-hover"></span>
                                                <span className="ubtn-data ubtn-text ">{last}</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    const handleChangePagina = (pagina) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        var row = pagina;

        var arrival = llegada;
        var departure = salida;
        var adults = adultos;
        var children = ninos;
        var babys = bebes;

        var eldestino = document.getElementById("destinoid").value;

        var nombreprop = palabra;

        var filtros = [];
        var eltipocasa = [];

        var idsprops = "";
        var tipoprops = [];

        var lamarca = [];

        var checkboxes = document.getElementsByName('filter_amenities[]');

        for (var checkbox of checkboxes) {
            if (checkbox.checked) {
                filtros.push(checkbox.value);
            }
        }

        var tipocasas = document.getElementsByName("tipocasa");
        for (var untipocasa of tipocasas) {
            if (untipocasa.checked) {
                eltipocasa.push(untipocasa.value);
            }
        }

        var marcas = document.getElementsByName("barrio");
        for (var unamarca of marcas) {
            if (unamarca.checked) {
                lamarca.push(unamarca.value);
            }
        }

        var idPropiedades = document.getElementsByName("idPropiedades");
        for (var unidpropiedades of idPropiedades) {
            if (unidpropiedades.checked) {
                idsprops = unidpropiedades.value;
                tipoprops.push(unidpropiedades.id);
            }
        }

        var form = document.createElement('form');

        form.method = 'GET';
        form.action = "/" + preidioma + t("busca");

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'entrada';
        input.value = arrival;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'salida';
        input.value = departure;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'adultos';
        input.value = adults;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'ninos';
        input.value = children;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'bebes';
        input.value = babys;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'destino';
        input.value = eldestino;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'tipocasa';
        input.value = JSON.stringify(eltipocasa);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'barrio';
        input.value = JSON.stringify(lamarca);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'tipoprops';
        input.value = JSON.stringify(tipoprops);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'nombreprop';
        input.value = nombreprop;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'pagina';
        input.value = row;
        form.appendChild(input);

        document.body.append(form);

        form.submit();
    }

    {/* ************* */ }

    return (
        <>
            {propiedades.forEach((propiedad, index) => {

                caracs = [];
                contador = 0;

                lasfotos = [];
                enlacenombre = [];
                botonreserva = [];

                {
                    propiedad.caracteristicas.forEach((carac, index2) => {
                        if (carac.imagen.includes("glyph iconbe-Horno")) {
                            if (contador > 0 && index2 === propiedad.caracteristicas.length - 1) {
                                caracs = caracs.concat(
                                    <span key={"c" + index2}> {", " + carac.nombre[lang]} </span>
                                )
                                contador++;
                            }
                            else if (contador > 0 && index2 !== propiedad.caracteristicas.length - 1) {
                                caracs = caracs.concat(
                                    <span key={"c" + index2}> {", " + carac.nombre[lang]} </span>
                                )
                                contador++;
                            }
                            else {
                                caracs = caracs.concat(
                                    <span key={"c" + index2}> {carac.nombre[lang]} </span>
                                )
                                contador++;
                            }
                        }
                    })
                }

                {
                    if (llegada == "" && salida == "") {
                        const fotos = propiedad.fotos_web;
                        fotos.map((unafoto, index) => (

                            lasfotos = lasfotos.concat(
                                <Carousel.Item key={index}>
                                    <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className='topropiedadfecha' target='_blank' key={"f" + index} style={{ cursor: "pointer" }}>
                                        <img
                                            className="d-block w-100"
                                            style={{ maxHeight: "460px" }}
                                            src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre}
                                        />
                                    </a>
                                </Carousel.Item>
                            )
                        ));


                        enlacenombre = enlacenombre.concat(
                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className='topropiedadfecha' target='_blank' key={"e" + index} style={{ cursor: "pointer", textDecoration: "none" }}>
                                <span className="nombreprop">{propiedad.nombre}</span>
                            </a>
                        )

                        botonreserva = botonreserva.concat(
                            <div className=" vc_custom_1676393671329" key={"b" + index} style={{ marginTop: "5px", marginRight: "15px", marginBottom: "0px", marginLeft: "15px", paddingTop: "5px", paddingRight: "15px", paddingBottom: "0px", paddingLeft: "15px", textAlign: "center" }}>
                                <a className="topropiedad ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} style={{ cursor: "pointer", fontWeight: "300", fill: "#FFFFFF", color: "#FFFFFF", backgroundColor: "#C19B77", borderStyle: "none", borderRadius: "0px 0px 0px 0px" }} target="_blank">
                                    <span className="elementor-button-content-wrapper" style={{ display: "flex", justifyContent: "center", width: "110px", marginLeft: "auto", marginRight: "auto" }}>
                                        <span className="elementor-button-text mayusculacss" style={{ textTransform: "uppercase", backgroundColor: "#C19B77", padding: "5px 3px", fonFfamily: "Lato", letterSpacing: "1.3px", fontSize: "13px", marginBottom: "20px" }}>{t("RESERVA")}</span>
                                    </span>
                                </a>
                            </div>
                        )
                    }
                    else {
                        const fotos = propiedad.fotos_web;
                        fotos.map((unafoto, index) => (

                            lasfotos = lasfotos.concat(
                                <Carousel.Item key={index}>
                                    <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className='topropiedad' target='_blank' key={"f" + index} style={{ cursor: "pointer" }}>
                                        <img
                                            className="d-block w-100"
                                            style={{ maxHeight: "460px" }}
                                            src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre}
                                        />
                                    </a>
                                </Carousel.Item>
                            )
                        ));

                        enlacenombre = enlacenombre.concat(
                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica} className='topropiedad' key={"e" + index} style={{ cursor: "pointer", textDecoration: "none" }} target="_blank">
                                <span className="nombreprop">{propiedad.nombre}</span>
                            </a>
                        )

                        botonreserva = botonreserva.concat(
                            <div className=" vc_custom_1676393671329" key={"b" + index} style={{ marginTop: "5px", marginRight: "15px", marginBottom: "0px", marginLeft: "15px", paddingTop: "5px", paddingRight: "15px", paddingBottom: "0px", paddingLeft: "15px", textAlign: "center" }}>
                                <a className="topropiedad ubtn-link ult-adjust-bottom-margin ubtn-center ubtn-small" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica + "?llegada=" + llegada + "&salida=" + salida + "&adultos=" + adultos + "&ninos=" + ninos + "&bebes=" + bebes} style={{ cursor: "pointer", fontWeight: "300", fill: "#FFFFFF", color: "#FFFFFF", backgroundColor: "#C19B77", borderStyle: "none", borderRadius: "0px 0px 0px 0px" }} target="_blank">
                                    <span className="elementor-button-content-wrapper" style={{ display: "flex", justifyContent: "center", width: "207px", marginLeft: "auto", marginRight: "auto" }}>
                                        <span className="elementor-button-text mayusculacss" style={{ textTransform: "uppercase", backgroundColor: "#C19B77", padding: "4px", fonFfamily: "Lato", letterSpacing: "1.3px", fontSize: "13px", marginBottom: "20px" }}>{t("RESERVA") + " " + t("por") + " " + ((propiedad.precio / 100) + propiedad.suplemento_limpieza).toFixed(2) + ' ' + moneda}</span>
                                    </span>
                                </a>
                            </div>
                        )
                    }
                }

                results = results.concat(
                    <div className="col-md-6 elementor-top-column elementor-element elementor-element-8602aa5" data-id="8602aa5" data-element_type="column" key={"pr" + index} style={{ minHeight: "1px", display: "flex" }}>
                        <div className="elementor-widget-wrap elementor-element-populated e-swiper-container" style={{ borderStyle: "solid", borderWidth: "1px 1px 1px 1px", borderColor: "#DEDEDE", transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s", margin: "9px 0px 9px 0px", padding: "10px 10px 10px 10px" }}>
                            <div className="elementor-element elementor-element-476abb2 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel e-widget-swiper">
                                <div className="elementor-widget-container">
                                    <div className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal" dir="ltr">
                                        <div className="elementor-image-carousel swiper-wrapper" >
                                            <div className='swiper-slide'>
                                                <Carousel slide={true} controls={true} indicators={false} interval={null}>
                                                    {lasfotos}
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-86a8e7c elementor-widget elementor-widget-text-editor" data-id="86a8e7c" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container" style={{ margin: "9px 23px 0px 23px", padding: "0px 0px 0px 0px" }}>
                                    <h3>
                                        {enlacenombre}
                                    </h3>
                                </div>
                            </div>

                            <div className="mt-4 mb-3 elementor-element elementor-element-5e29aac elementor-widget elementor-widget-image" data-id="5e29aac" data-element_type="widget" data-widget_type="image.default" style={{ textAlign: "center" }}>
                                <div className="elementor-widget-container" style={{ fontSize: "11px", fontWeight: "300" }}>
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676119123872 vc_row-has-fill vc_row-o-content-middle vc_row-flex" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                                        <div className="wpb_column vc_column_container imgcarac vc_col-sm-2">
                                            <div className="vc_column-inner carsprop" style={{ boxSizing: "border-box", width: "100%" }}>
                                                <div className="wpb_wrapper">
                                                    <div className="aio-icon-component style_1">
                                                        <div id="Info-box-wrap-5578" className="aio-icon-box top-icon">
                                                            <div className="mb-1 aio-icon-top">
                                                                <div className="ult-just-icon-wrapper">
                                                                    <div className="align-icon">
                                                                        <div className="aio-icon-img ">
                                                                            <img className="img-icon" alt="null" src="/images/web/magno/2023-01-per-3.png" style={{ height: "20px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-5578 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">{propiedad.capacidad_maxima + " " + t('Personas')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container imgcarac vc_col-sm-2">
                                            <div className="vc_column-inner carsprop" style={{ boxSizing: "border-box", width: "100%" }}>
                                                <div className="wpb_wrapper">
                                                    <div className="aio-icon-component style_1">
                                                        <div id="Info-box-wrap-6397" className="aio-icon-box top-icon">
                                                            <div className="mb-1 aio-icon-top">
                                                                <div className="ult-just-icon-wrapper ">
                                                                    <div className="align-icon">
                                                                        <div className="aio-icon-img">
                                                                            <img className="img-icon" alt="null" src="/images/web/magno/2023-01-mt-2.jpg" style={{ height: "20px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6397 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">{propiedad.metros_cuadrados + " " + t('M2')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container imgcarac vc_col-sm-2">
                                            <div className="vc_column-inner carsprop" style={{ boxSizing: "border-box", width: "100%" }}>
                                                <div className="wpb_wrapper">
                                                    <div className="aio-icon-component style_1">
                                                        <div id="Info-box-wrap-6663" className="aio-icon-box top-icon">
                                                            <div className="mb-1 aio-icon-top">
                                                                <div className="ult-just-icon-wrapper">
                                                                    <div className="align-icon">
                                                                        <div className="aio-icon-img ">
                                                                            <img className="img-icon" alt="null" src="/images/web//magno/2023-01-ban-2.jpg" style={{ height: "20px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6663 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">{propiedad.num_banyos + " " + t('Baños')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="wpb_column vc_column_container imgcarac vc_col-sm-3">
                                            <div className="vc_column-inner carsprop" style={{ boxSizing: "border-box", width: "100%" }}>
                                                <div className="wpb_wrapper">
                                                    <div className="aio-icon-component style_1">
                                                        <div id="Info-box-wrap-6716" className="aio-icon-box top-icon">
                                                            <div className="mb-1 aio-icon-top">
                                                                <div className="ult-just-icon-wrapper ">
                                                                    <div className="align-icon">
                                                                        <div className="aio-icon-img ">
                                                                            <img className="img-icon" alt="null" src="/images/web/magno/2023-01-cama-2.jpg" style={{ height: "20px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6716 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}">{propiedad.num_habitaciones + " " + t('Dormitorios')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-5e29aac elementor-widget elementor-widget-image" data-id="5e29aac" data-element_type="widget" data-widget_type="image.default" style={{ paddingTop: "10px" }}>
                                <div className="elementor-widget-container">
                                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676119123872 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
                                        <div className="wpb_column vc_column_container vc_col-sm-12">
                                            <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                    <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-5578 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ textAlign: "left", marginLeft: "25px", minHeight: "60px", fontSize: "11px", lineHeight: "20px", fontWeight: "350" }}>

                                                        {caracs}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-7625ecd elementor-widget elementor-widget-wp-widget-custom_html" data-id="7625ecd" data-element_type="widget" data-widget_type="wp-widget-custom_html.default">
                                <div className="elementor-widget-container">
                                    <div className="textwidget custom-html-widget">
                                        <hr width="100%" />
                                    </div>
                                </div>
                            </div>
                            {botonreserva}
                        </div>
                    </div>
                )
            })}

            {results}

            {
                propiedades.length == 0 ?
                    <div className="col-md-12">
                        <p>{t("No se han encontrado resultados con los datos sumistrados, cambie las fechas de entrada y salida y vuelva a intentarlo")}</p>
                    </div>
                    : ""
            }
            <div className="col-md-12 row seccionpaginacion">
                {paginacion}
            </div>
        </>
    );
}



const PropiedadesBuscar = ({ lang, ruta, propiedades, elementosweb, adultos, ninos, bebes, llegada, salida, config, moneda, totalapartamentos, pagina_actual, palabra }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    {/* ************ */ }

    let puntos2 = [];
    let centrolat = 0;
    let centrolong = 0;

    const myIcon = L.icon({
        iconUrl: logo,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    {
        propiedades.forEach((propiedad, index) => {
            centrolat += Number(propiedad.latitud);
            centrolong += Number(propiedad.longitud);
            let foto = propiedad.foto_principal ? ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre : "";
            let personas = propiedad.capacidad_maxima + " " + t('Personas');
            let metros = propiedad.metros_cuadrados + " " + t('M2');
            let banyos = propiedad.num_banyos + " " + t('Baños');
            let habs = propiedad.num_habitaciones + " " + t('Dormitorios');
            let urlpropiedad = "/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica;

            puntos2 = puntos2.concat(
                <Marker key={"ma" + index} position={[propiedad.latitud, propiedad.longitud]} icon={myIcon}>
                    <Popup>
                        <p>
                            <img src={foto} style={{ width: "220px" }} />
                        </p>
                        <h3 style={{ marginBottom: "18px", fontWeight: "800", fontSize: "1.17em", marginTop: "7px", display: "block", marginBlockStart: "1em", marginBlockEnd: "1em", marginInlineStart: "0px", marginInlineEnd: "0px", textTransform: "uppercase", textAlign: "center" }}>{propiedad.nombre}</h3>
                        <p style={{ marginTop: "-2px", fontWeight: "500", color: "#C19B77", textAlign: "center", fontFamily: "'Lato', sans-serif", fontSize: "12px", lineHeight: "15px" }}>{personas + " - " + metros + " - " + banyos + " - " + habs}</p>
                        <p style={{ marginTop: "3px", backgroundColor: "grey", lineHeight: "15px" }}>
                            <hr style={{ borderTop: "1px solid rgba(0,0,0,0.4)" }} />
                        </p>
                        <p style={{ marginTop: "3px", marginBottom: "40px", marginTop: "30px", textAlign: "center", lineHeight: "15px" }}>
                            <a href={urlpropiedad} className="Reserva" target="_blank">{t("RESERVA")}</a>
                        </p>
                    </Popup>
                </Marker>
            )
        })
    }

    if (propiedades.length > 0) {
        centrolat = Number(centrolat) / Number(propiedades.length);
        centrolong = Number(centrolong) / Number(propiedades.length);
    }

    {/* ************ */ }

    return (
        <div className="order-2 col-lg-8 order-lg-1 col-8-pers">
            <div className="vc_column-inner vc_custom_1676106508745" style={{ paddingTop: "0px " }}>
                <div className="row wpb_wrapper" style={{ marginLeft: "0px", paddingLeft: "0px" }}>
                    <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676031268988 vc_row-has-fill" style={{ marginTop: "0px ", marginRight: "22px ", marginBottom: "0px ", marginLeft: "22px ", borderTopWidth: "0px ", borderRightWidth: "22px ", borderBottomWidth: "0px ", borderLeftWidth: "22px ", paddingTop: "0px ", paddingRight: "22px ", paddingBottom: "0px ", paddingLeft: "22px " }}>
                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                            <div className="vc_column-inner vc_custom_1676106572591">
                                <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html container-fluid">
                                        <div className="wpb_wrapper">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="resultado" className=" col-md-12 row">
                        <ListaPropiedades
                            ruta={ruta}
                            propiedades={propiedades}
                            config={config}
                            lang={lang}
                            preidioma={preidioma}
                            llegada={llegada}
                            salida={salida}
                            adultos={adultos}
                            ninos={ninos}
                            bebes={bebes}
                            moneda={moneda}
                            totalapartamentos={totalapartamentos}
                            pagina_actual={pagina_actual}
                            palabra={palabra}
                        />
                    </div>
                    <div className="row solomobile">
                        <div className="col-md-12">
                            <div className="vc_column-inner vc_custom_1675194446775">
                                <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                        <div className="wpb_wrapper">
                                            <div id="map" style={{ width: "100%", height: "700px", marginBottom: "40px" }}>
                                                <MapContainer center={[centrolat, centrolong]} zoom={14} scrollWheelZoom={false}>
                                                    <TileLayer
                                                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                                    />
                                                    {puntos2}
                                                </MapContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropiedadesBuscar;

