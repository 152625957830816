import { React } from "react";
import { useTranslation } from 'react-i18next';

const TextosPropietarios = ({propietarios, ruta}) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png";

	if (propietarios.imagen_cabecera)
	{
		imagen = ruta+"/"+propietarios.imagen_cabecera;
	}

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={t("Propietarios")} style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center', backgroundSize: "cover"}} >
                        </div>
                        <h1 className="noticia-individual mt-5 mb-5">{ t("Propietarios") }</h1>
                        <p className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: propietarios.ladesc}}></p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default TextosPropietarios;
