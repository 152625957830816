import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const CustomSelect = (props)  => {
    const { t } = useTranslation();

    const [showOptionList, setShowOptionList] = useState(false);
    const [optionsList, setOptionsList] = useState(props.optionsList);
    const [nameInput, setNameInput] = useState(props.nameInput);
    const [realInput, setRealInput] = useState(props.realInput);
    const [nameId, setNameId] = useState(props.nameId);


    const defecte = useRef(null);

    // Aques mètode maneja el clic que passa fora del
    // select text i l'area de llista
    const handleClickOutside = (e) => {
        if (
          !e.target.classList.contains("custom-select-option") &&
          !e.target.classList.contains("selected-text")
        ) {
            setShowOptionList(false);
          }
    };

    // Maneja la llista d'opcions
    const handleListDisplay = () => {
        setShowOptionList(!showOptionList);
    };


    // Maneja posar el nom al select area
    // i la llista a la seleccío
    const handleOptionClick = (e) => {
        defecte.current.textContent=e.target.getAttribute("data-name");
        setShowOptionList(false);
        setRealInput(e.target.getAttribute("data-value"));
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    });

    return(
        
        <div className="custom-select-container">
            <div
            className={showOptionList ? "selected-text active" : "selected-text"}
            onClick={handleListDisplay} ref={defecte}
            >
                {t(props.defaultText)}
            </div>
            {showOptionList && (
                <ul className="select-options">
                    {optionsList.map(option => {
                    return (
                        <li
                        className="custom-select-option"
                        data-name={option.name.replace("<br>","")}
                        data-value={option.value}
                        key={option.id}
                        onClick={handleOptionClick}
                        >
                            { ReactHtmlParser(option.name) }
                        </li>
                    );
                    })}
                </ul>
            )}
            <input type="hidden" name={nameInput} id={nameId} defaultValue={realInput}/>
        </div>
    )
}

export default CustomSelect;