import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const AboutUs = ({ lang }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="container section">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-8">
                    <span className="title title--overhead js-lines" style={{ overflow: 'inherit' }}><div style={{ overflow: 'inherit' }}><div className="tl-line" style={{ display: 'block', textAlign: 'left', position: 'relative', transform: 'matrix(1, 0, 0, 1, 0, 0)', opacity: 1, overflow: 'inherit' }}>{t("Nuestra promesa")}</div></div></span>
                    <h1 className="title title--h1 js-lines" style={{ overflow: 'inherit' }}><div style={{ overflow: 'inherit' }}><div className="tl-line" style={{ display: 'block', textAlign: 'left', position: 'relative', transform: 'matrix(1, 0, 0, 1, 0, 0)', opacity: 1, overflow: 'inherit' }}>{t("Somos pequeños en")} </div></div><div style={{ overflow: 'inherit' }}><div className="tl-line" style={{ display: 'block', textAlign: 'left', position: 'relative', transform: 'matrix(1, 0, 0, 1, 0, 0)', opacity: 1, overflow: 'inherit' }}>{t("tamaño y grandes en")} </div></div><div style={{ overflow: 'inherit' }}><div className="tl-line" style={{ display: 'block', textAlign: 'left', position: 'relative', transform: 'matrix(1, 0, 0, 1, 0, 0)', opacity: 1, overflow: 'inherit' }}>{t("servicio")}.</div></div></h1>
                </div>
                <div className="text-left col-12 col-lg-6 text-sm-justify">
                    <p className="mr-0 mr-lg-4 paragraph js-scroll-show" style={{ opacity: 1, transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>{t("Creemos que la vida es mejor cuando tenemos un espacio para desconectar de la cotidianidad, cuando nos damos un tiempo para relajarnos, y nos enfocamos a disfrutar del día a día, del buen comer y del pequeño paraíso que es la costa sur de Tenerife")}.</p>
                    <p className="mr-0 mr-lg-4 paragraph js-scroll-show" style={{ opacity: 1, transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>{t("Te invitamos a vivir una experiencia irrepetible en una de nuestras  maravillosas villas. Cada una es única y diferente. Te prometemos que son el lugar ideal para sumergirte en un espacio propio de descanso y un momento de reconexión en cualquier época del año")}.</p>
                </div>
                <div className="text-left col-12 col-lg-6 text-sm-justify">
                    <p className="ml-0 ml-lg-4 paragraph js-scroll-show" style={{ opacity: 1, transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>{t("Ponemos alma y corazón para brindarte un servicio excelente y personalizado. Con nuestro conocimiento local te ayudamos y asesoramos durante toda tu estancia")}.</p>
                    <p className="ml-0 ml-lg-4 paragraph js-scroll-show" style={{ opacity: 1, transform: 'matrix(1, 0, 0, 1, 0, 0)' }}>{t("Nos encanta alegrar corazones por intermedio de comidas exquisitas y estamos convencidos de que una buena copa de vino o una rica cerveza local en el momento oportuno vale más que todas las riquezas de la tierra. Por eso nuestras villas llevan nombres sabrosos y en cada una de ellas encontrarás una sorpresa. Además, te sorprenderemos con recetas locales que podrás elaborar con tus amigos y tu familia en la villa")}.</p>
                </div>

                <div className="col-12 col-lg-6">
                    <figure className="mr-0 about-image-wrap mr-lg-4">
                        <a className="about-link" href={"/" + preidioma + t("villas-tenerife")}>{t("Más información")} <i className="fa-solid fa-arrow-right"></i></a>
                        <img className="cover about-image-portrait" src="/images/web/hhc/_CR55290.jpg" alt="about" />
                    </figure>
                </div>
                <div className="col-12 col-lg-6">
                    <figure className="ml-0 about-image-wrap ml-lg-4">
                        <img className="cover about-image-landscape" src="/images/web/hhc/_CR55276.jpg" alt="about" />
                    </figure>
                </div>
            </div>
        </div>

    );
};
export default AboutUs;
