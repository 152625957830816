
import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextosCanceladoTpv = () => {
    const { t } = useTranslation();
    return (
        <div className="container mt-5">
            <h3 className="centra">{ t("Pago cancelado")}</h3>
        </div>
    );
}
    
 export default TextosCanceladoTpv;