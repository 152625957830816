import Menu from './Compartido/Menu.jsx';
import FormularioContacto from './Contacto/FormularioContacto.jsx';
import Footer from './Compartido/Footer.jsx';
import { useTranslation } from 'react-i18next';

const Contacto = (props) => {
    
    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas = {props.idiomas}
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                ruta = {props.ruta}
                meta_titulo = {props.meta_titulo+" - "+t("Contacto")}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />
            <FormularioContacto
                lang = {props.lang}
                config = {props.websgrupo}
            />
            <Footer
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                idiomas = {props.idiomas}
                ruta = {props.ruta}
            />
        </main>
    );
}

export default Contacto;
