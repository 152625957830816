import React, { useState } from "react";
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Comentarios = ({comentarios, lang, config}) => {

    const { t } = useTranslation();

    let coms = [];

    {
        comentarios.forEach((uncomentario, index) => {
            let iconosestrellas="";
            for(let i=0; i<uncomentario.estrellas; i++)
            {
                iconosestrellas+="<i class='fa-solid fa-star' style='color:"+config.color_corporativo_web+"'></i>";
            }

            let objeto =  {
                id: index, 
                titulo: uncomentario.titulo[lang], 
                texto: uncomentario.texto[lang], 
                estrellas : iconosestrellas,
                persona : uncomentario.nombre_persona,
            }
            coms = coms.concat(objeto);
        })
    }

    // show the photo with this index
    const [currentIndex, setCurrentIndex] = useState(0);

    // move to the next photo
    // if we are at the end, go to the first photo
    const next = () => {
        setCurrentIndex((currentIndex + 1) % coms.length);
    };

    // move to the previous photo
    // if we are at the beginning, go to the last photo
    const prev = () => {
        setCurrentIndex((currentIndex - 1 + coms.length) % coms.length);
    };

    return (
        config.activa_comentarios_web==1 && comentarios.length>0 ?
            <Container>
                <h3 className="text-center">{ t("Testimonios") }</h3>
                <div className='carrusel-container'>
                    {
                        coms.map((comentario, index) => ( 
                            <div
                                key={comentario.id}

                                // if the photo is the current photo, show it
                                className={
                                    coms[currentIndex].id === comentario.id ? 'fader' : 'comentario fader'
                                }
                            >
                                <h3 className='titulo'>{comentario.titulo}</h3>
                                <h4 className='persona'>{comentario.persona}</h4>
                                <div className='estrellas' dangerouslySetInnerHTML={{__html: comentario.estrellas }}></div>
                                <div className='texto' dangerouslySetInnerHTML={{__html: comentario.texto }}></div>
                            </div>
                        ))
                    }
                    <button onClick={prev} className='prev' style={{ background: ""+config.color_corporativo_web+"" }}>&lt;</button>
                    <button onClick={next} className='next' style={{ background: ""+config.color_corporativo_web+"" }}>&gt;</button>
                </div>

                <div className='dots'>
                    {coms.map((comentario) => (
                        <span
                            key={comentario.id}
                            // highlight the dot that corresponds to the current element
                            className = {
                                coms[currentIndex].id === comentario.id ? 'dot activar' : 'dot'
                            }
                            // when the user clicks on a dot, go to the corresponding element
                            onClick={() => setCurrentIndex(coms.indexOf(comentario))}
                        ></span>
                    ))}
                </div>
            </Container>
        :
            <></>
    );
};
export default Comentarios;