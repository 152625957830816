import { useTranslation } from 'react-i18next';

const Vision = () => {
    const { t } = useTranslation();

    return (
        <div className="section mcb-section T60B80">
            <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column_attr clearfix align_center">
                            <h6 className="landing4-heading grey positionContainer" >{t("MISIÓN")}</h6>
                            <hr className="no_line margin15Auto"/>
                        </div>
                        
                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix">
                                <h2>{t("Misión")}</h2>
                                <p>
                                    <span lang="EN-GB">{t("Fundada el 15 de noviembre de 2004 como asociación privada sin ánimo de lucro, APARTUR tiene por objeto unir al sector en torno a objetivos comunes.")}</span>
                                </p>

                                <p className="MsoNormal imgInitioalPosition">
                                    <span lang="EN-GB">{t("Formada por más de 300 empresas de gestión, propietarios y particulares, representa el 85% de las propiedades con licencia de la provincia.")}</span>
                                </p>

                                <p className="MsoNormal imgInitioalPosition">
                                    <span lang="EN-GB">{t("APARTUR actúa como intermediario en las negociaciones con las instituciones gubernamentales y de otro tipo, garantizando la defensa y la visibilidad de los intereses colectivos de todos. Proporcionamos competitividad y garantía de calidad para este tipo de alojamiento en beneficio de los miembros y de la comunidad asociada:")}</span>
                                </p>
                                
                                <p>&nbsp;</p>
                            
                                <table border="0" align="center">
                                    <tbody>
                                        <tr>
                                            <td className="tdStyle">
                                                <h1><strong>1</strong></h1>
                                            </td>
                                            
                                            <td className="tdStyle">
                                                <h1 className="alignCenter"><strong>2</strong></h1>
                                            </td>

                                            <td className="tdStyle">
                                                <h1 className="alignCenter"><strong>3</strong></h1>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="centerBgNone">
                                                <span lang="EN-GB">{t("Participación activa en programas de desarrollo del sector turístico en la provincia")}</span>
                                            </td>
                                            <td className="centerBgNone">
                                                <span lang="EN-GB">{t("Puesta en marcha de mecanismos para mejorar la convivencia entre la actividad turística y los ciudadanos")}</span>
                                            </td>
                                            <td className="centerBgNone">
                                                <span lang="EN-GB">{t("Fortalecer el sector fomentando el potencial de diversidad entre los miembros para enriquecer la industria y garantizar excelentes experiencias a los huéspedes.")}</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="centerBgNone">
                                                <img src="https://www.apartur.com/images/web/informacion/visio-1.jpg" alt="" width="250" height="167"/>
                                            </td>
                                            <td className="centerBgNone">
                                                <img src="https://www.apartur.com/images/web/informacion/visio-2.jpg" alt="" width="250" height="167"/>
                                            </td>
                                            <td className="centerBgNone">
                                                <img src="https://www.apartur.com/images/web/informacion/visio-3.jpg" alt="" width="250" height="167"/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <p>&nbsp;</p>

                                <h2>{t("Visión")}</h2>

                                <p className="textJustify">
                                    <span lang="EN-GB">{t("El alquiler de apartamentos por días o semanas es una actividad muy arraigada en los principales destinos turísticos del mundo, y Barcelona no es una excepción. La demanda de apartamentos turísticos ha crecido significativamente en los últimos años y, en consecuencia, también lo ha hecho su oferta.")}</span>
                                </p>
                                
                                <p className="textJustify">
                                    <span lang="EN-GB">{t("En APARTUR, pretendemos apoyar esta actividad mejorando la calidad de los servicios de los alojamientos turísticos, garantizando su integración con el resto del sector turístico y la colaboración con las autoridades públicas.")}</span>
                                </p>

                                <p className="MsoNormal imgInitioalPosition" >
                                    <span lang="EN-GB">{t("Nuestros objetivos:")}</span>
                                </p>

                                <p>
                                    <img src="https://www.apartur.com/images/web/informacion/imagetools0.jpg" alt="" width="982" height="386"/>
                                </p>

                                <ul className="mg0cm" type="disc">
                                    <li>{t("Protección y defensa de los intereses de los afiliados.")}</li>
                                    <li>{t("Unidad en el sector y lucha contra el intrusismo.")}</li>
                                    <li>{t("Protección jurídica frente a las normativas que amenazan a nuestro sector.")}</li>

                                    <li>
                                        <span lang="EN-GB">{t("Coordinación y cooperación con las principales organizaciones a nivel regional, provincial, nacional y estatal.")}</span>
                                    </li>

                                    <li>
                                        <span lang="EN-GB">{t("Profesionalización del sector mediante el control de la calidad para garantizar un alto nivel entre las empresas y los profesionales del sector.")}</span>
                                    </li>

                                    <li>{t("Creación de sinergias entre los miembros y prestación de servicios compartidos.")}</li>
                                    <li>{t("Potenciar la implantación y obtención de certificaciones y sellos de calidad.")}</li>

                                    <li>
                                        <span lang="EN-GB">{t("Promocionar a nuestros miembros.")}</span>
                                    </li>

                                    <li>
                                        <span lang="EN-GB">{t("Presencia")}&nbsp;</span>
                                        <span lang="EN-GB">{t("en ferias y congresos nacionales e internacionales de turismo.")}</span>
                                    </li>

                                    <li>
                                        <span lang="EN-GB">{t("Participación")}&nbsp;</span>{t(" activa en gremios empresariales y turísticos, así como en organizaciones vecinales, sociales y cívicas.")}
                                    </li>

                                </ul>
                            
                                <hr className="no_line m10"/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Vision;