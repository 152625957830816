import React from "react";
import { Container } from "react-bootstrap";
import $ from 'jquery';

import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

const News = ({ lang, noticias, ruta }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    $('.select').on('click', '.placeholder', function () {
        var parent = $(this).closest('.select');
        if (!parent.hasClass('is-open')) {
            parent.addClass('is-open');
            $('.select.is-open').not(parent).removeClass('is-open');
        } else {
            parent.removeClass('is-open');
        }
    }).on('click', 'ul>li', function () {
        var parent = $(this).closest('.select');
        parent.removeClass('is-open').find('.placeholder').text($(this).text());
        parent.find('input[type=hidden]').attr('value', $(this).attr('data-value'));

        $('.filter__item').removeClass('active');
        $(this).addClass('active');
        var selector = $(this).attr('data-filter');
        $('.js-sort').isotope({
            filter: selector
        });
        return false;
    });

    return (
        <section className="container section section-first">
            <div className="row">
                <div className="col-12">
                    <div
                        className="select js-scroll-show"
                        style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                    >
                        <span className="placeholder">{t("Selecciona categoría")}</span>
                        <ul className="filter">
                            <li className="filter__item active" data-filter="*">
                                <a className="filter__link active" href="#filter">
                                    {t("Todos")}
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-">
                                <a className="filter__link" href="#filter">
                                    .
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-cultura">
                                <a className="filter__link" href="#filter">
                                    Cultura
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-gastronomia">
                                <a className="filter__link" href="#filter">
                                    Gastronomía
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-naturaleza">
                                <a className="filter__link" href="#filter">
                                    Naturaleza
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-playas">
                                <a className="filter__link" href="#filter">
                                    Playas
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-promociones">
                                <a className="filter__link" href="#filter">
                                    Promociones
                                </a>
                            </li>
                            <li className="filter__item" data-filter=".category-senderos">
                                <a className="filter__link" href="#filter">
                                    Senderos
                                </a>
                            </li>
                        </ul>
                        <input type="hidden" name="changemetoo" />
                    </div>
                </div>
            </div>
            <div
                className="mt-4 flex-container-z js-news js-sort mb80"
            >
                {noticias.map((unanoticia, index) => (

                    <div
                        className="itemNews category-cultura js-scroll-opacity" key={index}
                    >
                        <figure className="itemNews__img-wrap">
                            <a className="itemNews__link" href={"/" + preidioma + t("blog") + "/" + unanoticia.laurl}>
                                <div className="itemNews__date">
                                    {/* unanoticia.tags*/} <span> - {format(new Date(unanoticia.fecha_publica), 'dd MMM yyyy')}</span>
                                </div>
                                {
                                    unanoticia.imagen ?
                                        <img
                                            className="cover lazyload"
                                            src={ruta + "/blog/" + unanoticia.id + "/" + unanoticia.imagen}
                                            alt=""
                                        />
                                        :
                                        <img
                                            className="cover lazyload"
                                            src="/images/web/bedloop-nofoto.png"
                                            alt=""
                                        />
                                }
                            </a>
                        </figure>
                        <div className="itemNews__details">
                            <h4
                                className="title title--h4 js-lines"
                            >
                                {unanoticia.eltitulo}
                            </h4>
                        </div>
                    </div>
                ))}

                {/*
                <div
                    className="itemNews category-cultura js-scroll-opacity"
                    style={{
                        position: "absolute",
                        left: "0%",
                        top: 0,
                        opacity: 1,
                        marginTop: 0
                    }}
                >
                    <figure className="itemNews__img-wrap">
                        <a className="itemNews__link" href="/bloges/titulo">
                            <div className="itemNews__date">
                                Cultura <span>- 12 Nov 2021</span>
                            </div>
                            <img
                                className="cover ls-is-cached lazyloaded"
                                src="/assets/images/actividad/1/_cr53917-min.jpg"
                                alt=""
                            />
                        </a>
                    </figure>
                    <div className="itemNews__details">
                        <h4
                            className="title title--h4 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Titulo
                                </div>
                            </div>
                        </h4>
                    </div>
                </div>
                <div
                    className="itemNews category-gastronomia js-scroll-opacity"
                    style={{
                        position: "absolute",
                        left: "53.9992%",
                        top: 0,
                        opacity: 1,
                        marginTop: 0
                    }}
                >
                    <figure className="itemNews__img-wrap">
                        <a className="itemNews__link" href="/bloges/streik-bei-ryanair">
                            <div className="itemNews__date">
                                Gastronomía <span>- 21 Aug 2019</span>
                            </div>
                            <img
                                className="cover ls-is-cached lazyloaded"
                                src="/assets/images/bedloop-nofoto.png"
                                alt=""
                            />
                        </a>
                    </figure>
                    <div className="itemNews__details">
                        <h4
                            className="title title--h4 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Streik bei Ryanair
                                </div>
                            </div>
                        </h4>
                    </div>
                </div>
                <div
                    className="itemNews category-naturaleza js-scroll-opacity"
                    style={{
                        position: "absolute",
                        left: "0%",
                        top: "463.188px",
                        opacity: 1,
                        marginTop: 0
                    }}
                >
                    <figure className="itemNews__img-wrap">
                        <a
                            className="itemNews__link"
                            href="/bloges/autofahrer-aufgepaßt-enten-auf-der-straße"
                        >
                            <div className="itemNews__date">
                                Naturaleza <span>- 21 Aug 2019</span>
                            </div>
                            <img
                                className="cover ls-is-cached lazyloaded"
                                src="/assets/images/bedloop-nofoto.png"
                                alt=""
                            />
                        </a>
                    </figure>
                    <div className="itemNews__details">
                        <h4
                            className="title title--h4 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Autofahrer aufgepaßt: Enten auf der{" "}
                                </div>
                            </div>
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Straße
                                </div>
                            </div>
                        </h4>
                    </div>
                </div>
                <div
                    className="itemNews category- js-scroll-opacity"
                    style={{
                        position: "absolute",
                        left: "53.9992%",
                        top: "463.188px",
                        opacity: 1,
                        marginTop: 0
                    }}
                >
                    <figure className="itemNews__img-wrap">
                        <a
                            className="itemNews__link"
                            href="/bloges/40-ha-in-las-mercedesla-jardina-abgefackelt"
                        >
                            <div className="itemNews__date">
                                . <span>- 21 Aug 2019</span>
                            </div>
                            <img
                                className="cover ls-is-cached lazyloaded"
                                src="/assets/images/bedloop-nofoto.png"
                                alt=""
                            />
                        </a>
                    </figure>
                    <div className="itemNews__details">
                        <h4
                            className="title title--h4 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    40 ha in Las Mercedes/La Jardina{" "}
                                </div>
                            </div>
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    abgefackelt
                                </div>
                            </div>
                        </h4>
                    </div>
                </div>*/}
            </div>
        </section>

    );
};
export default News;
