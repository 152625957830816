import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import { Carousel } from 'react-bootstrap';
import ImagePopupGallery from "../Compartido/ImagePopupGallery.jsx";

const SobreNosotrosBox = ({ lang, texto, destinos, ruta }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        var script = document.createElement("script");
        script.type = "module";
        script.src = "https://widgets.thereviewsplace.com/2.0/rw-widget-slider.js";
        document.getElementsByTagName("head")[0].appendChild(script);
    }, []);

    {/* ***** */ }

    let gallery0 = [];
    let gallery2 = [];

    let urlprop = "https://www.holidayhomescanarias.com/";

    for (let i = 0; i < destinos.length; i++) {

        for (let j = 0; j < destinos[i].imagenes.length; j++) {

            const image = {
                original: ruta + '/destinos/' + destinos[i].id + '/' + destinos[i].imagenes[j].imagen,
                originalClass: "foto" + destinos[i].id
            }
            gallery0 = gallery0.concat(image);
        }
    }

    destinos.map((undestino, index) => (
        undestino.imagenes.map((imagen, indice) => (
            gallery2 = gallery2.concat(
                <Carousel.Item key={index + "-" + indice}>
                    <ImagePopupGallery
                        imageSrc={ruta + '/destinos/' + undestino.id + '/' + imagen.imagen}
                        urlprop={urlprop}
                        gallery={gallery0}
                        altura="650px"
                    />
                </Carousel.Item>
            )
        ))
    ));


    {/* ***** */ }

    return (
        <section id="about" className="container section">
            <div className="row">
                <div className="col-12 col-lg-5">
                    <span
                        className="title title--overhead js-lines"
                        style={{ overflow: "inherit" }}
                    >
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Nuestra promesa")}
                            </div>
                        </div>
                    </span>
                    <h2 className="title title--h2 js-lines" style={{ overflow: "inherit" }}>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Somos pequeños en")}{" "}
                            </div>
                        </div>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("tamaño y grandes")}{" "}
                            </div>
                        </div>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("en servicio")}.
                            </div>
                        </div>
                    </h2>
                </div>
                <div className="col-12 col-lg-6 offset-lg-1 offset-top" dangerouslySetInnerHTML={{ __html: texto.ladesc }}></div>
                {/* aqui va el slider */}


                {/*
                    <div className="space-slider"></div>
                    <div className="slider-about col-fullwidth swiper-container">
                        <div className="swiper-wrapper">
                            {destinos[0] && destinos[0].imagen_principal.length > 0 ?
                                <div className='swiper-slide'>
                                    <div className='coverSlider js-image' data-image={ruta + '/destinos/' + destinos[0].id + '/' + destinos[0].imagen_principal[0].imagen}></div>
                                </div>
                                :
                                <div className='swiper-slide'>
                                    <div className='coverSlider js-image' data-image="/images/web/bedloop-nofoto.png"></div>
                                </div>
                            }
                        </div>

                        <div className="swiper-control-center">
                            <div className="swiper-control swiper-control--bottom">
                                <div className="slash">
                                    <div className="swiper-button-next swiper-button-square"><i className="fa-solid fa-chevron-left"></i></div>
                                    <div className="swiper-button-prev swiper-button-square"><i className="fa-solid fa-chevron-right"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                */}

                <Carousel slide={true} controls={true} indicators={false}>
                    {gallery2}
                </Carousel>
            </div>
        </section>

    );
};
export default SobreNosotrosBox;
