import {React} from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import "./../../../../../css/magno/experiencias.css";

const TextosExperiencias = ({lang}) => {

    const { t } = useTranslation();

    return (
        <Container className="experienciasContainer">
            <h1 className="experiencias-title-mb elementor-heading-title elementor-size-medium mt-5" style={{ textAlign:"center", color: "#2E2E2E", fontSize: "50px", fontWeight: "200", fontStyle: "normal",lineHeight: "1.2em", letterSpacing: "1px", padding: "0 0 35px 0" }}>{ t('Experiencias') }</h1>

            <iframe className="civitatis-iframe" src={"https://www.civitatis.com/widget-activities/?agencyId=44556&display=cosy&cant=6&lang="+lang+";?&currency=EUR&zone=223&transfer=0&cmp=Widget_Sevilla_Provincia_ES&width=100%&hideButton=0&centerContent=1&typeSelection=all&color=030303&typography=Lato&removeBackground=1&showShadow=1&roundedButtons=1"} width="100%" frameBorder="0" data-maxwidth="100%" style={{maxWidth: "100%", overflow: 'hidden'}}></iframe>

            {/* <section className="elementor-section elementor-top-section elementor-element elementor-element-1687d60f elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1687d60f" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-10e692c5" data-id="10e692c5" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-8d97698 elementor-widget elementor-widget-heading" data-id="8d97698" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                    <h1 className="elementor-heading-title elementor-size-medium mt-5" style={{ textAlign:"center", color: "#2E2E2E", fontFamily: "'Roboto', Sans-serif", fontSize: "50px", fontWeight: "200", fontStyle: "normal",lineHeight: "1.2em", letterSpacing: "1px", padding: "0 0 35px 0" }}>{ t('Experiencias') }</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>*/}

            {/* <section className="elementor-section elementor-top-section elementor-element elementor-element-9d46c7e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9d46c7e" data-element_type="section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-43bce1a" data-id="43bce1a" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-7441400 elementor-widget elementor-widget-html" data-id="7441400" data-element_type="widget" data-widget_type="html.default">
                                <div className="elementor-widget-container">
                                    <div>
                                        <iframe className="civitatis-iframe" src={"https://www.civitatis.com/widget-activities/?agencyId=44556&display=cosy&cant=6&lang="+lang+";?&currency=EUR&zone=223&transfer=0&cmp=Widget_Sevilla_Provincia_ES&width=100%&hideButton=0&centerContent=1&typeSelection=all&color=030303&typography=Lato&removeBackground=1&showShadow=1&roundedButtons=1"} width="100%" frameborder="0" data-maxwidth="100%" style={{maxWidth
                                        : "100%", height:"1200px"}}></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
        </Container>
    )
}

export default TextosExperiencias;
