import { React } from "react";
import { useTranslation } from 'react-i18next';

const TextosServicios = ({servicios, config, ruta}) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (servicios.imagen_cabecera)
	{
		imagen = ruta+"/"+servicios.imagen_cabecera;
	}

    return (
        <>

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={ t("Servicios") } style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}} >
                        </div>
                        <h1 className="noticia-individual mb-5">{ t("Servicios") }</h1>
                        <p className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: servicios.ladesc}}></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TextosServicios;
