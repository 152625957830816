import {React } from "react";

import { useTranslation } from 'react-i18next';

const PropiedadesMarca = ({ propiedades, lang, config , ruta}) => {

    const { t } = useTranslation();

    return (
        <div className="pt-5">
            <div className="destacats">
                {propiedades.map((propiedad, index) => (
                    <div className="destacats-propietat" key={"d"+index}>
                        {
                            propiedad.foto_principal.length!=0
                            ? <img className="destacats-imatge-propietat" src={ruta+"/propiedades/"+propiedad.id+"/fotos/web/thumb/"+propiedad.foto_principal[0].nombre } />
                            :  <img className="destacats-imatge-propietat" src="/images/web/bedloop-nofoto.png" />
                        }
                        <div className="destacats-text">
                            <span className="destacats-icones-propietat" style={{ color: ""+config.color_corporativo_web+"" }}>
                                <i className="fa-solid fa-bed"></i> {propiedad.num_habitaciones} | <i className="fa-solid fa-users"></i> {propiedad.capacidad_maxima} | <i className="fa-solid fa-bath"></i> {propiedad.num_banyos}
                            </span>
                            <span className="destacats-titol-propietat">
                                <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica}>
                                { propiedad.nombre}
                                </a>
                            </span>
                            <span className="destacats-ciutat-propietat"> { propiedad.ciudad}</span>
                            <a href={"/"+t("apartamentos")+"-"+propiedad.destino.url_dinamica[lang]+"/"+propiedad.url_dinamica}className="btn btn-primary" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>{ t("Reservar") }</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}


export default PropiedadesMarca;
