import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Footer = ({ datos, terminos, protocolos, avisolegal, config, elementosweb, politicaprivacidad, lang, ruta }) => {

    const { t } = useTranslation();

    var currentTime = new Date();
    var year = currentTime.getFullYear();

    return (
        <footer className={"footer bg-white pt-5"}>
            <Container>
                <div className="row justify-content-center">
                    <div className="col-md-3">
                        { config.logo_fiscal!="" ? <img className="logo-footer" alt="Logo" title="Logo" src={ ruta+"/web/logo/"+config.logo_fiscal } /> : <img className="logo" alt="BedLoop" title="BedLoop" src="https://www.bedloop.com/imatges/logo.webp"/> }
                    </div>
                    <div className="col-md-3">
                        <ul className="no-padding">
                            <li>{config.nombre_comercial}</li>
                            <li>{config.nombre_fiscal}</li>
                            <li>{config.direccion_fiscal}</li>
                            <li>{config.codigo_postal_fiscal}</li>
                            <li>&copy; {year}</li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <ul className="no-padding">
                            <li><a href="/contacto">{t("Contacto")}</a></li>
                            {protocolos.ladesc !="" ? <li><a href={"/"+t("protocolos")}>{t("Protocolos")} </a></li> : ''}
                            <li><a href="/faqs">{ t("Faq's") }</a></li>
                            {terminos.ladesc != "" ? <li><a href={"/"+t("terminos")}>{t("Términos")}</a></li> : ''}
                            <li><a href="/politica-de-cancelacion">{t("Política_de_cancelación")}</a></li>
                            {politicaprivacidad.ladesc != "" ? <li><a href="/politica-de-privacidad">{t("Política de privacidad")}</a></li>: ''}
                            {avisolegal.ladesc != "" ? <li><a href={"/"+t("legal")}>{t("Aviso Legal")}</a></li> : ''}
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <ul className="no-padding">
                            {datos.url_facebook && datos.url_facebook != "" ? <li><a href={datos.url_facebook}><i className="fa-brands fa-facebook" target="_blank"></i> Facebook</a></li> : ''}
                            {datos.url_twitter  && datos.url_twitter != "" ? <li><a href={datos.url_twitter}><i className="fa-brands fa-twitter" target="_blank"></i> Twitter</a></li> : ''}
                            {datos.url_instagram && datos.url_instagram != "" ? <li><a href={datos.url_instagram}><i className="fa-brands fa-instagram" target="_blank"></i> Instagram</a></li> : ''}
                        </ul>
                    </div>
                </div>
            </Container>
        </footer>
    );
};
export default Footer;

