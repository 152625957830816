
import Menu from './Compartido/Menu.jsx';
import Buscador from './Buscar/Buscador.jsx';
import PropiedadesBuscar from './Buscar/PropiedadesBuscar.jsx';
import Footer from './Compartido/Footer.jsx';
import PropiedadesDestino from './Destino/PropiedadesDestino.jsx';

const Buscar= (props) => {
   
     return (
      <main>
        <Menu 
            destinos = {props.destinos}
            sobrenosotros = {props.textosobrenosotros} 
            clientes = {props.textoclientes}
            propietarios = {props.textopropietarios}
            servicios = {props.textoservicios}
            experiencias = {props.experiencias}
            idiomas = {props.idiomas}
            lang = {props.lang}
            tiposcasas = {props.tiposcasas}
            config = {props.config}
            textopropiedades = {props.textopropiedades}
            edificios = {props.edificios}
            marcas = {props.marcas}
            ruta = {props.ruta}
        />
        <Buscador 
            destino = {props.urldestino}
            destinos = {props.destinos}
            tiposcasas = {props.tipocasasdestino}
            adultos = {props.adultos}
            ninos = {props.ninos}
            bebes = {props.bebes}
            llegada = {props.llegada}
            salida = {props.salida}
            tipocasa = {props.tipocasa}
            lang = {props.lang}
            config = {props.websgrupo}
         />
        <PropiedadesBuscar 
            lang = {props.lang}
            ruta = {props.ruta}
            propiedades = {props.propiedades}
            elementosweb = {props.elementosweb}
            adultos = {props.adultos}
            ninos = {props.ninos}
            bebes = {props.bebes}
            llegada = {props.llegada}
            salida = {props.salida}
            config = {props.websgrupo}
        />
        <Footer 
           datos = {props.websgrupo}
           terminos = {props.textoterminos}
           protocolos = {props.textoprotocolos}
           avisolegal = {props.textoavisolegal}
           config = {props.config}
           elementosweb = {props.elementosweb}
           politicaprivacidad = {props.textopolitica}
           lang = {props.lang}
           ruta = {props.ruta}
        />
      </main>
     );
 }

 export default Buscar;
