import { React } from "react";
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TextosTerminos = ({terminos, ruta}) => {

    const { t } = useTranslation();

	var imagen = "/images/web/bedloop-nofoto.png"; 

	if (terminos.imagen_cabecera)
	{
		imagen = ruta+"/"+terminos.imagen_cabecera;
	}

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={ t("Términos") } style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}} ></div>
                    <h1 className="noticia-individual mt-5 mb-5">{ t("Términos") }</h1>
                    <p className="noticia-individual-descripcio-llarga mb-5" dangerouslySetInnerHTML={{__html: terminos.ladesc}}></p>
                </div>
            </div>
        </div>
    );
}

export default TextosTerminos;