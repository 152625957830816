import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from 'axios';


const FormularioPortada = ({ lang, preidioma }) => {

    const { t } = useTranslation();

    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [tema, setTema] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        if (nombre === "" || mensaje === "" || email === "" || !consentimiento) {
            let mensajeerror = "";
            if (nombre === "") mensajeerror += `${t("Nombre")}<br/>`;
            if (mensaje === "") mensajeerror += `${t("Mensaje")}<br/>`;
            if (email === "") mensajeerror += `${t('Email')}<br/>`;
            if (!consentimiento) mensajeerror += `${t("consentimiento")}<br/>`;
            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
            });
        } else {
            let elnombre = nombre;
            let eltema = tema;
            let elmail = email;
            let elmensaje = mensaje;
            let elidioma = document.getElementById("idioma").value;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                yourname: elnombre,
                contactMessage: eltema + "<br>" + elmensaje,
                contactEmail: elmail,
                lang: elidioma
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}<br/>${t('Nos pondremos en contacto pronto')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };



    return (
        <div className="section mcb-section" style={{ paddingTop: "30px", paddingBottom: "20px", backgroundColor: "#ffce60" }} data-parallax="3d">
            <div className="container">
                <div className="column one column_column aligncenter">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix column_attr mobile_align_center">
                                <hr className="no_line" style={{ margin: "0 auto 10px auto" }} />
                                <h2>{t("¡Escríbenos!")}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column one column_column" style={{ backgroundColor: "#fff", borderRadius: "15px" }}>
                    <div style={{ padding: "20px" }}>
                        <div id="contactWrapper">
                            <div id="contactform">
                                <form method="post" id="formportada" action="/enviacontacto" onSubmit={handleSubmit}>
                                    <input type="hidden" name="lang" id="idioma" value={lang.toString().toUpperCase()} />
                                    <div className="mt-20 column one-second">
                                        <input placeholder={t("Nombre") + " *"} id="name" type="text" name="name" required maxLength="50"
                                            value={nombre} onChange={(e) => setNombre(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-20 column one-second">
                                        <input placeholder={t("Email") + " *"} id="email" type="email" name="email" required maxLength="50"
                                            value={email} onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-20 column one">
                                        <input placeholder={t("Tema")} id="subject" type="text" name="subject" maxLength="50"
                                            value={tema} onChange={(e) => setTema(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-20 column one">
                                        <textarea placeholder={t("Mensaje") + " *"} id="message" name="message" rows="10" maxLength="6000" required value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
                                    </div>

                                    <div className="column one">
                                        <div className="g-recaptcha" data-sitekey="6LfsiDEUAAAAAL_oH_ULiiYC0znKCwytP2-S_Ann"></div>
                                    </div>

                                    <div className="mt-20 column one">
                                        <input id="consentimiento" type="checkbox" name="consentimiento" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} />{t("He leido y doy")} <a href={preidioma + t('consentimiento-formulario')}> {t('consentimiento')} </a> {t("para el tratamiento de datos")}
                                    </div>

                                    <div className="column one">
                                        <button className="button-primary" id="submit" type="submit" style={{ color: "#FFF" }}>{t("Enviar")}</button>
                                        <p>{t("Todos los campos * son obligatorios")}</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormularioPortada;
