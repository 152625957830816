import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import BtnFloating from './Compartido/BtnFloating.jsx';
import Content from './Reserve/Content.jsx';
import Scripts from './Compartido/Scripts.jsx';

import '../../../../../resources/css/web/holidayhomescanarias/header.css'
import '../../../../../resources/css/web/holidayhomescanarias/general.css';
import '../../../../../resources/css/web/holidayhomescanarias/footer.css'
import '../../../../../resources/css/web/holidayhomescanarias/intro.css'
import '../../../../../resources/css/web/holidayhomescanarias/buttons.css'
import '../../../../../resources/css/web/holidayhomescanarias/faqses.css'
import '../../../../../resources/css/web/holidayhomescanarias/reserve.css'

const Reserva = (props) => {
    return (
        <main>
            <Header
                datos={props.websgrupo}
                lang={props.lang}
                idiomas={props.idiomas}
                tipo="reserva"
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
            />
            <Content
                propiedad={props.propiedad}
                precios={props.precios}
                llegada={props.llegada}
                salida={props.salida}
                difdias={props.difdias}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                mascotas={props.mascotas}
                lang={props.lang}
                ruta={props.ruta}
                config={props.config}
                tpvs={props.tpvs}
                paypal={props.paypal}
                transferencia={props.transferencia}
                stripe={props.stripe}
                paycomet={props.paycomet}
                moneda={props.moneda}
                numpropiedades={props.numpropiedades}
                websgrupo={props.websgrupo}
            />
            <Footer
                lang={props.lang}
                config={props.config}
            />
            <BtnFloating />

            <Scripts />
        </main>
    );
}

export default Reserva;

