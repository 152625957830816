import React from "react";
import { Container } from "react-bootstrap";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import { useTranslation } from 'react-i18next';

const Map = ({ lang, propiedad }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <MapContainer center={[propiedad.latitud, propiedad.longitud]} zoom={18} scrollWheelZoom={false}>
                <TileLayer
                    attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                />
                <Marker position={[propiedad.latitud, propiedad.longitud]}></Marker>
            </MapContainer>
            {/* <div className="map-bottom mapboxgl-map"></div>*/}
        </>
    );
};
export default Map;
