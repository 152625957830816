
import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextosGraciasTpv = () => {
    const { t } = useTranslation();
    return (
        <div className="nd_options_container nd_options_clearfix"> 
            <div className="">
                <div id="post-12159" className="post-12159 page type-page status-publish hentry">
                    <div data-elementor-type="wp-page" data-elementor-id="12159" className="elementor elementor-12159">

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-3625dda elementor-section-content-middle elementor-section-stretched elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="3625dda" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                            <div className="elementor-background-overlay"></div>
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5b48ef6" data-id="5b48ef6" data-element_type="column">
                                    <div className="elementor-widget-wrap"></div>
                                </div>
                            </div>
                        </section>

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-1a55ff66 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1a55ff66" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-49d7b8f6" data-id="49d7b8f6" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-2a33f359 elementor-widget elementor-widget-text-editor" data-id="2a33f359" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">			
                                                <h2>
                                                    { t("Gracias") }
                                                </h2>						
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-d5242f2 elementor-widget elementor-widget-text-editor" data-id="d5242f2" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                {t("¡Gracias por su reserva!")}		
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
 export default TextosGraciasTpv;