import React from "react";
import { Container } from "react-bootstrap";
import $ from "jquery";
import { useTranslation } from 'react-i18next';

import '../../../../../../resources/css/web/holidayhomescanarias/btnFloating.css'


const BtnFloating = () => {
    function ShowToScroll() {
        var $targetElement = $('.js-show-to-scroll'),
            $showBackTotop = 100; //$(window).height()

        //$targetElement.hide();
        $targetElement.addClass('animated bounceOut');

        $(window).on('scroll', function () {
            var windowScrollTop = $(window).scrollTop();
            if (windowScrollTop > $showBackTotop) {
                //$targetElement.fadeIn('800, swing');
                $targetElement.addClass('bounceIn');
                $targetElement.removeClass('bounceOut');
            } else {
                //$targetElement.fadeOut('500, swing');
                $targetElement.removeClass('bounceIn');
                $targetElement.addClass('bounceOut');
            }

            $targetElement.removeClass('is-bottom');
            if ($(window).scrollTop() + $(window).height() > ($(document).height() - 100)) {
                $targetElement.addClass('is-bottom');
            }
        });
    }

    ShowToScroll();

    return (
        <div className="btn-floating js-show-to-scroll">
            <div className="social-links">
                <div className="facebook">
                    {" "}
                    <a href="https://www.facebook.com/HolidayHomesCanarias">
                        <img src="/images/web/hhc/facebook.png" />
                    </a>
                </div>
                <div className="instagram">
                    {" "}
                    <a href="https://www.instagram.com/holidayhomescanarias/">
                        <img src="/images/web/hhc/instagram.png" />
                    </a>
                </div>
                <div className="youtube">
                    {" "}
                    <a href="https://www.youtube.com/channel/UCknzY3LyNvGHQCffNIzgEVw">
                        <img src="/images/web/hhc/youtube.png" />
                    </a>
                </div>
                <div className="whatsapp">
                    {" "}
                    <a href="https://wa.me/+34689302854">
                        <img src="/images/web/hhc/whatsapp-bubble.png" />
                    </a>
                </div>{" "}
            </div>
        </div>
    );
};
export default BtnFloating;
