import { useTranslation } from 'react-i18next';

const Register = () => {
    const { t } = useTranslation();

    return (
        <div className="section pTop20" >
            <div className="section_wrapper clearfix">
                <div className="column_attr clearfix align_center p08">
                    <h6 className="landing4-heading grey positionContainer">{t("REGISTRO")}</h6>
                    <hr className="no_line margin15Auto"/>
                </div>

                <div className="column one column_blog unetetext textJustify">
                    <p>{t("Hay dos criterios básicos que deben cumplirse para formar parte de Apartur:")}</p>
                    
                    <ul>
                        <li>{t("Disponer de viviendas de uso turístico en la provincia de Barcelona.")}</li>
                        <li>{t("El alojamiento debe tener autorización municipal y estar inscrito en el Registro de Turismo de Cataluña.")}</li>
                    </ul>

                    <p>{t("Ser socio de Apartur significa disfrutar de los servicios que presta la Asociación, contar con la ayuda de los profesionales que integran el equipo técnico, tener acceso a formación especializada y estar informado de todos los temas relacionados con nuestro sector.")}</p>
                    <p>{t("Con tu participación como socio contribuirás a fortalecer la Asociación en la defensa y representación del sector.")}</p>
                    <p>{t("Al formar parte de una asociación de turismo también tiene una imagen positiva y apoyo, un punto a su favor ante clientes, comunidades y entidades públicas.")}</p>
                    <p>{t("Para iniciar el proceso de afiliación, puede rellenar el formulario con sus datos. Una vez recibida su solicitud, nos pondremos en contacto con usted y le informaremos detalladamente.")}</p>
                    <p>&nbsp;</p> 
                </div>
            </div>
        </div>
    )
}

export default Register;