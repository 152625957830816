import { React, useState} from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Content = ({lang, normativas, notas}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    const [showtitulo, setShowTitulo] = useState('block');

    const [shownormas, setShowNormas] = useState('none');
    const handleShowNormas = () => {
        if (shownormas == "none") {
            setShowNormas("block");
            setShowTitulo("none");
            setShowNotas("none");
        }
        else setShowNormas("none");
    }


    const [shownotas, setShowNotas] = useState('none');
    const handleShowNotas = () => {
        if (shownotas == "none") {
            setShowNotas("block");
            setShowTitulo("none");
            setShowNormas("none")
        }
        else setShowNotas("none");
    }



    const handleSearch = () => {
        let palabra = document.getElementById("paraula").value;
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/busca-notas', {
            palabra: palabra,
        },{
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            let respuesta = "";
            let notas =response.data.notas;

            if (notas.length>0)
            {
                respuesta +="<div class='column one column_blog' style='width:100%;padding-bottom: 3px;border-bottom: 1px solid  #E87C0C; display: inline-block;border-color: #E87C0C;margin-bottom:20px;'>";
                respuesta +="   <div class='column mcb-column one-second column_column' style='margin-bottom:10px'>";
                respuesta +="       <b>"+t("Título")+"</b>";
                respuesta +="   </div>";
                respuesta +="   <div class='column mcb-column one-sixth column_column' style='margin-bottom:10px;'>";
                respuesta +="       <b>"+t("Fecha")+"</b>";
                respuesta +="   </div>";
                respuesta +="   <div class='column mcb-column one-third column_column' style='margin-bottom:10px; text-align:center;'>";
                respuesta +="       <b>"+t("Enlace")+"</b>";
                respuesta +="   </div>";
                respuesta +="</div>";

                for(var i = 0; i < notas.length; i++) {
                    var unanota =notas[i];

                    respuesta += "<div class='column one column_blog' style='width:100%;padding-bottom: 3px;border-bottom: 1px solid  #E87C0C; display: inline-block;border-color: #E87C0C;margin-bottom:20px;'>";
                    respuesta += "  <div class='column mcb-column one-second column_column' style='margin-bottom:10px'>";
                    respuesta += "      <h5>";
                    respuesta += "          <img src='/images/apartur/corporation2-relations-pic2.png' width='15' height='15' style='margin-right: 10px;'> "+unanota.nombre;
                    respuesta += "      </h5>";
                    respuesta += "  </div>";
                    respuesta += "  <div class='column mcb-column one-sixth column_column' style='margin-bottom:10px'>";
                    respuesta += "      <h6 style='font-weight:400;'> "+new Date(unanota.created_at).toLocaleDateString()+"</h6>";
                    respuesta += "  </div>";
                    respuesta += "  <div class='column mcb-column one-third column_column' style='margin-bottom:10px; text-align:end;'>";
                    respuesta += "      <span style='font-size:12px'>"+unanota.respuesta+"</span>";
                    respuesta += "  </div>";
                    respuesta += "</div>";
                }
            }
            document.querySelectorAll(".notasres")[0].innerHTML=respuesta;
        })
    }

    const pressEnter = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleSearch();
        }
    }

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <div className="section mcb-section no-margin-h no-margin-v">
                            <div className="section_wrapper mcb-section-inner submenuweb">
                                <div className="clearfix wrap mcb-wrap one-second valign-top move-up mTop-80W" id="positionDivWhite"  data-mobile="no-up">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_image">
                                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                                <div className="image_wrapper">
                                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon1.png" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line m40" />
                                        </div>

                                        <div className="column mcb-column one column_column ">
                                            <div className="clearfix column_attr">
                                                <h4 className="fs-25 h4M0 " >
                                                    <a onClick={handleShowNormas} className="curpointer">{ t("NORMATIVA") }</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="clearfix wrap mcb-wrap one-second valign-top move-up mTop-80O" id="positionDivOange" data-mobile="no-up">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_image">
                                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                                <div className="image_wrapper">
                                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon3.png"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line h4M0"/>
                                        </div>

                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix column_attr">
                                                <h4 className="fs-25 h4M0">
                                                    <a onClick={handleShowNotas} className="curpointer">{t("NOTAS") }</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section" style={{paddingTop:"60px"}}>
                            <div className="clearfix section_wrapper">
                                <div className="clearfix column_attr align_center"  style={{display: showtitulo }}>
                                    <h6 className="landing4-heading grey" style={{paddingBottom: "3px", borderBottom: "1px solid #E87C0C",  display: "inline-block" , borderColor: "#E87C0C" }}>{ t("Seleccione la sección a visualizar") }</h6>
                                </div>

                               {/* **** NORMATIVA ***** */}
                               <div className="sections_group normas" style={{ display: shownormas }}>
                                    <div className="section">
                                        <div className="clearfix section_wrapper">
                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C"}}>{t("NORMATIVA")}</h6>
                                                <hr className="no_line" style={{margin: "0 auto 15px auto"}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="column one column_blog">
                                                    {
                                                        normativas.map((unanorma, index) =>(
                                                            <div className="column mcb-column one-third column_column minh-300" style={{borderBottom: "1px solid  #E87C0C"}}  key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/apartur/corporation2-relations-pic2.png" width="30" height="36" className="imgnorma"/>
                                                                    {unanorma.nombre}
                                                                </h3>
                                                                <h6 style={{fontWeight:"400"}}> { new Date(unanorma.created_at).toLocaleDateString()}</h6>
                                                                <div style={{ width:"100%", paddingBottom: "3px", display: "inline-block", marginBottom:"20px", lineHeight:"21px"}} className="fs-13" dangerouslySetInnerHTML={{__html: unanorma.respuesta}}>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ****FIN NORMATIVA ***** */}

                                {/* **** NOTAS ***** */}
                                <div className="sections_group notas" style={{ display: shownotas }}>
                                    <div className="section">
                                        <div className="clearfix section_wrapper">
                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C"}}>{t("NOTAS")}</h6>
                                                <hr className="no_line" style={{ margin: "0 auto 15px auto"}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper" style={{ maxWidth:"200%"}}>
                                                <div className="clearfix items_group">
                                                    <div className="column one column_column">
                                                        <div className="column_attr">
                                                            <div id="contactWrapper">
                                                                <form id="notasform">
                                                                    <div className="column one-third">
                                                                        <label className="labelsearch">
                                                                            <input type="text" name="paraula" id="paraula" aria-required="true" aria-invalid="false" placeholder={t(" Buscar")} onKeyPress={(e) => pressEnter(e)}/>
                                                                        </label>
                                                                    </div>
                                                                    <div className="column one-fourth">
                                                                        <input type="button" value="Buscar" id="submit_popup" onClick={handleSearch}/>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="notasres">
                                                    <div className="column one column_blog" style={{ width:"100%",paddingBottom: "3px", borderBottom: "1px solid #E87C0C", display: "inline-block" , borderColor:" #E87C0C", marginBottom:"20px"}}>
                                                        <div className="column mcb-column one-second column_column" style={{marginBottom:"10px"}}>
                                                            <b>{t("Título")}</b>
                                                        </div>
                                                        <div className="column mcb-column one-sixth column_column" style={{marginBottom:"10px"}}>
                                                            <b>{t("Fecha")}</b>
                                                        </div>
                                                        <div className="column mcb-column one-third column_column" style={{marginBottom:"10px", textAlign:"center"}}>
                                                            <b>{("Enlace")}</b>
                                                        </div>
                                                    </div>
                                                    {
                                                        notas.map((unanota, index) =>(
                                                            <div className="column one column_blog" style={{ width:"100%", paddingbBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C", marginBottom:"20px"}} key={index}>
                                                                <div className="column mcb-column one-second column_column" style={{marginBottom:"10px"}}>
                                                                    <h5>
                                                                        <img src="/images/apartur/corporation2-relations-pic2.png" width="15" height="15" style={{marginRight: "10px"}}/>
                                                                        { unanota.nombre }
                                                                    </h5>
                                                                </div>
                                                                <div className="column mcb-column one-sixth column_column" style={{marginBottom:"10px"}}>
                                                                    <h6 style={{fontWeight:"400"}}> { new Date(unanota.created_at).toLocaleDateString()}</h6>
                                                                </div>
                                                                <div className="column mcb-column one-third column_column" style={{marginBottom:"10px", textAlign:"end"}}>
                                                                    <span style={{ fontSize:"12px"}} dangerouslySetInnerHTML={{__html: unanota.respuesta}}>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* **** FIN NOTAS ***** */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
