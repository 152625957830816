import Cabecera from './Compartido/Cabecera.jsx';
import Footer from './Compartido/Footer.jsx';
import '../../../../../resources/css/web/reysabio/styles.css';
import EsenciaCasa from './LaCasa/EsenciaCasa.jsx';
import Detalles from './LaCasa/Detalles.jsx';
import Caracteristicas from './LaCasa/Caracteristicas.jsx';

const LaCasa = (props) => {
    return (
        <main>
            <Cabecera
                lang={props.lang}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
                config={props.websgrupo}
            />
            <EsenciaCasa />
            <Detalles />
            <Caracteristicas />
            <Footer
                lang={props.lang}
            />
        </main>
    );
}

export default LaCasa;

