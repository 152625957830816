import Menu from './Compartido/Menu.jsx';
import TextosEdificio from './Edificio/TextosEdificio.jsx';
import PropiedadesEdificio from './Edificio/PropiedadesEdificio.jsx';
import Footer from './Compartido/Footer.jsx';

const Edificio = (props) => {
   
    return (
        <main>
            <Menu 
                destinos = {props.destinos}
                sobrenosotros = {props.textosobrenosotros} 
                clientes = {props.textoclientes}
                propietarios = {props.textopropietarios}
                servicios = {props.textoservicios}
                experiencias = {props.experiencias}
                idiomas = {props.idiomas}
                lang = {props.lang}
                tiposcasas = {props.tiposcasas}
                config = {props.websgrupo}
                textopropiedades = {props.textopropiedades}
                edificios = {props.edificios}
                marcas = {props.marcas}
                ruta = {props.ruta}
                destinoslarga = {props.destinoslarga}
                meta_titulo = {props.meta_titulo}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />
            <TextosEdificio
                texto={props.eledificio} 
                ruta={props.ruta}
                lang = {props.lang}
            />
            <PropiedadesEdificio 
                propiedades={props.propiedades} 
                lang = {props.lang}
                config = {props.websgrupo}
                ruta = {props.ruta}
            />
            <Footer 
                datos = {props.websgrupo}
                terminos = {props.textoterminos}
                protocolos = {props.textoprotocolos}
                avisolegal = {props.textoavisolegal}
                config = {props.config}
                elementosweb = {props.elementosweb}
                politicaprivacidad = {props.textopolitica}
                lang = {props.lang}
                ruta = {props.ruta}
                logos_pie = {props.logos_pie}
            />
        </main>
    );
}

export default Edificio;