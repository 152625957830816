import React from "react";
import { useTranslation } from 'react-i18next';

const Content = ({ lang, noticia, ruta, notanterior, notsiguiente, populares, websgrupo }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div id="post-45" className="no-title share-simple post format-standard has-post-thumbnail hentry category-events">
                        {
                            notanterior ?
                                <a className="fixed-nav fixed-nav-prev format- style-default" href={"/" + preidioma + t("actualidad") + "/" + notanterior.laurl}>
                                    <span className="arrow">
                                        <i className="fa-solid fa-less-than"></i>
                                    </span>

                                    <div className="photo">
                                        {notanterior.imagen != ""
                                            ? <img src={ruta + "/blog/" + notanterior.id + "/" + notanterior.imagen} className="attachment-blog-navi size-blog-navi wp-post-image" alt="" />
                                            : <img src="images/web/bedloop-nofoto.png" className="attachment-blog-navi size-blog-navi wp-post-image" alt="" />
                                        }
                                    </div>
                                    <div className="desc">
                                        <h6>{notanterior.eltitulo}</h6>
                                        <span className="date"><i className="fa-regular fa-clock"></i>{new Date(notanterior.fecha_publica).toDateString()}</span>
                                    </div>
                                </a>
                                :
                                ""
                        }
                        {
                            notsiguiente ?
                                <a className="fixed-nav fixed-nav-next format- style-default" href={"/" + preidioma + t("actualidad") + "/" + notsiguiente.laurl}>
                                    <span className="arrow">
                                        <i className="fa-solid fa-greater-than"></i>
                                    </span>

                                    <div className="photo">
                                        {notsiguiente.imagen != ""
                                            ? <img src={ruta + "/blog/" + notsiguiente.id + "/" + notsiguiente.imagen} className="attachment-blog-navi size-blog-navi wp-post-image" alt="" />
                                            : <img src="images/web/bedloop-nofoto.png" className="attachment-blog-navi size-blog-navi wp-post-image" alt="" />
                                        }
                                    </div>
                                    <div className="desc">
                                        <h6>{notsiguiente.eltitulo}</h6>
                                        <span className="date"><i className="fa-regular fa-clock"></i>{new Date(notsiguiente.fecha_publica).toDateString()}</span>
                                    </div>
                                </a>
                                :
                                ""

                        }

                        <div className="post-wrapper-content">
                            <div className="section mcb-section" style={{ paddingTop: "60px" }}>
                                <div className="section_wrapper mcb-section-inner">
                                    <div className="clearfix wrap mcb-wrap one valign-top">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-columnone column_column" style={{ margin: "0 1% 0px" }}>
                                                <div className="clearfix column_attr">
                                                    <h3>{noticia.elsubtitulo}</h3>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one column_divider">
                                                <hr className="no_line" style={{ margin: " 0 auto 0px" }} />
                                            </div>

                                            <div className="column mcb-columnone column_column">
                                                <div className="clearfix column_attr" dangerouslySetInnerHTML={{ __html: noticia.ladesclarga }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section section-post-footer">
                                <div className="clearfix section_wrapper">
                                    <div className="column one post-pager"></div>
                                </div>
                            </div>
                            <div className="section section-post-intro-share">
                                <div className="clearfix section_wrapper">
                                    <div className="column one">
                                        <div className="share-simple-wrapper">
                                            <span className="share-label">{t("Compartir")}</span>
                                            <div className="icons">
                                                <a target="_blank" className="facebook" href={"https://www.facebook.com/sharer/sharer.php?u=" + websgrupo.url + "/" + preidioma + t("actualidad") + "/" + noticia.laurl}><i className="fa-brands fa-facebook-f"></i></a>
                                                <a target="_blank" className="twitter" href={"https://twitter.com/intent/tweet?text=" + noticia.eltitulo + "+" + websgrupo.url + "/" + preidioma + t("actualidad") + "/" + noticia.laurl}><i className="fa-brands fa-twitter"></i></a>
                                                <a target="_blank" className="linkedin" href={"https://www.linkedin.com/shareArticle?mini=true&amp;url=" + websgrupo.url + t("actualidad") + "/" + preidioma + "/" + noticia.laurl + "&title=" + noticia.eltitulo + "&source="}><i className="fa-brands fa-linkedin-in"></i></a>
                                                <a target="_blank" className="pinterest" href={"https://pinterest.com/pin/find/?url=" + websgrupo.url + "/" + preidioma + "/" + noticia.laurl}><i className="fa-brands fa-pinterest-p"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section section-post-related">
                            <div className="clearfix section_wrapper">
                                <div className="section-related-adjustment simple">
                                    <h4>{t("Más Notícias")}</h4>
                                    <div className="section-related-ul">
                                        {
                                            populares.map((popular, index) => (
                                                index < 3 ?
                                                    <div className="column post-related post format-standard has-post-thumbnail hentry" key={index}>
                                                        <div className="single-photo-wrapper image">
                                                            <div className="image_frame scale-with-grid">
                                                                <div className="image_wrapper border-radius">
                                                                    <a href={"/" + preidioma + popular.laurl}>
                                                                        <div className="mask"></div>
                                                                        {popular.imagen != ""
                                                                            ? <img src={ruta + "/blog/" + popular.id + "/" + popular.imagen} className="scale-with-grid wp-post-image min-height" alt="" />
                                                                            : <img src="images/web/bedloop-nofoto.png" className="scale-with-grid wp-post-image min-heighte" alt="" />
                                                                        }
                                                                    </a>
                                                                    <div className="image_links double">
                                                                        <a href={"/" + preidioma + t("actualidad") + "/" + popular.laurl} className="zoom" rel="prettyphoto">
                                                                            <i className="fa-solid fa-magnifying-glass"></i>
                                                                        </a>
                                                                        <a href={"/" + preidioma + t("actualidad") + "/" + popular.laurl} className="link">
                                                                            <i className="fa-solid fa-link"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="date_label">
                                                            {popular.fecha_publica}
                                                        </div>
                                                        <div className="desc">
                                                            <h4>
                                                                <a href={"/" + preidioma + t("actualidad") + "/" + popular.laurl}>
                                                                    {popular.eltitulo}
                                                                </a>
                                                            </h4>
                                                            <hr className="hr_color" />

                                                            <a href={"/" + preidioma + t("actualidad") + "/" + popular.laurl} className="post-more">
                                                                <i className="fa-regular fa-file-lines"></i> {t("Leer más")}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
