
import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextosGraciasStripe = () => {
    const { t } = useTranslation();
    return (
        <div className="container mt-5">
            <h3 className="centra">{t("¡Gracias por su reserva!")}</h3>
        </div>
    );
}

export default TextosGraciasStripe;
