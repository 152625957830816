import React from "react";
import { useTranslation } from 'react-i18next';

const TextoCancelado = () => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container-fluid w-100" style={{ marginTop: "300px", padding: "0 100px" }}>
                <h1 className="titulopagina">{t("Gracias")}</h1>
                <hr />
            </div>
            <div className="container-fluid w-100">
                <p>{t("Gracias por tu reserva")}</p>
            </div>
        </>
    );
};
export default TextoCancelado;
