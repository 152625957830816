import React from "react";
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';

const TextoFaqs = ({ categorias, faqs, lang }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container-fluid w-100 padlados100" style={{ marginTop: "300px" }}>
                <h1 className="titulopagina">{t("FAQs")}</h1>
            </div>
            <div className="container-fluid w-100 padlados100">

                {categorias.map((categoria, index) => (
                    <div key={index}>
                        <hr style={{ borderTop: "2px solid rgb(51, 51, 51)" }} />
                        <div className="container-fluid w-100 d-flex">
                            <h2 className="d-flex noto faqmobile1" style={{ flex: "1", fontWeight: "400", fontSize: "29px" }}>{categoria.nombre[lang]}</h2>
                            <div className="d-flex faqmobile2" style={{ flex: "3" }}>
                                <Accordion className="losfaqs">
                                    {faqs.filter(faq => faq.categoria_id == categoria.id).map((faq, index2) => (
                                        <Accordion.Item eventKey={index + "" + index2} key={"f" + index2}>
                                            <Accordion.Header >{faq.pregunta[lang]}</Accordion.Header>
                                            <Accordion.Body>
                                                <div dangerouslySetInnerHTML={{ __html: faq.respuesta[lang] }}></div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
export default TextoFaqs;
