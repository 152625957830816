import { React, useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import axios from "axios";

import { useTranslation } from 'react-i18next';

const MapaContacto = ({ config }) => {

    const { t } = useTranslation();

    const [address, setAddress] = useState(config.direccion_fiscal + "," + config.codigo_postal_fiscal + "," + config.poblacion_fiscal);
    const [address2, setAddress2] = useState(config.poblacion_fiscal);
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        if (address === "") {
            return;
        }

        axios
            .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address}`)
            .then((res) => {
                if (res.data.length > 0) {
                    setCoordinates([res.data[0].lat, res.data[0].lon]);
                }
                else {
                    axios
                        .get(`https://nominatim.openstreetmap.org/search?format=json&q=${address2}`)
                        .then((res) => {
                            if (res.data.length > 0) {
                                setCoordinates([res.data[0].lat, res.data[0].lon]);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                }
            })
            .catch((err) => {
                console.error(err);
            });

    }, [address, address2]);

    return (
        <>
            <div className="mapacontacto pt-1 pb-5">
                {coordinates.length > 0 && (
                    <MapContainer center={coordinates} zoom={16} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                            url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                        />
                        <Marker position={coordinates}>
                        </Marker>
                    </MapContainer>
                )}
                <div className="noticia-individual">
                    <p><i className="fa-duotone fa-map-location"></i>  {config.direccion_fiscal}, {config.codigo_postal_fiscal}, {config.poblacion_fiscal}</p>
                    <p><i className="fa-duotone fa-mobile"></i>  {config.telefono_fiscal}</p>
                    <p><i className="fa-duotone fa-envelope"></i>  {config.email_fiscal}</p>
                </div>
            </div>
        </>
    );
}

export default MapaContacto;
