import React, { useState } from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';

const Seccion1 = ({ adultos, ninos, bebes, llegada, salida, lang }) => {

    const { t } = useTranslation();

    registerLocale('es', es)

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    const [arrival, setArrival] = useState(entr);
    const [departure, setDeparture] = useState(sali);

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="container-fluid section1 w-100">
            <div>
                <h1 className="noto">{t("Nuestros apartamentos")}</h1>
            </div>
            <div>
                <p>{t("Exclusivamente decorados cuidando al máximo cada detalle, contamos con apartamentos independientes que se adaptan a todas tus necesidades")}</p>
            </div>


            <div className="p-0 container-fluid d-flex justify-content-center box-form">
                <div className="d-flex justify-content-center apartamentos-">
                    <form className="container d-flex form-apartamentos" method="get" name="Nuevo formulario" action={"/" + preidioma + t("buscar")} style={{ zIndex: "5" }} id="formcerca">
                        <div className="grid-main-form">
                            <div className="form-content d-flex">
                                <div>
                                    <label htmlFor="form-field-name" className="elementor-field-label">{t("Fecha entrada")}</label>
                                    <DatePicker
                                        id="form-field-name"
                                        className="elementor-field elementor-size-sm elementor-field-textual elementor-date-field entradahome inp-text"
                                        autoComplete="off"
                                        selected={arrival}
                                        startDate={arrival}
                                        onChange={(date) => setArrival(date)}
                                        dateFormat="yyyy/MM/dd"
                                        placeholderText="dd / mm / aaaa"
                                        locale={lang}
                                        minDate={new Date()}
                                        name="arrival"
                                    >
                                    </DatePicker>
                                </div>
                                <div>
                                    <label htmlFor="form-field-name" className="elementor-field-label">{t("Fecha salida")}</label>
                                    <DatePicker
                                        id="form-field-field_451cb70"
                                        className="inp-text entradahome"
                                        autoComplete="off"
                                        selected={departure}
                                        startDate={departure}
                                        onChange={(date) => setDeparture(date)}
                                        dateFormat="yyyy/MM/dd"
                                        placeholderText="dd / mm / aaaa"
                                        locale={lang}
                                        minDate={new Date()}
                                        name="departure"
                                    >
                                    </DatePicker>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº adultos")}</label>
                                        <span className="small-text tnegre"> ({t("Desde 12 años")})</span>
                                    </div>
                                    <div className="number-input w-100">
                                        <input type="number" name="adultos" id="form-field-field_3fde374" className="elementor-field elementor-size-sm elementor-field-textual inp-number" placeholder="0" min="1" max="6" defaultValue={adultos} />
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº niños")}</label>
                                        <span className="small-text tnegre"> ({t("De 2 a 12 años")})</span>
                                    </div>
                                    <div className="number-input w-100">
                                        <input type="number" name="ninos" id="form-field-field_3fde374" className="elementor-field elementor-size-sm elementor-field-textual inp-number" placeholder="0" min="0" max="6" defaultValue={ninos} />
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº bebés")}</label>
                                        <span className="small-text tnegre"> ({t("De 0 a 2 años")})</span>
                                    </div>
                                    <div className="number-input w-100">
                                        <input type="number" name="bebes" id="" placeholder="0" min="0" max="6" defaultValue={bebes} className="inp-text" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center w-100">
                                <button type="submit" className="noto btn-buscar amayusculas">{t("Buscar")}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="p-0 container-fluid d-flex w-100">
                <ul className="list-unstyled d-flex align-items-center sec1-opt">
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="114" />
                            <button type="submit" className="noto">{t("Estudio")}</button>
                        </form>
                    </li>
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="109,110,111,112,113" />
                            <button type="submit" className="noto">{t("Estándar")}</button>
                        </form>
                    </li>
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="116,117,118,119,120,121,122" />
                            <button type="submit" className="noto">{t("Superior")}</button>
                        </form>
                    </li>
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="123" />
                            <button type="submit" className="noto">{t("Presidencial")}</button>
                        </form>
                    </li>
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="115" />
                            <button type="submit" className="noto">{t("Dúplex")}</button>
                        </form>
                    </li>
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="123,124,125" />
                            <button type="submit" className="noto">{t("Terraza")}</button>
                        </form>
                    </li>
                    <li>
                        <form action={"/" + preidioma + t("buscar")} method="get">
                            <input type="hidden" name="idPropiedades" value="123,124" />
                            <button type="submit" className="noto">{t("Piscina")}</button>
                        </form>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default Seccion1;
