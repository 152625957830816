import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const CajaSeparacion = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container">
            <div
                className="row cta-box cta-negative js-scroll-show" style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}>
                <div className="col-12 col-lg-7">
                    <h2 className="title title--h2">
                        {t("Convéncete tú mismo de tu estancia inolvidable")}.
                    </h2>
                    <p className="paragraph">
                        {t("Recárgate con el eterno sol y desconecta de tu agitada vida cotidiana")}.
                    </p>
                </div>
                <div className="col-12 col-lg-5 text-lg-right">
                    <a href={"/" + preidioma + t("buscar")} className="btn btn__large btn--white black-text">
                        ¡{t("Reserva ya")}!
                    </a>
                </div>
            </div>
        </section>

    );
};
export default CajaSeparacion;
