import Menu from './Compartido/Menu.jsx';
import MapaContacto from './Contacto/MapaContacto.jsx';
import FormularioContacto from './Contacto/FormularioContacto.jsx';
import Footer from './Compartido/Footer.jsx';

const Contacto = (props) => {
    return (
        <main>
             <Menu
                destinos = {props.destinos}
                sobrenosotros = {props.textosobrenosotros}
                clientes = {props.textoclientes}
                propietarios = {props.textopropietarios}
                servicios = {props.textoservicios}
                experiencias = {props.experiencias}
                idiomas = {props.idiomas}
                lang = {props.lang}
                tiposcasas = {props.tiposcasas}
                config = {props.config}
                textopropiedades = {props.textopropiedades}
                edificios = {props.edificios}
                marcas = {props.marcas}
                ruta = {props.ruta}
            />
            <MapaContacto
                config ={props.config}
            />
            <FormularioContacto
                lang = {props.lang}
                config = {props.websgrupo}
            />
            <Footer
                datos = {props.websgrupo}
                terminos = {props.textoterminos}
                protocolos = {props.textoprotocolos}
                avisolegal = {props.textoavisolegal}
                config = {props.config}
                elementosweb = {props.elementosweb}        
                politicaprivacidad = {props.textopolitica}   
                lang = {props.lang} 
                ruta = {props.ruta}
            />
        </main>
    );
}

export default Contacto;
