import { React } from "react";
import { useTranslation } from 'react-i18next';
import codigoImagen from './CajaImagen.jsx';

const Destinos = ({ destinos, ruta, config, lang }) => {

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const { t } = useTranslation();

    const parseArray6 = (num) => {
        const chunks = []
        while (num > 6) chunks.push(6), num -= 6;
        chunks.push(num);
        return chunks;
    }

    const estructuraNumPar = num => {
        let primerosDestinos = destinosCopy.slice(0, num);
        destinosCopy.splice(0, num);
        primerosDestinos.forEach((undestino, index) => {
            tempResult = tempResult.concat(
                codigoImagen(undestino, preidioma, ruta, t, "destacats-imatge-desti", index)
            );
        });
        result = result.concat(
            <div className={`destacats-desti desti-grid${num}`}>
                {tempResult}
            </div>
        );
        tempResult = [];
    }

    let result = [];
    let tempResult = []
    let array = parseArray6(destinos.length)
    let destinosCopy = destinos.slice();

    {
        for (let i = 0; i < array.length; i++) {
            if (array[i] === 1) {
                let primerosDestinos = destinosCopy.slice(0, 1);
                destinosCopy.splice(0, 1);
                primerosDestinos.forEach((undestino, index) => {
                    tempResult = tempResult.concat(
                        codigoImagen(undestino, preidioma, ruta, t, "destacats-imatge-desti", "", index)
                    );
                });
                result = result.concat(
                    <div className="destacats-desti" key={"d1" + i}>
                        {tempResult}
                    </div>
                );
                tempResult = [];
            }
            else if (array[i] === 2)
                estructuraNumPar(2);
            else if (array[i] === 3) {
                let primerosDestinos = destinosCopy.slice(0, 3);
                destinosCopy.splice(0, 3);
                primerosDestinos.forEach((undestino, index) => {
                    tempResult = tempResult.concat(
                        codigoImagen(undestino, preidioma, ruta, t, (index == 2) ? "destacats-imatge-desti3" : "destacats-imatge-desti", (index == 2) ? "mt-2" : "", index)
                    );
                });
                result = result.concat(
                    <div className="destacats-desti desti-grid3" key={"d3" + i}>
                        {tempResult}
                    </div>
                );
                tempResult = [];
            }
            else if (array[i] === 4)
                estructuraNumPar(4)
            else if (array[i] === 5) {
                let primerosDestinos = destinosCopy.slice(0, 5);
                destinosCopy.splice(0, 5);
                primerosDestinos.forEach((undestino, index) => {
                    tempResult = tempResult.concat(
                        codigoImagen(undestino, preidioma, ruta, t, (index == 3 || index == 4) ? "destacats-imatge-desti5" : "destacats-imatge-desti", (index != 3 || index != 4) ? "d-block" : "", index)
                    );
                });
                result = result.concat(
                    <div className="destacats-desti desti-grid5" key={"d5" + i}>
                        {tempResult}
                    </div>
                );
                tempResult = [];
            }
            else if (array[i] === 6)
                estructuraNumPar(6)
            else
                null
        }
    }

    {/* ************* */ }

    return (
        <>
            <h2 className="text-center">{t("Nuestros destinos")}</h2>
            <div className="destacats flex-dColumn" style={{ gap: '25px' }}>
                {result}
            </div>
        </>
    )
}
export default Destinos;
