import { useTranslation } from 'react-i18next';

const Section = () => {
    const { t } = useTranslation();

    return(
        <div className="container">
            <div className="column one">
                <p>
                    <b>{t("Apartur es miembro de:")} </b>
                </p>
            </div>
        
            <div className="column one">
                <div className="column one-fourth mbcolaboradors alignCenter">
                    <img src="/images/apartur/section/federatur.jpg" height="50"/>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter" >
                    <a href="http://www.fevitur.com" target="_blank">
                        <img src="/images/apartur/section/fevitur2.png" height="50"/>
                        </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter">
                    <a href="https://www.pimec.org" target="_blank">
                        <img src="/images/apartur/section/Pimec.png" height="140" className="mTop-40"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter" >
                    <a href="http://www.foment.com" target="_blank">
                        <img src="/images/apartur/section/foment.png" height="65"/>
                    </a>
                </div>
            </div>


            <div className="column one">
                <div className="column one-fourth mbcolaboradors alignCenter">
                    <a href="https://cercledeconomia.com" target="_blank">
                        <img src="/images/apartur/section/cercle-economia.png" height="40"/>
                        </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter">
                    <a href="https://barcelonaoberta.cat" target="_blank"> 
                        <img src="/images/apartur/section/BarcelonaOberta.jpg" height="60"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter">
                    <img src="/images/apartur/section/agencia_catalana_turisme.png" height="60"/>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter" >
                    <a href="http://www.barcelonaturisme.com/" target="_blank">
                        <img src="/images/apartur/section/Turisme-Barcelona-1.jpg" height="50"/>
                    </a>
                </div>
            </div>



            <div className="column one">
                <p><b> {t("Apartur colabora con") }: </b></p>
            </div>

            <div className="column one">
                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="https://www.cambrabcn.org" target="_blank">
                        <img src="/images/apartur/section/cambra-barcelona.png" height="40"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="http://act.gencat.cat/" target="_blank">
                        <img src="/images/apartur/section/agencia_catalana_turisme.jpg" height="60"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="http://www.institutoturismoresponsable.com/" target="_blank">
                        <img src="/images/apartur/section/instituto-internacional-responsable_.png" height="55" />
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="https://www.biospheretourism.com/" target="_blank">
                        <img src="/images/apartur/section/Biosphere.jpg" height="80"/>
                    </a>
                </div>
            </div>
            <div className="column one">
                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="https://mossos.gencat.cat/ca/consells_de_seguretat/" target="_blank">
                        <img src="/images/apartur/section/BannerMossos.png" height="50"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="http://www.casaldelsinfants.org/" target="_blank">
                        <img src="/images/apartur/section/casal-dels-infants4.png" height="60"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="https://www.fundacioroure.org/" target="_blank">
                        <img src="/images/apartur/section/Roure.png" height="60"/>
                    </a>
                </div>

                <div className="column one-fourth mbcolaboradors alignCenter"  >
                    <a href="https://iqs.edu/" target="_blank">
                        <img src="/images/apartur/section/IQS.png" height="60"/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Section;