import { React } from "react";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Carousel } from 'react-bootstrap';
import 'react-lazy-load-image-component/src/effects/blur.css';

import ImagePopup from "../Compartido/ImagePopup.jsx";

import "./../../../../../css/magno/destacados.css";

const ListaDestacados = ({ ruta, destacadas, config, lang, preidioma }) => {

    const { t } = useTranslation();

    let results = [];
    let caracs = [];
    let contador = 0;
    let lasfotosgaleria = [];

    {/* ************* */ }

    return (
        <>
            {destacadas.forEach((propiedad, index) => {
                if (index < 9) {
                    caracs = [];
                    contador = 0;
                    lasfotosgaleria = [];

                    const fotos = propiedad.fotos_web;

                    fotos.map((unafoto, index) => (

                        lasfotosgaleria = lasfotosgaleria.concat(
                            <Carousel.Item key={index}>
                                <a href={"https://www.magnoapartments.com/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica}>
                                    <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre} style={{ height: "260px" }} />
                                </a>
                            </Carousel.Item>
                        )

                    ));

                    {
                        propiedad.caracteristicas.forEach((carac, index2) => {
                            if (carac.imagen.includes("glyph iconbe-Horno")) {
                                if (contador > 0 && index2 === propiedad.caracteristicas.length - 1) {
                                    caracs = caracs.concat(
                                        ", " + carac.nombre[lang]
                                    );
                                    contador++;
                                }
                                else if (contador > 0 && index2 !== propiedad.caracteristicas.length - 1) {
                                    caracs = caracs.concat(
                                        ", " + carac.nombre[lang]
                                    );
                                    contador++;
                                }
                                else {
                                    caracs = caracs.concat(
                                        carac.nombre[lang]
                                    );
                                    contador++;
                                }
                            }
                        })
                    }


                    results = results.concat(
                        <div className="col-md-4 elementor-top-column elementor-element elementor-element-8602aa5 divbox-destacados-conatiner" data-id="8602aa5" data-element_type="column" key={index} style={{ minHeight: "1px", paddingLeft: "6px", paddingRight: "6px" }}>
                            <div className="elementor-widget-wrap elementor-element-populated e-swiper-container destacados-container" style={{ borderStyle: "solid", borderWidth: "1px 1px 1px 1px", borderColor: "#DEDEDE", transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s", display: "flex", minHeight: "660px" }}>
                                <div className="elementor-element elementor-element-476abb2 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel e-widget-swiper" data-id="476abb2" data-element_type="widget" data-settings="{&quot;slides_to_show&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;autoplay&quot;:&quot;no&quot;,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}" data-widget_type="image-carousel.default" style={{ marginBottom: "20px" }}>
                                    <div className="elementor-widget-container">
                                        <div className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal" dir="ltr">
                                            <Carousel slide={true} controls={true} indicators={false} interval={null}>
                                                {lasfotosgaleria}
                                            </Carousel>

                                        </div>
                                    </div>
                                </div>

                                <div className="elementor-element elementor-element-86a8e7c elementor-widget elementor-widget-text-editor" data-id="86a8e7c" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div className="elementor-widget-container" style={{ margin: "9px 23px 0px 23px", padding: "0px" }}>
                                        <h3>
                                            <a className="topropiedad titulo-propiedad" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica} target="_blank">{propiedad.nombre}</a>
                                        </h3>
                                    </div>
                                </div>

                                <div className="elementor-element elementor-element-5e29aac elementor-widget elementor-widget-image" data-id="5e29aac" data-element_type="widget" data-widget_type="image.default" style={{ textAlign: "center" }}>
                                    <div className="elementor-widget-container" style={{ fontSize: "11px", fontWeight: "300" }}>
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676119123872 vc_row-has-fill vc_row-o-content-middle vc_row-flex" style={{ display: "flex", flexWrap: "inherit", justifyContent: "center", margin: "0 12px" }}>
                                            <div className="wpb_column vc_column_container vc_col-sm-2" style={{}}>
                                                <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "0px", width: "100%" }}>
                                                    <div className="wpb_wrapper">
                                                        <div className="aio-icon-component style_1">
                                                            <div id="Info-box-wrap-5578" className="aio-icon-box top-icon">
                                                                <div className="aio-icon-top">
                                                                    <div className="ult-just-icon-wrapper">
                                                                        <div className="align-icon">
                                                                            <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                <img className="img-icon" alt="null" src="/images/web/magno/2023-01-per-3.png" style={{ height: "20px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-5578 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedad.capacidad_maxima + " " + t('Personas')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-2" style={{}}>
                                                <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "25px", width: "100%" }}>
                                                    <div className="wpb_wrapper">
                                                        <div className="aio-icon-component style_1">
                                                            <div id="Info-box-wrap-6397" className="aio-icon-box top-icon">
                                                                <div className="aio-icon-top">
                                                                    <div className="ult-just-icon-wrapper ">
                                                                        <div className="align-icon">
                                                                            <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                <img className="img-icon" alt="null" src="/images/web/magno/2023-01-mt-2.jpg" style={{ height: "20px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6397 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedad.metros_cuadrados + " " + t('M2')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-2" style={{}}>
                                                <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "25px", width: "100%" }}>
                                                    <div className="wpb_wrapper">
                                                        <div className="aio-icon-component style_1">
                                                            <div id="Info-box-wrap-6663" className="aio-icon-box top-icon">
                                                                <div className="aio-icon-top">
                                                                    <div className="ult-just-icon-wrapper">
                                                                        <div className="align-icon">
                                                                            <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                <img className="img-icon" alt="null" src="/images/web//magno/2023-01-ban-2.jpg" style={{ height: "20px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6663 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedad.num_banyos + " " + t('Baños')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wpb_column vc_column_container vc_col-sm-3" style={{}}>
                                                <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "25px", width: "100%" }}>
                                                    <div className="wpb_wrapper">
                                                        <div className="aio-icon-component style_1">
                                                            <div id="Info-box-wrap-6716" className="aio-icon-box top-icon">
                                                                <div className="aio-icon-top">
                                                                    <div className="ult-just-icon-wrapper ">
                                                                        <div className="align-icon">
                                                                            <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                <img className="img-icon" alt="null" src="/images/web/magno/2023-01-cama-2.jpg" style={{ height: "20px" }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6716 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedad.num_habitaciones + " " + t('Dormitorios')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="elementor-element elementor-element-5e29aac elementor-widget elementor-widget-image" data-id="5e29aac" data-element_type="widget" data-widget_type="image.default">
                                    <div className="elementor-widget-container">
                                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676119123872 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                                <div className="vc_column-inner">
                                                    <div className="mt-3 wpb_wrapper">
                                                        <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-5578 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ textAlign: "left", marginLeft: "20px", minHeight: "60px", fontSize: "11px", lineHeight: "20px", fontWeight: "350", minHeight: "60px", paddingRight: "20px", fontFamily: "'Roboto', sans-serif" }}>

                                                            {caracs}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="elementor-element elementor-element-7625ecd elementor-widget elementor-widget-wp-widget-custom_html" data-id="7625ecd" data-element_type="widget" data-widget_type="wp-widget-custom_html.default">
                                    <div className="elementor-widget-container">
                                        <div className="textwidget custom-html-widget">
                                            <hr style={{ margin: "20px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-element elementor-element-3f8a4d8 elementor-button-info elementor-align-center elementor-widget elementor-widget-button" data-id="3f8a4d8" data-element_type="widget" data-widget_type="button.default">
                                    <div className="elementor-widget-container">
                                        <div className="mb-2 elementor-button-wrapper">
                                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica} className="topropiedad elementor-button-link elementor-button elementor-size-xs elementor-animation-shrink bgcolorcorporativo" role="button" style={{ cursor: "pointer", fontWeight: "300", fill: "#FFFFFF", color: "#FFFFFF", borderStyle: "none", borderRadius: "0px 0px 0px 0px" }}>
                                                <span className="elementor-button-content-wrapper" style={{ display: "flex", justifyContent: "center" }}>
                                                    <span className="elementor-button-text-destacados mayusculacss bgcolorcorporativo"> {t("RESERVA")}</span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
            {results}
        </>
    );
}

const ListaDestacadosMobile1 = ({ ruta, destacadas, config, lang, preidioma }) => {

    const { t } = useTranslation();
    let results = [];

    return (
        <>
            {destacadas.forEach((propiedad, index) => {
                if (index < 4) {
                    const fotos = propiedad.fotos_web;
                    results = results.concat(
                        <div className="image-container" key={index}>
                            <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + fotos[0].nombre} />
                            <div className="overlay">
                                <div>
                                    <p className="elementor-flip-box__layer__description">{propiedad.nombre}</p>
                                    <a className="elementor-flip-box__button elementor-button elementor-size-xs" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica}>{t("RESERVA")}</a>
                                </div>
                            </div>
                        </div>
                    );
                }
            })}
            {results}
        </>
    )
}

const ListaDestacadosMobile2 = ({ ruta, destacadas, config, lang, preidioma }) => {

    const { t } = useTranslation();
    let results = [];

    return (
        <>
            {destacadas.forEach((propiedad, index) => {
                if (index > 3 && index < 8) {
                    const fotos = propiedad.fotos_web;
                    results = results.concat(
                        <div className="image-container" key={index}>
                            <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/thumb/" + fotos[0].nombre} />
                            <div className="overlay">
                                <div>
                                    <p className="elementor-flip-box__layer__description">{propiedad.nombre}</p>
                                    <a className="elementor-flip-box__button elementor-button elementor-size-xs" href={"/" + preidioma + t("apartamentos") + "-" + propiedad.url_destino + "/" + propiedad.url_dinamica}>{t("RESERVA")}</a>
                                </div>
                            </div>
                        </div>
                    );
                }
            })}
            {results}
        </>
    )
}

const Destacados = ({ lang, config, elementosweb, ruta, destacadas }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className=" mt-500">
            <section>
                <div className="elementor-element elementor-element-47f93228 elementor-widget elementor-widget-heading" data-id="47f93228" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                    <div className="elementor-widget-container" style={{ marginBottom: "20px" }}>
                        <h3 className="elementor-heading-title elementor-size-default mayusculacss titdest1" style={{ color: "#5C5C5C", fontFamily: "Roboto, Sans-serif", fontSize: "12px", fontWeight: "400", lineHeight: "1em", letterSpacing: "4px" }}>{elementosweb.subtitulo_buscador[lang]}</h3>
                    </div>
                </div>

                <div className="elementor-element elementor-element-3dd83f7 elementor-widget elementor-widget-heading" data-id="3dd83f7" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                    <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-medium titulodest" style={{ color: "#2E2E2E", fontFamily: "'Lato',Sans-serif", fontWeight: "300", lineHeight: "1.2em", letterSpacing: "1px" }}>{elementosweb.subtitulo_propiedades[lang]}</h3>
                    </div>
                </div>
            </section>

            <section className="row justify-content-center solodesktop box-destacados" style={{ marginTop: "18px", marginBottom: "5px", position: "relative" }}>
                <ListaDestacados
                    ruta={ruta}
                    destacadas={destacadas}
                    config={config}
                    lang={lang}
                    preidioma={preidioma}
                />
            </section>

            <section className="solomobile elementor-section elementor-top-section elementor-element elementor-element-b2385cb elementor-section-stretched elementor-hidden-desktop elementor-section-boxed elementor-section-height-default box-destacados-fotos" data-id="b2385cb" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}" style={{ width: "100%" }}>
                <div className="row" style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
                    <div className="p-0 col-6 no-pad600 d-flex col-destacados" data-id="54f6fe51" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style={{ paddingRight: "0" }}>
                        <ListaDestacadosMobile1
                            ruta={ruta}
                            destacadas={destacadas}
                            config={config}
                            lang={lang}
                            preidioma={preidioma}
                        />
                    </div>

                    <div className="p-0 col-6 no-pad600 d-flex col-destacados" data-id="54f6fe51" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style={{ paddingLeft: "0" }}>
                        <ListaDestacadosMobile2
                            ruta={ruta}
                            destacadas={destacadas}
                            config={config}
                            lang={lang}
                            preidioma={preidioma}
                        />
                    </div>

                </div>
            </section>

            <section className="p-0 row justify-content-center box-destacados-btn">
                <div className="p-0 row">
                    <div className="col-md-6 col-12 box-buttons-destacados">
                        <div className="elementor-widget-wrap elementor-element-populated vertodos">
                            <div className="elementor-element elementor-element-0fc24c7 elementor-button-info elementor-align-justify elementor-hidden-mobile_extra elementor-hidden-mobile elementor-widget elementor-widget-button" data-id="0fc24c7" data-element_type="widget" data-widget_type="button.default">
                                <div className="elementor-widget-container left-btn">
                                    <div className="elementor-button-wrapper ver-btn" style={{}}>
                                        <a href={"/" + preidioma + t("busca")} className="topropiedad elementor-button-link elementor-button elementor-size-xs elementor-animation-shrink" role="button" style={{ fontFamily: "Roboto, Sans-serif", fontWeight: "300" }}>
                                            <span className="elementor-button-content-wrapper" style={{ display: "flex", justifyContent: "center", color: "white", margin: 0 }}>
                                                <span className="todos-los-alojamientos span-destacados" id="todosApartamentos" >{t('VER TODOS LOS ALOJAMIENTOS')}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-12 box-buttons-destacados">
                        <div className="elementor-widget-wrap elementor-element-populated" style={{}}>
                            <div className="elementor-element elementor-element-43a3545 elementor-search-form--skin-minimal elementor-widget elementor-widget-search-form" style={{}}>
                                <div className="elementor-widget-container right-btn">
                                    <form className="elementor-search-form buscanombre" role="search" action={"/" + preidioma + t("busca")} method="get" autoComplete="off">
                                        <div className="elementor-search-form__container buscar-alojamiento">
                                            <button type="submit" style={{ background: "none", border: "0" }} className="mobil10px">
                                                <i aria-hidden="true" className="fas fa-search buscalupa" style={{ color: "#5c5c5c" }}></i>
                                            </button>
                                            <input id="elementor-search-form-43a3545" placeholder={t("BUSCAR POR ALOJAMIENTO")} style={{ fontFamily: "'Roboto', sans-serif", fontWeight: "300", letterSpacing: "0.9px", height: "30px", border: "medium", outline: "0px", background: "#eceeef" }} className="elementor-search-form__input input-destacados" type="search" name="nombreprop" title={t("Buscar")} defaultValue=""></input>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};
export default Destacados;
