import { React } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import { useTranslation } from 'react-i18next';

const MapaContacto = ({ config }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="pt-1 pb-5 mapacontacto">
                <MapContainer center={[51.505, -0.09]} zoom={12} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                    />
                    <Marker position={[51.505, -0.09]}>
                    </Marker>
                </MapContainer>
                <div className="noticia-individual">
                    <p><i className="fa-duotone fa-map-location"></i>  {config.direccion_fiscal}, {config.codigo_postal_fiscal}, {config.poblacion_fiscal}</p>
                    <p><i className="fa-duotone fa-mobile"></i>  {config.telefono_fiscal}</p>
                    <p><i className="fa-duotone fa-envelope"></i>  {config.email_fiscal}</p>
                </div>
            </div>
        </>
    );
}

export default MapaContacto;
