import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import BtnFloating from './Compartido/BtnFloating.jsx';
import Intro from './SobreNosotros/Intro.jsx';
import PanelSeparador from './SobreNosotros/PanelSeparador.jsx';
import SobreNosotrosBox from './SobreNosotros/SobreNosotrosBox.jsx';
import Team from './SobreNosotros/Team.jsx';
import Scripts from './Compartido/Scripts.jsx';

import '../../../../../resources/css/web/holidayhomescanarias/header.css'
import '../../../../../resources/css/web/holidayhomescanarias/general.css';
import '../../../../../resources/css/web/holidayhomescanarias/footer.css'
import '../../../../../resources/css/web/holidayhomescanarias/intro.css'
import '../../../../../resources/css/web/holidayhomescanarias/about-us-page.css'
import '../../../../../resources/css/web/holidayhomescanarias/buttons.css'

const SobreNosotros = (props) => {
    return (
        <main>
            <Header
                datos={props.websgrupo}
                lang={props.lang}
                idiomas={props.idiomas}
                tipo="inicio"
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
            />
            <Intro
                lang={props.lang}
                ruta={props.ruta}
                sobrenosotros={props.textosobrenosotros}
            />
            <PanelSeparador
                lang={props.lang}
                config={props.config}
            />
            <SobreNosotrosBox
                lang={props.lang}
                texto={props.textosobrenosotros}
                destinos={props.destinos}
                ruta={props.ruta}
            />
            <Team
                lang={props.lang}
                personas={props.personas}
                ruta={props.ruta}
            />
            <Footer
                lang={props.lang}
                config={props.config}
            />
            <BtnFloating />

            <Scripts />
        </main>
    );
}

export default SobreNosotros;

