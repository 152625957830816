import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Footer = ({ lang, config }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://weatherwidget.io/js/widget.min.js';
        script.id = 'weatherwidget-io-js';
        document.body.appendChild(script);
    }, []);

    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-3">
                        <h4 className="white-text mb20">{t("Contacto")}</h4>
                        <ul>
                            <li>{config.email_fiscal}</li>
                            <li>{config.telefono_fiscal}</li>
                            <li>{t("Dirección")}: {config.direccion_fiscal} - Tenerife</li>
                        </ul>
                        <div id="sellowebAixaCorpore" data-name={2022102127}>
                            <a href="https://www.aixacorpore.es" target="_blank">
                                <img
                                    src="https://clientes.aixacorpore.com/sellowebjs/Sellos/2022102127.png"
                                    alt="sello de certificacion web Aixa Corpore"
                                    width="130px"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <h4 className="white-text mb20">{t("Links de interés")}</h4>
                        <ul>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("villas-tenerife")}>
                                    {t("Nuestras villas")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("servicios")}>
                                    {t("Servicios")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("ventajas-web")}>
                                    {t("Ventajas")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("blog")} />
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("experiencias")}>
                                    {t("Community")}
                                </a>
                            </li>
                            {/*li><a className="nostyle" href="/">Promociones</a></li*/}
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("sobre-nosotros")}>
                                    {t("Sobre Nosotros")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("faqs")}>
                                    {t("FAQS")}
                                </a>
                            </li>
                            {/* <li>
                                <a className="nostyle login" data-toggle="modal" href="#loginModal">
                                    {t("Acceso clientes")}
                                </a>
                            </li>*/}
                        </ul>
                    </div>
                    <div className="col-12 col-lg-3">
                        <h4 className="white-text mb20">{t("Términos")}</h4>
                        <ul>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("terminos")}>
                                    {t("Términos Y Condiciones")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("politica-de-privacidad")}>
                                    {t("Política de privacidad")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("cookies")}>
                                    {t("Política de Cookies")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("politica-de-cancelacion")}>
                                    {t("Política de Cancelación")}
                                </a>
                            </li>
                            <li>
                                <a className="nostyle" href={"/" + preidioma + t("legal")}>
                                    {t("Aviso Legal")}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-3">
                        <h4 className="white-text mb20">Pagos</h4>
                        <div className="payment-container">
                            <img className="payment-logo" src="/images/web/hhc/pagos.png" />
                            <img className="payment-logo" src="/images/web/hhc/visa.png" />
                            <img className="payment-logo" src="/images/web/hhc/logo-bizum.png" />
                        </div>

                        <a className="weatherwidget-io"
                            href="https://forecast7.com/es/28d46n16d25/santa-cruz-de-tenerife/"
                            data-label_1="TENERIFE"
                            data-label_2={t("Tiempo")}
                            data-font="Roboto"
                            data-mode="Current"
                            data-days={3}
                            data-basecolor="#01C2CB"
                            data-accent="#01C2CB"
                            data-textcolor="#fff"
                            data-highcolor="#fff"
                            data-lowcolor="#fff"
                            data-suncolor="#fff"
                            data-mooncolor="#fff"
                            data-cloudcolor="#fff"
                            data-cloudfill="#fff"
                            data-raincolor="#fff"
                            data-snowcolor="#fff"
                            data-icons="Climacons Animated" >
                            {"TENERIFE " + t("Tiempo").toUpperCase()}
                        </a>
                    </div>
                </div>
                <div className="row-fluid">
                    <p className="white-text copyright-text">
                        © {new Date().getFullYear()} {config.nombre_comercial} | {config.nombre_fiscal} | {config.nif_fiscal} | {config.direccion_fiscal} | {config.poblacion_fiscal + " " + config.poblacion_fiscal}
                    </p>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
