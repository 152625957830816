import React from "react";
import { Container } from "react-bootstrap";
import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

const Content = ({ lang, noticia, notanterior, notsiguiente, ruta }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section section-first">
            <div className="row justify-content-center">
                {/* Header post */}
                <header className="col-12 col-lg-12 col-xl-10 post-header">
                    <div
                        className="title title--overhead js-lines"
                        style={{ overflow: "inherit" }}
                    >
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {/* Cultura*/} - {format(new Date(noticia.fecha_publica), 'dd MMM yyyy')}
                            </div>
                        </div>
                    </div>
                    <h1 className="title title--h2 js-lines" style={{ overflow: "inherit" }}>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {noticia.eltitulo}
                            </div>
                        </div>
                    </h1>
                </header>
                {/* Main image post */}
                <figure className="col-12 post-main-image">
                    {
                        noticia.imagen ?
                            <img
                                className="cover ls-is-cached lazyloaded"
                                src={ruta + "/blog/" + noticia.id + "/" + noticia.imagen}
                                alt=""
                            />
                            :
                            <img
                                className="cover ls-is-cached lazyloaded"
                                src="/images/web/hhc/bedloop-nofoto.png"
                                alt=""
                            />
                    }
                </figure>
                {/* Content post */}
                <div className="col-12 col-lg-10 col-xl-8">
                    <p className="paragraph paragraph--large" dangerouslySetInnerHTML={{ __html: noticia.ladesccorta }}></p>
                    <p className="paragraph paragraph--large" dangerouslySetInnerHTML={{ __html: noticia.ladesclarga }}></p>
                </div>
                {/* Footer post */}
                <footer className="col-12 col-lg-10 col-xl-8 post-footer">
                    <div className="share-post js-lines" style={{ overflow: "inherit" }}>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "center",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=https://www.holidayhomescanarias.com/" + preidioma + t("blog") + "/" + noticia.laurl}>
                                    <i className="fa-brands fa-facebook" />
                                    <span>{t("Facebook")}</span>
                                </a>
                                <a href={"https://twitter.com/intent/tweet?original_referer=https://www.holidayhomescanarias.com/" + preidioma + t("blog") + "/" + noticia.laurl + "&text=" + noticia.eltitulo + "&url=https://www.holidayhomescanarias.com/" + preidioma + t("blog") + "/" + noticia.laurl}>
                                    <i className="fa-brands fa-twitter" />
                                    <span>{t("Tweet")}</span>
                                </a>
                                <a href={"http://www.linkedin.com/shareArticle?mini=true&url=https://www.holidayhomescanarias.com/" + preidioma + t("blog") + "/" + noticia.laurl + "&title=" + noticia.eltitulo + "&source=Holiday Homes Canarias"}>
                                    <i className="fa-brands fa-linkedin-in" />
                                    <span>{t("Linkedin")}</span>
                                </a>
                                <a
                                    target="_blank"
                                    href={"mailto:?&body=https://www.holidayhomescanarias.com/" + preidioma + t("blog") + "/" + noticia.laurl + "&title=" + noticia.eltitulo}
                                >
                                    <i className="fa-thin fa-envelope" />
                                    <span>{t("E-mail")}</span>
                                </a>
                                <a
                                    target="_blank"
                                    href={"whatsapp://send?text= Titulo https://www.holidayhomescanarias.com/" + preidioma + t("blog") + "/" + noticia.laurl}
                                >
                                    <i className="fa-brands fa-whatsapp" />
                                    <span>{t("Whatsapp")}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div >
            {/* Page nav */}
            <nav className="page-nav mb80" >
                <div className="text-center row justify-content-center">
                    <div className="col-12 col-lg-8 col-xl-6">
                        {notsiguiente != "" ?
                            <>
                                <div
                                    className="title title--overhead js-lines"
                                    style={{ overflow: "inherit" }}
                                >
                                    <div style={{ overflow: "inherit" }}>
                                        <div
                                            className="tl-line"
                                            style={{
                                                display: "block",
                                                textAlign: "center",
                                                position: "relative",
                                                transform: "matrix(1, 0, 0, 1, 0, 0)",
                                                opacity: 1,
                                                overflow: "inherit"
                                            }}
                                        >
                                            {t("Siguiente")}
                                        </div>
                                    </div>
                                </div>
                                <h3
                                    className="title title--h3 js-lines"
                                    style={{ overflow: "inherit" }}
                                >
                                    <div style={{ overflow: "inherit" }}>
                                        <div
                                            className="tl-line"
                                            style={{
                                                display: "block",
                                                textAlign: "center",
                                                position: "relative",
                                                transform: "matrix(1, 0, 0, 1, 0, 0)",
                                                opacity: 1,
                                                overflow: "inherit"
                                            }}
                                        >
                                            {notsiguiente.eltitulo}
                                        </div>
                                    </div>
                                </h3>
                                <a className="btn btn__circle" href={"/" + preidioma + t("blog") + "/" + notsiguiente.laurl}>
                                    <i className="fa-solid fa-arrow-right" />
                                </a>{" "}
                            </>
                            :
                            null
                        }

                        {/* *********** */}

                        {notanterior != "" ?
                            <>
                                <div
                                    className="title title--overhead js-lines"
                                    style={{ overflow: "inherit" }}
                                >
                                    <div style={{ overflow: "inherit" }}>
                                        <div
                                            className="tl-line"
                                            style={{
                                                display: "block",
                                                textAlign: "center",
                                                position: "relative",
                                                transform: "matrix(1, 0, 0, 1, 0, 0)",
                                                opacity: 1,
                                                overflow: "inherit"
                                            }}
                                        >
                                            {t("Anterior")}
                                        </div>
                                    </div>
                                </div>
                                <h3
                                    className="title title--h3 js-lines"
                                    style={{ overflow: "inherit" }}
                                >
                                    <div style={{ overflow: "inherit" }}>
                                        <div
                                            className="tl-line"
                                            style={{
                                                display: "block",
                                                textAlign: "center",
                                                position: "relative",
                                                transform: "matrix(1, 0, 0, 1, 0, 0)",
                                                opacity: 1,
                                                overflow: "inherit"
                                            }}
                                        >
                                            {notanterior.eltitulo}
                                        </div>
                                    </div>
                                </h3>
                                <a className="btn btn__circle" href={"/" + preidioma + t("blog") + "/" + notanterior.laurl}>
                                    <i className="fa-solid fa-arrow-left" />
                                </a>{" "}
                            </>
                            :
                            null
                        }
                    </div>
                </div>
            </nav>
            {/* /Page nav */}
        </section >
    );
};
export default Content;
