import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Team = ({ lang, personas, ruta }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section">
            <div className="mb-0 row mb-md-4">
                <div className="col-12">
                    <span
                        className="title title--overhead js-lines"
                        style={{ overflow: "inherit" }}
                    >
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Únicos y auténticos")}
                            </div>
                        </div>
                    </span>
                    <h1 className="title title--h1 js-lines" style={{ overflow: "inherit" }}>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Te tratamos de tú a tú")}
                            </div>
                        </div>
                    </h1>
                </div>
            </div>
            <div className="row">
                {personas.map((equipo) => (
                    <div
                        key={equipo.id}
                        className="col-12 col-md-12 col-lg-4 js-scroll-show"
                        style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                    >
                        <div className="itemPerson">
                            <figure className="itemPerson__img-wrap">
                                {" "}
                                {
                                    equipo.imagen ?
                                        <img
                                            className="cover ls-is-cached lazyloaded"
                                            src={ruta + "/quienesSomos/" + equipo.id + "/" + equipo.imagen}
                                            alt={equipo.nombre}
                                        />
                                        :
                                        <img
                                            className="cover ls-is-cached lazyloaded"
                                            src="/images/web/bedloop-nofoto.png"
                                            alt={equipo.nombre}
                                        />

                                }
                                {" "}
                            </figure>
                            <div className="itemPerson__details">
                                <h4 className="title title--h4">{equipo.nombre}</h4>
                                <span className="title title--overhead-small">{t("A tu lado para _")}</span>
                                <div dangerouslySetInnerHTML={{ __html: equipo.ladesc }}></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>

    );
};
export default Team;
