import { React, useState } from "react";
import { Container } from "react-bootstrap";
import axios from 'axios';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseISO } from 'date-fns';


import { useTranslation } from 'react-i18next';

const DatosCabecera = ({ propiedad, ruta, adultos, ninos, bebes, llegada, salida, lang, diasbloqueados }) => {

    const { t } = useTranslation();

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    const [arrival, setArrival] = useState(entr);
    const [departure, setDeparture] = useState(sali);

    let dias = [];

    diasbloqueados.forEach((undia, index) => {
        dias.push(new Date(undia))
    })

    {/* *************** */ }

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const [dataRes, setDataRes] = useState("<p></p>");

    const handleSubmit = (e) => {
        e.preventDefault()

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/haydispo', {
            id: propiedad.id,
            range: e.target.arrival.value.replace("-", "/") + " - " + e.target.departure.value.replace("-", "/"),
            personas: Number(e.target.adultos.value) + Number(e.target.ninos.value),
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {

                    let noches = 0;
                    let estancia = 0;
                    let eltotal = 0;
                    var textoextras = "";

                    const precios = solucion.precios[0];
                    const extras = solucion.extras.relacion_extras;

                    if (propiedad.by_request == 0) {
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        estancia = estancia * 0.01;

                        eltotal = eltotal + estancia;

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                textoextras += "  <div class='row mb-3'>" +
                                    "<div class='col-8 color-white'>" +
                                    t(extras[j].nombre) +
                                    "</div>" +
                                    "<div class='col-4 text-end color-white'>" +
                                    extras[j].precio * 0.01 + '€' +
                                    "</div>" +
                                    "</div>";
                                eltotal = eltotal + (extras[j].precio * 0.01);
                            }

                        }
                    }

                    if (propiedad.by_request == 1) {
                        var formreserva =
                            "<div class='row mb-3 w400'>" +
                            "<div class='col-8 color-white'>" +
                            solucion.noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end color-white'>" +
                            "</div>" +
                            "</div>" +
                            "<div>" +
                            "<a href='/" + preidioma + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.arrival.value.replace("-", "/") + " - " + e.target.departure.value.replace("-", "/") + "&amp;adultos=" + Number(e.target.adultos.value) + "&amp;ninos=" + Number(e.target.ninos.value) + "&amp;bebes=" + Number(e.target.bebes.value) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:rgb(234, 232, 228); border: 1px solid rgba(0,0,0,0); color:#5A3938'>" + t("Petición") + "</a>";
                        "</div>"
                    }
                    else {
                        var formreserva =
                            "<div class='row mb-3 w400'>" +
                            "<div class='col-8 color-white'>" +
                            noches + " " + t("Noches") + " / " + (Number(e.target.adultos.value) + Number(e.target.ninos.value)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end color-white'>" +
                            estancia.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder color-white'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder color-white'>" +
                            eltotal.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +
                            "<div>" +
                            "<a href='/" + preidioma + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.arrival.value.replace("-", "/") + " - " + e.target.departure.value.replace("-", "/") + "&amp;adultos=" + Number(e.target.adultos.value) + "&amp;ninos=" + Number(e.target.ninos.value) + "&amp;bebes=" + Number(e.target.bebes.value) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:rgb(234, 232, 228); border: 1px solid rgba(0,0,0,0); color:#5A3938'>" + t("Reservar") + "</a>";
                        "</div>"
                    }

                    setDataRes(
                        formreserva
                    );
                }
                else {
                    console.log(t(solucion.Error));
                    setDataRes("<p class='errordispo'>" + t(solucion.Error) + "</p>");
                }
            });
    }

    const cambioFechas = (date) => {
        setArrival(date);
        setDeparture(date);
    };

    const cambioFechas2 = (date) => {
        setDeparture(date);
    };

    {/* *************** */ }

    return (
        <div className="container-fluid w-100" style={{ marginTop: "300px" }}>
            <div className="w-100 d-flex">
                {
                    propiedad.fotos_web.length != 0 ?
                        <div className="max-h-100 mitaddesktop1" style={{ backgroundImage: "url('" + ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.fotos_web[0].nombre + "')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "15px" }}>
                        </div>
                        :
                        <div className="max-h-100 mitaddesktop1" style={{ backgroundImage: "url('/images/web/bedloop-nofoto.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "15px" }}>
                        </div>
                }
                <div className="max-h-100 mitaddesktop2">
                    <h1 className="elementor-heading-title elementor-size-default tituloprop">{propiedad.nombre}</h1>
                    <div className="mb-5 elementor-divider">
                        <span className="elementor-divider-separator">
                            <div className="d-flex align-items-center">
                                <p className="p-0 m-0 w-50 estilomagia">{t("Magia y elegancia")}</p>
                                <hr className="w-50 bg-dark" />
                            </div>
                        </span>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: propiedad.ladesc }}></div>

                    <div className="p-0 container-fluid d-flex justify-content-center box-form">
                        <div className="d-flex justify-content-center" style={{ width: "100%" }}>
                            <form className="container d-flex formpropiedad" method="get" name={t("Nuevo formulario")} onSubmit={handleSubmit} style={{ zIndex: "5" }} id="formcerca">
                                <div className="grid-main-form" style={{ width: "100%" }}>
                                    <div className="d-flex justify-content-center w-100">
                                        <div style={{ width: "100%" }}>
                                            <label htmlFor="form-field-name" className="elementor-field-label">{t("Fecha entrada")}</label>
                                            <DatePicker
                                                id="form-field-name"
                                                className="elementor-field elementor-size-sm elementor-field-textual elementor-date-field entradahome inp-text"
                                                autoComplete="off"
                                                selected={arrival}
                                                startDate={arrival}
                                                onChange={(date) => cambioFechas(date)}
                                                dateFormat="yyyy/MM/dd"
                                                placeholderText="dd / mm / aaaa"
                                                locale={lang.toUpperCase()}
                                                minDate={new Date()}
                                                excludeDates={dias}
                                                name="arrival"
                                            >
                                            </DatePicker>
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <label htmlFor="form-field-name" className="elementor-field-label">{t("Fecha salida")}</label>
                                            <DatePicker
                                                id="form-field-field_451cb70"
                                                className="inp-text salidahome"
                                                autoComplete="off"
                                                selected={departure}
                                                startDate={departure}
                                                onChange={(date) => cambioFechas2(date)}
                                                dateFormat="yyyy/MM/dd"
                                                placeholderText="dd / mm / aaaa"
                                                locale={lang.toUpperCase()}
                                                minDate={new Date()}
                                                excludeDates={dias}
                                                name="departure"
                                            >
                                            </DatePicker>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center w-100">
                                        <div style={{ width: "33%" }}>
                                            <div className="d-flex align-items-center">
                                                <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº adultos")} <span className="small-text"> ({t("Desde 12 años")})</span></label>

                                            </div>
                                            <div className="number-input w-100">
                                                <input style={{ width: "95%" }} type="number" name="adultos" id="form-field-field_3fde374" className="elementor-field elementor-size-sm elementor-field-textual inp-number" placeholder="0" min="1" max={propiedad.capacidad_maxima} defaultValue="1" />
                                            </div>
                                        </div>
                                        <div style={{ width: "33%" }}>
                                            <div className="d-flex align-items-center">
                                                <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº niños")} <span className="small-text"> ({t("De 2 a 12 años")})</span></label>

                                            </div>
                                            <div className="number-input w-100">
                                                <input style={{ width: "95%" }} type="number" name="ninos" id="form-field-field_3fde374" className="elementor-field elementor-size-sm elementor-field-textual inp-number" placeholder="0" min="0" max={propiedad.capacidad_maxima} defaultValue="0" />
                                            </div>
                                        </div>
                                        <div style={{ width: "33%" }}>
                                            <div className="d-flex align-items-center">
                                                <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº bebés")} <span className="small-text"> ({t("De 0 a 2 años")})</span></label>
                                            </div>
                                            <div className="number-input w-100">
                                                <input style={{ width: "95%" }} type="number" name="bebes" id="" placeholder="0" min="0" max={propiedad.capacidad_maxima} defaultValue="0" className="inp-text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center w-100">
                                        <button style={{ width: "98%" }} type="submit" className="noto btn-buscar amayusculas">{t("buscar")}</button>
                                    </div>

                                    <div className="d-flex justify-content-center w-100">
                                        <div className="mt-5" dangerouslySetInnerHTML={{ __html: dataRes }}></div>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>
            </div >
        </div >
    );
}
export default DatosCabecera;
