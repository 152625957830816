import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Content = ({ lang, ventajas, ruta }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";


    return (
        <main className="mt-5 main mb80">
            <section className="container section section-first">
                <div className="row justify-content-center">
                    {/* Content */}
                    <div className="col-12 col-lg-10 col-xl-11">
                        <h1
                            className="title title--h2 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    {t("Tus Ventajas")}
                                </div>
                            </div>
                        </h1>
                        <p className="paragraph paragraph--large" />
                        <div className="row">
                            {ventajas.map((ventaja) => (
                                <div className="col-lg-3 mb-30" style={{ textAlign: "center" }} key={ventaja.id}>
                                    {" "}
                                    <img
                                        className="img-fluid"
                                        src={`${ruta}/ventajas/${ventaja.id}/${ventaja.imagen}`}
                                    />
                                    {" "}
                                    <h3 className="mt-20 nombrepersona mb-30">{ventaja.elnombre}</h3>{" "}
                                    <div className="text-justify mb-30 fs14" dangerouslySetInnerHTML={{ __html: ventaja.eltexto }}></div>
                                </div>
                            ))}
                            {/* <div className="col-lg-3 mb-30" style={{ textAlign: "center" }}>
                                {" "}
                                <img
                                    className="img-fluid"
                                    src="/images/ventajas_web/1/ventaja1.jpg"
                                />{" "}
                                <h3 className="mt-20 nombrepersona mb-30">Regalo de bienvenida</h3>{" "}
                                <div className="text-justify mb-30 fs14">
                                    Recibirás un magnífico regalo de bienvenida del cual podrás
                                    disfrutar a lo largo de tu estancia y le dará un plus a tus
                                    vacaciones. Si vienes con mascota, te prometemos que tu mascota
                                    también obtendrá una sorpresita.
                                </div>
                            </div>
                            <div className="col-lg-3 mb-30" style={{ textAlign: "center" }}>
                                {" "}
                                <img
                                    className="img-fluid"
                                    src="/images/ventajas_web/2/ventaja2.jpg"
                                />{" "}
                                <h3 className="mt-20 nombrepersona mb-30">Mejor precio</h3>{" "}
                                <div className="text-justify mb-30 fs14">
                                    Si reservas con nosotros, te garantizamos el mejor precio. ¡Y si
                                    encuentras uno mejor, te lo igualamos!&nbsp;
                                </div>
                            </div>
                            <div className="col-lg-3 mb-30" style={{ textAlign: "center" }}>
                                {" "}
                                <img
                                    className="img-fluid"
                                    src="/images/ventajas_web/3/ventaja3.jpg"
                                />{" "}
                                <h3 className="mt-20 nombrepersona mb-30">
                                    Promociones exclusivas
                                </h3>{" "}
                                <div className="text-justify mb-30 fs14">
                                    Disfrutarás de promociones y ofertas exclusivas y nuestros
                                    clientes fieles reciben un 10% de descuento en sus próximas
                                    reservas.
                                </div>
                            </div>
                            <div className="col-lg-3 mb-30" style={{ textAlign: "center" }}>
                                {" "}
                                <img
                                    className="img-fluid"
                                    src="/images/ventajas_web/4/ventaja4.jpg"
                                />{" "}
                                <h3 className="mt-20 nombrepersona mb-30">Pagos seguros</h3>{" "}
                                <div className="text-justify mb-30 fs14">
                                    Puedes pagar con tarjeta de crédito, transferencia bancaria o
                                    bizum - y aseguramos que tus pagos se realizan sin riesgos con un
                                    certificado de seguridad.
                                </div>
                            </div>*/}
                        </div>{" "}
                    </div>
                </div>
            </section>
        </main>
    );
};
export default Content;
