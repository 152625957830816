const Presence = () => {
    return (
        <div className="section mcb-section T60B80">
            <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column_attr clearfix align_center">
                            <h6 className="landing4-heading grey positionContainer1px">{t("PRESENCIA INSTITUCIONAL")}</h6>
                            <hr className="no_line margin15Auto"/>
                        </div>
                        
                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix">
                                <p className="textJustify">
                                    <span lang="EN-GB">{t("La presencia de APARTUR y la representación del sector de la vivienda turística en las principales entidades público-privadas de la ciudad es constante y muy visible.")}</span>
                                </p>

                                <p className="textJustify">
                                    <span lang="EN-GB">{t("Estas conexiones nos permiten perseguir objetivos compartidos en beneficio del ecosistema turístico de Barcelona, así como abordar los nuevos retos e incertidumbres a los que se enfrentará la ciudad en el futuro.")}</span>
                                </p>

                                <p className="textJustify">
                                    <span lang="EN-GB">{t("APARTUR está presente en las siguientes organizaciones e instituciones:")}</span>
                                </p>


                                <ul className="column_column">
                                    <li>Agència Catalana de Turisme</li>
                                    <li>Ajuntament de Barcelona (Consell Municipal de Turisme i Ciutat)&nbsp;</li>
                                    <li>Ajuntament de Barcelona (Compromís per la Sostenibilitat Turística Destinació Barcelona Biosphere)</li>
                                    <li>Ajuntament de Barcelona (Comissió observatori Tourism Data System d'Eurecat)</li>
                                    <li>Barcelona Oberta</li>
                                    <li>Cambra de Comerç de Barcelona</li>
                                    <li>Cercle d'Economia</li>
                                    <li>Confecat - (Confederació Empresarial d'Hostaleria i Restauració de Catalunya)</li>
                                    <li>Foment del Treball Nacional</li>
                                    <li>Grup de treball del Consell de Seguretat Urbana de Barcelona</li>
                                    <li>Pimec (Comissió de Turisme)</li>
                                    <li>Pla estratègic de Turisme de la ciutat de Barcelona</li>
                                    <li>Taula del Turisme de Catalunya</li>
                                    <li>Turisme de Barcelona</li>
                                </ul>
                                
                                <p>
                                    <span className="underline"><strong>{t("Apartur es miembro de:")}</strong></span>
                                </p>
                                
                                <p>&nbsp;</p>

                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="borderNone">
                                                <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/federatur.jpg" width="150" height=""/>
                                            </td>

                                            <td className="borderNone">
                                                <a href="http://www.fevitur.com/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/fevitur2.png" width="150" height=""/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <a href="http://professional.barcelonaturisme.com/Professionals/barcelona-sustainable-tourism/barcelona-sustainable-tourism-home/_2swCqLRbUEKrIbNgNB9h-_dXMvkaCPMMMRTEkvEvuET8337HFxePtA" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/BCN-Sustainable-tourism.jpg" width="150" height=""/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/Logo_circulo_economia.png" width="150" height=""/>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="borderNone">
                                                <a href="http://www.foment.com/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/foment.png" width="100" height=""/>
                                                </a>
                                            </td>
                                            
                                            <td className="borderNone">
                                                <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/barcelonaoberta.png" width="180" height=""/>

                                            </td>
                                            
                                            <td className="borderNone">
                                                <a href="http://www.barcelonaglobal.com/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/barcelona-global.png" width="" height="60"/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <img src="https://www.apartur.com/app/vistas/apartur/images/miembro/agencia_catalana_turisme.png" width="130" height=""/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>
                                    <span className="underline"><strong>{t("Apartur colabora con:")}</strong></span>
                                </p>

                                <p>&nbsp;</p>

                                
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="borderNone">
                                                <a href="http://www.barcelonaturisme.com/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/colabora/Turisme-Barcelona-1.jpg" width="130" height=""/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <a href="http://act.gencat.cat/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/colabora/agencia_catalana_turisme.jpg" width="130" height=""/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <a href="http://www.institutoturismoresponsable.com/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/colabora/instituto-internacional-responsable_.png" width="150" height=""/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <a href="https://www.biospheretourism.com/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/colabora/Biosphere-vertical.png" width="" height="70"/>
                                                </a>
                                            </td>

                                        </tr>
                                        
                                        
                                        <tr>
                                            <td className="borderNone">
                                                <a href="http://www.casaldelsinfants.org/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/colabora/casal-dels-infants4.png" width="160" height=""/>
                                                </a>
                                            </td>

                                            <td className="borderNone">
                                                <a href="https://mossos.gencat.cat/ca/prevencio/consells_de_seguretat/per_unes_vacances_segures/" target="_blank" rel="noopener">
                                                    <img src="https://www.apartur.com/app/vistas/apartur/images/colabora/BannerMossos.png" width="130" height=""/>
                                                </a>
                                            </td>
                                            
                                            <td className="borderNone">&nbsp;</td>
                                            <td className="borderNone">&nbsp;</td>
                                        </tr>

                                    </tbody>
                                </table>  
                                
                                <hr className="no_line m10"/>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Presence;