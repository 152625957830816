import React, {useState, useEffect, useRef} from "react";
import { Button, Container } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import CustomSelect from '../Compartido/CustomSelect.jsx';
import es from 'date-fns/locale/es';

import { useTranslation } from 'react-i18next';

const Banner = ({ destinos, lang, ruta, d_ale,config }) => {

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    const { t } = useTranslation();

    registerLocale('es', es)

    let results3 = [];

    const numpersonas = useRef(null);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if(show=='none') setShow('block');
        else setShow('none');
    };

    let imagenfondo = "";

    { destinos.forEach((undestino, index) => {
        let objeto =  {id: index, name: undestino.elnombre, value: undestino.laurl}
        results3 = results3.concat(objeto);
    })}

    if(destinos[d_ale] && destinos[d_ale].imagen_principal.length>0)
        imagenfondo = ruta+'/destinos/'+destinos[d_ale].id+'/'+destinos[d_ale].imagen_principal[0].imagen;
    else
        imagenfondo = "/images/web/bedloop-nofoto.png";

    const [titlePersonas, setTitlePersonas] = useState(t("Personas"));

    {/* ******************* */}

    const [countAdults, setCountAdults] = useState(0);
    const [countNinos, setCountNinos] = useState(0);
    const [countBebes, setCountBebes] = useState(0);

    const handleSumaAdultos = () => {

        if(countAdults<10)
        {
            setCountAdults(ca => ca+1);
            handleEtiqueta(countAdults+1, countNinos, countBebes);
        }
        
    }

    const handleRestaAdultos = () => {
        if(countAdults>0)
        {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(countAdults-1, countNinos, countBebes);
        }
    }

    const handleSumaNinos = () => {

        if(countNinos<10)
        {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(countAdults, countNinos+1, countBebes);
        }
    }

    const handleRestaNinos = () => {

        if(countNinos>0)
        {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(countAdults, countNinos-1, countBebes);
        }
    }

    const handleSumaBebes = () => {

        if(countBebes<10)
        {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(countAdults, countNinos, countBebes+1);
        }
    }

    const handleRestaBebes = () => {
        if(countBebes>0)
        {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(countAdults, countNinos, countBebes-1);
        }
    }

    const handleEtiqueta = (adults, ninos, bebes) => {

        let frase = t("Personas");
        if(adults>0) frase = " "+adults+" "+t("Adultos");
        if(ninos>0) frase += " "+ninos+" "+t("Niños");
        if(bebes>0) frase += " "+bebes+" "+t("Bebés");

        numpersonas.current.textContent=frase;

    }

    {/* ******************* */}

    return (
        <Container fluid className="no-padding">
            <figure className="position-relative" style={{
                backgroundImage: 'url('+imagenfondo+')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: "cover",
                height: "700px"
                }}>
            </figure>
                <div className="container fondo-buscador caixa-home">
                    {/* PENDENT DE FER */}
                   {/* {elementosweb.cabecera_buscador && <h5 className="text-center">{elementosweb.cabecera_buscador[lang]}</h5>} */}
                    <form method="get" action={"/"+preidioma+t("buscar")}>
                        <div className="row">
                            <div className="col buscador-inputs">
                                <CustomSelect
                                    defaultText= "Destinos"
                                    optionsList= {results3}
                                    nameInput="destino" 
                                />
                            </div>
                            <div className="col buscador-inputs">
                                <DatePicker
                                    isClearable={true}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    className="form-control cpointer"
                                    autoComplete="off"
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                    monthsShown={2}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    placeholderText={ t("Entrada - Salida") }
                                    locale = {lang}
                                    name="daterange"
                                    >
                                </DatePicker>
                            </div>
                            <div className="col buscador-inputs">
                                <div onClick={handleShow} className="form-control numpersonas mw201" ref={numpersonas}>{t("Personas")}</div>
                                {/* ********* */}

                                <div className="formpersonas" style={{ display: show }} onBlur={handleShow} tabIndex="0" onMouseLeave={handleShow}>

                                    <div className="row mt-2">
                                        <label className="col-md-6 col-6 etiqueta-formulari"> 
                                            {t("Adultos")}
                                            <div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div>
                                        </label>
                                        <div className="col-md-4 col-6">
                                            <div className="input-group mt-4">
                                                <span className="input-group-btn">
                                                    <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                        <span className="centrar-boto-cercle">
                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                        </span>
                                                    </button>
                                                </span>
                                                <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="0" max="10" id="numadultos" readOnly={true} />
                                                <span className="input-group-btn">
                                                    <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                        <span className="centrar-boto-cercle">
                                                            <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="linia"></div>

                                    <div className="row mt-3">
                                        <label className="col-md-6 col-6 etiqueta-formulari"> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                        <div className="col-md-4 col-6 mt-2">
                                            <div className="input-group mt-3">
                                                <span className="input-group-btn">
                                                    <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                        <span className="centrar-boto-cercle">
                                                            <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                        </span>
                                                    </button>
                                                </span>
                                                <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max="10" id="numninos" readOnly={true} />
                                                <span className="input-group-btn">
                                                    <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                        <span className="centrar-boto-cercle">
                                                            <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="linia"></div>

                                    <div className="row mt-3">
                                        <label className="col-md-6 col-6 etiqueta-formulari"> {t("Bebés")}<div className="mini-etiqueta-formulari">( {t("De_0_a_2_años")})</div></label>
                                        <div className="col-md-4 col-6 mt-2">
                                            <div className="input-group mt-3">
                                                <span className="input-group-btn">
                                                    <button className="btn btn-xs btn-number boto-cercle" type="button" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                        <span className="centrar-boto-cercle">
                                                            <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </span>
                                                <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max="10" id="numbebes" readOnly={true} />
                                                <span className="input-group-btn">
                                                    <button className="btn btn-xs btn-number boto-cercle" type="button" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                        <span className="centrar-boto-cercle">
                                                            <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* ********* */}
                            </div>
                            <div className="col buscador-inputs">
                                <Button variant="primary" size="md" type="submit" style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}>
                                    { t("Enviar") }
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
        </Container>
    );
}

export default Banner;
