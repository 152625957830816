import {React } from "react";
import {Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TextosEdificio = ({texto, ruta, lang}) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (texto.fotos_ota.length>0)
	{
		imagen = ruta+"/edificios/"+texto.id+"/fotos/ota/"+texto.fotos_ota[0].nombre;
        
	}

    return (
        <Container>
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={ texto.nombre } style={{  backgroundImage: "url('"+imagen+"')" , backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundSize: "cover"} }></div>

                    <h1 className="noticia-individual mb-5">{ texto.nombre }</h1>
                    <div className="noticia-individual mt-5 mb-5">
                        <div className="noticia-individual-descripcio-llarga" dangerouslySetInnerHTML={{__html: texto.descripcion[lang]}}></div>
                    </div>
                </div>
            </div>
        </Container>
    );
}


export default TextosEdificio;
