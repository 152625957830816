import { useEffect, useRef, useState } from "react";
import { NavDropdown, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const MyNavDropdown = ({config, lang}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  var preidioma = ""
  if(lang!='es') preidioma = lang+"/";

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = (e) => {
    // Cierra el dropdown solo si el cursor no está sobre el contenido abierto
    if (!dropdownRef.current.contains(e.relatedTarget) && !e.relatedTarget.contains(dropdownRef.current)) {
        setIsOpen(false);
    }
  };

  useEffect(() => {

    const dropdownElement = dropdownRef.current;
    dropdownElement.addEventListener("mouseenter", handleMouseEnter);
    dropdownElement.addEventListener("mouseleave", handleMouseLeave);

    // Limpia los eventos del oyente al desmontar el componente
    return () => {
      dropdownElement.removeEventListener("mouseenter", handleMouseEnter);
      dropdownElement.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <NavDropdown
        className="navegacio letrablanca ubermenu ubermenu-nojs ubermenu-main ubermenu-menu-27 ubermenu-responsive ubermenu-responsive-default ubermenu-responsive-collapse ubermenu-horizontal ubermenu-transition-fade ubermenu-trigger-hover_intent ubermenu-skin-minimal ubermenu-bar-align-right ubermenu-items-align-right ubermenu-bound-inner ubermenu-disable-submenu-scroll ubermenu-sub-indicators ubermenu-sub-indicators-align-text ubermenu-retractors-responsive ubermenu-submenu-indicator-closes hover-contact" title={ t("Contactar") } id="collasible-nav-dropdown.ayuda ubermenu-main-27s" style={{ marginTop:"2px" }}
        show={isOpen}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={dropdownRef}
    >
        <Container className="mt-1 mb-1 ajuda">
            <Row className="ml-5 ubermenu-nav" data-title="MAGNO" id="ubermenu-nav-main-27">
                <Col xs="12" md="12" sm="6" className="mt-2 mb-3 ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-14448 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto">
                <span className="bold text-box-contactar" style={{ paddingLeft: "12px"}}><i className="fa-regular fa-comments"></i> { t("¿Alguna pregunta? ¡Hablemos!") }</span>
                </Col>
                <hr></hr>
                <Col xs="12" md="12" sm="6" className="text-box-contactar">
                <span style={{ display:"block", marginBottom:"6px" }}>
                    <i className="fa-solid fa-phone"></i> { config.telefono_fiscal}
                </span>
                    <span style={{ display:"block", marginBottom:"20px" }}>
                    <a href={"/"+preidioma+t('contacto') } className="text-box-contactar"><i className="fa-regular fa-envelope" aria-hidden="true"></i> { config.email_fiscal}</a>
                </span>
                </Col>
            </Row>
        </Container>
   </NavDropdown>
  );
};

export default MyNavDropdown;
