import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Content = ({ lang, categorias, faqs }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    document.querySelectorAll('.question-container').forEach(item => {
        item.addEventListener('click', () => {
            const answer = item.querySelector('.answer');
            const flecha = item.querySelector('.open-question')
            answer.classList.toggle('hidden');
            flecha.classList.toggle('close-question');
        });
    });

    return (
        <main className="mt-5 main mb80">
            <section className="container section section-first">
                <div className="row mb80">
                    <div className="col-12 col-lg-12">
                        <span
                            className="title title--overhead js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    {t("FAQ")}
                                </div>
                            </div>
                        </span>
                        <h2
                            className="title title--h2 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    {t("Preguntas frecuentes")}
                                </div>
                            </div>
                        </h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/* Content */}
                    {categorias.map((categoria, index) => (
                        <>
                            {faqs.filter(faq => faq.categoria_id == categoria.id).length > 0 ?
                                <div className="mb-5 col-12 col-lg-12 col-xl-12" key={"cat" + index}>
                                    <h1
                                        className="title title--h3 js-lines"
                                        style={{ overflow: "inherit" }}
                                    >
                                        <div style={{ overflow: "inherit" }}>
                                            <div
                                                className="tl-line"
                                                style={{
                                                    display: "block",
                                                    textAlign: "left",
                                                    position: "relative",
                                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                                    opacity: 1,
                                                    overflow: "inherit"
                                                }}
                                            >
                                                {categoria.nombre[lang]}
                                            </div>
                                        </div>
                                    </h1>
                                    {faqs.filter(faq => faq.categoria_id == categoria.id).map((faq, index2) => (
                                        <div className="question-container" key={"f" + index2}>
                                            {" "}
                                            <div className="question-title-container">
                                                {" "}
                                                <h5 className="mb0">
                                                    {faq.pregunta[lang]}
                                                </h5>{" "}
                                                <span className="open-question" />
                                            </div>{" "}
                                            <div className="hidden paragraph answer" dangerouslySetInnerHTML={{ __html: faq.respuesta[lang] }}></div>
                                        </div>
                                    ))}
                                </div>
                                :
                                null
                            }
                        </>
                    ))}

                    {/* <div className="col-12 col-lg-12 col-xl-12">
                        {" "}
                        <h1
                            className="title title--h3 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Normas del alojamiento
                                </div>
                            </div>
                        </h1>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuándo puedo hacer check-in? ¿Y cuándo el check-out?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                El check-in (entrada) regular es a las 16:00, mientras que la hora
                                del check-out (salida) es a las 10:00. Los check-in tempraneros y
                                los check-out tardías dependen de la disponibilidad y pueden incluir
                                un costo adicional. Por favor consúltanos directamente para
                                ofrecerte la mejor opción.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Cómo funciona la entrega de llaves?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Te tratamos de forma personalizada y te daremos la bienvenida en
                                persona el día de tu llegada para enseñarte la vivienda vacacional y
                                entregarte las llaves en mano. Con muchísimo gusto te damos
                                recomendaciones de que hacer en Tenerife, dónde comprar u otras
                                informaciones que requieras durante tu estancia. Por favor avísanos
                                30 minutos antes de llegar a la vivienda vacacional o al salir del
                                aeropuerto por Whatsapp para estar en la vivienda vacacional cuando
                                llegues.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Se puede fumar en las viviendas vacacionales?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                No se permite fumar en el interior de las viviendas vacacionales.
                                Fumar tabaco, cigarrillos electrónicos u otros productos que
                                produzcan vapor o humo solo están permitidon en las zonas exteriores
                                de las viviendas. Si fuman en el interior de la vivienda, debemos
                                hacer una limpieza especial para eliminar el olor a tabaco.&nbsp;
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Las fiestas o reuniones están permitidas?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                No están permitidas fiestas en las zonas interiores como exteriores
                                de nuestras viviendas, música elevada y cualquier actividad que
                                pueda alterar el órden público o crear molestias al vecindario,
                                tanto durante el día como en horario nocturno.&nbsp; *El
                                incumplimiento de estas normas se considerará como un incumplimiento
                                de la estancia y se procederá a la cancelación inmediata de la
                                reserva, reteniendo la fianza y el pago total de la misma en
                                concepto de daños y perjuicios y procediendo a la expulsión
                                inmediata. Cualquier otra multa que se produzca como consecuencia
                                del incumplimiento será cargada íntegramente al huésped.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuántas personas se pueden hospedar en una vivienda vacacional?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Depende de la vivienda vacacional que escojas para tu estancia.
                                Ofrecemos viviendas con dos, tres y cuatro dormitorios que son
                                ideales para hospedar cuatro, seis u ocho personas. No está
                                permitido que se hospeden más personas que las que figuran en la
                                confirmación de reserva*. En todo momento debes respetar las medidas
                                sanitarias actuales y el número máximo ocupación establecidas por el
                                Gobierno frente al COVID-19.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Tengo que limpiar la vivienda después de mi estancia?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                El día de tu salida, deberás dejar la vivienda vacacional en un
                                estado razonable de limpieza. Esto significa que la vajilla (platos,
                                vasos, cubiertos y utensilios de cocina) y la barbacoa deben estar
                                limpios. La basura debe sacarse y depositarse en los contenedores de
                                basura. Está prohibido dejar basura en la vivienda vacacional. En
                                caso de que la vivienda vacacional no se encuentre en condiciones
                                aceptables, se aplicará un cargo por la limpieza adicional de 150€.
                                Este cargo se descontará de la fianza abonada.{" "}
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Tengo que identificarme como huésped?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Si, tenemos la obligación de remitir a la Dirección General de la
                                Policía, la información relativa a la estancia de las personas que
                                se alojan en ella. Se establece que todas las personas mayores de
                                catorce años que hagan uso de la vivienda vacacional deben rellenar
                                y firmar los partes de entrada, conforme al sistema y modelo que se
                                establezca. En el caso de las personas menores de catorce años, sus
                                datos serán proporcionados por la persona mayor de edad de la que
                                vayan acompañados. Con carácter previo al inicio de la actividad y
                                conforme al procedimiento que se establezca, los sujetos obligados
                                deberán comunicar a las autoridades competentes los datos que se
                                contemplan. Te vamos a solicitar el parte de entrada antes de tu
                                llegada.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Puedo recibir invitados durante mi estancia?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Te permitimos recibir invitados durante tu estancia, no obstante es
                                tu responsabilidad de que no se supere el número de ocupación máximo
                                permitido. Todo invitado deberá cumplir las normas y reglas de las
                                viviendas vacacionales. Si un invitado quiere pasar la noche en la
                                propiedad, deberá ser comunicado y aprobado anteriormente por
                                nosotros pudiendo estar sujeto a una tarifa extra.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Qué ocurre si entran a robar en la vivienda?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Por suerte no nos ha pasado hasta ahora. A partir del día de
                                check-in hasta el día de check-out y siempre que salgas de la
                                vivienda vacacional, está bajo tu propia responsabilidad asegurarte
                                de que las ventanas y las puertas estén bien cerradas para mantener
                                la seguridad y prevenir daños provocados por robos, la lluvia o el
                                viento.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Qué ocurre si ocasiono un daño?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                ¡Esperemos que no! Te recomendamos tener un seguro que cubra
                                posibles daños. En el caso que se te rompa algo durante tu estancia,
                                nos lo debes comunicar de inmediato. Te damos algunos consejos
                                prácticos: Para evitar daños y desperfectos se prohíbe trasladar
                                mobiliario de una habitación a otra y tampoco debes usar el
                                mobiliario interior en el exterior sin previo consentimiento por
                                nuestra parte.&nbsp; Especial atención le debes prestar a los toldos
                                que se encuentran en el exterior. Los toldos solamente se pueden
                                usar cuando estás presente en la vivienda vacacional y recuerda que
                                siempre deben ir sujetos a las barras proporcionadas para su
                                sujeccion adecuada. Si no te encuentras en la vivienda o de noche,
                                deberás recogerlo siempre. En el peor de los casos, si se te rompe
                                algo se te reducirá parte o la totalidad de la fianza.&nbsp;
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Qué puede hacer para cuidar del medio ambiente?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Creemos en que la única forma de mejorar la calidad del medio
                                ambiente, es involucrar a todos. Por eso promocionamos el uso
                                efectivo del agua, el ahorro de energía y el tratamiento adecuado de
                                los residuos. Te pedimos amablemente que colabores con nosotros con
                                lo siguiente: Mantén apagada aquella luz que no estés usando. Apaga
                                el televisor cuando no la estés viendo. Deposíta las toallas que
                                hayas usado en la ducha después de tu salida. Las toallas limpias
                                las puedes dejar guardadas en el armario. Se responsable con el uso
                                del agua y a la hora de lavarte los dientes. Para tus residuos usa
                                los diferentes contenedores de basura. Muy cerca de las viviendas
                                vacacionales hay diferentes contenedores de basura: Contenedores
                                Verdes: Para el vidrio Contenedores Azules: Para el papel y cartón
                                Contenedores Amarillos: Destinados a los envases ligeros (metal,
                                plástico y briks) Si amas tu vida, empieza por amar la del planeta.
                                Te lo agradecemos de todo corazón para luchar contra la
                                contaminación de nuestra tierra y su deterioro irreversible.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        {" "}
                        <h1
                            className="title title--h3 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Contacto
                                </div>
                            </div>
                        </h1>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cómo puedo ponerme en contacto con ustedes?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Si estás buscando información detallada sobre una de nuestras
                                viviendas, o si tienes preguntas específicas sobre tu próxima
                                estadía, comunícate con nosotros por Email
                                (hello@holidayhomescanarias.com) o por teléfono (+34 689 30 28 54).
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Puedo contactarme con ustedes por Whatsapp?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Sí, nos puedes contactar por Whatsapp, o bien escribirnos un email o
                                llamarnos. Aquí encuentras nuestros contactos.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        {" "}
                        <h1
                            className="title title--h3 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    Villas
                                </div>
                            </div>
                        </h1>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Qué tipo de viviendas vacacionales ofrecen?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Ofrecemos cinco viviendas vacacionales. Nuestras viviendas
                                vacacionales cuentan con dos a cuatro dormitorios que son ideales
                                para hospedar de cuatro a ocho personas. Todas las viviendas
                                disponen de terrazas privadas con barbacoa. Cuatro de nuestras
                                viviendas cuentan con piscinas las cuales son de uso exclusi
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Las viviendas vacacionales son aptas para niños?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                En nuestras viviendas vacacionales recibimos a todos los miembros de
                                la familia y los niños pequeños sin costo alguno. Te proporcionamos
                                cuna, trona y vajilla especial para los más pequeños. Avísano de
                                antemano si vienes con bebés o niños pequeños para acomodar la
                                vivienda como te la mereces.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Las viviendas vacacionales son aptas para mascotas?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                A parte de que nos encantan los animales, somos conscientes de la
                                importancia que tienen tus mascotas para ti y queremos que las
                                lleves contigo a donde sea que vayas, sin ningún coste adicional.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuál es la velocidad del WIFI en las viviendas vacacionales?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                La velocidad de WIFI puede variar y depende del operador de
                                telefonía. En general te podemos decir que contamos con una conexión
                                de fibra.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Proporcionan ropa de cama?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Por supuesto, te proporcionamos a tí y a los demás huéspedes ropa de
                                cama consistentes en nórdico y almohadas de 50x60cm. Si lo deseas
                                también te ofrecemos un cambio de fundas nórdicas.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Proporcionan toallas?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Sí, te proporcionamos a tí y a los demás huéspedes una toalla de
                                baño grande y una pequeña así como una toalla de playa o piscina.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Puedo lavar mi ropa?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                ¡Claro! Todas nuestras viviendas vacacionales cuentan con lavadoras
                                y secadoras. También ponemos a disposición una tabla de planchar y
                                una plancha. Lo único que necesitas llevar contigo o comprar es
                                detergente y suavizante para la ropa.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cómo se limpian las viviendas vacacionales?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Hoy más que nunca, tu salud y tu bienestar es una prioridad
                                absoluta. Por eso nos comprometemos a limpiar las viviendas
                                vacacionales. Una limpieza implica dar un paso más allá, esto
                                implica entre otros que sanitamos las viviendas vacacionales,
                                pasamos la aspiradora y fregamos el suelo, limpiamos los
                                dormitorios, cambiamos las sábanas y toallas y desinfectamos los
                                armarios por dentro y por afuera, limpiamos los baños a fondo con
                                lejia, lavamos todos los textiles como sábanas, fundas de cojines y
                                almohadas, cortinas a la máxima temperatura posible, lavamos los
                                armarios, las gabetas en la cocina y la vajilla, limpiamos todas las
                                ventanas Queremos que disfrutes de la privacidad de sentirte como en
                                casa en una vivienda lejos de lugares concurridos.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Cuándo vienen a limpiar las piscinas?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Las piscinas de nuestras viviendas vacacionales se limpian una a dos
                                veces por semana. La persona encargada viene o bien por la mañana
                                (antes de las 10h) o por la tarde (después de las 18h).
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Tengo que regar las plantas durante mi estancia?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                No, nosotros nos encargamos de esto para que puedas desconectar,
                                relajarte y no pensar en deberes. La persona encargada viene dos a
                                tres veces por semana para regar las plantas en las zonas exteriores
                                de las viviendas vacacionales.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Dónde puedo dejar mi vehículo?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Tenemos a disposición tres aparcamientos para nuestros huéspedes.
                                Dos aparcamientos se encuentran en El Porís y uno se encuentra en La
                                Listada. Los aparcamientos son comunitarios para todos nuestros
                                huéspedes, es decir no podemos ofrecer el uso exclusivo de un
                                aparcamiento. En caso de que no haya aparcamiento libre, los
                                aparcamientos públicos y sin costa están a una distancia corta
                                caminando de las viviendas. Para más información, ten la confianza
                                de preguntarnos y te ayudamos.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        {" "}
                        <h1
                            className="title title--h3 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 0,
                                        overflow: "inherit"
                                    }}
                                >
                                    Pagos, precios, reembolsos y cancelaciones
                                </div>
                            </div>
                        </h1>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Cómo puedo pagar mi reserva?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Tienes tres formas para pagar tu reserva en nuestra página web: por
                                transferencia bancaria antes de tu estancia, con una tarjeta de
                                crédito válida (Visa o Mastercard) o utilizando Bizum. Los pagos
                                deben hacerse por adelantado.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cómo son las normas de pago? ¿De cuánto es el prepago y el pago
                                    final?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Para confirmar tu reserva, te solicitamos un prepago de un 30% del
                                importe total en el momento de hacer la reserva. El pago final y
                                completo se debe efectuar hasta 60 días antes de la llegada. Si
                                efectuas una reserva y te quedan menos de 60 días para hacer el
                                check-in, te pediremos un pago del del importe total de la reserva.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Tengo que pagar un depósito de seguridad?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Sí, debes abonar un depósito antes de que comience tu estancia. Te
                                pediremos que abones un depósito de 300€, o bien por transferencia
                                bancaria, con tarjeta de crédito válida o Bizum. Si no se producen
                                daños a la vivienda vacacional durante tu estancia, te devolveremos
                                el depósito 14 días después de tu salida o antes de la llegada del
                                próximo huésped. Es posible que tu banco tarde unos días en procesar
                                la devolución.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Qué es la tarifa de limpieza?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Los gastos de limpieza ascienden a 150€ por vivienda reservada y se
                                establecen para cubrir el coste de mantener la vivienda vacacional
                                impecable. Este importe se paga una sola vez y se suma a tu reserva.
                                Los gastos de limpieza sirven para cubrir los costes necesarios para
                                preparar tu vivienda antes de que llegues y una vez que te marches.
                                También te proporcionaremos toallas de baño y playa y la ropa de
                                cama.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Qué es la tarifa de administración?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                La tarifa de administración asciende a 25€ y se establece para
                                curbir los gastos de check-in y check-out. Al mismo tiempo te
                                garantizamos que estamos a tu disponibilidad durante nuestro horario
                                de oficina de lunes a domingo de 09:00h. a 21.00h. (GMT+1).
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿El IGIC está incluido en el importe total de reserva?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Claro, el IGIC está incluido en el precio total.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Puedo solicitar una factura de mi reserva?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                ¡Por supuesto! Pídenos la factura de tu reserva y te la enviaremos
                                con mucho gusto.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuál es la es la Política de Cancelación?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Los huéspedes pueden cancelar hasta 60 días antes de la llegada y
                                recibir un reembolso del 50% del total de la reserva (precio por
                                noche, gastos de limpieza, gestos de administración). Ejemplo: 60
                                días antes de llegada: 01 de julio (llegada: 30 de agosto - salida:
                                10 de septiembre):&nbsp;Para obtener un reembolso del 50% del precio
                                por noche, la cancelación la debes realizar 60 días completos antes
                                de la llegada. Si cancelas con menos de 60 días de antelación, no se
                                te reembolsará el importe total de la reserva. Si llegas y decides
                                dejar tu vivienda vacacional antes de tiempo, las noches no
                                disfrutadas no serán reembolsadas. Tampoco se reembolsará la tarifa
                                de limpieza ni la de administración. Las cancelaciones siempre deben
                                notificarse por escrito mediante correo electrónico a
                                hello@holidayhomescanarias.com
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Qué sucede si tengo que cancelar una reserva debido a una
                                    urgencia?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Quizá podamos emitir un reembolso si debes cancelar una reserva
                                debido a una urgencia. Por favor lee detenidamente la Política de
                                Causas de Fuerza Mayor o la Política de Causas de Fuerza Mayor
                                Relativa al Coronavirus.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuál es la Política de Causas de Fuerza Mayor?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                En caso de que tengas que cancelar en virtud de esta política,
                                tendrá prioridad sobre la Política de Cancelación. Si te ves
                                afectado por alguna de las situaciones incluidas en esta política
                                podrás cancelar tu reserva y recibir un crédito de hospedaje. No
                                efectuamos la devolución del dinero. Estas situaciones están
                                cubiertas: En la Política de Causas de Fuerza Mayor hablamos de
                                «situación» para referirnos a las circunstancias que surjan tras tu
                                la reserva, que no podías anticipar, y que te impiden o prohíben
                                legalmente completarla, como pueden ser: • Cambios inesperados en
                                los requisitos para solicitar un visado o pasaporte que imponga una
                                autoridad gubernamental y que impidan viajar al destino. No están
                                contempladas la pérdida o caducidad de los documentos necesarios
                                para viajar u otras circunstancias personales relacionadas con la
                                autorización de un viajero para desplazarse. • Emergencias
                                nacionales o locales, epidemias, pandemias y emergencias de salud
                                pública que declare oficialmente el Gobierno. No se incluyen las
                                enfermedades que son endémicas o que ya están asociadas con una zona
                                determinada, como el Covid19. • Restricciones de viaje que imponga
                                una autoridad gubernamental y que impidan o prohíban viajar a
                                Tenerife u hospedarse en la vivienda vacacional. No se incluyen las
                                recomendaciones para viajeros no vinculantes y otros consejos
                                similares que pueda dar el Gobierno. • Actos de guerra, conflictos,
                                invasiones, guerras civiles, terrorismo, explosiones, bombardeos,
                                rebeliones, disturbios, insurrección, desorden público e
                                inestabilidad civil. • Catástrofes naturales, actos fortuitos,
                                interrupciones a gran escala de los suministros públicos básicos,
                                erupciones volcánicas, tsunamis y otros fenómenos meteorológicos
                                extremos y anormales. No se incluyen los fenómenos meteorológicos ni
                                las condiciones naturales que son habituales en una zona y que, por
                                lo tanto, pueden ser previsibles como, por ejemplo, los vientos. Las
                                siguientes situaciones no están cubiertas: Todas las demás. Esta
                                política solo permite que se cancelen reservas debido a las
                                situaciones descritas anteriormente. Queda excluido todo lo demás.
                                Algunos ejemplos de situaciones que esta política no cubre son: •
                                Dolencias, enfermedades o lesiones inesperadas • Obligaciones
                                gubernamentales como ser convocado para formar parte de un jurado •
                                Comparecencias ante los tribunales o deberes militares •
                                Recomendaciones para viajeros u otros consejos del Gobierno (que no
                                restrinjan o prohíban los viajes) • Cancelación o reprogramación de
                                un evento para el que se realizó la reserva • Interrupciones del
                                transporte que no estén relacionadas con una situación cubierta,
                                como cortes de carreteras y cancelaciones de vuelos, trenes,
                                autobuses y ferris.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuál es la Política de Causas de Fuerza Mayor Relativa al
                                    Coronavirus?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                El 11 de marzo 2020, la Organización Mundial de la Salud (OMS)
                                declaró el brote de coronavirus pandemia mundial. Desde entonces, ha
                                evolucionado rápidamente y los Gobiernos de todo el mundo han tomado
                                medidas con diligencia para frenar su propagación. Reservas
                                realizadas hasta el 14 de marzo de 2020 Si efectuaste una reserva en
                                una de nuestras viviendas vacacionales hasta el 14 de marzo de 2020
                                con fecha de inicio en los próximos 45 días, entonces están
                                cubiertas por la Política de Causas de Fuerza Mayor Relativa al
                                Coronavirus y podrás cancelar tu reserva antes de que comience tu
                                estadía recibiendo un crédito de hospedaje. No efectuamos la
                                devolución del dinero. Si una reserva ya ha comenzado (es decir, si
                                ha pasado la fecha de inicio), no podrás acogerte a la Política de
                                Causas de Fuerza Mayor. Reservas realizadas después del 14 de marzo
                                de 2020 Nuestra Política de Causas de Fuerza Mayor no cubrirá las
                                reservas de nuestras viviendas vacacionales realizadas después del
                                14 de marzo de 2020. Las circunstancias relacionadas con el
                                coronavirus que quedan excluidas de nuestra cobertura son, entre
                                otras: • Interrupciones y cancelaciones del transporte •
                                Restricciones y recomendaciones para los viajeros • Instrucciones
                                sanitarias y cuarentenas • Cambios en la legislación aplicable y
                                otras medidas que decreten las autoridades, como órdenes de
                                evacuación, cierres de fronteras, prohibiciones de alquileres de
                                corta duración, cambio en ocupaciones máximas o la obligación de
                                autoaislamiento En esos casos, aplicaremos la Política de
                                Cancelación como de costumbre. El objetivo de nuestra Política de
                                Causas de Fuerza Mayor es proteger a nuestros huéspedes en caso de
                                circunstancias imprevistas que surjan después de reservar. Desde que
                                la Organización Mundial de la Salud declaró la COVID-19 como
                                pandemia mundial, no podemos aplicar la Política de Causas de Fuerza
                                Mayor a las cancelaciones relacionadas con el coronavirus y sus
                                consecuencias, puesto que han dejado de considerarse una
                                circunstancia imprevista o inesperada.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cuánto se tarda en recibir un reembolso?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Enviamos los reembolsos inmediatamente después de la cancelación.
                                Por lo general, se reciben en un plazo de 14 días.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        {" "}
                        <h1
                            className="title title--h3 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 0,
                                        overflow: "inherit"
                                    }}
                                >
                                    Mi cuenta
                                </div>
                            </div>
                        </h1>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">¿Cómo creo mi cuenta personal?</h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Tu cuenta se genera automáticamente cuando efectuas una reserva en
                                nuestra página web, pero no se activa hasta que no generes una
                                contraseña propia y confirmes tu cuenta.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Qué ventajas tengo al crear mi cuenta?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Tu cuenta personal te sirve para ver tus reservas presentes y
                                pasadas, tu perfil y la facturación.
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cómo puedo cambiar o restablecer mi contraseña?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Es muy fácil, solo tienes que ir a la página web para restablecer la
                                contraseña y seguir con estos pasos: (1)&nbsp;Haz click en "Log In"
                                - (2)&nbsp;Haz click en "Me olvidé de mi contraseña" -
                                (3)&nbsp;Introduce tu dirección de correo electrónico que utilizaste
                                al efectuar la reserva - (4) Haz clic en "Enviar" para restablecer
                                la contraseña - (5)&nbsp;Te mandaremos el enlace correspondiente por
                                correo electrónico
                            </p>
                        </div>
                        <div className="question-container">
                            {" "}
                            <div className="question-title-container">
                                {" "}
                                <h5 className="mb0">
                                    ¿Cómo puedo editar o cambiar detalles en mi cuenta?
                                </h5>{" "}
                                <span className="open-question" />
                            </div>{" "}
                            <p className="hidden paragraph answer">
                                Puedes cambiar y añadir datos en tu perfil como tus datos
                                personales, tu teléfono, tu dirección, tus datos de facturación y tu
                                contraseña personal. Si quieres modificar las fechas de tu reserva,
                                debes ponerte en contacto con nosotros.
                            </p>
                        </div>
                    </div>{" "} */}
                </div>
            </section>
        </main>

    );
};
export default Content;
