import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

var script = document.createElement("script"); script.type = "module"; script.src = "https://widgets.thereviewsplace.com/2.0/rw-widget-masonry.js"; document.getElementsByTagName("head")[0].appendChild(script);

const Widget = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section">
            <div className="row">
                <div className="col-12 col-lg-12 mb80">
                    {/* Begin widget code */}
                    <div data-rw-masonry={25735}>
                        <rw-widget-masonry
                            data-rw-masonry={25735}
                            className="hydrated"
                            style={{ display: "inline-block" }}
                        />
                    </div>
                    {/* End widget code */}
                </div>
            </div>
        </section>

    );
};
export default Widget;
