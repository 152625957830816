import { React, useState } from "react";
import ImageGallery from "react-image-gallery";
import { Modal, Image } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';



const Galeria = ({ propiedad, ruta, lang }) => {
    const { t } = useTranslation();

    let results = [];
    let results2 = [];

    let galeria = [];

    const [fullscreen, setFullscreen] = useState(true);

    const fotos = propiedad.fotos_web;

    for (let i = 0; i < fotos.length; i++) {
        const image = {
            original: ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + fotos[i].nombre
        }
        galeria = galeria.concat(image);
    }

    const [showGallery, setShowGallery] = useState(false);

    const handleShowGallery = () => {
        setShowGallery(!showGallery)
        setFullscreen(true);
    }

    propiedad.fotos_web.forEach((unafoto, index) => {
        if (index % 2 == 0) {
            results = results.concat(
                <a className="w-100 heightgaleria" style={{ backgroundImage: "url('" + ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre + "')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} key={index} onClick={() => handleShowGallery()}>
                    <div></div>
                </a>
            )
        }
        else {
            results2 = results2.concat(
                <a className="w-100 heightgaleria" style={{ backgroundImage: "url('" + ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre + "')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} key={index} onClick={() => handleShowGallery()} >
                    <div></div>
                </a>
            )
        }
    })

    return (
        <>
            <div className="mt-5 container-fluid w-100">
                <div className="d-flex align-items-center justify-content-start text-align-center w-100 sep-serv">
                    <p className="p-0 text-align-left amayusculas">{t("Galeria")}</p>
                    <hr className="bg-dark" style={{ width: "100%" }} />
                </div>

                <div className="container-fluid galeria-fotos">
                    <div className="box-galeria w-100">
                        <div>
                            {results}
                        </div>
                        <div>
                            {results2}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showGallery}
                onHide={() => setShowGallery(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery
                        items={galeria}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>
        </>





    );
}
export default Galeria;
