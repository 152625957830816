import { React, useState } from "react";
import { Modal, Button, Dropdown, Carousel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const ImagePopup = ({ imageSrc, urlprop }) => {

    const { t } = useTranslation();

    const [showI, setShowI] = useState(false);

    const handleCloseI = () => setShowI(false);
    const handleShowI = () => setShowI(true);

    return (
        <>
            <img
                src={imageSrc}
                className="d-block w-100"
                style={{ maxHeight: "440px", cursor: "pointer" }}
                onClick={handleShowI}
            />
            <Modal
                show={showI}
                onHide={handleCloseI}
                dialogClassName="modalpropietat modal-fullscreen"
            >
                <Modal.Header>
                    <Button
                        id="maszoom"
                        variant="secondary"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                            let img = document.getElementById("laimagen");
                            img.style.transform = "scale(3)";
                            document.getElementById("menoszoom").style.display = "block";
                            document.getElementById("maszoom").style.display = "none";
                        }
                        }
                    >
                        <i className="fa-regular fa-magnifying-glass-plus"></i>
                    </Button>
                    <Button
                        id="menoszoom"
                        variant="secondary"
                        style={{ background: "none", border: "none", display: "none" }}
                        onClick={() => {
                            let img = document.getElementById("laimagen");
                            img.style.transform = "scale(1)";
                            document.getElementById("menoszoom").style.display = "none";
                            document.getElementById("maszoom").style.display = "block";
                        }
                        }
                    >
                        <i className="fa-regular fa-magnifying-glass-minus"></i>
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{ background: "none", border: "none" }}>
                            <i className='fa-solid fa-share'></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                href={"https://www.facebook.com/sharer/sharer.php?u=" + urlprop + "%2F&t=Alojamiento"}
                                target="_blank"
                            >
                                <i className="fa-brands fa-facebook"></i> {t("Compartir en") + " Facebook"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={"https://twitter.com/intent/tweet?text=" + urlprop}
                                target="_blank"
                            >
                                <i className="fa-brands fa-twitter"></i> {t("Compartir en") + " Twitter"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={"https://www.pinterest.com/pin/create/button/?url=&media=" + imageSrc}
                                target="_blank"
                            >
                                <i className="fa-brands fa-pinterest"></i> {t("Compartir en") + " Pinterest"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="secondary" onClick={handleCloseI} style={{ marginLeft: "90%", background: "none", border: "none" }}>
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={imageSrc} alt="Imagen" id="laimagen" style={{ width: "75%", marginLeft: "10%", marginRight: "10%", objectFit: 'contain' }} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ImagePopup;
