import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const ListaCaracteristicas = ({ caracteristicas, config }) => {

    const { t } = useTranslation();

    return (
        <>    
            <div className="row text-center">
                <div className="col-md-8 offset-md-2">
                    <h2>{config.nombre_comercial}</h2>
                    <p className="font-italic mb-5">{ t("¿Por_qué_reservar_con_nosotros?") }</p>
                </div>
            </div>
            <div className="row gx-lg-5">
                    {caracteristicas.map((caracteristica, index) => (
                        <div  className="col-lg-6 col-xxl-4 mb-5" id={index} key={index}>
                            <div className="card bg-light border-0 h-100 shadow br15 bg-white">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <div className="caracteristica mb-5">
                                        <i className={ "fa-duotone "+caracteristica['imagen'] +" fa-2x" }></i>
                                    </div>
                                    <h2 className="fs-4 fw-bold">{ caracteristica['elnombre'] }</h2>
                                    <p className="mb-0" dangerouslySetInnerHTML={{__html: caracteristica['ladesc']}}></p>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    )
}

const Caracteristicas = ({caracteristicas, config}) => {

    return (
        caracteristicas.length>0 ? 
            <div className="mb-5 pt-4 pb-5">
                <Container>
                    <ListaCaracteristicas 
                        caracteristicas={caracteristicas} 
                        config = {config}
                    />
                </Container>
            </div>
        :
            <></>
    );
}

export default Caracteristicas;
