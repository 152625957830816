import { React } from "react";
import { Col, Nav, Row, Tab} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TextosAyuda = ({config}) => {
    const { t } = useTranslation();

    return (
            <div className="container mt-5">
            
                <Tab.Container id="left-tabs-example">
                    <Row>
                        <Col sm={3}>
                            <h4 className="uppercase">{ t("Enlace_rápidos") }</h4>
                            <Nav className="flex-column navleft">
                                <Nav.Link href="/terminos">{ t("Términos") }</Nav.Link>
                                <Nav.Link href="/legal">{ t("Legal") }</Nav.Link>
                                <Nav.Link href="#">{ t("Ayuda") }</Nav.Link>
                                <Nav.Link href="/contacto">{ t("Contacto") }</Nav.Link>
                            </Nav>
                        </Col>
                        <Col sm={9} className="bf7">
                            <div className="mb-3"></div>
                            <h2 className="text-left">{ t("Ayuda")+" "+config.nombre_comercial}</h2>
                            <div className="bb mb-4"></div>
                            <h3 className="text-center mb-4">{ t("¿No_encuentra_lo_que_busca?_Aquí_tiene_algunas_opciones") }</h3>
                            <div className="row">
                                <div className="col-sm-6 offset-sm-3 mb-4">
                                    <div className="featured-box">
                                        <div className="icon bg-primary">
                                            <i className="fa fa-comments"></i>
                                        </div>
                                        <div className="content">
                                            <h4 className="uppercase">{ t("Contactar_con_soporte") }</h4>
                                            <p>
                                                { config.nombre_comercial }<br/>
                                                { config.direccion_fiscal }<br/>
                                                { config.email_fiscal}<br/>
                                                { config.telefono_fiscal }
                                            </p>
                                            <a href={"tel:+"+ config.telefono_fiscal }>{ t("Contactar_con_soporte") }<i className="fa fa-caret-right ml-5"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-5"></div>                           
                        </Col>
                    </Row>
                </Tab.Container>
               
            </div>
    );
}

export default TextosAyuda;