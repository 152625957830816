import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Content = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <main className="mt-5 main mb80">
            <section className="container section section-first">
                <div className="row justify-content-center">
                    {/* Content */}
                    <div className="col-12 col-lg-10 col-xl-8">
                        <h1
                            className="title title--h2 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div className="tl-line" style={{ display: "block", textAlign: "left", position: "relative", transform: "matrix(1, 0, 0, 1, 0, 0)", opacity: 1, overflow: "inherit" }}>
                                    {t("Servicios")}
                                </div>
                            </div>
                        </h1>
                        <p className="paragraph paragraph--large" />
                        <p>{t("Aqui se describen nuestros servicios")}</p>
                        <p />
                    </div>
                </div>
            </section>
        </main>

    );
};
export default Content;
