import React from "react";
import { Container } from "react-bootstrap";
// import '../../../../../../resources/css/web/reysabio/styles.css';
import { useTranslation } from 'react-i18next';

const TextoSevilla = ({ lang }) => {

    const { t } = useTranslation();

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="p-0 m-0 container-fluid">
            {/* title sevilla */}
            <div className="p-0 m-0 container-fluid d-flex justify-content-center title-sev">
                <div>
                    <h1 className="m-0 noto">{t("Tu Casa para vivir Sevilla.")}</h1>
                </div>
            </div>
            {/* content sevilla */}
            <div className="p-0 m-0 container-fluid d-flex justify-content-center">
                <div className="w-100 d-flex box-sev">

                    <div className="mt-4 mb-4 max-h-100">
                        <div className="h-100 bg-dark">
                            <video src="/images/web/reysabio/Pexels-Anna-Nekrashevich-7946210-1.m4v" autoPlay="autoplay" loop="loop" muted="muted" playsInline="" controlsList="nodownload" className="encaja"></video>
                        </div>
                    </div>

                    <div className="max-h-100" >
                        {/* <div className="" ></div> */}
                        <div className="bg-dark" >
                            <img src="/images/web/reysabio/Casa-del-Rey-Sabio-208.jpeg" alt="" className="encaja" />
                        </div>
                    </div>

                    <div className="max-h-100 d-flex justify-content-center w-50">
                        <div className="h-100 content-sev">
                            <div className="pr-4">
                                <h2 className="noto">{t("EL DISEÑO, LA ELEGANCIA Y LA CALIDEZ DE UN HOGAR.")}</h2>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="p-0 m-0 w-50">{t("LA CASA")}</p>
                                <hr className="w-50 bg-dark" />
                            </div>
                            <div className="mb-4">
                                <p>{t("Casa del Rey Sabio es una casa palacio de origen islámico del siglo XII recién reformada. Su decoración incorpora elementos tradicionales y cálidos como son los patios en la planta baja, techos altos en los apartamentos, puertas y ventanas de madera y escaleras en piedra.")}</p>
                            </div>
                            <div className="mb-4">
                                <p>{t("Este establecimiento ofrece elegantes apartamentos equipados con todas las comodidades para una excelente estancia.")}</p>
                            </div>
                            <div className="">
                                <div className="btn-con">
                                    <a className="text-decoration-none" href={"/" + preidioma + t("sobre-nosotros")}>{t("Conócenos")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TextoSevilla;
