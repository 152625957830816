import { useTranslation } from 'react-i18next';

const Options = ({preidioma}) =>{

    const { t } = useTranslation();

    return (
        <div className="section mcb-section no-margin-h no-margin-v">
            <div className="section_wrapper mcb-section-inner submenuweb">
                <div className="wrap mcb-wrap one-fourth valign-top move-up clearfix  mTop-80W" id="positionDivWhite"  data-mobile="no-up">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper">
                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon1.png" />
                                </div>
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_divider">
                            <hr className="no_line m40" />
                        </div>
                        
                        <div className="column mcb-column one column_column ">
                            <div className="column_attr clearfix blackArrow" id="blackArrow">
                                <h4 className="fs-25 h4M0 " >
                                    <a href={"/"+preidioma+t("carta-del-presidente")}>{ t("LA ASOCIACIÓN") }</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div className="wrap mcb-wrap one-fourth valign-top move-up clearfix  mTop-80G" id="positionDivGray" data-mobile="no-up">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper">
                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon2.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="column mcb-column one column_divider">
                            <hr className="no_line h4M0"/>
                        </div>
                        
                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix whiteArrow" id="whiteArrow" >
                                <h4 className="fs-25 h4M0">
                                    <a className="white" href={"/"+preidioma+t("los-asociados")}>{t("LOS ASOCIADOS") }</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="wrap mcb-wrap one-fourth valign-top move-up clearfix  mTop-80O" id="positionDivOange" data-mobile="no-up">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper">
                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon3.png"/>
                                </div>
                            </div>
                        </div>

                        <div className="column mcb-column one column_divider">
                            <hr className="no_line m40"/>
                        </div>

                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix blackArrow" id="blackArrow">
                                <h4 className="fs-25 h4M0">
                                    <a className='text-uppercase' href={"/"+preidioma+t("servicios")}>{t("Servicios") }</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap mcb-wrap one-fourth valign-top move-up clearfix  mTop-80B" id="positionDivBlack" data-mobile="no-up">
                    <div className="mcb-wrap-inner">
                        <div className="column mcb-column one column_image">
                            <div className="image_frame image_item no_link scale-with-grid no_border">
                                <div className="image_wrapper">
                                    <img className="scale-with-grid" src="/images/apartur/shortcuts-icon4.png"/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="column mcb-column one column_divider">
                            <hr className="no_line m40"/>
                        </div>
                        
                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix whiteArrow" id="whiteArrow">
                                <h4 className="fs-25 h4M0" >
                                    <a className="black" href={"/"+preidioma+t("club-colaborador") }>{ t("CLUB COLABORADOR") }</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default Options;