
import { React } from "react";
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import { useTranslation } from 'react-i18next';

const Mapa = ({ destinos, elementosweb, config, lang }) => {
    let results = [];

    const { t } = useTranslation();
    
    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    var centrolat = 0;
    var centrolong = 0;

    destinos.forEach((undestino, index) => {
        centrolat += undestino.latitud;
        centrolong += undestino.longitud;

        results = results.concat(
            <Marker position={[undestino.latitud, undestino.longitud]} key={index}>
                <Popup>
                    <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl}>{undestino.elnombre}</a>
                </Popup>
            </Marker>
        );
    })
    centrolat = centrolat/destinos.length;
    centrolong = centrolong/destinos.length;

    return (
        config.activa_mapa_web ?
            <div className="mapa mt-4 pt-4 pb-5">
                
                <h2 className="text-center">{ elementosweb.titulo_mapa ? elementosweb.titulo_mapa[lang] : "" }</h2>
                <p className="text-center">{ elementosweb.subtitulo_mapa ? elementosweb.subtitulo_mapa[lang] : "" }</p>
                  
                <MapContainer center={[centrolat, centrolong]} zoom={config.zoom_mapa_web} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    { results }
                </MapContainer>
            </div>
        :
            <></>
    );
}

export default Mapa;
