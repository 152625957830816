import React from "react";
import { Container } from "react-bootstrap";
// import '../../../../../../resources/css/web/reysabio/styles.css';
import { useTranslation } from 'react-i18next';

const Content = ({ lang }) => {

    const { t } = useTranslation();

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="container-fluid contacto-box">
            <div>
                <h1 className="noto">{t("Contáctanos")}</h1>
            </div>
            <div className="w-100">
                <form action="" className="contactoForm w-100">
                    <div className="mt-3">
                        <input type="text" placeholder={t("Su nombre")} />
                    </div>
                    <div className="mt-3">
                        <input type="text" placeholder={t("Email")} />
                    </div>
                    <div className="mt-3">
                        <input type="text" placeholder={t("Mensaje")} />
                    </div>
                    <div>
                        <button type="submit" className="amayusculas">{t("Enviar")}</button>
                    </div>
                </form>
                <div>
                    <p><strong style={{ fontWeight: "700" }}>{t("Dirección")}: </strong><a href="mailto:info@casadelreysabio.com">Calle Don Alonso el Sabio, 7, 41004 Sevilla</a></p>
                    <p><strong style={{ fontWeight: "700" }}>{t("Teléfono")}: </strong><a href="tel:+34695637232">+34 695 637 232</a></p>
                    <p><strong style={{ fontWeight: "700" }}>{t("Email")}: </strong><a href="mailto:info@casadelreysabio.com">info@casadelreysabio.com</a></p>
                </div>
                <div>
                    <iframe style={{ width: "100%", height: "100%" }} data-placeholder-image="/images/web/reysabio/google-maps-minimal-1280x920.jpg" data-category="marketing" data-service="google-maps" className="cmplz-placeholder-element cmplz-no-video cmplz-processed cmplz-activated" data-cmplz-target="src" data-src-cmplz="https://maps.google.com/maps?q=Casa%20del%20Rey%20Sabio&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?q=Casa%20del%20Rey%20Sabio&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near" title="Casa del Rey Sabio" aria-label="Casa del Rey Sabio"></iframe>
                </div>
            </div>
        </div>
    );
};
export default Content;
