import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Detalles = ({ lang }) => {

    const { t } = useTranslation();

    // var currentTime = new Date();
    // var year = currentTime.getFullYear();

    // var preidioma = ""
    // if(lang!='es') preidioma = lang+"/";

    return (
        <div className="container-fluid box-detalles w-100">
            {/* separador */}
            <div className="p-0 d-flex align-items-center sep-exp">
                <hr className="bg-dark" />
                <p className="p-0">{t("NUESTRO OBJETIVO ES QUE ESTÉS BIEN")}</p>
            </div>
            <div className="" style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-193b.jpg')", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <div className="content-detalles">
                    <div>
                        <h2 className="noto">{t("Los detalles marcan la diferencia")}</h2>
                    </div>
                    <div>
                        <p>{t("Lujo y confort. Nuestra máxima es la excelencia. Queremos que tu experiencia en nuestros apartamentos sea lo más agradable posible. Que desconectes de todo lo que no sea Sevilla, tu descanso y la casa en la que te alojas.")}</p>
                        <p>{t("La exclusividad de la casa se hace palpable hasta en el más mínimo detalle; desde la estética del edificio hasta el trato a cada huésped. Ofrecemos personalización y atendemos las necesidades particulares de cada uno.")}</p>
                        <p>{t("Consulta con nuestro personal qué podemos hacer por ti.")}</p>
                        <p>{t("Te esperamos.")}</p>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default Detalles;

