import Menu from './Compartido/Menu.jsx';
import TextosLegal from './Legal/TextosLegal.jsx';
import Footer from './Compartido/Footer.jsx';
import { useTranslation } from 'react-i18next';

const Legal = (props) => {

    let { t } = useTranslation();

    return (
        <main>
            <Menu
               idiomas = {props.idiomas}
               lang = {props.lang}
               config = {props.websgrupo}
               config2 = {props.config}
               ruta = {props.ruta}
               meta_titulo = {"Magno Apartments - "+t("Aviso Legal")+", "+t("Privacidad")+", "+t("Política de Cookies")}
               meta_descripcion = {props.meta_descripcion}
               meta_keywords = {props.meta_keywords}
            />
            <TextosLegal 
               ruta = {props.ruta}
               legal = {props.textoavisolegal}
               cookies = {props.textocookies}
            />
            <Footer
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                idiomas = {props.idiomas}
                ruta = {props.ruta}
            />
        </main>
    );
}

export default Legal;