import { React } from "react";
import { Col, Nav, Row, Tab, Accordion } from 'react-bootstrap';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { useTranslation } from 'react-i18next';

import "./../../../../../css/magno/faqs.css";
import "./../../../../../css/magno/elementor.css";


const TextosFaqs = ({ categorias, faqs, lang }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <div id="post-1740" className="" >
                <div data-elementor-type="wp-page" data-elementor-id="1740" className="mt-20 elementor elementor-1740">
                    <section className="elementor-section elementor-top-section elementor-element elementor-element-bb133d elementor-section-content-top elementor-section-boxed elementor-section-height-default box-title-faqs" data-id="bb133d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style={{ marginTop: "50px", paddingBottom: "60px" }}>
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-container elementor-column-gap-default">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7da4a899" data-id="7da4a899" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <div className="elementor-element elementor-element-7d7def28 elementor-widget elementor-widget-heading" data-id="7d7def28" data-element_type="widget" data-widget_type="heading.default">
                                        <div className="elementor-widget-container">
                                            <h1 className="title elementor-heading-title elementor-size-medium title-font-mb" style={{ textAlign: "center", paddingLeft: '36px' }}>{t('Hola, ¿cómo podemos ayudarle?')}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-5 elementor-section elementor-top-section elementor-element elementor-element-1ac5f521 elementor-section-stretched elementor-section-boxed elementor-section-height-default faqs-box" data-id="1ac5f521" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                        <div className="elementor-column-gap-default" style={{ width: '100%' }}>
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1a3d8869" data-id="1a3d8869" data-element_type="column" style={{ padding: '0 20px' }}>
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-7fa1d9e3 elementor-section-full_width elementor-section-height-default" data-id="7fa1d9e3" data-element_type="section">
                                        <div className="row">
                                            {categorias.map((categoria, index) => (
                                                <div className="col-md-3" data-id="2cf4cdd9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" key={"c" + index}>
                                                    <div className='elementor-widget-wrap elementor-element-populated columnafaqs' style={{ backgroundColor: "#F8F8F8", paddingTop: "30px", paddingRight: "16px", paddingLeft: "16px", marginBottom: "20px" }}>
                                                        <div className="elementor-element elementor-element-5b08d15b elementor-widget elementor-widget-heading" data-id="5b08d15b" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h5 className="mb-4 elementor-heading-title elementor-size-default" style={{ paddingLeft: "0" }}>{categoria.nombre[lang]}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-21bbc79 elementor-widget elementor-widget-toggle" data-id="21bbc79" data-element_type="widget" data-widget_type="toggle.default">
                                                            <div className={"id" + categoria.id + " elementor-widget-container"} >
                                                                <div className="elementor-toggle" role="tablist">
                                                                    {faqs.filter(faq => faq.categoria_id == categoria.id).map((faq, index2) => (
                                                                        <>
                                                                            <Accordion key={"f" + index2}>
                                                                                <Accordion.Item eventKey={"p" + index2} style={{ backgroundColor: "#F8F8F8" }}>
                                                                                    <Accordion.Header >
                                                                                        <div className="preguntafaq">
                                                                                            <i className="fa-solid fa-check" style={{ fontSize: "14px", marginRight: "10px", color: "rgb(207, 203, 203)" }}></i>
                                                                                            {faq.pregunta[lang]}
                                                                                        </div>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body style={{ paddingTop: "0", paddingLeft: "10px" }}>
                                                                                        <div className="textfaq">
                                                                                            {ReactHtmlParser(faq.respuesta[lang])}
                                                                                        </div>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion>
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section className="elementor-section elementor-top-section elementor-element elementor-element-18cdcd5a elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default finalfaqs" data-id="18cdcd5a" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-default row">
                        <div className="elementor-column elementor-col-66 col-12 col-md-11 elementor-top-column elementor-element elementor-element-660dc22d" data-id="660dc22d" data-element_type="column" style={{padding:"0px 15px 0px 15px", alignContent: "center", alignItems: "center"}}>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-5bf4fd3e elementor-widget elementor-widget-heading" data-id="5bf4fd3e" data-element_type="widget" data-widget_type="heading.default">
                                    <div className="elementor-widget-container">
                                        <h3 className="elementor-heading-title elementor-size-default align-center-mb" style={{fontSize: "17px"}}>{ t('Si no encuentras la respuesta deseada, escríbenos y estaremos encantados de ayudarte')}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="elementor-column elementor-col-33 col-12 col-md-1 lementor-top-column elementor-element elementor-element-677140d1" data-id="677140d1" data-element_type="column">
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-32be55c1 elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="32be55c1" data-element_type="widget" data-widget_type="button.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-button-wrapper">
                                            <a href={"/"+preidioma+t("contacto") } className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink" role="button">
                                                <span className="elementor-button-content-wrapper">
                                                    <span className="elementor-button-text btn-faqs">{ t('contactar') }</span>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    </section>*/}
                </div>
            </div>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-18cdcd5a elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default finalfaqs" data-id="18cdcd5a" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                <div className="elementor-background-overlay"></div>
                <div className="elementor-container elementor-column-gap-default row">
                    <div className="elementor-column elementor-col-66 col-12 col-md-11 elementor-top-column elementor-element elementor-element-660dc22d" data-id="660dc22d" data-element_type="column" style={{ padding: "0px 15px 0px 15px", alignContent: "center", alignItems: "center" }}>
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-5bf4fd3e elementor-widget elementor-widget-heading" data-id="5bf4fd3e" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container">
                                    <h3 className="elementor-heading-title elementor-size-default align-center-mb" style={{ fontSize: "17px" }}>{t('Si no encuentras la respuesta deseada, escríbenos y estaremos encantados de ayudarte')}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="elementor-column elementor-col-33 col-12 col-md-1 lementor-top-column elementor-element elementor-element-677140d1" data-id="677140d1" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-32be55c1 elementor-align-right elementor-mobile-align-center elementor-widget elementor-widget-button" data-id="32be55c1" data-element_type="widget" data-widget_type="button.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-button-wrapper">
                                        <a href={"/" + preidioma + t("contacto")} className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink" role="button">
                                            <span className="elementor-button-content-wrapper">
                                                <span className="elementor-button-text btn-faqs">{t('Contactar')}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default TextosFaqs;
