import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';


const Recomendaciones = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        var script = document.createElement("script");
        script.type = "module";
        script.src = "https://widgets.thereviewsplace.com/2.0/rw-widget-slider.js";
        document.getElementsByTagName("head")[0].appendChild(script);
    }, []);

    return (
        <section className="section section-testimonials section-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <span className="title title--overhead js-lines black-text">
                            {t("Recomendaciones")}
                        </span>
                        <h1 className="title title--h1 js-lines">
                            {t("Nuestros huéspedes hablan por nosotros")}.
                        </h1>
                    </div>
                </div>
                <div
                    className="row pb-80 js-scroll-show"
                    style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                >
                    {/* Begin widget code */}
                    <div data-rw-slider={25736}>
                        <rw-widget-slider
                            data-rw-slider={25736}
                            className="hydrated"
                            style={{ fontFamily: "Lato", display: "inline-block" }}
                        >
                            <link
                                rel="stylesheet"
                                href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap"
                            />
                        </rw-widget-slider>
                    </div>
                    {/* End widget code */}
                </div>
            </div>
        </section>

    );
};
export default Recomendaciones;
