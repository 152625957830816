import Cabecera from './Compartido/Cabecera.jsx';
import Footer from './Compartido/Footer.jsx';
import Slider from './Inicio/Slider.jsx';
import Apartamentos from './Inicio/Apartamentos.jsx';
import TextoSevilla from './Inicio/TextoSevilla.jsx';
import Servicios from './Inicio/Servicios.jsx';
import '../../../../../resources/css/web/reysabio/styles.css';

const Inicio = (props) => {
    return (
        <main>
            <Cabecera
                lang={props.lang}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
                config={props.websgrupo}
            />
            <Slider
                lang={props.lang}
                destinos={props.destinos}
                ruta={props.ruta}
            />
            <TextoSevilla
                lang={props.lang}
            />
            <Apartamentos
                lang={props.lang}
                destacadas={props.destacadasmagno}
                ruta={props.ruta}
            />
            <Servicios />
            <Footer
                lang={props.lang}
            />
        </main>
    );
}

export default Inicio;

