import { React } from "react";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import "./../../../../../css/magno/blog.css";
import "./../../../../../css/magno/elementor.css";

const ListaNoticias = ({ noticias, ruta, lang}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    let results = [];
    return (
        <div className="">
            <div className="">
                <div id="post-558" className="post-558 page type-page status-publish hentry">
                    <div data-elementor-type="wp-page" data-elementor-id="558" className="elementor elementor-558">
                        <section className="elementor-section elementor-top-section elementor-element elementor-element-a0f0b57 elementor-section-boxed elementor-section-height-default" data-id="a0f0b57" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-32d62a8" data-id="32d62a8" data-element_type="column">
                                    <div className="elementor-widget-wrap"></div>
                                </div>
                            </div>
                        </section>

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-1687d60f elementor-section-stretched elementor-section-boxed elementor-section-height-default" data-id="1687d60f" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                            <div className="elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-10e692c5" data-id="10e692c5" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-8d97698 elementor-widget elementor-widget-heading" data-id="8d97698" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="mt-5 mb-5 elementor-heading-title elementor-size-medium" style={{textAlign:"center"}}>{ t('Blog') }</h3>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-5e6dde5e elementor-widget elementor-widget-postgrid" data-id="5e6dde5e" data-element_type="widget" data-widget_type="postgrid.default">
                                            <div className="elementor-widget-container box-lista-noticias">
                                                <div className="p-0 row" style={{wdith: '100%', marginRight: 'auto', marginLeft: 'auto'}}>
                                                    {noticias.map((noticia, index) => (
                                                        <div className="col-md-12 col-lg-6 col-xl-3" key={index} style={{ marginBottom: "20px", padding: '10px' }}>
                                                            <div className="nd_elements_section nd_elements_background_color_fff nd_elements_border_1_solid_grey" style={{ boxShadow: "0px 0px 30px 0px rgb(0 0 0 / 5%)" }}>
                                                                <a href={"/"+preidioma+t("blog")+"/"+noticia.laurl}>
                                                                    {
                                                                        noticia.imagen != ""
                                                                            ?
                                                                            <div style={{ backgroundImage: "url('"+ruta + "/blog/" + noticia.id + "/" + noticia.imagen+"')" , backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover", width: "100%", height: "233px"}}></div>
                                                                            :  <div style={{ backgroundImage: "url('/images/web/bedloop-nofoto.png')" , backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover", width: "100%", height: "233px"}}></div>
                                                                    }
                                                                </a>
                                                                <div className="mt-5 nd_elements_section nd_elements_padding_40 nd_elements_padding_20_iphone nd_elements_box_sizing_border_box">

                                                                    <a className="nd_elements_section" href={"/"+preidioma+t("blog")+"/"+noticia.laurl} style={{ marginLeft:"10px",marginRight:"20px" }}>
                                                                        <h3 className="nd_elements_font_size_23 nd_elements_posgrid_widget_l4_title nd_elements_word_break_break_word nd_elements_font_size_20_iphone nd_elements_line_height_23 nd_elements_margin_0_important nd_elements_letter_spacing_1 titulomargin20">
                                                                            <strong className="titulonoticia">{ noticia.eltitulo }</strong>
                                                                        </h3>
                                                                    </a>
                                                                    <div className="nd_elements_section nd_elements_height_20"></div>
                                                                    <p className="nd_elements_font_size_15 nd_elements_section nd_elements_margin_0_important textolistanoticias nd_elements_posgrid_widget_l4_excerpt nd_elements_line_height_2" style={{textAlign: "left"}}>
                                                                        { ReactHtmlParser(noticia.ladesccorta) }[...]
                                                                    </p>

                                                                    <div className="nd_elements_section nd_elements_height_20"></div>

                                                                    <a className="nd_options_color_white nd_elements_font_size_13 nd_elements_posgrid_widget_l4_button nd_elements_letter_spacing_1 nd_elements_line_height_1 nd_elements_padding_10_20" href={"/"+preidioma+t("blog")+"/"+noticia.laurl} style={{ marginLeft:"10px", float:"left" }}><strong className="mayusculacss"> {t('Ver más')}</strong></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="elementor-element elementor-element-56d4245 elementor-widget elementor-widget-customcss" data-id="56d4245" data-element_type="widget" data-widget_type="customcss.default">
                                            <div className="elementor-widget-container"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListaNoticias;
