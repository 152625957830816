import React, { useState } from "react";
// import '../../../../../../resources/css/web/reysabio/styles.css';
import { useTranslation } from 'react-i18next';
import { Modal, Image } from 'react-bootstrap';
import ImageGallery from "react-image-gallery";

const Seccion1 = () => {

    const { t } = useTranslation();

    const [activeClass, setActiveClass] = useState('zc'); // clase activa por defecto

    const handleButtonClick = (className) => {
        setActiveClass(className);
    };



    let galleryZC = [];
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-183.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-184.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_16_-_piscina_privada_B3CD3B9E-C806-4F70-2D05-656CE8B335F4-4.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_16_-_piscina_privada_D162BC3C-95F1-239F-D9A7-E121C149F232-20.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-208.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-200.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-222.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/seville-casa_del_rey_sabio_-_suite_con_terraza_DC52AAC5-1C59-BA04-EADA-0BBE3A39AF43-25.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-243.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-263.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-197.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-255.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-188.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0896_0553.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_16_-_piscina_privada_5D4F6222-5A47-85D2-EE66-E245322D451D-13.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-193b.jpg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-202.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-228.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-231.jpeg" });
    galleryZC = galleryZC.concat({ original: "images/web/reysabio/Casa-del-Rey-Sabio-259.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-267.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-207.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0883_0546.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0953_057704.jpg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-201b.jpg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Captura-de-pantalla-2022-06-24-a-las-10.03.17.png" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-182.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-190.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-221.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-229.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-253.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-261.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-268.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-225.jpeg" });
    galleryZC = galleryZC.concat({ original: "/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0926_0564.jpeg" });


    let galleryAP = [];
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_C4C60C4E-7C3D-F03A-27DB-83C06A978D2C-10.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_962F4588-5DEE-F564-50F6-DC64B65C8475-6.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_15-_suite_presidencial_4ECA60A6-8322-74D7-3579-6B85CB923666-0.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_12_F20348EF-6F8D-F686-AA7A-0448A6B53881-1-1.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_15-_suite_presidencial_C6FA6A31-7F83-7743-3B19-5F54FCF24B6A-6.jpeg" });
    galleryAP = galleryAP.concat({ original: "images/web/reysabio/sevilla-casa_del_rey_sabio_-_suite_deluxe_f_2E5F808B-9079-80CB-41B3-720C5CC8CC44-6.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_8_E67F484D-1492-D7FC-FDD4-EA3AFBEAC5A5-7.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_5_B40228FB-A790-8303-7453-34CB05FEA9BF-5.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_11_D643D84E-ED1E-D9C0-EA35-1ECB54B87F01-0.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_BE348DBC-7A03-062E-01AB-CDCAAB9BEDB1-7.jpeg" });
    galleryAP = galleryAP.concat({ original: "images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_244D4B7E-F32E-126A-2975-85A3176473A9-2.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_13_15E0BC85-A195-B3F7-9891-F1622FDEB17C-2.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_13_74741640-4B32-49E3-2388-1C02083CB509-7.jpeg" });
    galleryAP = galleryAP.concat({ original: "images/web/reysabio/sevilla-casa_del_rey_sabio_8_34DD56D5-DFEF-B56B-DF82-F370F5581D98-2.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_8_5CBB1DC9-F75F-A526-7F5C-D80E57DC6BC9-8.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_5_A2709934-4F1F-5BF2-9B6E-1135B9ABD064-6.jpeg" });
    galleryAP = galleryAP.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_11_277783EC-E883-D9B2-8B17-65C302CA344E-6.jpeg" });

    let gallerySE = [];
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/AlfonsoelSabio_AP10_GN3_1482_021004.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-190-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-308-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-330-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-126-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-10404.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-12304.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-29504.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-33404.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-36004.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-52904.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/6-Desyuno-dobleDSC0572204.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-27-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-230-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-445-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-29-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-2604.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-12504.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-34004.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-45304.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-53104.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_AP15_GN3_0974_048904.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_AP16_GN3_1808_052804.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_AP16_GN3_1790_051904.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_15019C67-E31F-B279-190C-FCC2349975FE-8.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_15-_suite_presidencial_9AE1A28F-3D37-0EDB-9CDE-E9D8A47BEDC4-1.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_13_4D2534D5-AD8B-B8D2-6F13-46947303705C-5.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_13_797A793E-593D-61FA-C619-C950CBABBE78-8.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_-_suite_deluxe_f_66B4E0FB-21D2-38B8-E827-AB729A53E958-3.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_5_D8C48E77-4240-0572-CB8E-97C99422DD9C-1.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_3_949F94F9-6849-39AB-5C62-E45F56CF1F36-0.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/sevilla-casa_del_rey_sabio_7_5BD1761C-5B04-51A4-9235-D108155C213E-2.jpeg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/AlfonsoelSabio_AP11_GN3_0658_032804.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-22004.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-221-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-488-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-135-copia04.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-6704.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-15604.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-35104.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-45504.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CASA-REYSABIO-55604.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_AP15_GN3_0984_049004.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0968_058304.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_AP15_GN3_0883_047204.jpg" });
    gallerySE = gallerySE.concat({ original: "/images/web/reysabio/CasadelReySabio_AP15_GN3_0950_048404.jpg" });

    let galleryAL = [];
    galleryAL = galleryAL.concat({ original: "/images/web/reysabio/Casa-del-Rey-Sabio-278.jpeg" });

    const [showGalleryZC, setShowGalleryZC] = useState(false);
    const [showGalleryAP, setShowGalleryAP] = useState(false);
    const [showGallerySE, setShowGallerySE] = useState(false);
    const [showGalleryAL, setShowGalleryAL] = useState(false);

    const [fullscreen, setFullscreen] = useState(true);


    const handleShowGalleryZC = () => {
        setShowGalleryZC(!showGalleryZC)
        setFullscreen(true);
    }

    const handleShowGalleryAP = () => {
        setShowGalleryAP(!showGalleryAP)
        setFullscreen(true);
    }

    const handleShowGallerySE = () => {
        setShowGallerySE(!showGallerySE)
        setFullscreen(true);
    }

    const handleShowGalleryAL = () => {
        setShowGalleryAL(!showGalleryAL)
        setFullscreen(true);
    }


    return (
        <div className="container-fluid section1 w-100 custom-sec1">
            <div>
                <h1 className="noto">{t("Descubre la Casa")}</h1>
            </div>
            <div>
                <p>{t("Todos los detalles han sido cuidadosamente trabajados para que cada persona que venga, se sienta en su Casa de Sevilla.")}</p>
                <p>{t("Descubre todos nuestros rincones, zonas comunes, habitaciones y acabados.")}</p>
            </div>

            <div className="p-0 container-fluid d-flex w-100">
                <ul className="list-unstyled d-flex align-items-center sec1-opt">
                    <li>
                        <input type="hidden" name="" />
                        <button type="submit" className="noto" onClick={() => handleButtonClick('zc')}>{t("Zonas Comunes")}</button>
                    </li>
                    <li>
                        <input type="hidden" name="" />
                        <button type="submit" className="noto" onClick={() => handleButtonClick('ap')}>{t("Apartamentos")}</button>
                    </li>
                    <li>
                        <input type="hidden" name="" />
                        <button type="submit" className="noto" onClick={() => handleButtonClick('se')}>{t("Servicios")}</button>
                    </li>
                    <li>
                        <input type="hidden" name="" />
                        <button type="submit" className="noto" onClick={() => handleButtonClick('al')}>{t("Alrededores")}</button>
                    </li>
                </ul>
            </div>
            <div className="container-fluid galeria-fotos">
                <div className="box-galeria w-100">
                    <div>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-183.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_16_-_piscina_privada_B3CD3B9E-C806-4F70-2D05-656CE8B335F4-4.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-208.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-200.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-222.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/seville-casa_del_rey_sabio_-_suite_con_terraza_DC52AAC5-1C59-BA04-EADA-0BBE3A39AF43-25.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-243.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-263.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-197.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-255.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_C4C60C4E-7C3D-F03A-27DB-83C06A978D2C-10.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_962F4588-5DEE-F564-50F6-DC64B65C8475-6.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_15-_suite_presidencial_4ECA60A6-8322-74D7-3579-6B85CB923666-0.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_12_F20348EF-6F8D-F686-AA7A-0448A6B53881-1-1.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_15-_suite_presidencial_C6FA6A31-7F83-7743-3B19-5F54FCF24B6A-6.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_-_suite_deluxe_f_2E5F808B-9079-80CB-41B3-720C5CC8CC44-6.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_8_E67F484D-1492-D7FC-FDD4-EA3AFBEAC5A5-7.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_5_B40228FB-A790-8303-7453-34CB05FEA9BF-5.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_11_D643D84E-ED1E-D9C0-EA35-1ECB54B87F01-0.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/AlfonsoelSabio_AP10_GN3_1482_021004.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-190-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-308-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-330-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-126-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-10404.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-12304.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-29504.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-33404.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-36004.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-52904.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                    </div>
                    <div>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-188.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0896_0553.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_16_-_piscina_privada_5D4F6222-5A47-85D2-EE66-E245322D451D-13.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-193b.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-184.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-202.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-228.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-231.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-259.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-267.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-207.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0883_0546.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_BE348DBC-7A03-062E-01AB-CDCAAB9BEDB1-7.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_244D4B7E-F32E-126A-2975-85A3176473A9-2.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_13_15E0BC85-A195-B3F7-9891-F1622FDEB17C-2.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_13_74741640-4B32-49E3-2388-1C02083CB509-7.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_8_34DD56D5-DFEF-B56B-DF82-F370F5581D98-2.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_8_5CBB1DC9-F75F-A526-7F5C-D80E57DC6BC9-8.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_5_A2709934-4F1F-5BF2-9B6E-1135B9ABD064-6.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_11_277783EC-E883-D9B2-8B17-65C302CA344E-6.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/6-Desyuno-dobleDSC0572204.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-27-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-230-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-445-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-29-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-2604.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-12504.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-34004.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-45304.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-53104.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_AP15_GN3_0974_048904.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_AP16_GN3_1808_052804.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'al' ? 'w-100 al' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-278.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAL()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_AP16_GN3_1790_051904.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                    </div>
                    <div>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-201b.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_16_-_piscina_privada_D162BC3C-95F1-239F-D9A7-E121C149F232-20.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Captura-de-pantalla-2022-06-24-a-las-10.03.17.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '450px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-182.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-190.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-221.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-229.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-253.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-261.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-268.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-225.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0926_0564.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_17_-_terraza_15019C67-E31F-B279-190C-FCC2349975FE-8.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_15-_suite_presidencial_9AE1A28F-3D37-0EDB-9CDE-E9D8A47BEDC4-1.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_13_4D2534D5-AD8B-B8D2-6F13-46947303705C-5.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_13_797A793E-593D-61FA-C619-C950CBABBE78-8.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_-_suite_deluxe_f_66B4E0FB-21D2-38B8-E827-AB729A53E958-3.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_5_D8C48E77-4240-0572-CB8E-97C99422DD9C-1.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_3_949F94F9-6849-39AB-5C62-E45F56CF1F36-0.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'ap' ? 'w-100 ap' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/sevilla-casa_del_rey_sabio_7_5BD1761C-5B04-51A4-9235-D108155C213E-2.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryAP()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/AlfonsoelSabio_AP11_GN3_0658_032804.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-22004.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-221-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-488-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-135-copia04.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-6704.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-15604.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-35104.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '450px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-45504.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CASA-REYSABIO-55604.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '700px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_AP15_GN3_0984_049004.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'zc' ? 'w-100 zc' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0953_057704.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGalleryZC()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_ZonasComunes_GN3_0968_058304.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_AP15_GN3_0883_047204.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                        <a className={activeClass === 'se' ? 'w-100 se' : 'hidden'} style={{ backgroundImage: "url('/images/web/reysabio/CasadelReySabio_AP15_GN3_0950_048404.jpg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: '350px' }} onClick={() => handleShowGallerySE()}>
                            <div></div>
                        </a>
                    </div>

                </div>
            </div>

            <Modal
                show={showGalleryZC}
                onHide={() => setShowGalleryZC(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery
                        items={galleryZC}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showGalleryAP}
                onHide={() => setShowGalleryAP(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery
                        items={galleryAP}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showGallerySE}
                onHide={() => setShowGallerySE(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery
                        items={gallerySE}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>

            <Modal
                show={showGalleryAL}
                onHide={() => setShowGalleryAL(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="background_black">
                    <ImageGallery
                        items={galleryAL}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                    />
                </Modal.Body>
            </Modal>



        </div>


    );
};
export default Seccion1;
