
import Menu from './Compartido/Menu.jsx';
import DatosPropiedad  from './FichaPropiedad/DatosPropiedad.jsx';
import Footer from './Compartido/Footer.jsx';

const FichaPropiedad = (props) => {
     return (
      <main>
        <Menu
         idiomas = {props.idiomas}
         lang = {props.lang}
         config = {props.websgrupo}
         config2 = {props.config}
         ruta = {props.ruta}
         meta_titulo = {"Magno Apartments - "+props.propiedad.nombre}
         meta_descripcion = {props.meta_descripcion}
         meta_keywords = {props.meta_keywords}
        />
        <DatosPropiedad
          propiedad = {props.propiedad}
          servicios = {props.servicios}
          politicas = {props.politicas}
          diasbloqueados = {props.diasbloqueados}
          primerdialibre = {props.primerdialibre}
          calendario = {props.calendario}
          lang = {props.lang}
          config = {props.config}
          extras = {props.extras}
          llegada = {props.llegada}
          salida = {props.salida}
          adultos = {props.adultos}
          ninos = {props.ninos}
          bebes = {props.bebes}
          configweb = {props.websgrupo}
          ruta = {props.ruta}
          relacionados = {props.relacionados}
          moneda = {props.moneda}
        />

        <Footer
          lang = {props.lang}
          config = {props.websgrupo}
          config2 = {props.config}
          idiomas = {props.idiomas}
          ruta = {props.ruta}
        />
      </main>
     );
 }

 export default FichaPropiedad;
