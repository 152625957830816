import {React} from "react";
import { useTranslation } from 'react-i18next';

const Destinos = ({destinos, ruta, config, lang}) => {

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    const { t } = useTranslation();
    let resultsdreta = [];
    let resultsesquerra = [];
    let resultsmig = [];

    {   
        (destinos.length==2)?
            destinos.forEach((undestino, index) => {
                (index%2==0) ?
                    resultsesquerra = resultsesquerra.concat(
                        <div className="destacats-desti" key={index}>
                            <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                {
                                    undestino.imagen_principal.length>0 ? 
                                        <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                    :
                                        <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                    
                                }
                                <span className="sobreimagen">{ undestino.elnombre }</span>
                            </a>
                        </div>
                    )
                : 
                    resultsdreta = resultsdreta.concat(
                        <div className="destacats-desti" key={index}>
                            <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                {
                                    undestino.imagen_principal.length>0 ? 
                                        <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                    :
                                        <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                    
                                }
                                <span className="sobreimagen">{ undestino.elnombre }</span>
                            </a>
                        </div>
                    )
            })
        :
            (destinos.length%3==0)?
                destinos.forEach((undestino, index) => {
                    (index%3==0) ?
                        resultsesquerra = resultsesquerra.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : (index%3==1) ?
                        resultsesquerra = resultsesquerra.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )

                    : 
                        resultsdreta = resultsdreta.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti3" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti3" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                })
            :
            (destinos.length%3==1) ?
                destinos.forEach((undestino, index) => {
                    (index%3==0 && index!=destinos.length-1) ?
                        resultsesquerra = resultsesquerra.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : (index%3==0 && index==destinos.length-1) ?
                        resultsmig = resultsmig.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : (index%3==1) ?
                        resultsesquerra = resultsesquerra.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : 
                        resultsdreta = resultsdreta.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti3" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti3" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                })
            :
                destinos.forEach((undestino, index) => {
                    (index%3==0 && destinos.length-2!=index) ?
                        resultsesquerra = resultsesquerra.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : (index%3==0 && destinos.length-2==index) ?
                        resultsmig = resultsmig.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : (index%3==1 && destinos.length-1!=index) ?
                        resultsesquerra = resultsesquerra.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : (index%3==1 && destinos.length-1==index) ?
                        resultsmig = resultsmig.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                    : 
                        resultsdreta = resultsdreta.concat(
                            <div className="destacats-desti" key={index}>
                                <a href={"/"+preidioma+t("apartamentos")+"-"+undestino.laurl } className="caixa-destins">
                                    {
                                        undestino.imagen_principal.length>0 ? 
                                            <img className="destacats-imatge-desti3" src={ruta+'/destinos/'+undestino.id+'/'+undestino.imagen_principal[0].imagen}  alt="" />
                                        :
                                            <img className="destacats-imatge-desti3" src="/images/web/bedloop-nofoto.png"  alt="" />
                                        
                                    }
                                    <span className="sobreimagen">{ undestino.elnombre }</span>
                                </a>
                            </div>
                        )
                })
    }

    {/* ************* */}

     return (
        <>
            <h2 className="text-center">{ t("Nuestros destinos") }</h2>
            { 
                (destinos.length==2)?
                    <div className="destacats">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                { resultsesquerra }
                            </div> 
                            <div className="col-12 col-lg-6">
                                { resultsdreta }
                            </div>
                        </div>
                    </div>
                :
                    <div className="destacats">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                { resultsesquerra }
                            </div> 
                            <div className="col-12 col-lg-6">
                                { resultsdreta }
                            </div>
                        </div>
                        <div className="row ml_50">
                            <div className="col-12 col-lg-6">
                                { resultsmig }
                            </div>
                        </div>
                    </div>
            }
        </>
     )
}
export default Destinos;