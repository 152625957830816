import { React, useState, useEffect, useRef} from "react";
import axios from 'axios';
import {Modal} from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const DatosPeticion = ({ propiedad, llegada, salida, difdias, adultos, ninos, bebes,ruta, numpropiedades, websgrupo }) => {

    let { t } = useTranslation();

    let vectornumpropiedades = []

    for(let i=0;i<numpropiedades;i++)
    {
        if(i==0)
            vectornumpropiedades= vectornumpropiedades.concat(<option value={i+1} key={i}>{(i+1) +" "+t("propiedad")} </option>)
        else 
            vectornumpropiedades= vectornumpropiedades.concat(<option value={i+1} key={i}>{(i+1) +" "+t("propiedades")} </option>)
    }

     {/* *************** */ }

    const cantApartRef = useRef(1);

    const nombrecRef = useRef(null);
    const apellidoscRef = useRef(null);
    const emailcRef = useRef(null);
    const telefonocRef = useRef(null);
    const documentocRef = useRef(null);
    const aceptacondicionesRef = useRef(null);

    const formulariopeticionRef = useRef(null);

    const datospeticionactualesRef = useRef(null);

    const numpropiedades0Ref = useRef(null);

    {/* *************** */ }

    const roundN = (value, decimals) => {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
    }

    const handleChangeNumPropiedades = () => {
        cantApartRef.current.value = numpropiedades0Ref.current.value;
        handleChangeTarifa();
    };

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    const handleSubmit = () => {
        if(nombrecRef.current.value && nombrecRef.current.value.replace(/\s+/g, '').length>0 &&
        apellidoscRef.current.value && apellidoscRef.current.value.replace(/\s+/g, '').length>0 &&
        emailcRef.current.value && emailcRef.current.value.replace(/\s+/g, '').length>0 &&
        telefonocRef.current.value && telefonocRef.current.value.replace(/\s+/g, '').length>0 &&
        documentocRef.current.value && documentocRef.current.value.replace(/\s+/g, '').length>0 &&
        aceptacondicionesRef.current.checked)
            formulariopeticionRef.current.submit();
        else 
            handleShowModal()
    }

    const handleBack = () => {
        window.history.back();
    }

    const [showMore, setshowMore] = useState(-1);

    {/* *************** */ }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-7 col-lg-8">

                    { /*-- ********************** FINAL TARIFES  ********************************* */}

                    <form method="get" action="/resumenpeticion" ref={formulariopeticionRef}>
                        <input type="hidden" name="idpropiedad" id="idPropiedad" defaultValue={propiedad.id} />
                        <input type="hidden" name="llegada" defaultValue={llegada} id="llegada" />
                        <input type="hidden" name="salida" defaultValue={salida} id="salida" />
                        <input type="hidden" name="adultos" defaultValue={adultos} />
                        <input type="hidden" name="ninos" defaultValue={ninos}/>
                        <input type="hidden" name="bebes" defaultValue={bebes} />
                        <input type="hidden" name="idusuario" id="user" defaultValue="" />
                        <input type="hidden" name="difdias" defaultValue={difdias}/>

                        <input type="hidden" name="cantidadAparts" id="cantidadAparts" defaultValue="1"  ref={cantApartRef}/>

                        {/* ***************** DADES CLIENT ***************** */}
                        <div className="metro-box-wrapper" id="userInfo">
                            <h3>{t("Información")}</h3>
                            <div className="linia"></div>
                            <div className="content">
                                <div className="form-horizontal">
                                    <div className="form-group row">
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Nombre")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Nombre")} name="firstname" required defaultValue="" id="nombrec" ref={nombrecRef}/>
                                        </div>
                                        <label className="col-md-6 col-lg-2  mt-1">{t("Apellidos")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Apellidos")} name="lastname" required defaultValue="" id="apellidosc" ref={apellidoscRef}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-6  col-lg-2 mt-1">{t("Email")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input id="emailc" type="email" className="form-control mb-0" placeholder={t("Email")} name="email" required defaultValue="" ref={emailcRef}/>
                                        </div>
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Teléfono")} *</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Teléfono")} name="phone" required defaultValue="" id="telefonoc" ref={telefonocRef}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Pasaporte/ID")}*</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input id="documentoc" type="text" className="form-control mb-0" placeholder={t("Pasaporte/ID")} name="documentousuario" required defaultValue="" ref={documentocRef} />
                                        </div>
                                        <label className="col-md-6 col-lg-2 mt-1">{t("Ciudad")}</label>
                                        <div className="col-md-6 col-lg-4 mb-2">
                                            <input type="text" className="form-control mb-0" placeholder={t("Ciudad")} name="city" defaultValue="" />
                                        </div>
                                    </div>                                    
                                    <div className="form-group row">
                                        <div className="col-md-12 mt-5 text-end">{t("Todos los campos * son obligatorios")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ***************** FINAL DADES CLIENT ***************** */}

                    </form>

                    <Modal
                        show={showModal} onHide={() => setShowModal(false)} 
                        aria-labelledby="contained-modal-title-vcenter" 
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Error")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row mb-20 ml-20 clearfix">
                                {t("Campos * son obligatorios")}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>


                <div className="col-md-5 col-lg-4">
                    <div className="caixa-preus mt-5 sticky-top">
                        <div className="row col-md-12">
                            <div className="col-md-12 col-lg-5">
                                {
                                    propiedad.foto_principal.length != 0
                                        ? <img src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre} alt="--" title="" className="imatge-reserva-pas2" />
                                        : <img src="/images/web/bedloop-nofoto.png" alt="--" title="" className="imatge-reserva-pas2" />
                                }
                            </div>
                            <div className="col-md-12 col-lg-7">
                                <h5>{propiedad.nombre}</h5>
                                <p className="small"><i className="fa-duotone fa-location-dot"></i> {propiedad.ciudad + ", " + propiedad.region}</p>
                            </div>
                        </div>
                        <div className="mt-3 mb-5 text-start">
                            <button className="btn btn-primary" onClick={handleBack} style={{ background: ""+websgrupo.color_corporativo_web+"", border: "1px solid"+websgrupo.color_corporativo_web }}>
                                {t("Cambiar fechas")}
                            </button>
                        </div>
                        { websgrupo.activa_multiunit==1 && numpropiedades>1
                            ?   
                                <>
                                    <h4>{t("Número de propiedades")}</h4>
                                    <div className="linia"></div>
                                    <div className="mt-3 mb-3">
                                        <select className="form-control mb-0 extra" id="numpropiedades0" onChange={() => handleChangeNumPropiedades()} defaultValue="1" ref={numpropiedades0Ref}>
                                            {vectornumpropiedades}
                                        </select>
                                    </div>

                                </>
                            :
                                null
                        }


                        <h4>{t("Resumen")}</h4>
                        <div className="linia"></div>

                        <div className="row mt-3 mb-3">
                            <div className="col-6">{t("Entrada")} {llegada}</div>
                            <div className="col-6 text-end">{t("Salida")} {salida}</div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-12">{adultos + " "+ t("Adultos")} {ninos > 0 ? " , " + ninos +" "+ t("Niños") : ""} {bebes > 0 ? " , " + bebes + " "+ t("Bebés") : ""}</div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-8">{difdias + " "+t("Noches")}</div>
                        </div>

                        <div className="linia mt-2 mb-2"></div>

                        <div className="row mt-3 mb-5 text-center">
                            <button className="btn btn-primary" onClick={handleSubmit} style={{ background: ""+websgrupo.color_corporativo_web+"", border: "1px solid "+websgrupo.color_corporativo_web }}>
                                {t("Petición")}
                            </button>
                        </div>

                        <p className="mt-5 mb-0">
                            <input type="checkbox" name="aceptacondiciones" required defaultChecked id="aceptacondiciones" ref={aceptacondicionesRef}/>
                            <span className="small ms-2 text-start">
                                {t("Al reservar, acepta los")}<a href="./terminos" target="_blank"> {t("términos y condiciones")}</a>, {t("así como la")} <a href="/politica-de-cancelacion" target="_blank">{t("política de cancelación")}</a>. *
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DatosPeticion;
