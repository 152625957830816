import { React } from "react";

import { useTranslation } from 'react-i18next';

const CabeceraLegal = ({ruta,legal}) => {
    const { t } = useTranslation();

	var imagen = "/images/web/bedloop-nofoto.png"; 

	if (legal.imagen_cabecera)
	{
		imagen = ruta+"/"+legal.imagen_cabecera;
	}

    return (
        <>
			<div className="imatge-banner" title={ t("Legal") } style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}} >
            </div>
        </>
    );
}

export default CabeceraLegal;




				