import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const EsenciaCasa = ({ lang }) => {

    const { t } = useTranslation();

    // var currentTime = new Date();
    // var year = currentTime.getFullYear();

    // var preidioma = ""
    // if(lang!='es') preidioma = lang+"/";

    return (
        <div className="container-fluid esenciaCasa d-flex w-100">
            <div className="esenciaCasa-left">
                <div className="esenciaCasa-left-top">
                    <div>
                        <h1 className="noto">{t("La verdadera esencia de la Casa")}</h1>
                    </div>
                    <div className="d-flex align-items-center">
                        <p style={{ flex: "6" }} >{t("LA EXCLUSIVIDAD Y EL DISEÑO")}</p>
                        <hr className="bg-dark" style={{ flex: "1" }} />
                    </div>
                    <div>
                        <p className="fr17">{t("La Casa del Rey Sabio es un viaje a través de los sentidos. Es una escapada a Sevilla como nunca habías imaginado.")}</p>
                        <p className="fr17">{t("Sus paredes, con más de ocho siglos de antigüedad, albergan historia y cultura, pero también elegancia, actualidad y sobriedad. Los elementos naturales se integran en total armonía con una decoración exclusiva y minimalista que apuesta por la sencillez. Piedra y barro en los suelos, madera en techos y ventanas combinadas con paredes de mortero a la cal. Todos los elementos encajan a la perfección para dar lugar a una experiencia inolvidable en tu visita a Sevilla")}</p>
                        <p className="fr17">{t("Suites con una decoración única y exclusiva que invitan al descanso y la calma. Zonas comunes en las que el agua y la naturaleza son protagonistas absolutos. Un oasis en pleno corazón de Sevilla.")}</p>
                    </div>
                </div>
                <div className="esenciaCasa-left-bottom">
                    <div style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-149-1024x843.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </div>
                </div>
            </div>
            <div className="esenciaCasa-right">
                <div style={{ backgroundImage: "url('/images/web/reysabio/Casa-del-Rey-Sabio-183.jpeg')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                </div>
                <div>
                    <h2 className="noto">«{t("Sevilla no se puede explicar, hay que vivirla. Tiene un alma única")}»<br /> - Ferran Adriá</h2>
                </div>
            </div>
        </div>
    );
};
export default EsenciaCasa;

