import Menu from './Compartido/Menu.jsx';
import ListaNoticias from './ListaBlog/ListaNoticias.jsx';
import Footer from './Compartido/Footer.jsx';

import { useTranslation } from 'react-i18next';

const ListaBlog = (props) => {
    
    let { t } = useTranslation();
    
    return (
        <main>
            <Menu
                idiomas = {props.idiomas}
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                ruta = {props.ruta}
                meta_titulo = {"Magno Apartments - "+t("Blog")}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />
            <ListaNoticias 
                noticias = {props.noticias}
                ruta = {props.ruta}
                lang = {props.lang}
            />
            <Footer
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                idiomas = {props.idiomas}
                ruta = {props.ruta}
            />
        </main>
    );
}

export default ListaBlog;