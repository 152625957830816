
import Menu from './Compartido/Menu.jsx';
import BannerPropiedad from './FichaPropiedad/BannerPropiedad.jsx';
import DatosPropiedad from './FichaPropiedad/DatosPropiedad.jsx';
import Footer from './Compartido/Footer.jsx';

const FichaPropiedad = (props) => {
     return (
      <main>
        <Menu 
          destinos = {props.destinos}
          sobrenosotros = {props.textosobrenosotros} 
          clientes = {props.textoclientes}
          propietarios = {props.textopropietarios}
          servicios = {props.textoservicios}
          experiencias = {props.experiencias}
          idiomas = {props.idiomas}
          lang = {props.lang}
          tiposcasas = {props.tiposcasas}
          config = {props.config}
          textopropiedades = {props.textopropiedades}
          edificios = {props.edificios}
          marcas = {props.marcas}
          ruta = {props.ruta}
        />
        <BannerPropiedad 
          propiedad = {props.propiedad}
          ruta = {props.ruta}
        />
        <DatosPropiedad 
          propiedad = {props.propiedad}
          servicios = {props.servicios}
          politicas = {props.politicas}
          calendario = {props.calendario}
          diasbloqueados = {props.diasbloqueados}
          lang = {props.lang}
          config = {props.config}
          extras = {props.extras}
          llegada = {props.llegada}
          salida = {props.salida}
          adultos = {props.adultos}
          ninos = {props.ninos}
          bebes = {props.bebes}
          configweb = {props.websgrupo}
        />
        <Footer 
          datos = {props.websgrupo}
          terminos = {props.textoterminos}
          protocolos = {props.textoprotocolos}
          avisolegal = {props.textoavisolegal}
          config = {props.config}
          elementosweb = {props.elementosweb}
          politicaprivacidad = {props.textopolitica}
          lang = {props.lang}
          ruta = {props.ruta}
        />
      </main>
     );
 }

 export default FichaPropiedad;
