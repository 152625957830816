const Contact = () =>{
    return(
        <div className="section mcb-section T60B40">
            <div className="column_attr clearfix align_center">
                <h6 className="landing4-heading grey positionContainer">CONTACT</h6>
                <hr className="no_line margin15Auto" />
            </div>
            
            <div className="section_wrapper mcb-section-inner">
                <div className="column mcb-column one-second column_column">
                    <p><strong>Associació d'Apartaments Turístics de Barcelona</strong></p>
                    <p>C/ Aribau, 229, entlo. 4ª.<br/>08008 Barcelona<br/><br/><strong>Telephone number</strong> :&nbsp;+34 93.315.27.96<br/><strong>E-mail</strong> : 
                        <img src="https://www.apartur.com/images/web/contacto/emailapartur.png" alt="https://www.apartur.com/images/web/contacto/emailapartur.png" width="127" height="15"/><br/><strong>Opening Hours</strong><strong>:</strong>&nbsp;From Monday to Friday, from 9h to 13h <br/><br/>
                        <img src="https://www.apartur.com/app/vistas/apartur/images/ferrocarrils-catalans.png" alt="" width="18" height="18"/>&nbsp; Ferrocarrils 
                        <a href="https://www.google.com/maps/place/Proven%C3%A7a/@41.392814,2.158031,21z/data=!4m6!3m5!1s0x12a4a291baef8eef:0x57ed6ec0724e2cbc!8m2!3d41.3928406!4d2.1580767!16s%2Fm%2F02qtbt6?hl=ca" target="_blank" rel="noopener">Provença</a> <br/>
                        <img src="https://www.apartur.com/app/vistas/apartur/images/metro-barcelona.svg" alt="" width="23" height="18"/> Metro 
                        <a title="L5" href="https://www.tmb.cat/ca/barcelona/metro/-/lineametro/L5" target="_blank" rel="noopener">L5</a> - 
                        <a title="L3" href="https://www.tmb.cat/ca/barcelona/metro/-/lineametro/L3" target="_blank" rel="noopener">L3</a> Diagonal<br/>
                        <img src="https://www.apartur.com/app/vistas/apartur/images/bus-barcelona.png" alt="" width="18" height="18"/>&nbsp; Balmes (Còrcega - Rosselló) Line 
                        <a href="https://www.tmb.cat/ca/barcelona/autobusos/-/lineabus/7" target="_blank" rel="noopener">7</a>, 
                        <a href="https://www.tmb.cat/ca/barcelona/autobusos/-/lineabus/67" target="_blank" rel="noopener">67</a> and 
                        <a href="https://www.tmb.cat/ca/barcelona/autobusos/-/lineabus/V15" target="_blank" rel="noopener">V15</a>.
                    </p>
                </div>
                
                <div className="column mcb-column one-second column_column">
                    <div id="map-and-friends">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.147191343418!2d2.152329036513644!3d41.392609712938636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a29045f882f1%3A0xc4517d0eb2b694c!2sC%2F%20del%20Rossell%C3%B3%2C%20186%2C%2008008%20Barcelona!5e0!3m2!1sca!2ses!4v1676459596881!5m2!1sca!2ses" width="600" height="450" className="border0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;