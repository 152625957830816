import { React, useState, useEffect, useRef, forwardRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Carousel } from 'react-bootstrap';
import es from 'date-fns/locale/es';
import axios from 'axios';
import { parseISO } from 'date-fns';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { Modal, Button, Dropdown } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import { t } from "i18next";

import logo from "/images/magno/LOGO-MAGNO.png";
import L from 'leaflet';

import "./../../../../../css/magno/fichaprop.css";
import { transform } from "lodash";

import ImagePopupGallery from "../Compartido/ImagePopupGallery.jsx";


const DatosPropiedad = ({ propiedad, servicios, politicas, diasbloqueados, primerdialibre, calendario, lang, config, extras, llegada, salida, adultos, ninos, bebes, configweb, ruta, relacionados, moneda }) => {

    const { t } = useTranslation();

    registerLocale('es', es)

    const myIcon = L.icon({
        iconUrl: logo,
        iconSize: [40, 49],
        iconAnchor: [20, 49],
        popupAnchor: [0, -49],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    let foto = ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.fotos_web[0].nombre;
    let personas = propiedad.capacidad_maxima + " " + t('Personas');
    let metros = propiedad.metros_cuadrados + " " + t('M2');
    let banyos = propiedad.num_banyos + " " + t('Baños');
    let habs = propiedad.num_habitaciones + " " + t('Dormitorios');

    registerLocale('es', es)

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const fotos = propiedad.fotos_web;

    let urlprop = "https://www.magnoapartments.com/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica;

    let gallery0 = [];
    let gallery2 = []
    let gallerymobile = []

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    let primdialibre = parseISO(primerdialibre);

    let dias = [];
    {
        diasbloqueados.forEach((undia, index) => {
            dias.push(new Date(undia))
        })
    }

    for (let i = 0; i < fotos.length; i++) {

        const image = {
            original: ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + fotos[i].nombre,
            originalClass: "foto" + propiedad.id
        }
        gallery0 = gallery0.concat(image);
    }

    fotos.map((unafoto, index) => (

        gallery2 = gallery2.concat(
            <Carousel.Item key={index}>
                <ImagePopupGallery
                    imageSrc={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre}
                    urlprop={urlprop}
                    gallery={gallery0}
                />
            </Carousel.Item>
        )
    ));

    fotos.map((unafoto, index) => (
        gallerymobile = gallerymobile.concat(
            <Carousel.Item key={index}>
                <img
                    src={ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + unafoto.nombre}
                    className="d-block w-100"
                    style={{ maxHeight: "460px" }}
                />
            </Carousel.Item>
        )
    ));

    {/* ********************** */ }

    let botones = document.querySelectorAll(".boton");

    const cuandoSeHaceClick = function (evento) {

        var nuevo_mes = this.getAttribute("data-nuevo-mes");
        var nuevo_ano = this.getAttribute("data-nuevo-ano");

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/calendario0', {
            idPropiedad: propiedad.id,
            nuevo_mes: nuevo_mes,
            nuevo_ano: nuevo_ano,
            bloqueos: JSON.stringify(diasbloqueados),
            lang: lang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                document.querySelectorAll(".calendario")[0].innerHTML = response.data;
                botones = document.querySelectorAll(".boton");
                botones.forEach(boton => {
                    boton.addEventListener("click", cuandoSeHaceClick);
                })
            })

    }

    botones.forEach(boton => {
        boton.addEventListener("click", cuandoSeHaceClick);
    })


    {/* ********************** */ }

    let inicioreserva = null;
    let finalreserva = null;

    let difdias = " - ";

    if (llegada && salida) {
        var fechaInicio = new Date(llegada).getTime();
        var fechaFin = new Date(salida).getTime();

        var diff = fechaFin - fechaInicio;

        difdias = diff / (1000 * 60 * 60 * 24);

        inicioreserva = new Date(llegada);
        finalreserva = new Date(salida);

    }

    const [startDate, setStartDate] = useState(inicioreserva);

    const ExampleCustomInputEntrada = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref}>
            <div className="nd_booking_float_left nd_booking_text_align_right">
                <h1 id="nd_booking_date_number_to_front" className="nd_booking_font_size_50 nd_booking_color_yellow_important colorcorporativo">
                    {value != "" ? ("0" + new Date(value).getDate()).slice(-2) : "- -"}
                    <sup style={{ textTransform: "capitalize", fontFamily: "Lato", fontWeight: "350", paddingRight: "6px", fontSize: "12px" }}>
                        {value != "" ? new Date(value).toLocaleString('es-ES', { month: 'short' }) : ""}
                    </sup>
                    {
                        value != "" ?
                            <img src="/images/magno/down_icon.svg" width="10" style={{ marginLeft: "-20px" }} />
                            :
                            <img src="/images/magno/down_icon.svg" width="10" />
                    }
                </h1>
            </div>
            <input type="hidden" id="fechaentrada" defaultValue={value} />
        </div>
    ));

    const [endDate, setEndDate] = useState(finalreserva);

    const ExampleCustomInputSalida = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref}>
            <div className="nd_booking_float_left nd_booking_text_align_right">
                <h1 id="nd_booking_date_number_to_front" className="nd_booking_font_size_50 nd_booking_color_yellow_important colorcorporativo">
                    {value != "" ? ("0" + new Date(value).getDate()).slice(-2) : "- -"}
                    <sup style={{ textTransform: "capitalize", fontFamily: "Lato", fontWeight: "350", paddingRight: "6px", fontSize: "12px" }}>
                        {value != "" ? new Date(value).toLocaleString('es-ES', { month: 'short' }) : ""}
                    </sup>
                    {
                        value != "" ?
                            <img src="/images/magno/down_icon.svg" width="10" style={{ marginLeft: "-20px" }} />
                            :
                            <img src="/images/magno/down_icon.svg" width="10" />
                    }
                </h1>
            </div>
            <input type="hidden" id="fechasalida" defaultValue={value} />
        </div>
    ));

    const cambioFechas = (date) => {
        setStartDate(date);
        setEndDate(date);
    };

    const cambioFechas2 = (date) => {
        setEndDate(date);
        var fechaInicio = new Date(startDate).getTime();
        var fechaFin = new Date(date).getTime();

        var diff = fechaFin - fechaInicio;

        difdias = diff / (1000 * 60 * 60 * 24);

        var campos = document.getElementsByClassName('difdias');

        for (var i = 0; i < campos.length; i++) {
            campos[i].innerHTML = Math.floor(difdias);
        }
    };

    {/* *************** */ }

    const [dataRes, setDataRes] = useState("<p></p>");

    const handleSubmit = () => {

        let token = document.getElementById('meta_token').getAttribute('content');

        let fentrada = document.getElementById("fechaentrada").value;
        let fsalida = document.getElementById("fechasalida").value;

        const result = axios.post('/haydispomagno', {
            id: propiedad.id,
            entrada: fentrada,
            salida: fsalida,
            personas: Number(countAdults) + Number(countNinos),
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {

                    const precios = solucion.precios[0];

                    if (propiedad.by_request == 1) {
                        let form = document.createElement('form');

                        form.method = 'GET';
                        form.action = "/" + t("peticion");

                        let input1 = document.createElement('input');
                        input1.type = 'hidden';
                        input1.name = 'idpropiedad';
                        input1.value = propiedad.id;
                        form.appendChild(input1);

                        let input2 = document.createElement('input');
                        input2.type = 'hidden';
                        input2.name = 'entrada';
                        input2.value = fentrada;
                        form.appendChild(input2);

                        let input3 = document.createElement('input');
                        input3.type = 'hidden';
                        input3.name = 'salida';
                        input3.value = fsalida;
                        form.appendChild(input3);

                        let input4 = document.createElement('input');
                        input4.type = 'hidden';
                        input4.name = 'adultos';
                        input4.value = Number(countAdults);
                        form.appendChild(input4);

                        let input5 = document.createElement('input');
                        input5.type = 'hidden';
                        input5.name = 'ninos';
                        input5.value = Number(countNinos);
                        form.appendChild(input5);

                        let input6 = document.createElement('input');
                        input6.type = 'hidden';
                        input6.name = 'bebes';
                        input6.value = Number(countBebes);
                        form.appendChild(input6);

                        let input7 = document.createElement('input');
                        input7.type = 'hidden';
                        input7.name = 'solorequest';
                        input7.value = 1
                        form.appendChild(input7);

                        document.body.append(form);

                        form.submit();
                    }
                    else {
                        let form = document.createElement('form');

                        form.method = 'GET';
                        form.action = "/" + t("reserva");

                        let input1 = document.createElement('input');
                        input1.type = 'hidden';
                        input1.name = 'idpropiedad';
                        input1.value = propiedad.id;
                        form.appendChild(input1);

                        let input2 = document.createElement('input');
                        input2.type = 'hidden';
                        input2.name = 'entrada';
                        input2.value = fentrada;
                        form.appendChild(input2);

                        let input3 = document.createElement('input');
                        input3.type = 'hidden';
                        input3.name = 'salida';
                        input3.value = fsalida;
                        form.appendChild(input3);

                        let input4 = document.createElement('input');
                        input4.type = 'hidden';
                        input4.name = 'adultos';
                        input4.value = Number(countAdults);
                        form.appendChild(input4);

                        let input5 = document.createElement('input');
                        input5.type = 'hidden';
                        input5.name = 'ninos';
                        input5.value = Number(countNinos);
                        form.appendChild(input5);

                        let input6 = document.createElement('input');
                        input6.type = 'hidden';
                        input6.name = 'bebes';
                        input6.value = Number(countBebes);
                        form.appendChild(input6);

                        let input7 = document.createElement('input');
                        input7.type = 'hidden';
                        input7.name = 'solorequest';
                        input7.value = 0
                        form.appendChild(input7);

                        let input8 = document.createElement('input');
                        input8.type = 'hidden';
                        input8.name = 'tarifa';
                        input8.value = precios.id
                        form.appendChild(input8);

                        document.body.append(form);

                        form.submit();
                    }
                }
                else {
                    setDataRes("<p>" + solucion.Error + "</p>");
                }
            });
    }

    useEffect(() => {
        if (adultos > 0 && entr != null && sali != null) {
            let token = document.getElementById('meta_token').getAttribute('content');

            const fecha = new Date(llegada);
            const fecha2 = new Date(salida);
            const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const llegadaFormateada = fecha.toLocaleDateString('es-ES', opciones);
            const salidaformateada = fecha2.toLocaleDateString('es-ES', opciones);

            const result = axios.post('/api/v1/rates', {
                id: propiedad.id,
                range: llegadaFormateada + " - " + salidaformateada,
                personas: Number(countAdults) + Number(countNinos),
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    const solucion = response.data;
                    if (solucion.Error == "OK") {
                        const precios = solucion.precios[0];

                        let estancia = 0;
                        let eltotal = 0;
                        let noches = 0;
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        estancia = estancia * 0.01;

                        eltotal = eltotal + estancia;

                        const extras = solucion.extras.relacion_extras;

                        var textoextras = "";

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                eltotal = eltotal + (extras[j].precio * 0.01);
                            }

                        }

                        if (propiedad.by_request == 1) {
                            var urlreserva = "<a href='/" + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("PETICIÓN") + "</a>";
                        }
                        else {
                            var urlreserva = "<a href='/" + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("RESERVAR") + "</a>";
                        }

                        setDataRes(
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4'>" +
                            eltotal.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +

                            "<div class='linia mt-3 mb-3'>" +
                            "/div>" +

                            "<div class='mt-2 mb-3'>" +
                            urlreserva +
                            "</div>"
                        );
                    }
                    else {
                        setDataRes("<p>" + solucion.Error + "</p>");
                    }
                });
        }
    }
        , [])


    {/* ********************** */ }

    let txtpersonas = "";
    let txtbanyos = "";
    let txthabs = "";

    if (propiedad.capacidad_maxima) txtpersonas = t('Personas');
    else txtpersonas = t("Persona");

    if (propiedad.num_banyos > 1) txtbanyos = t('Baños');
    else txtbanyos = t("Baño");

    if (propiedad.num_habitaciones > 1) txthabs = t('Dormitorios');
    else txthabs = t('Dormitorio');

    let caracs1 = [];
    let caracs2 = [];
    let caracs3 = [];
    let caracs4 = [];
    let contador = 0;

    servicios.forEach((unservicio, index) => {
        if (unservicio.imagen.includes("glyph iconbe-Horno")) {
            if (contador % 4 == 0) {
                caracs1 = caracs1.concat(
                    <div className="wpb_raw_code wpb_content_element wpb_raw_html" key={index}>
                        <div className="wpb_wrapper" style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>
                            <i className="fa fa-check fa-1-5x colorcorporativo"></i> {unservicio.elnombre}
                        </div>
                    </div>
                )
                contador++;
            }
            else if (contador % 4 == 1) {
                caracs2 = caracs2.concat(
                    <div className="wpb_raw_code wpb_content_element wpb_raw_html" key={index}>
                        <div className="wpb_wrapper" style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>
                            <i className="fa fa-check fa-1-5x colorcorporativo"></i> {unservicio.elnombre}
                        </div>
                    </div>
                )
                contador++;
            }
            else if (contador % 4 == 2) {
                caracs3 = caracs3.concat(
                    <div className="wpb_raw_code wpb_content_element wpb_raw_html" key={index}>
                        <div className="wpb_wrapper" style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>
                            <i className="fa fa-check fa-1-5x colorcorporativo"></i> {unservicio.elnombre}
                        </div>
                    </div>
                )
                contador++;
            }
            else {
                caracs4 = caracs4.concat(
                    <div className="wpb_raw_code wpb_content_element wpb_raw_html" key={index}>
                        <div className="wpb_wrapper" style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>
                            <i className="fa fa-check fa-1-5x colorcorporativo"></i> {unservicio.elnombre}
                        </div>
                    </div>
                )
                contador++;
            }
        }
    })

    let results2 = [];


    {/* ************* */ }

    const [show, setShow] = useState('none');
    const [showm, setShowM] = useState('none');

    const handleShow = () => {
        if (show == 'none') setShow('block');
        else setShow('none');
    }
    const handleShowM = () => {
        if (showm == 'none') setShowM('block');
        else setShowM('none');
    }

    const onClickOutsideListener = () => {
        handleShow()
        document.removeEventListener("click", onClickOutsideListener)
    }

    const onClickOutsideListenerM = () => {
        handleShowM()
        document.removeEventListener("click", onClickOutsideListenerM)
    }

    let textopersonas = "";

    if (adultos > 0 || ninos > 0 || bebes > 0) {
        textopersonas = Number(adultos) + Number(ninos) + Number(bebes);
    }
    else {
        textopersonas = 1;
        adultos = 1;
        ninos = 0;
        bebes = 0;
    }

    const numpersonas = useRef(null);
    const numpersonasm = useRef(null);

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const handleSumaAdultos = () => {

        if (countAdults + countNinos < propiedad.capacidad_maxima) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(parseInt(countAdults) + 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleRestaAdultos = () => {

        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(parseInt(countAdults) - 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleSumaNinos = () => {
        if (countNinos + countAdults < propiedad.capacidad_maxima) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) + 1, parseInt(countBebes));
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) - 1, parseInt(countBebes));
        }
    }

    const handleSumaBebes = () => {
        if (countBebes < propiedad.capacidad_maxima) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) - 1);
        }
    }

    const handleEtiqueta = (nadults, nninos, nbebes) => {

        let frase = Number(nadults) + Number(nninos) + Number(nbebes)

        numpersonas.current.textContent = frase;
        numpersonasm.current.textContent = frase;
    }

    {/* ************* */ }

    let caracs = [];
    let resultsrel = [];

    {/* ************* */ }

    return (
        <>
            <div className="container mt-60">
                <div className="row capsapropietat">
                    <div className="order-last col-md-7 col-lg-8 order-sm-first box-col-left datospropiedad">
                        {/* GALLERY Y NOMBRE PROP */}
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1675964080771 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                                <div className="vc_column-inner vc_custom_1674933997336">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_gallery wpb_content_element vc_clearfix vc_custom_1678172623603">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_gallery_slides wpb_flexslider flexslider_slide flexslider solodesktop" data-interval="10" data-flex_fx="slide">
                                                    <Carousel slide={true} controls={true} indicators={false}>
                                                        {gallery2}
                                                    </Carousel>
                                                </div>
                                                <div className="wpb_gallery_slides wpb_flexslider flexslider_slide flexslider solomobile" data-interval="10" data-flex_fx="slide" style={{ marginLeft: "10px" }}>
                                                    <Carousel slide={true} controls={true} indicators={false}>
                                                        {gallerymobile}
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 wpb_text_column wpb_content_element vc_custom_1675946185144">
                                            <div className="wpb_wrapper">
                                                <h1 style={{ fontWeight: "300", fontSize: '30px' }}>{propiedad.nombre} </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row wpb_row vc_inner vc_row-fluid vc_custom_1676023331799 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
                            <div className="wpb_column vc_column_container col-md-2 col-2 vc_col-has-fill ubi-propiedad">
                                <div className="vc_column-inner vc_custom_1676569416425">
                                    <div className="wpb_wrapper">
                                        <div className="vc_icon_element vc_icon_element-outer vc_custom_1676655529069 padding_top vc_icon_element-align-left">
                                            <div className="vc_icon_element-inner vc_icon_element-color-custom vc_icon_element-size-xs vc_icon_element-style- vc_icon_element-background-color-grey">
                                                <span className="vc_icon_element-icon fas fa-map-marker-alt" style={{ color: "#c19b77" }}></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container col-md-10 col-10">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element vc_custom_1676023480352">
                                            <div className="wpb_wrapper">
                                                <h5> {propiedad.calle + " (" + propiedad.ciudad + ")"}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row wpb_row vc_inner vc_row-fluid vc_custom_1676023297856 vc_row-has-fill vc_row-o-content-middle vc_row-flex">
                            <div className="wpb_column vc_column_container col-md-2 col-3 vc_col-has-fill pl-25 compartirtitulo">
                                <div className="vc_column-inner vc_custom_1674933997336">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element vc_custom_1675963895971">
                                            <div className="wpb_wrapper">
                                                <p style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>{t('Compartir')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wpb_column vc_column_container col-md-10 col-9">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="essb_links essb_counters essb_displayed_shortcode essb_share essb_template_grey-circles-retina essb_26497640 essb_size_xs print-no" id="essb_displayed_shortcode_26497640" data-essb-postid="12576" data-essb-position="shortcode" data-essb-button-style="icon" data-essb-template="grey-circles-retina" data-essb-counter-pos="hidden" data-essb-url="" data-essb-fullurl="" data-essb-instance="26497640">
                                            <ul className="essb_links_list essb_force_hide_name essb_force_hide" style={{ padding: "0", margin: "0", listStyle: "none" }}>

                                                <li className="essb_item essb_link_facebook nolightbox" style={{ display: "inline-block" }}>
                                                    <a href={"https://www.facebook.com/sharer/sharer.php?u=" + urlprop + "%2F&t=Alojamiento"} title={t("Compartir en Facebook")} target="_blank" rel="noreferrer noopener nofollow" className="nolightbox redesprop">
                                                        <i className="fa-brands fa-facebook-f iconored"></i>
                                                        <span className="essb_icon essb_icon_facebook" style={{ width: "24px", height: "24px" }}></span>
                                                        <span className="essb_network_name essb_noname"></span>
                                                    </a>
                                                    <span className="essb_counter_hidden" data-cnt="" data-cnt-short=""></span>
                                                </li>

                                                <li className="essb_item essb_link_twitter nolightbox" style={{ display: "inline" }}>
                                                    <a href={"https://twitter.com/intent/tweet?text=" + urlprop} title={t("Compartir en X")} target="_blank" rel="noreferrer noopener nofollow" className="nolightbox redesprop">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: "13px", marginLeft: "5px", marginBottom: "1px" }}>{/* Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.*/}<path className="x-color" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                                                        {/* <i className="fa-brands fa-twitter iconored"></i>*/}
                                                        <span className="essb_icon essb_icon_twitter " style={{ width: "24px", height: "24px" }}></span>
                                                        <span className="essb_network_name essb_noname"></span>
                                                    </a>
                                                    <span className="essb_counter_hidden" data-cnt="4" data-cnt-short=""></span>
                                                </li>

                                                <li className="essb_item essb_link_pinterest nolightbox" style={{ display: "inline" }}>
                                                    <a href={"https://www.pinterest.com/pin/create/button/?url=" + urlprop} title={t("Compartir en Pinterest")} target="_blank" rel="noreferrer noopener nofollow" className="nolightbox redesprop">
                                                        <i className="fa-brands fa-pinterest-p iconored2"></i>
                                                        <span className="essb_icon essb_icon_pinterest-p" style={{ width: "24px", height: "24px" }}></span>
                                                        <span className="essb_network_name essb_noname"></span>
                                                    </a>
                                                    <span className="essb_counter_hidden" data-cnt="" data-cnt-short=""></span>
                                                </li>

                                                <li className="essb_item essb_link_whatsapp nolightbox" style={{ display: "inline" }}>
                                                    <a href={"whatsapp://send?text=Alojamiento%20" + urlprop + "%2F"} title={t("Compartir en  WhatsApp")} target="_blank" rel="noreferrer noopener nofollow" className="nolightbox redesprop">
                                                        <i className="fa-brands fa-whatsapp iconored2"></i>
                                                        <span className="essb_icon essb_icon_whatsapp" style={{ width: "24px", height: "24px" }}></span>
                                                        <span className="essb_network_name essb_noname"></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row wpb_row vc_inner vc_row-fluid vc_custom_1676119123872 vc_row-has-fill vc_row-o-content-middle vc_row-flex box-icons">
                            <div className="wpb_column vc_column_container col-md-3 col-3" style={{ alignContent: 'center' }}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="aio-icon-component style_1">
                                            <div id="Info-box-wrap-5578" className="aio-icon-box top-icon">
                                                <div className="aio-icon-top">
                                                    <div className="ult-just-icon-wrapper">
                                                        <div className="align-icon" style={{ textAlign: "center" }}>
                                                            <div className="aio-icon-img" style={{ fontSize: "27px", display: "inline-block" }}>
                                                                <img className="img-icon" style={{ width: "1em", maxWidth: "100%" }} alt="null" src="/images/web//magno/2023-01-per-3.png" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-5578 .aio-icon-description" data-responsive-json-new='{"font-size":"","line-height":""}' style={{ textAlign: "center" }}>{propiedad.capacidad_maxima + " " + txtpersonas}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wpb_column vc_column_container col-md-2 col-3" style={{ alignContent: 'center' }}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="aio-icon-component style_1">
                                            <div id="Info-box-wrap-6397" className="aio-icon-box top-icon">
                                                <div className="aio-icon-top">
                                                    <div className="ult-just-icon-wrapper ">
                                                        <div className="align-icon" style={{ textAlign: "center" }}>
                                                            <div className="aio-icon-img" style={{ fontSize: "27px", display: "inline-block" }}>
                                                                <img className="img-icon" alt="null" style={{ width: "1em", maxWidth: "100%" }} src="/images/web//magno//2023-01-mt-2.jpg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6397 .aio-icon-description" data-responsive-json-new='{"font-size":"","line-height":""}' style={{ textAlign: "center" }}>{propiedad.metros_cuadrados + " " + t('m2')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wpb_column vc_column_container col-md-3 col-3" style={{ alignContent: 'center' }}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="aio-icon-component style_1">
                                            <div id="Info-box-wrap-6663" className="aio-icon-box top-icon">
                                                <div className="aio-icon-top">
                                                    <div className="ult-just-icon-wrapper ">
                                                        <div className="align-icon" style={{ textAlign: "center" }}>
                                                            <div className="aio-icon-img" style={{ fontSize: "27px", display: "inline-block" }}>
                                                                <img className="img-icon" alt="null" style={{ width: "1em", maxWidth: "100%" }} src="/images/web/magno/2023-01-ban-2.jpg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6663 .aio-icon-description" data-responsive-json-new='{"font-size":"","line-height":""}' style={{ textAlign: "center" }}>{propiedad.num_banyos + " " + txtbanyos}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wpb_column vc_column_container col-md-3 col-3" style={{ alignContent: 'center' }}>
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="aio-icon-component style_1">
                                            <div id="Info-box-wrap-6716" className="aio-icon-box top-icon">
                                                <div className="aio-icon-top">
                                                    <div className="ult-just-icon-wrapper ">
                                                        <div className="align-icon" style={{ textAlign: "center" }}>
                                                            <div className="aio-icon-img" style={{ fontSize: "27px", display: "inline-block" }}>
                                                                <img className="img-icon" alt="null" style={{ width: "1em", maxWidth: "100%" }} src="/images/web//magno/2023-01-cama-2.jpg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6716 .aio-icon-description" data-responsive-json-new='{"font-size":"","line-height":""}' style={{ textAlign: "center" }}>{propiedad.num_habitaciones + " " + txthabs}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="wpb_column vc_column_container col-md-3">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper"></div>
                                </div>
                            </div>
                        </div>

                        {/* ********** */}

                        <div className="pt-3 pb-2 mt-3 mb-3 vc_column-inner vc_custom_1684831039819 solomobile" style={{ background: "black", marginLeft: "10px" }} >
                            <div className="row">
                                <div className="col-md-12 row" style={{ textAlign: "center", marginTop: "15px" }}>
                                    <div className="col-md-6 col-6 fechamovil">
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12 dpentrada" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Entrada").toUpperCase()}</h6>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => cambioFechas(date)}
                                            dateFormat="MM/dd/yyyy"
                                            minDate={primdialibre}
                                            excludeDates={dias}
                                            locale={lang}
                                            popperClassName="centradam"
                                            customInput={<ExampleCustomInputEntrada />}
                                            popperPlacement="bottom-end"
                                        />
                                        <input type="hidden" id="nd_booking_date_month_from" className="nd_booking_section nd_booking_margin_top_20" />
                                        <input type="hidden" id="nd_booking_date_number_from" className="nd_booking_section nd_booking_margin_top_20" />
                                    </div>

                                    <div className="col-md-6 col-6 fechamovil">
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12 dpsalida" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Salida").toUpperCase()}</h6>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => cambioFechas2(date)}
                                            dateFormat="MM/dd/yyyy"
                                            minDate={endDate}
                                            excludeDates={dias}
                                            locale={lang}
                                            popperClassName="csalidam"
                                            customInput={<ExampleCustomInputSalida />}
                                            popperPlacement="bottom-start"
                                        />
                                        <input type="hidden" id="nd_booking_date_month_to" className="nd_booking_section nd_booking_margin_top_20" />
                                        <input type="hidden" id="nd_booking_date_number_to" className="nd_booking_section nd_booking_margin_top_20" />
                                    </div>
                                </div>
                                <div className="col-md-12 row" style={{ textAlign: "center", marginTop: "15px" }}>
                                    <div className="col-md-6 col-6">
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Personas").toUpperCase()}</h6>
                                        <div className="nd_booking_float_left nd_booking_text_align_right">
                                            <h1 className="nd_booking_font_size_50 nd_booking_color_yellow_important nd_booking_guests_number colorcorporativo" onClick={handleShowM} ref={numpersonasm}>{textopersonas}</h1>
                                            <img decoding='async' className='nd_booking_float_right nd_booking_guests_decrease nd_booking_cursor_pointer' alt='' width='10' src='/images/magno/down_icon.svg' style={{ position: "absolute", marginTop: "-40px", marginLeft: "15px" }} />
                                        </div>
                                        <div className="nd_booking_float_right nd_booking_text_align_center nd_booking_margin_left_10">
                                            <div className="nd_booking_section nd_booking_height_10"></div>
                                            <div className="nd_booking_section">
                                                <img decoding="async" className="nd_booking_float_right nd_booking_guests_decrease nd_booking_cursor_pointer" alt="" width="12" src="/images/nd-booking-inc-shortcodes-include-search-results-icon-down-arrow-white.svg" />
                                            </div>
                                        </div>

                                        {/* ************ */}

                                        <div className="formpersonas" style={{ display: showm }} tabIndex="0" onMouseLeave={() => { document.addEventListener("click", onClickOutsideListenerM) }} >
                                            <div className="mt-1 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                                <div className="col-md-5 col-6">
                                                    <div className="mt-2 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="0" max={propiedad.capacidad_maxima} id="numadultosm" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-1 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                                <div className="col-md-5 col-6">
                                                    <div className="mt-2 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max={propiedad.capacidad_maxima} id="numninosm" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-1 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Bebés")}<div className="mini-etiqueta-formulari">({t("De 0 a 2 años")})</div></label>
                                                <div className="col-md-5 col-6">
                                                    <div className="mt-2 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max={propiedad.capacidad_maxima} id="numbebesm" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ************ */}
                                    </div>
                                    <div className="col-md-6 col-6">
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Noches").toUpperCase()}</h6>
                                        <div className="nd_booking_section nd_booking_height_15"></div>
                                        <div className="nd_booking_display_inline_flex ">
                                            <div className="nd_booking_float_left nd_booking_text_align_right">
                                                <h1 className="nd_booking_font_size_50 nd_booking_color_yellow_important nd_booking_nights_number difdias colorcorporativo">
                                                    {difdias}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ textAlign: "center", marginTop: "15px" }}>
                                    <button type='button' id='buscador' className='ubtn bgcolorcorporativo' style={{ color: "#FFF", width: "80%", border: "none", padding: "7px" }} onClick={handleSubmit}>
                                        <span className='ubtn-hover'></span>
                                        <span className='ubtn-data ubtn-text '>{t("RESERVA")}</span>
                                    </button>
                                </div>
                                <div className="col-md-12" style={{ textAlign: "center", marginTop: "30px", maxHeight: "300px" }}>
                                    <div className=""></div>
                                    <div className="detail-subtotal-price" style={{ color: "#FFF", width: "100%" }}>
                                        <div>
                                            {ReactHtmlParser(dataRes)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* *********** */}

                        <h3 className="pl-10 vc_custom_heading vc_custom_1676118797766 mayusculacss">{t('Descripción')}</h3>
                        <div className="wpb_text_column wpb_content_element vc_custom_1676567371409 color">
                            <div className="wpb_wrapper">
                                <div className="pl-10 descprop" style={{ textAlign: "left", fontFamily: "'Lato', sans-serif", fontWeight: "300", fontSize: "14px", lineHeight: "27px", letterSpacing: "1px" }}>
                                    <div>
                                        {ReactHtmlParser(propiedad.ladesc)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1676119765323">
                            <span className="vc_sep_holder vc_sep_holder_l">
                                <span className="vc_sep_line">
                                    <hr className="colorcorporativo" />
                                </span>
                            </span>
                            <span className="vc_sep_holder vc_sep_holder_r">
                                <span className="vc_sep_line"></span>
                            </span>
                        </div>

                        <h6 className="pl-10 vc_custom_heading vc_custom_1676194308133 colorcorporativo mayusculascss"> {t('Servicios')}</h6>

                        <div className="pl-10 row wpb_row vc_inner vc_row-fluid vc_custom_1676196978693 vc_row-has-fill nomarginnopadding box-servicios">
                            <div className="wpb_column vc_column_container col-md-3">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        {caracs1}
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container col-md-3">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        {caracs2}
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container col-md-3">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        {caracs3}
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column vc_column_container col-md-3">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        {caracs4}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1676119765323">
                            <span className="vc_sep_holder vc_sep_holder_l">
                                <span className="vc_sep_line">
                                    <hr className="colorcorporativo" />
                                </span>
                            </span>
                            <span className="vc_sep_holder vc_sep_holder_r">
                                <span className="vc_sep_line"></span>
                            </span>
                        </div>

                        <h6 className="vc_custom_heading vc_custom_1676194308133 mayusculascss title-equip">{t('Equipamiento')}</h6>
                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676196978693 vc_row-has-fill">
                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                <div className="vc_column-inner">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                            <div className="wpb_wrapper box-equip">
                                                <span style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px" }}>
                                                    {ReactHtmlParser(propiedad.textras)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1676119765323">
                            <span className="vc_sep_holder vc_sep_holder_l">
                                <span className="vc_sep_line">
                                    <hr className="colorcorporativo" />
                                </span>
                            </span>
                            <span className="vc_sep_holder vc_sep_holder_r">
                                <span className="vc_sep_line"></span>
                            </span>
                        </div>

                        <h3 className="vc_custom_heading vc_custom_1676194159391 mayusculacss">{t('Información')}</h3>

                        <h6 className="vc_custom_heading vc_custom_1676221780447 h6-sec-inf">{t("Horas de entrada/salida")}</h6>
                        <div className="wpb_text_column wpb_content_element vc_custom_1676119543881">
                            <div className="wpb_wrapper box-content-inf">
                                <p style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px", color: "rgb(92, 92, 92)", textAlign: "justify", fontWeight: "300", lineHeight: "27px" }}>
                                    {t("Check-in: a partir de las") + " " + propiedad.hora_checkin + "h"}
                                    <br />
                                    {t("Check out: antes de las") + " " + propiedad.hora_checkout + "h."}
                                    <br />
                                    {t('Si necesita entrar/salir antes o después de dichas horas, deberá solicitarlo en el momento de la reserva. Estas opciones quedan sujetas a disponibilidad y pueden generar un coste adicional.')}
                                </p>
                            </div>
                        </div>

                        <h6 className="vc_custom_heading vc_custom_1676221795191 h6-sec-inf">{t("Cancelaciones")}</h6>
                        <div className="wpb_text_column wpb_content_element vc_custom_1678171747259">
                            <div className="wpb_wrapper box-content-inf">
                                {politicas.forEach((unapolitica, index) => {
                                    results2 = results2.concat(
                                        <div key={"p" + index} style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px", color: "rgb(92, 92, 92)", textAlign: "justify", fontWeight: "300", lineHeight: "27px" }}>
                                            <strong style={{ fontWeight: "bold" }}>{unapolitica.elnombre}</strong><br />
                                            <span>
                                                {ReactHtmlParser(unapolitica.ladesc)}
                                            </span>
                                        </div>
                                    )
                                })}
                                {results2}
                            </div>
                        </div>

                        <h6 className="vc_custom_heading vc_custom_1676221816060 h6-sec-inf">{t("Información adicional")}</h6>
                        <div className="wpb_text_column wpb_content_element vc_custom_1676194099117">
                            <div className="wpb_wrapper box-content-inf">
                                <p style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px", color: "rgb(92, 92, 92)", textAlign: "justify", fontWeight: "300", lineHeight: "27px" }}>
                                    {t("Estancia mínima")}:  2  {t("días")}<br />
                                    {t("Suplemento limpieza final")}: {propiedad.suplemento_limpieza} {moneda}<br />
                                </p>
                                <span style={{ fontFamily: "Roboto, sans-serif", fontSize: "14px", color: "rgb(92, 92, 92)", textAlign: "justify", fontWeight: "300", lineHeight: "27px" }}>
                                    {ReactHtmlParser(propiedad.lainfo)}
                                </span>
                            </div>
                        </div>

                        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1675946271274 vc_row-has-fill solodesktop">
                            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-has-fill">
                                <div className="vc_column-inner vc_custom_1675961891996">
                                    <div className="wpb_wrapper">
                                        <div className="wpb_text_column wpb_content_element vc_custom_1678703264853">
                                            <div className="wpb_wrapper text-duda">
                                                <h4 style={{ fontSize: "17px" }}>{t("¿Tienes alguna pregunta o duda sobre este alojamiento?")}</h4>
                                            </div>
                                        </div>

                                        <div className="vc_custom_1677759470986 box-btn-sol-info">
                                            <a className="ubtn-link ult-adjust-bottom-margin ubtn-custom " href={"/" + preidioma + t("contacto")}>
                                                <button type="button" id="ubtn-8595" className="ubtn ult-adjust-bottom-margin ult-responsive ubtn-custom ubtn-no-hover-bg none tooltip-646df1d42f720 btn-sol-info " style={{ background: "white", padding: "5px" }}>
                                                    <span className="ubtn-hover"></span>
                                                    <span className="ubtn-data ubtn-text mayusculacss botoninfo" style={{ color: "#000", fontSize: "13px", fontFamily: "Lato", letterSpacing: "1.3px", lineHeigt: "16.8px" }}>{t("Solicita información")}</span>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3 className="vc_custom_heading vc_custom_1675964282620 h3-n-licen">{t("Número de licencia")}</h3>
                        <div className="wpb_text_column wpb_content_element vc_custom_1676194110008 text-n-licen">
                            <div className="wpb_wrapper">
                                <p className="text-n-licen-p" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>{propiedad.hutb}</p>
                            </div>
                        </div>

                        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1676119765323">
                            <span className="vc_sep_holder vc_sep_holder_l">
                                <span className="vc_sep_line">
                                    <hr className="colorcorporativo" />
                                </span>
                            </span>
                            <span className="vc_sep_holder vc_sep_holder_r">
                                <span className="vc_sep_line"></span>
                            </span>
                        </div>

                        <h3 className="pl-10 vc_custom_heading vc_custom_1676194187223 title-h3-disponibilidad">{t("Disponibilidad")}</h3>
                        <div className="pl-10 wpb_single_image wpb_content_element vc_align_left vc_custom_1677577495936 full-box-calendario">
                            <figure className="wpb_wrapper vc_figure">

                                <div className="calendario">
                                    <div>
                                        {ReactHtmlParser(calendario)}
                                    </div>
                                </div>

                                <div className="clearfix map-neighbour-label">
                                    <ul className="clearfix map-neighbour-label">
                                        <li className="color-04">
                                            {t("Ocupado")}
                                        </li>
                                    </ul>
                                </div>
                            </figure>
                        </div>

                        <div className="row solomobile" style={{ background: "#fbfbfd" }}>
                            <div className="col-md-12">
                                <div className="map">
                                    <MapContainer center={[propiedad.latitud, propiedad.longitud]} zoom={16} scrollWheelZoom={false} style={{ width: "100%", marginLeft: "0" }}>
                                        <TileLayer
                                            attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                            url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                        />
                                        <Marker position={[propiedad.latitud, propiedad.longitud]} icon={myIcon}>
                                            <Popup>
                                                <p>
                                                    <img src={foto} style={{ width: "220px" }} />
                                                </p>
                                                <h3 style={{ marginBottom: "18px", fontWeight: "800", fontSize: "1.17em", marginTop: "7px", display: "block", marginBlockStart: "1em", marginBlockEnd: "1em", marginInlineStart: "0px", marginInlineEnd: "0px", textTransform: "uppercase", textAlign: "center" }}>{propiedad.nombre}</h3>
                                                <p style={{ marginTop: "-2px", fontWeight: "500", color: "#C19B77", textAlign: "center", fontFamily: "'Lato', sans-serif", fontSize: "12px", lineHeight: "15px" }}>{personas + " - " + metros + " - " + banyos + " - " + habs}</p>
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                </div>
                            </div>
                        </div>

                        <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_custom_1676119765323">
                            <span className="vc_sep_holder vc_sep_holder_l">
                                <span className="vc_sep_line">
                                    <hr className="colorcorporativo" />
                                </span>
                            </span>
                            <span className="vc_sep_holder vc_sep_holder_r">
                                <span className="vc_sep_line"></span>
                            </span>
                        </div>
                    </div>

                    <div className="col-md-5 col-lg-4 detail-right-sidebar solodesktop right-col">
                        <div className="vc_column-inner vc_custom_1684831039819 w-100">
                            <input type="hidden" id="idpropiedad" value={propiedad.id} />
                            <input type="hidden" id="moneda" name="moneda" value="EUR" />
                            <input type="hidden" id="lang" name="lang" value={lang} />
                            <div className="row box-right-sidebar" style={{ height: "440px" }}>
                                <div className="col-md-12 row" style={{ textAlign: "center", marginTop: "30px", marginLeft: "0px" }}>
                                    <div className="col-md-6" style={{ height: '95%' }}>
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12 dp-entrada" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }} >{t("Entrada").toUpperCase()}</h6>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => cambioFechas(date)}
                                            dateFormat="MM/dd/yyyy"
                                            minDate={primdialibre}
                                            excludeDates={dias}
                                            locale={lang}
                                            popperClassName="cnetrada"
                                            customInput={<ExampleCustomInputEntrada />}
                                            popperPlacement="bottom-start"
                                        />
                                        <input type="hidden" id="nd_booking_date_month_from" className="nd_booking_section nd_booking_margin_top_20" />
                                        <input type="hidden" id="nd_booking_date_number_from" className="nd_booking_section nd_booking_margin_top_20" />
                                    </div>

                                    <div className="col-md-6 col-6" style={{ height: '95%' }}>
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12 dpsalida" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Salida").toUpperCase()}</h6>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => cambioFechas2(date)}
                                            dateFormat="MM/dd/yyyy"
                                            minDate={endDate}
                                            excludeDates={dias}
                                            locale={lang}
                                            popperClassName="csalida"
                                            customInput={<ExampleCustomInputSalida />}
                                            popperPlacement="bottom"
                                        />
                                        <input type="hidden" id="nd_booking_date_month_to" className="nd_booking_section nd_booking_margin_top_20" />
                                        <input type="hidden" id="nd_booking_date_number_to" className="nd_booking_section nd_booking_margin_top_20" />
                                    </div>
                                </div>
                                <div className="col-md-12 row" style={{ textAlign: "center", marginTop: "15px", marginLeft: "0px" }}>
                                    <div className="col-md-6">
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Personas").toUpperCase()}</h6>
                                        <div className="nd_booking_float_left nd_booking_text_align_right">
                                            <h1 className="nd_booking_font_size_50 nd_booking_color_yellow_important nd_booking_guests_number colorcorporativo" onClick={handleShow} ref={numpersonas}>{textopersonas}</h1>
                                            <img decoding='async' className='nd_booking_float_right nd_booking_guests_decrease nd_booking_cursor_pointer' alt='' width='10' src='/images/magno/down_icon.svg' style={{ position: "absolute", marginTop: "-40px", marginLeft: "15px" }} />
                                        </div>
                                        <div className="nd_booking_float_right nd_booking_text_align_center nd_booking_margin_left_10">
                                            <div className="nd_booking_section nd_booking_height_10"></div>
                                            <div className="nd_booking_section">
                                                <img decoding="async" className="nd_booking_float_right nd_booking_guests_decrease nd_booking_cursor_pointer" alt="" width="12" src="/images/nd-booking-inc-shortcodes-include-search-results-icon-down-arrow-white.svg" />
                                            </div>
                                        </div>

                                        {/* ************ */}

                                        <div className="formpersonas" style={{ display: show }} tabIndex="0" onMouseLeave={() => { document.addEventListener("click", onClickOutsideListener) }}>
                                            <div className="mt-1 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                                <div className="col-md-5 col-6">
                                                    <div className="mt-2 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="0" max={propiedad.capacidad_maxima} id="numadultos" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-1 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                                <div className="col-md-5 col-6">
                                                    <div className="mt-2 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max={propiedad.capacidad_maxima} id="numninos" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-1 row">
                                                <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Bebés")}<div className="mini-etiqueta-formulari">({t("De_0_a_2_años")})</div></label>
                                                <div className="col-md-5 col-6">
                                                    <div className="mt-2 input-group">
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max={propiedad.capacidad_maxima} id="numbebes" readOnly={true} />
                                                        <span className="input-group-btn">
                                                            <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                                <span className="centrar-boto-cercle">
                                                                    <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                        <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ************ */}
                                    </div>
                                    <div className="col-md-6">
                                        <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: "'Lato', sans-serif", fontWeight: "350", letterSpacing: "2px", fontSize: "12px" }}>{t("Noches").toUpperCase()}</h6>
                                        <div className="nd_booking_section nd_booking_height_15"></div>
                                        <div className="nd_booking_display_inline_flex ">
                                            <div className="nd_booking_float_left nd_booking_text_align_right">
                                                <h1 className="nd_booking_font_size_50 nd_booking_color_yellow_important nd_booking_nights_number difdias colorcorporativo">
                                                    {difdias}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ textAlign: "center", marginTop: "50px" }}>
                                    <button type='button' id='buscador' className='ubtn bgcolorcorporativo btn-reserva-datos' style={{ color: "#FFF", width: "90%", border: "none", padding: "10px" }} onClick={handleSubmit}>
                                        <span className='ubtn-hover'></span>
                                        <span className='ubtn-data ubtn-text '>{t("RESERVA")}</span>
                                    </button>
                                </div>
                                <div className="col-md-12" style={{ textAlign: "center", marginTop: "15px", maxHeight: "300px" }}>
                                    <div className=""></div>
                                    <div className="detail-subtotal-price" style={{ color: "#FFF", width: "100%" }}>
                                        <div>
                                            {ReactHtmlParser(dataRes)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row solodesktop" style={{ background: "#fbfbfd" }}>
                                <div className="p-0 col-md-12" style={{}}>
                                    <div className="map">
                                        <MapContainer center={[propiedad.latitud, propiedad.longitud]} zoom={16} scrollWheelZoom={false} style={{ width: "100%", marginLeft: "0" }}>
                                            <TileLayer
                                                attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                                url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                            />
                                            <Marker position={[propiedad.latitud, propiedad.longitud]} icon={myIcon}>
                                                <Popup>
                                                    <p>
                                                        <img src={foto} style={{ width: "220px" }} />
                                                    </p>
                                                    <h3 style={{ marginBottom: "18px", fontWeight: "800", fontSize: "1.17em", marginTop: "7px", display: "block", marginBlockStart: "1em", marginBlockEnd: "1em", marginInlineStart: "0px", marginInlineEnd: "0px", textTransform: "uppercase", textAlign: "center" }}>{propiedad.nombre}</h3>
                                                    <p style={{ marginTop: "-2px", fontWeight: "500", color: "#C19B77", textAlign: "center", fontFamily: "'Lato', sans-serif", fontSize: "12px", lineHeight: "15px" }}>{personas + " - " + metros + " - " + banyos + " - " + habs}</p>
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="order-last col-md-12 datospropiedad">
                        <div className="wpb_column vc_column_container vc_col-sm-12 vc_hidden-xs vc_col-has-fill">
                            <div className="vc_column-inner vc_custom_1684831053438">
                                <div className="wpb_wrapper">
                                    <div className="wpb_text_column wpb_content_element vc_custom_1676655173575">
                                        <div className="wpb_wrapper box-apart-relacionados">
                                            <h1>{t("Apartamentos relacionados")}</h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {relacionados.forEach((propiedadrel, index) => {

                                            caracs = [];
                                            contador = 0;

                                            const foto = propiedadrel.foto_principal;

                                            resultsrel = resultsrel.concat(
                                                <div className="col-md-4 elementor-top-column elementor-element elementor-element-8602aa5" data-id="8602aa5" data-element_type="column" key={index} style={{ minHeight: "1px", paddingLeft: "6px", paddingRight: "6px" }}>
                                                    <div className="elementor-widget-wrap elementor-element-populated e-swiper-container" style={{ borderStyle: "solid", borderWidth: "1px 1px 1px 1px", borderColor: "#DEDEDE", transition: "background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s", margin: "9px 4px 9px 4px", padding: "10px 10px 10px 10px", display: "flex" }}>
                                                        <div className="elementor-element elementor-element-476abb2 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel e-widget-swiper" data-id="476abb2" data-element_type="widget" data-settings="{&quot;slides_to_show&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;autoplay&quot;:&quot;no&quot;,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}" data-widget_type="image-carousel.default" style={{ marginBottom: "20px" }}>
                                                            <a href={"/" + preidioma + t("apartamentos") + "-" + propiedadrel.destino.url_dinamica[lang] + "/" + propiedadrel.url_dinamica}>
                                                                <img src={ruta + "/propiedades/" + propiedadrel.id + "/fotos/web/thumb/" + propiedadrel.foto_principal[0].nombre} className="img-fluid" style={{ width: "100%" }} />
                                                            </a>
                                                        </div>

                                                        <div className="elementor-element elementor-element-86a8e7c elementor-widget elementor-widget-text-editor" data-id="86a8e7c" data-element_type="widget" data-widget_type="text-editor.default">
                                                            <div className="elementor-widget-container" style={{ margin: "9px 23px 0px 23px", padding: "0px" }}>
                                                                <h3 style={{ textAlign: "left", fontFamily: "'Lato', sans-serif", fontWeight: "300", fontSize: "20px", lineHeight: "27px !important", letterSpacing: "1px" }}>
                                                                    <a className="topropiedad titulo-propiedad" href={"/" + preidioma + t("apartamentos") + "-" + propiedadrel.destino.url_dinamica[lang] + "/" + propiedadrel.url_dinamica} target="_blank">{propiedadrel.nombre}</a>
                                                                </h3>
                                                            </div>
                                                        </div>

                                                        <div className="elementor-element elementor-element-5e29aac elementor-widget elementor-widget-image" data-id="5e29aac" data-element_type="widget" data-widget_type="image.default" style={{ textAlign: "center" }}>
                                                            <div className="elementor-widget-container" style={{ fontSize: "11px", fontWeight: "300" }}>
                                                                <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1676119123872 vc_row-has-fill vc_row-o-content-middle vc_row-flex" style={{ display: "flex", flexWrap: "inherit", justifyContent: "center", margin: "0 12px" }}>
                                                                    <div className="wpb_column vc_column_container vc_col-sm-2" style={{}}>
                                                                        <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "0px", width: "100%" }}>
                                                                            <div className="wpb_wrapper">
                                                                                <div className="aio-icon-component style_1">
                                                                                    <div id="Info-box-wrap-5578" className="aio-icon-box top-icon">
                                                                                        <div className="aio-icon-top">
                                                                                            <div className="ult-just-icon-wrapper">
                                                                                                <div className="align-icon">
                                                                                                    <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                                        <img className="img-icon" alt="null" src="/images/web/magno/2023-01-per-3.png" style={{ height: "20px" }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-5578 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedadrel.capacidad_maxima + " " + t('Personas')}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wpb_column vc_column_container vc_col-sm-2" style={{}}>
                                                                        <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "25px", width: "100%" }}>
                                                                            <div className="wpb_wrapper">
                                                                                <div className="aio-icon-component style_1">
                                                                                    <div id="Info-box-wrap-6397" className="aio-icon-box top-icon">
                                                                                        <div className="aio-icon-top">
                                                                                            <div className="ult-just-icon-wrapper ">
                                                                                                <div className="align-icon">
                                                                                                    <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                                        <img className="img-icon" alt="null" src="/images/web/magno/2023-01-mt-2.jpg" style={{ height: "20px" }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6397 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedadrel.metros_cuadrados + " " + t('M2')}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wpb_column vc_column_container vc_col-sm-2" style={{}}>
                                                                        <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "25px", width: "100%" }}>
                                                                            <div className="wpb_wrapper">
                                                                                <div className="aio-icon-component style_1">
                                                                                    <div id="Info-box-wrap-6663" className="aio-icon-box top-icon">
                                                                                        <div className="aio-icon-top">
                                                                                            <div className="ult-just-icon-wrapper">
                                                                                                <div className="align-icon">
                                                                                                    <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                                        <img className="img-icon" alt="null" src="/images/web//magno/2023-01-ban-2.jpg" style={{ height: "20px" }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6663 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedadrel.num_banyos + " " + t('Baños')}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wpb_column vc_column_container vc_col-sm-3" style={{}}>
                                                                        <div className="vc_column-inner" style={{ boxSizing: "border-box", paddingLeft: "25px", width: "100%" }}>
                                                                            <div className="wpb_wrapper">
                                                                                <div className="aio-icon-component style_1">
                                                                                    <div id="Info-box-wrap-6716" className="aio-icon-box top-icon">
                                                                                        <div className="aio-icon-top">
                                                                                            <div className="ult-just-icon-wrapper ">
                                                                                                <div className="align-icon">
                                                                                                    <div className="aio-icon-img " style={{ fontSize: "27px", display: "inline-block" }}>
                                                                                                        <img className="img-icon" alt="null" src="/images/web/magno/2023-01-cama-2.jpg" style={{ height: "20px" }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="aio-icon-description ult-responsive" data-ultimate-target="#Info-box-wrap-6716 .aio-icon-description" data-responsive-json-new="{&quot;font-size&quot;:&quot;&quot;,&quot;line-height&quot;:&quot;&quot;}" style={{ fontSize: "11px", fontWeight: "300", fontFamily: "'Roboto', sans-serif" }}>{propiedadrel.num_habitaciones + " " + t('Dormitorios')}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="elementor-element elementor-element-7625ecd elementor-widget elementor-widget-wp-widget-custom_html" data-id="7625ecd" data-element_type="widget" data-widget_type="wp-widget-custom_html.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="textwidget custom-html-widget">
                                                                    <hr style={{ margin: "20px" }} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="elementor-element elementor-element-3f8a4d8 elementor-button-info elementor-align-center elementor-widget elementor-widget-button" data-id="3f8a4d8" data-element_type="widget" data-widget_type="button.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="mb-2 elementor-button-wrapper">
                                                                    <a href={"/" + preidioma + t("apartamentos") + "-" + propiedadrel.destino.url_dinamica[lang] + "/" + propiedadrel.url_dinamica} className="topropiedad elementor-button-link elementor-button elementor-size-xs elementor-animation-shrink bgcolorcorporativo" role="button" style={{ cursor: "pointer", fontWeight: "300", fill: "#FFFFFF", color: "#FFFFFF", borderStyle: "none", borderRadius: "0px 0px 0px 0px" }}>
                                                                        <span className="elementor-button-content-wrapper" style={{ display: "flex", justifyContent: "center" }}>
                                                                            <span className="elementor-button-text-destacados mayusculacss bgcolorcorporativo"> {t("RESERVA")}</span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        )}
                                        {resultsrel}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DatosPropiedad;
