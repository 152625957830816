import React, {useState} from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';

const Content = ({lang, noticias, ruta, contadores}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if(show=='none') setShow('block');
        else setShow('none');
    };

    return (
        <div id="Content">
            <div className="content_wrapper clearfix">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma = {preidioma}
                        />
                        {/* *********** */}
                        <div className="section section-filters">
                            <div className="section_wrapper clearfix">
                                <div id="Filters" className="column one">
                                    <ul className="filters_buttons">
                                        <li className="label">
                                            { t("Filtros de búsqueda") }
                                        </li>
                                        <li className="anyos">
                                            <a className="open" onClick={handleShow}><i className="fa-solid fa-page"></i>{ t("Años")}<i className="fa-solid fa-caret-down"></i></a>
                                        </li>
                                        <li className="reset">
                                            <a className="close" data-rel="*" href={"/"+preidioma+t("actualidad")}><i className="fa-solid fa-xmark"></i>{ t("Ver todos") }</a>
                                        </li>
                                    </ul>
                                    <div className="filters_wrapper" style={{ display: show }}>
                                        <ul className="anyos" style={{ display: show }}>
                                            <li className="reset-inner">
                                                <a data-rel="*" href={"/"+preidioma+t("actualidad")}>{ t("Ver todos") }</a>
                                            </li>
                                            {
                                                contadores.map((uncontador, index) => (
                                                    <li key={index}>  
                                                        <a data-rel={"."+uncontador.anyo} href={"/"+preidioma+t("actualidad")+"/anyo/"+uncontador.anyo}>{uncontador.anyo}({uncontador.total})</a>
                                                    </li>
                                                ))
                                            }                                     
                                            <li className="close">
                                                <a onClick={handleShow} className="curpointer"><i className="fa-solid fa-xmark"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="section_wrapper clearfix">
                                <div className="column one column_blog">
                                    <div className="blog_wrapper isotope_wrapper">
                                        <div className="posts_group lm_wrapper grid">
                                            {
                                                noticias.map((unanoticia, index) => (
                                                    <div className="post-item isotope-item clearfix author-admin post format-standard has-post-thumbnail hentry noticianoborde" id="elem0" style={{height: "743px"}} key={index}>
                                                        <div className="image_frame post-photo-wrapper scale-with-grid image">
                                                            <div className="image_wrapper border-radius">
                                                                <a href={"/"+preidioma+"/"+unanoticia.laurl}>
                                                                    <div className="mask"></div>
                                                                    { unanoticia.imagen != ""
                                                                    ?   <img src={ruta + "/blog/" + unanoticia.id + "/" + unanoticia.imagen} className="scale-with-grid wp-post-image h230 border-radius-imatge" alt=""/> 
                                                                    :    <img src="images/web/bedloop-nofoto.png" className="scale-with-grid wp-post-image h230 border-radius-imatge" alt=""/> 
                                                                    }
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="post-desc-wrapper bg-">
                                                            <div className="post-desc">
                                                                <div className="post-title espai-titol-noticia">
                                                                    <h2 className="entry-title mt-20">
                                                                        <a href={"/"+preidioma+t("actualidad")+"/"+unanoticia.laurl}>
                                                                            <strong>{unanoticia.eltitulo}</strong>
                                                                        </a>
                                                                    </h2>
                                                                </div>
                                                                <div className="post-meta clearfix">
                                                                    <div className="author-date">
                                                                        <span className="date">
                                                                            <i className="fa-regular fa-clock"></i> <span className="post-date updated">{new Date(unanoticia.fecha_publica).toDateString()}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="post-excerpt espai-titol-noticia">
                                                                    <p dangerouslySetInnerHTML={{__html: unanoticia.elsubtitulo}}></p>                               
                                                                </div>
                                                                <div className="post-footer mh50">
                                                                    <div className="post-links2" style={{ marginLeft: "5px"}}>
                                                                        <a href={"/"+preidioma+t("actualidad")+"/"+unanoticia.laurl} className="post-more">
                                                                        <i className="fa-regular fa-file-lines"></i> { t("Leer más") } 
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>    
                                                ))
                                            }
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        
                        {/* *********** */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;