import {React} from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import "./../../../../../css/magno/cabecera.css";

const Investments = ({lang, config, websgrupo, textopropiedades}) => {
    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div  className="investmentsh desktopymobileinvest" style={{backgroundColor: "black", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <section className="container capsainvestments" data-id="30a94b1" data-element_type="section" style={{ marginTop: "27px", marginBottom: "17px", backgroundColor: "#00000" }}>
                <div className="elementor-container elementor-column-gap-default investmentstext">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e12b9ff" data-id="e12b9ff" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-eb19767 elementor-widget elementor-widget-heading" data-id="eb19767" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container" style={{ textAlign:"center" }}>
                                    <h3 className="elementor-heading-title elementor-size-default" style={{ color: "#FFFFFF", fontFamily: "Roboto, Sans-serif" , fontSize: "12px", fontWeight: "400", lineHeight: "1em", letterSpacing: "4px", marginBottom:"20px" }}>{ t('¿QUIERES INVERTIR CON NOSOTROS?') }</h3>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-d286a76 elementor-widget elementor-widget-heading" data-id="d286a76" data-element_type="widget" data-widget_type="heading.default" style={{ alignText:"center" }}>
                                <div className="elementor-widget-container" style={{ textAlign:"center" }}>
                                    <h3 className="elementor-heading-title elementor-size-medium tamtitulo titinvestments" style={{ color: "#FFFFFF", fontFamily: "Lato,  Sans-serif", fontWeight: "300", lineHeight: "1.2em", letterSpacing: "1px" }}>Magno Investments</h3>
                                </div>
                            </div>
                            <div className="solodesktopinvest elementor-element elementor-element-3720667 elementor-widget elementor-widget-heading box-investments" data-id="3720667" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container p-investments" style={{ fontFamily:"'Roboto',sans-seriff", fontSize:"14px", fontWeight:"300",letterSpacing:"1px",lineHeight:"27px", color:"#FFF" }}>
                                    { ReactHtmlParser(textopropiedades.ladesc) }
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-00854f5 elementor-align-center elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="00854f5" data-element_type="widget" data-widget_type="button.default" style={{ textAlign:"center" }}>
                                <div className="elementor-widget-container">
                                    <div className="elementor-button-wrapper">
                                        <a href="https://magnoinvestments.es/" target="_blank" className="elementor-button-link elementor-button elementor-size-lg elementor-animation-shrink" role="button" style={{ fontFamily: "Roboto, Sans-serif", fontSize: "12px", fontWeight: "normal", lineHeight: "1px", letterSpacing: "2px", fill: "#FFFFFF" , color: "#FFFFFF",  borderRadius: "0px 0px 0px 0px", padding: "19px 19px 19px 19px" }}>
                                            <span className="elementor-button-content-wrapper">
                                                <span className="investments">{ t('CONOCE MAGNO INVESTMENTS') }</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
);
}

export default Investments;
