import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Enlaces = ({ lang, propanterior, propsiguiente }) => {

    const { t } = useTranslation();

    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <section className="pt-3 elementor-section elementor-top-section elementor-element elementor-element-396cd2e elementor-section-boxed elementor-section-height-default" data-id="396cd2e" data-element_type="section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cf362a1" data-id="cf362a1" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-ab4162b elementor-widget elementor-widget-post-navigation" data-id="ab4162b" data-element_type="widget" data-widget_type="post-navigation.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-post-navigation" >
                                        <div className="elementor-post-navigation__prev elementor-post-navigation__link">
                                            {
                                                propanterior ?
                                                    <a href={"/" + preidioma + t("apartamentos") + "-" + propanterior.destino.url_dinamica[lang] + "/" + propanterior.url_dinamica} rel="prev">
                                                        <span className="elementor-post-navigation__link__prev">
                                                            <span className="post-navigation__prev--label">{t("Anterior")}</span>
                                                        </span>
                                                    </a>
                                                    :
                                                    ""
                                            }
                                        </div>

                                        <div className="elementor-post-navigation__next elementor-post-navigation__link">
                                            {
                                                propsiguiente ?
                                                    <a href={"/" + preidioma + t("apartamentos") + "-" + propsiguiente.destino.url_dinamica[lang] + "/" + propsiguiente.url_dinamica} rel="prev">
                                                        <span className="elementor-post-navigation__link__next">
                                                            <span className="post-navigation__prev--label">{t("Siguiente")}</span>
                                                        </span>
                                                    </a>
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="elementor-section elementor-top-section elementor-element elementor-element-12b71fb elementor-section-boxed elementor-section-height-default" data-id="12b71fb" data-element_type="section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-18561ed" data-id="18561ed" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-bcba236 elementor-align-center elementor-widget elementor-widget-button" data-id="bcba236" data-element_type="widget" data-widget_type="button.default">
                                <div className="elementor-widget-container">
                                    <div className="elementor-button-wrapper">
                                        <a href={"/" + preidioma + t("buscar")} className="elementor-button-link elementor-button elementor-size-sm" role="button">
                                            <span className="elementor-button-content-wrapper">
                                                <span className="elementor-button-text">{t("Volver")}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Enlaces;
