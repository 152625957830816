import { React } from "react";
import { Tab, Tabs } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TextoPoliticas = ({politicas, config, ruta}) => {
    let results = [];

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (ruta+'/politicas/cabecera/cabecera.jpg')
	{
		imagen = ruta+'/politicas/cabecera/cabecera.jpg';
	}

    return (
        <div className="container">

            <div className="imatge-banner" title={ t("Política_de_cancelación") } style={{  backgroundImage: "url('"+imagen+"')" , backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundSize: "cover"} }></div>

            <div className="container mb-5 ">
                <Tabs
                    defaultActiveKey="0"
                    id="uncontrolled-tab-example"
                    className="mb-5 shadow-sm "
                >
                    { politicas.forEach((unapolitica, index) => {
                        results = results.concat(
                            <Tab eventKey={ index } title={ unapolitica.elnombre } key={index} >
                                <div dangerouslySetInnerHTML={{__html: unapolitica.ladesc}}></div>  
                            </Tab>
                        )
                    })}

                    { results }
                </Tabs>
            </div>
        </div>
    );
}

export default TextoPoliticas;