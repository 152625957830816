import { React } from "react";

import { useTranslation } from 'react-i18next';

const CambiarContrasenya = ({ lang, config }) => {
    const { t } = useTranslation();
    return (
        <section className="mt-5 mb-5">
            <div className="container bg-white shadow mt-5 mb-5 br15 col-sm-5">
                <div className="row noticia-individual noticia-individual-descripcio-llarga mx-auto col-sm-6">
                    <h2 className="noticia-individual mb-5 mt-5 border-bottom pb-3">{t("Actualiza tu contraseña")}</h2>
                    <form className="noticia-individual" id="contactForm" action="/cambiarPsw">
                        <input type="hidden" name="lang" value={lang.toString().toUpperCase()} />
                        <input type="hidden" name="idusuario" value="" />
                        <input type="hidden" name="lang" value="" />
                        <div className="row">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder={t("Contraseña antigua")} name="oldpassword" required="" />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder={t("Contraseña nueva")} name="newpassword" required="" />
                            </div>
                            <div className="pull-right mt-4 mb-5">
                                <input type="submit" className="btn btn-danger" value={t("Guardar")} style={{ background: "" + config.color_corporativo_web + "", border: "1px solid" + config.color_corporativo_web }} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
export default CambiarContrasenya;
