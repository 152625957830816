import Menu from './Compartido/Menu.jsx';
import DatosReserva from './Reserva/DatosReserva.jsx';
import Footer from './Compartido/Footer.jsx';

const Reserva = (props) => {
    return (
        <main>

            <Menu
                destinos={props.destinos}
                sobrenosotros={props.textosobrenosotros}
                clientes={props.textoclientes}
                propietarios={props.textopropietarios}
                servicios={props.textoservicios}
                experiencias={props.experiencias}
                idiomas={props.idiomas}
                lang={props.lang}
                tiposcasas={props.tiposcasas}
                config={props.websgrupo}
                textopropiedades={props.textopropiedades}
                edificios={props.edificios}
                marcas={props.marcas}
                ruta={props.ruta}
                destinoslarga={props.destinoslarga}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                numblog={props.numblog}
                numfaqs={props.numfaqs}
                paginapersonalizada={props.paginapersonalizada}
            />

            <DatosReserva
                propiedad={props.propiedad}
                precios={props.precios}
                llegada={props.llegada}
                salida={props.salida}
                difdias={props.difdias}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                lang={props.lang}
                ruta={props.ruta}
                config={props.config}
                tpvs={props.tpvs}
                paypal={props.paypal}
                transferencia={props.transferencia}
                stripe={props.stripe}
                paycomet={props.paycomet}
                moneda={props.moneda}
                numpropiedades={props.numpropiedades}
                websgrupo={props.websgrupo}
            />

            <Footer
                datos={props.websgrupo}
                terminos={props.textoterminos}
                protocolos={props.textoprotocolos}
                avisolegal={props.textoavisolegal}
                config={props.config}
                elementosweb={props.elementosweb}
                politicaprivacidad={props.textopolitica}
                lang={props.lang}
                ruta={props.ruta}
                logos_pie={props.logos_pie}
                websgrupo={props.websgrupo}
                numfaqs={props.numfaqs}
            />
        </main>
    );
}

export default Reserva;
