
import { React } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const Mapa = ({ destinos, elementosweb, config, lang }) => {
    let results = [];
    return (
        config.activa_mapa_web ?
            <div className="mapa mt-4 pt-4 pb-5">

                <h2 className="text-center">{elementosweb.titulo_mapa ? elementosweb.titulo_mapa[lang] : ""}</h2>
                <p className="text-center">{elementosweb.subtitulo_mapa ? elementosweb.subtitulo_mapa[lang] : ""}</p>

                <MapContainer center={[51.505, -0.09]} zoom={config.zoom_mapa_web} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                    />

                    {destinos.forEach((undestino, index) => {
                        results = results.concat(
                            <Marker position={[undestino.latitud, undestino.longitud]} key={index}>
                                <Popup>
                                    <a href={"/apartamentos-" + undestino.laurl}>{undestino.elnombre}</a>
                                </Popup>
                            </Marker>
                        );
                    })}
                    {results}
                </MapContainer>
            </div>
            :
            <></>
    );
}

export default Mapa;
