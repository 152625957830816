import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from 'react-datepicker';
// import '../../../../../../resources/css/web/reysabio/styles.css';
import { useTranslation } from 'react-i18next';
import es from 'date-fns/locale/es';

const Slider = ({ lang, destinos, ruta }) => {

    const { t } = useTranslation();
    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    registerLocale('es', es)

    const [arrival, setArrival] = useState(null);
    const [departure, setDeparture] = useState(null);

    const [imagenfondo, setImagenFondo] = useState(ruta + '/destinos/' + destinos[0].id + '/' + destinos[0].imagenes[0].imagen); // imagen inicial
    const [opacity, setOpacity] = useState(1); // opacidad inicial

    const images = []
    {
        destinos[0].imagenes.forEach((unaimagen) => {
            images.push(ruta + '/destinos/' + destinos[0].id + '/' + unaimagen.imagen); // array de imágenes
        });
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setOpacity(0); // fade-out
            setTimeout(() => {
                const currentIndex = images.indexOf(imagenfondo);
                const nextIndex = (currentIndex + 1) % images.length;
                setImagenFondo(images[nextIndex]);
                setOpacity(1); // fade-in
            }, 300); // tiempo de fade-out
        }, 7000); // 7 segundos
        return () => clearInterval(intervalId);
    }, [imagenfondo, images, opacity]);


    return (
        <div className="p-0 m-0 container-fluid main-slider">
            {/* aquí irá el slider de img */}

            <img src={imagenfondo} alt="Imagen de fondo" className="p-0 m-0 full-screen-img" style={{
                width: "100%", opacity: opacity, transition: 'opacity 0.3s', // tiempo de transición
            }} />
            {/*<div className="p-0 m-0 full-screen-img bg-secondary bg-gradient"></div>*/} {/* mientras un div */}

            <div className="p-0 position-absolute container-fluid d-flex justify-content-center box-form">
                <div className="d-flex justify-content-center">
                    <form className="container d-flex" method="get" name={t("Nuevo formulario")} action={"/" + preidioma + t("buscar")} style={{ zIndex: "5" }} id="formcerca">
                        <div className="grid-main-form">
                            <div className="form-content d-flex">
                                <div>
                                    <label htmlFor="form-field-name" className="elementor-field-label">{t("Fecha entrada")}</label>
                                    <DatePicker
                                        id="form-field-name"
                                        className="elementor-field elementor-size-sm elementor-field-textual elementor-date-field entradahome inp-text"
                                        autoComplete="off"
                                        selected={arrival}
                                        startDate={arrival}
                                        onChange={(date) => setArrival(date)}
                                        dateFormat="yyyy/MM/dd"
                                        placeholderText="dd / mm / aaaa"
                                        locale={lang}
                                        minDate={new Date()}
                                        name="arrival"
                                    >
                                    </DatePicker>
                                </div>
                                <div>
                                    <label htmlFor="form-field-name" className="elementor-field-label">{t("Fecha salida")}</label>
                                    <DatePicker
                                        id="form-field-field_451cb70"
                                        className="inp-text"
                                        autoComplete="off"
                                        selected={departure}
                                        startDate={departure}
                                        onChange={(date) => setDeparture(date)}
                                        dateFormat="yyyy/MM/dd"
                                        placeholderText="dd / mm / aaaa"
                                        locale={lang}
                                        minDate={new Date()}
                                        name="departure"
                                    >
                                    </DatePicker>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº adultos")}</label>
                                        <span className="small-text tnegre"> ({t("Desde 12 años")})</span>
                                    </div>
                                    <div className="number-input w-100">
                                        <input type="number" name="adultos" id="form-field-field_3fde374" className="elementor-field elementor-size-sm elementor-field-textual inp-number" placeholder="0" min="1" max="6" defaultValue="1" />
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº niños")}</label>
                                        <span className="small-text tnegre"> ({t("De 2 a 12 años")})</span>
                                    </div>
                                    <div className="number-input w-100">
                                        <input type="number" name="ninos" id="form-field-field_3fde374" className="elementor-field elementor-size-sm elementor-field-textual inp-number" placeholder="0" min="0" max="6" defaultValue="0" />
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="form-field-name" className="elementor-field-label">{t("Nº bebés")}</label>
                                        <span className="small-text tnegre"> ({t("De 0 a 2 años")})</span>
                                    </div>
                                    <div className="number-input w-100">
                                        <input type="number" name="bebes" id="" placeholder="0" min="0" max="6" defaultValue="0" className="inp-text" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center w-100">
                                <button type="submit" className="noto btn-buscar amayusculas">{t("Buscar")}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>

    );
};
export default Slider;
