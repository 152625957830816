import {React,useState} from "react";
import { Modal } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import Cookies from 'js-cookie';

const Popup = ({popup, lang }) => {
  const { t } = useTranslation();

  const cookiepopup = Cookies.get('cookiepopup');

  var preidioma = ""
  if(lang!='es') preidioma = lang+"/";


  const handleClose = () => {
    setShow(false);
    Cookies.set('cookiepopup', "1",  { expires: 365 });
  }

  const [show, setShow] = useState(popup.activar==1 && cookiepopup!="1");

  let codigopopup="";

  if(popup.activar==1)
  {
     if(popup.plantilla==1)
     {
        codigopopup += "<div class='row' id='fondoPopup' style='background:"+popup.background_color[lang]+";padding:10px 0'>"
          codigopopup += "<div class='col-md-6'><img src='"+popup.url_imagen[lang]+"' id='imagenPopup' width='100%' height='auto' title='"+popup.titulo_imagen[lang]+"' alt='"+popup.alt_imagen[lang]+"'/></div>"
          codigopopup += "<div class='col-md-6'>"
            codigopopup += "<div class='row'>"
              codigopopup += "<div class='col-md-12' id='tituloPopup' style='color:"+popup.color_titulo[lang]+"'>"+popup.titulo[lang]+"</div>"
            codigopopup += "</div>"
            codigopopup += "<div class='row'>"
              codigopopup += "<div class='col-md-12' id='descripcionPopup' style='color:"+popup.color_descripcion[lang]+"'>"+popup.descripcion[lang]+"</div>"
            codigopopup += "</div>"
            codigopopup += "<div class='row'>"
              codigopopup += "<div class='col-md-12' id='divbotonPopup' style='background:"+popup.color_boton[lang]+"; color:"+popup.color_texto_boton[lang]+";width:"+popup.tamanyo_boton[lang]+"%; border-radius:15px'><a class='btn btn-square' href='"+popup.enlace_boton[lang]+"' style='color:"+popup.color_texto_boton[lang]+";' id='botonPopup'>"+popup.texto_boton[lang]+"</a></div>"
            codigopopup += "</div>"
          codigopopup += "</div>"
        codigopopup += "</div>"
     }
     else if(popup.plantilla==2)
     {
        codigopopup += "<div id='fondoPopup' style='padding:10px 0'>";
          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"'>"
            codigopopup += "<div class='col-md-12'><img src='"+popup.url_imagen[lang]+"' id='imagenPopup' width='100%' height='auto' title='"+popup.titulo_imagen[lang]+"' alt='"+popup.alt_imagen[lang]+"'/></div>"
          codigopopup += "</div>"
          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"'>"
            codigopopup += "<div class='col-md-6' id='tituloPopup' style='color:"+popup.color_titulo[lang]+"'>"+popup.titulo[lang]+"</div>"
            codigopopup += "<div class='col-md-6' id='descripcionPopup' style='color:"+popup.color_descripcion[lang]+"'>"+popup.descripcion[lang]+"</div>"
          codigopopup += "</div>"
          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"'>"
            codigopopup += "<div class='col-md-6' id='divbotonPopup' style='background:"+popup.color_boton[lang]+"; color:"+popup.color_texto_boton[lang]+";width:"+popup.tamanyo_boton[lang]+"%; border-radius:15px'><a href='"+popup.enlace_boton[lang]+"' id='botonPopup' class='btn btn-square' style='color:"+popup.color_texto_boton[lang]+";'>"+popup.texto_boton[lang]+"</a></div>"
          codigopopup += "</div>"
        codigopopup += "</div>";
     }
     else if(popup.plantilla==3)
     {
        codigopopup += "<div id='fondoPopup' style='padding:10px 0'>";
          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"'>"
            codigopopup += "<div class='col-md-12'><img src='"+popup.url_imagen[lang]+"' id='imagenPopup' width='100%' height='auto' title='"+popup.titulo_imagen[lang]+"' alt='"+popup.alt_imagen[lang]+"'/></div>"
          codigopopup += "</div>"

          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"'>"
            codigopopup += "<div class='col-md-12' id='tituloPopup' style='color:"+popup.color_titulo[lang]+"'>"+popup.titulo[lang]+"</div>"
          codigopopup += "</div>"
          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"''>"
            codigopopup += "<div class='col-md-12' id='descripcionPopup' style='color:"+popup.color_descripcion[lang]+"'>"+popup.descripcion[lang]+"</div>"
          codigopopup += "</div>"
          codigopopup += "<div class='row' style='background:"+popup.background_color[lang]+"''>"
            codigopopup += "<div class='col-md-12' id='divbotonPopup' style='background:"+popup.color_boton[lang]+"; color:"+popup.color_texto_boton[lang]+";width:"+popup.tamanyo_boton[lang]+"%; border-radius:15px'><a href='"+popup.enlace_boton[lang]+"' id='botonPopup' class='btn btn-square' style='color:"+popup.color_texto_boton[lang]+";'>"+popup.texto_boton[lang]+"</a></div>"
          codigopopup += "</div>"
        codigopopup += "</div>";
     }
     else if(popup.plantilla==4)
     {
        codigopopup += "<div class='row' id='fondoPopup' style='background:"+popup.background_color[lang]+";padding:10px 0''>"
          codigopopup += "<div class='col-md-6'>"
            codigopopup += "<div class='row'>"
              codigopopup += "<div class='col-md-12' id='tituloPopup' style='color:"+popup.color_titulo[lang]+"'>"+popup.titulo[lang]+"</div>"
            codigopopup += "</div>"
            codigopopup += "<div class='row'>"
              codigopopup += "<div class='col-md-12' id='descripcionPopup' style='color:"+popup.color_descripcion[lang]+"'>"+popup.descripcion[lang]+"</div>"
            codigopopup += "</div>"
            codigopopup += "<div class='row'>"
              codigopopup += "<div class='col-md-12' id='divbotonPopup' style='background:"+popup.color_boton[lang]+"; color:"+popup.color_texto_boton[lang]+";width:"+popup.tamanyo_boton[lang]+"%;border-radius:15px'><a href='"+popup.enlace_boton[lang]+"' id='botonPopup' class='btn btn-square'' style='color:"+popup.color_texto_boton[lang]+";'>"+popup.texto_boton[lang]+"</a></div>"
            codigopopup += "</div>"
          codigopopup += "</div>"
          codigopopup += "<div class='col-md-6'><img src='"+popup.url_imagen[lang]+"' id='imagenPopup' width='100%' height='auto' title='"+popup.titulo_imagen[lang]+"' alt='"+popup.alt_imagen[lang]+"'/></div>"
        codigopopup += "</div>"
     }
  }
  
  return (
    <Modal 
      show={show} 
      onHide={handleClose} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="nopadding">
        <div dangerouslySetInnerHTML={{__html: codigopopup}}></div>
      </Modal.Body>
    </Modal>
  );
}

export default Popup;
