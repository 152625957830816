import { React } from "react";

import { useTranslation } from 'react-i18next';

const CabeceraFaqs = ({ruta}) => {
    const { t } = useTranslation();

    var imagen = ruta+"/faqs/cabecera/cabecera.jpg"; 

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title="Protocolos" style={{  backgroundImage: "url('"+imagen+"')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}}>
			        </div>
                </div>
            </div>
        </div>
    );
}

export default CabeceraFaqs;




