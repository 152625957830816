import { React } from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import "./../../../../../css/magno/terminos.css";

const TextosTerminos = ({terminos, ruta}) => {

    const { t } = useTranslation();

    return (
        <>
            <section className="elementor-section" style={{ background: "url('/images/web/magno/cabecera-terminos-y-condiciones-25.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center', height:"300px" }}>
                <div className="elementor-background-overlay"></div>
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-87736d9" data-id="87736d9" data-element_type="column">
                        <div className="elementor-widget-wrap"></div>
                    </div>
                </div>
            </section>
            <div className="container" style={{ margin: "auto", padding: "40px"}}>
                <h2 className="textoterminos"  style={{ fontSize: "45px", fontFamily:" 'Lato',sans-serif" ,lineHeight:"initial", margin: "30px 0", fontWeight: "300"}}>{ t("Términos y condiciones") }</h2>
                <div className="textoterminos" style={{ marginBottom: "30px" }}>
                    { ReactHtmlParser(terminos.ladesc) }
                </div>

            </div>
        </>
    );
}

export default TextosTerminos;
