import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Servicios = ({ servicios }) => {

    const { t } = useTranslation();

    let results = [];
    let results2 = [];
    let results3 = [];
    let results4 = [];

    {
        servicios.forEach((unservicio, index) => {
            if (index % 4 == 0) {
                results = results.concat(
                    <div className="altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 1080 1080" style={{ enableBackground: "new 0 0 1080 1080", height: "25px" }}>
                                <g>
                                    <path className="st3" d="M430.68,1049.68c-53.32-69.49-85.46-109.16-116.55-147.53c-36.45-44.98-70.88-87.46-135.4-172.95  c-4.71-6.25-3.47-15.13,2.77-19.85c6.27-4.73,15.14-3.46,19.85,2.77c64.23,85.09,98.5,127.4,134.79,172.18  c28.16,34.74,57.18,70.55,101.91,128.47c123.15-303.05,289.25-626,481.27-935.61c4.12-6.65,12.84-8.7,19.51-4.57  c6.66,4.12,8.7,12.86,4.57,19.51c-196.04,316.09-364.9,646.05-488.33,954.21L430.68,1049.68z"></path>
                                </g>
                            </svg>
                        </span>
                        <span className="with-icon">{unservicio.elnombre}</span>
                    </div>
                )
            }
            if (index % 4 == 1) {
                results2 = results2.concat(
                    <div className="altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 1080 1080" style={{ enableBackground: "new 0 0 1080 1080", height: "25px" }}>
                                <g>
                                    <path className="st3" d="M430.68,1049.68c-53.32-69.49-85.46-109.16-116.55-147.53c-36.45-44.98-70.88-87.46-135.4-172.95  c-4.71-6.25-3.47-15.13,2.77-19.85c6.27-4.73,15.14-3.46,19.85,2.77c64.23,85.09,98.5,127.4,134.79,172.18  c28.16,34.74,57.18,70.55,101.91,128.47c123.15-303.05,289.25-626,481.27-935.61c4.12-6.65,12.84-8.7,19.51-4.57  c6.66,4.12,8.7,12.86,4.57,19.51c-196.04,316.09-364.9,646.05-488.33,954.21L430.68,1049.68z"></path>
                                </g>
                            </svg>
                        </span>
                        <span className="with-icon">{unservicio.elnombre}</span>
                    </div>
                )
            }
            if (index % 4 == 2) {
                results3 = results3.concat(
                    <div className="altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 1080 1080" style={{ enableBackground: "new 0 0 1080 1080", height: "25px" }}>
                                <g>
                                    <path className="st3" d="M430.68,1049.68c-53.32-69.49-85.46-109.16-116.55-147.53c-36.45-44.98-70.88-87.46-135.4-172.95  c-4.71-6.25-3.47-15.13,2.77-19.85c6.27-4.73,15.14-3.46,19.85,2.77c64.23,85.09,98.5,127.4,134.79,172.18  c28.16,34.74,57.18,70.55,101.91,128.47c123.15-303.05,289.25-626,481.27-935.61c4.12-6.65,12.84-8.7,19.51-4.57  c6.66,4.12,8.7,12.86,4.57,19.51c-196.04,316.09-364.9,646.05-488.33,954.21L430.68,1049.68z"></path>
                                </g>
                            </svg>
                        </span>
                        <span className="with-icon">{unservicio.elnombre}</span>
                    </div>
                )
            }
            if (index % 4 == 3) {
                results4 = results4.concat(
                    <div className="altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 1080 1080" style={{ enableBackground: "new 0 0 1080 1080", height: "25px" }}>
                                <g>
                                    <path className="st3" d="M430.68,1049.68c-53.32-69.49-85.46-109.16-116.55-147.53c-36.45-44.98-70.88-87.46-135.4-172.95  c-4.71-6.25-3.47-15.13,2.77-19.85c6.27-4.73,15.14-3.46,19.85,2.77c64.23,85.09,98.5,127.4,134.79,172.18  c28.16,34.74,57.18,70.55,101.91,128.47c123.15-303.05,289.25-626,481.27-935.61c4.12-6.65,12.84-8.7,19.51-4.57  c6.66,4.12,8.7,12.86,4.57,19.51c-196.04,316.09-364.9,646.05-488.33,954.21L430.68,1049.68z"></path>
                                </g>
                            </svg>
                        </span>
                        <span className="with-icon">{unservicio.elnombre}</span>
                    </div>
                )
            }


        })
    }

    return (

        <div className="container-fluid w-100">

            <div className="d-flex align-items-center justify-content-center text-align-center w-100 sep-serv">
                <hr className="bg-dark" />
                <p className="p-0 text-align-center amayusculas">{t("Servicios")}</p>
                <hr className="bg-dark" />
            </div>

            <div className="container-fluid galeria-fotos">
                <div className="box-galeria box-galeria-servicios w-100">
                    <div>
                        {results}
                    </div>
                    <div>
                        {results2}
                    </div>
                    <div>
                        {results3}
                    </div>
                    <div>
                        {results4}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Servicios;
