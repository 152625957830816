import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Intro = ({ lang, ruta, propiedad }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    const fotos = propiedad.fotos_web;
    var lafoto = "/images/web/bedloop-nofoto.png";
    if (fotos.length > 0) {
        lafoto = ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + fotos[0].nombre
    }

    return (
        <section className="intro">
            <div className="intro__bg-wrap">
                <div
                    className="overlay intro__bg js-image js-parallax js-scale-down"
                    data-image={lafoto}
                >
                </div>
            </div>
            <div className="container intro__container">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="text-center col-12 col-xl-8">
                        <h1
                            className="title title--display-1 js-lines"
                        >
                            {propiedad.nombre}
                        </h1>
                    </div>
                </div>
            </div>
            {/* Scroll To */}
            <a className="scroll-to" href="!#content">
                {t("Scroll")}
                <span className="scroll-to__line" />
            </a>
        </section>
    );
};
export default Intro;
