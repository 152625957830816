import React from "react";
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';

const TextoPoliticas = ({ politicas }) => {

    const { t } = useTranslation();

    let results = [];

    return (
        <>
            <div className="container-fluid w-100 padlados100" style={{ marginTop: "300px" }}>
                <h1 className="titulopagina">{t("Política_de_cancelación")}</h1>
                <hr />
            </div>
            <div className="container-fluid w-100 padlados100">
                <Accordion>
                    {politicas.forEach((unapolitica, index) => {
                        results = results.concat(
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>{unapolitica.elnombre}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{ __html: unapolitica.ladesc }}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })}
                    {results}
                </Accordion>
            </div>
        </>
    );
};
export default TextoPoliticas;
