import React from "react";
import { useTranslation } from 'react-i18next';

const Content = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <main className="mt-5 main mb80">
            <section className="container section section-first">
                <div className="row justify-content-center">
                    {/* Content */}
                    <div className="col-12 col-lg-10 col-xl-8">
                        <h3 className="centra">{t("Gracias por su solicitud de reserva. En breve confirmaremos su solicitud y le indicaremos la forma de pago. Un cordial saludo")}</h3>
                    </div>
                </div>
            </section>
        </main>

    );
};
export default Content;
