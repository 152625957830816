import React from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';
import axios from 'axios';


const Content = ({preidioma, colaboradores, ruta}) => {

    const { t } = useTranslation();

    const handleSearch = () => {
        let  palabra = document.getElementById("paraula").value;
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/busca-colaboradores', {
            palabra: palabra,
        },{
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            let respuesta = "";
            let colaboradores =response.data.colaboradores;

            if (colaboradores.length>0)
            {
                for(var i = 0; i < colaboradores.length; i++) {
                    var uncolaborador =colaboradores[i];

                    respuesta+= "<div class='column three-fourth column_column'>";
                    respuesta+= "  <h5>"+uncolaborador.elnombre+"</h5>";
                    respuesta+=   uncolaborador.eltexto;
                    respuesta+= "</div>"; 
                    respuesta+= "<div class='column one-fourth column_column'>";
                    respuesta+= "  <img src='"+ruta+"/ventajas/"+uncolaborador.id+"/"+uncolaborador.imagen+"'style='width:75%;float:right'>";
                    respuesta+= "</div>";
                    respuesta+= "<hr style='border-bottom: 1px solid  #E87C0C; display: inline-block;border-color: #E87C0C;margin-bottom:20px; background-color: #FFF;'>";  

                }
            }
            document.querySelectorAll(".lista-colaboradores")[0].innerHTML=respuesta;
        })
    }

    const pressEnter = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleSearch();
        }
    }

    return (
        <div id="Content">
            <div className="content_wrapper clearfix">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma = {preidioma}
                        />

                        <div className="section mcb-section no-margin-h" style={{ paddingTop:"60px", paddingBottom:"80px"}}>
                            <div className="section_wrapper mcb-section-inner">
                                <div className="wrap mcb-wrap one valign-top clearfix">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one-sixth column_placeholder">
                                            <div className="placeholder"></div>
                                        </div>
                                        <div className="column mcb-column two-third column_column">
                                            <div className="column_attr clearfix align_center">
                                                <div className="column_attr clearfix align_center" style={{padding: "0 8%"}}>
                                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "3px solid  #E87C0C", display: "inline-block" , borderColor: "#E87C0C" }}>{ t("CLUB COLABORADOR") }</h6>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }}/>
                                                </div>
                                                <div className="column one column_blog textJustify" >
                                                    <p>{t("La idea del Club de Colaboradores surgió en 2014 para aportar valor a nuestros asociados a través de la negociación de beneficios en productos o servicios útiles para el sector. Las empresas que forman parte del Club de Colaboradores son elegidas a través de un cuidadoso y exhaustivo proceso. Buscamos profesionalidad y compromiso por parte de nuestros miembros, así como una alta calidad en los servicios ofrecidos.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* ********** */}

                                <div className="section_wrapper clearfix">
                                    <div className="items_group clearfix">
                                        <div className="column one column_column">
                                            <div className="column_attr">
                                                <div id="contactWrapper">
                                                    <form id="contactform">
                                                        <div className="column one-third">
                                                            <label className="labelsearch">
                                                                <input type="text" name="paraula" id="paraula" aria-required="true" aria-invalid="false" placeholder={ t("Nombre")} onKeyPress={(e) => pressEnter(e)}/>
                                                            </label>
                                                        </div>
                                                        
                                                        <div className="column one-fourth">
                                                            <input type="button" value={t("Buscar")} id="submit_popup" onClick={handleSearch}/>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ********** */}

                                <div className="section">
                                    <div className="section_wrapper clearfix">
                                        <div className="items_group lista-colaboradores clearfix">
                                            {
                                                colaboradores.map((uncolaborador, index) => (
                                                    <>
                                                        <div className="column three-fourth column_column" key={index}>  
                                                            <h5>{uncolaborador.elnombre}</h5>
                                                            <div dangerouslySetInnerHTML={{__html: uncolaborador.eltexto}}></div>
                                                        </div>
                                        
                                                        <div className="column one-fourth column_column">  
                                                            <img src={ruta+"/ventajas/"+uncolaborador.id+"/"+uncolaborador.imagen} className="imgPosition"/>
                                                        </div>
                                                        <hr className="highlightedBorder"/>
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* ********** */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;