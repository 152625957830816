import { React } from "react";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';


const ListaNoticias = ({ noticias, ruta, lang, config }) => {

    const { t } = useTranslation();

    var imagen = ruta+"/blog/cabecera/cabecera.jpg"; 

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    let results = [];
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <div className="imatge-banner" title={t("Blog")} style={{  backgroundImage: "url('"+imagen+"')" , backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}} ></div>
                <h2 className="mt-5 text-center">{t("Blog")}</h2>
                <div className="noticies">
                    {noticias.forEach((unanoticia, index) => {
                        results = results.concat(
                            <div className="noticies-blog" key={index}>
                                <a href={"/"+preidioma+t("blog") + "/" + unanoticia.laurl}>
                                    {
                                        unanoticia.imagen ?
                                            <LazyLoadImage effect='blur' threshold={10} className="noticies-blog-imatge" src={ruta + "/blog/" + unanoticia.id + "/" + unanoticia.imagen} alt={"Blog - " + index} />
                                            :
                                            <LazyLoadImage effect='blur' threshold={10} className="noticies-blog-imatge" src="/images/web/bedloop-nofoto.png" alt={"Blog - " + index} />
                                    }
                                </a>
                                <div className="noticies-blog-text">
                                    <h5 className="noticies-blog-titol">
                                        <a href={"/"+preidioma+t("blog") + "/" + unanoticia.laurl}>{unanoticia.eltitulo} </a>
                                    </h5>
                                    <p>{unanoticia.elsubtitulo}</p>
                                    <div className="text-center">
                                        <a className="btn btn-primary" href={"/"+preidioma+t("blog")+"/"+unanoticia.laurl} style={{ background: ""+config.color_corporativo_web+"", border: "1px solid"+config.color_corporativo_web }}> {t("leer más")} </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {results}
                </div>
                </div>
            </div>
        </div>

    );
}

export default ListaNoticias;
