import { useTranslation } from 'react-i18next';

const GovernmentOrgans = () => {
    const { t } = useTranslation();

    return (
        <div className="section mcb-section T60B80">
            <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column_attr clearfix align_center">
                            <h6 className="landing4-heading grey positionContainer">{t("ÓRGANOS GUBERNAMENTALES")}</h6>
                            <hr className="no_line margin15Auto"/>
                        </div>

                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix">
                                <h2>{t("Asamblea General")}</h2>
                                <p className="textJustify">
                                    <span lang="EN-GB">{t("La Asamblea General es el máximo órgano de gobierno de la organización. Está compuesta por todos los miembros asociados a Apartur, y se reúne una vez al año. Cada miembro tiene voz y voto y comparte los mismos derechos y obligaciones.")}</span>
                                </p>

                                <p className="MsoNormal positionGeneralAsembly">
                                    <span lang="EN-GB">{t("Entre las tareas de la Asamblea están: elegir la Junta Directiva, aprobar los presupuestos y establecer las directrices generales de actuación de la Asociación que le permitan cumplir sus fines y objetivos.")}&nbsp;</span>
                                </p>

                                <h2><br/>{t("Consejo de Administración")}</h2>
                                
                                <p className="MsoNormal positionImageAsembly" >
                                    <span lang="EN-GB">{t("La Junta Directiva está compuesta por un equipo de profesionales con amplia experiencia en el sector. El mandato del Presidente de la Asociación es de 4 años. Entre las numerosas responsabilidades de la Junta Directiva se encuentran dirigir, gestionar y representar a la organización, supervisar sus operaciones, buscar fuentes de financiación y garantizar el cumplimiento de las resoluciones de la Asamblea General.")}</span>
                                </p>

                                <p>&nbsp;</p>
                                
                                <p>
                                    <span className="textStylesGov">&nbsp;</span>
                                </p>

                                <table align="center">
                                    <tbody>
                                        <tr>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/Enrique byn.jpg" alt="" width="135" height="203"/></td>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/David byn.jpg"   alt="" width="135" height="203"/></td>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/Ventura byn.jpg" alt="" width="135" height="202"/></td>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/Pablo byn.jpg"   alt="" width="135" height="203"/></td>
                                        </tr>

                                        <tr>
                                            <td className="alignCenter">{t("Presidente")}<br/>Enrique Alcántara<br/>Stay Unique</td>
                                            <td className="alignCenter">{t("Vicepresidente")}<br/>David Riba<br/>Habitat Apartments</td>
                                            <td className="alignCenter">{t("Vicepresidente")}<br/>Bonaventura Durall<br/>Durlet Apartments</td>
                                            <td className="alignCenter">{t("Tesorero")}<br/>Pablo Zubicaray<br/>Ola Living</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>&nbsp;</p>

                                <table className="h284" width="546" align="center">
                                    <tbody>
                                        <tr>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/2G8A9857 copia.jpg" alt="" width="134" height="201"/></td>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/Jaume byn.jpg"      alt="" width="135" height="203"/></td>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/Lluis byn.jpg"      alt="" width="135" height="202"/></td>
                                            <td><img src="https://www.apartur.com/images/web/propietarios/Ezequiel byn.jpg"   alt="" width="135" height="203"/></td>
                                        </tr>
                                        
                                        <tr>
                                            <td className="alignCenter">{t("Secretaria")}<br/>Judith Gómez de la Lastra<br/>Apartur</td>
                                            <td className="alignCenter">{t("Vocal")}<br/>Jaume Barberena<br/>Bizflats</td>
                                            <td className="alignCenter">{t("Vocal")}<br/>Lluís Caldentey<br/>Aparteasy</td>
                                            <td className="alignCenter">{t("Vocal")}<br/>Ezequiel Vallejo<br/>Lodging Apartments</td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <hr className="no_line m10"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default GovernmentOrgans;