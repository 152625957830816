import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();

    return (
        <div className="section mcb-section T60B80">
            <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one valign-top clearfix">
                    <div className="mcb-wrap-inner">
                        <div className="column_attr clearfix align_center p08">
                            <h6 className="landing4-heading grey positionContainer">{t("SERVICIOS")}</h6>
                            <hr className="no_line margin15Auto"/>
                        </div>

                        <div className="column mcb-column one column_column">
                            <div className="column_attr clearfix">
                                <p className="textJustify">
                                    <span lang="EN-GB">{t("Uno de los activos más importantes de Apartur son sus servicios personalizados para los asociados. Este apoyo ayuda a fomentar las oportunidades y el potencial de la diversidad entre nuestros miembros, permitiéndoles fortalecer sus negocios, enriquecer el sector y garantizar experiencias excepcionales a todos sus clientes.")}</span>
                                </p>

                                <p className="MsoNormal ideographJustifyWhite" >
                                    <span lang="EN-GB">{t("Otro aspecto de la Asociación que sabemos que aporta valor añadido a nuestros miembros es nuestra gama de servicios profesionales directamente relacionados con el funcionamiento de las empresas para mejorar la gestión, el flujo de información y el desarrollo empresarial.")}</span>
                                </p>

                                <p className="textJustify">
                                    <strong>{t("Directorio de servicios ofrecidos:")}</strong>
                                </p>
                                
                                
                                <ul>
                                    <li>{t("Información actualizada sobre el sector")}</li>
                                    <li>{t("Asesoramiento jurídico personalizado")}&nbsp;</li>
                                    <li>{t("Acceso a acuerdos beneficiosos entre la Asociación y las instituciones")}</li>
                                    <li>{t("Acuerdos exclusivos con empresas y proveedores del sector")}</li>
                                    <li>{t("Seguro específico para el colectivo")}</li>
                                    <li>{t("Estadísticas y previsiones mensuales de ocupación")}</li>
                                    <li>{t("Daily press clippin with industry news")}</li>
                                    <li>{t("Recortes de prensa diarios sobre noticias del sector")}</li>
                                    <li><span lang="EN-GB">{t('Acceso al chat "Foro')}&nbsp;{t('Apartur')}</span></li>
                                    <li>{t("Bolsa de trabajo")}</li>
                                    <li>{t("Acceso a códigos éticos, manual de buenas prácticas y sellos de calidad y excelencia")}&nbsp;</li>
                                    <li>{t("Cursos de formación específicos e innovadores")}</li>
                                </ul>
                                
                                <hr className="no_line m10"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services;