import React from "react";
import { useTranslation } from 'react-i18next';

const TextosCookies = ({ textocookies }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container-fluid w-100 padlados100" style={{ marginTop: "300px" }}>
                <h1 className="titulopagina">{t("Política de cookies")}</h1>
                <hr />
            </div>
            <div className="container-fluid w-100 padlados100">
                <div className="mt-5" dangerouslySetInnerHTML={{ __html: textocookies.ladesc }}></div>
            </div>
        </>
    );
};
export default TextosCookies;
