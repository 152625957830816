import Cabecera from './Compartido/Cabecera.jsx';
import Footer from './Compartido/Footer.jsx';
import DatosReserva from './Reserva/DatosReserva.jsx';
import '../../../../../resources/css/web/reysabio/styles.css';

const Reserva = (props) => {
    return (
        <main>
            <Cabecera
                lang={props.lang}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
                config={props.websgrupo}
            />

            <DatosReserva
                propiedad={props.propiedad}
                precios={props.precios}
                llegada={props.llegada}
                salida={props.salida}
                difdias={props.difdias}
                adultos={props.adultos}
                ninos={props.ninos}
                bebes={props.bebes}
                lang={props.lang}
                ruta={props.ruta}
                config={props.config}
                tpv={props.tpvs}
                paypal={props.paypal}
                transferencia={props.transferencia}
                stripe={props.stripe}
                moneda={props.moneda}
                numpropiedades={props.numpropiedades}
                websgrupo={props.websgrupo}
                paises={props.paises}
            />

            <Footer
                lang={props.lang}
            />
        </main>
    );
}

export default Reserva;

