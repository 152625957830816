import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const GridApartamentos = ({ lang, propiedades, ruta, adultos, ninos, bebes, llegada, salida }) => {

    const { t } = useTranslation();
    let preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <div className="container-fluid grid-apartamentos">
            {
                propiedades.map((unapropiedad, index) => (
                    <div className="card-grid-apartamentos d-flex" key={index}>
                        <div>
                            <a href={"/" + preidioma + t("apartamentos") + "-" + unapropiedad.destino.url_dinamica[lang] + "/" + unapropiedad.url_dinamica}>
                                <h3 className="noto">{unapropiedad.nombre}</h3>
                            </a>
                            <p>
                                {unapropiedad.metros_cuadrados} m2<br />
                                {unapropiedad.num_habitaciones} {t("Dormitorios")}<br />
                                {unapropiedad.num_banyos} {t("Baños")}<br />

                                {unapropiedad.id != 114
                                    ?
                                    <>
                                        {t("Salón-Comedor")}<br />
                                        1 {t("Sofá Cama")} <br />
                                    </>
                                    :
                                    ""
                                }

                                {unapropiedad.capacidad_maxima} {t("Adultos")}<br />
                                {t("Cocina Equipada")}<br />
                                {
                                    [123, 124, 125].includes(unapropiedad.id) ?
                                        <>
                                            {t('Terraza Privada')}<br />
                                        </>
                                        : ""
                                }
                                {
                                    [123, 124].includes(unapropiedad.id) ?
                                        <>
                                            {t('Piscina privada')}<br />
                                        </>
                                        : ""
                                }
                                {
                                    [121, 122].includes(unapropiedad.id) ?
                                        <>
                                            {t('Balcón')}<br />
                                        </>
                                        : ""
                                }
                            </p>
                            {
                                llegada && unapropiedad.precio > 0 ?
                                    <>
                                        <p style={{ marginBottom: "10px" }}>
                                            <span className="price">{(unapropiedad.precio / 100).toFixed(2)}€</span>
                                        </p>
                                        <center>
                                            <form action={"/" + t("reserva")} method="get" target="_blank">
                                                <input type="hidden" name="idpropiedad" value={unapropiedad.id} />
                                                <input type="hidden" name="daterange" value={llegada.replace("-", "/") + " - " + salida.replace("-", "/")} />
                                                <input type="hidden" name="adultos" value={adultos} />
                                                <input type="hidden" name="ninos" value={ninos} />
                                                <input type="hidden" name="bebes" value={bebes} />
                                                <button className="elementor-button elementor-size-sm noto btn-buscar2" type="submit"> {t("RESERVAR")}</button>
                                            </form>
                                        </center>
                                    </>
                                    : ""
                            }
                        </div>
                        {
                            unapropiedad.foto_principal.length != 0 ?
                                <a href={"/" + preidioma + t("apartamentos") + "-" + unapropiedad.destino.url_dinamica[lang] + "/" + unapropiedad.url_dinamica} style={{ backgroundImage: "url('" + ruta + "/propiedades/" + unapropiedad.id + "/fotos/web/" + unapropiedad.foto_principal[0].nombre + "')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "500px" }}>
                                </a>
                                :
                                <a href={"/" + preidioma + t("apartamentos") + "-" + unapropiedad.destino.url_dinamica[lang] + "/" + unapropiedad.url_dinamica} style={{ backgroundImage: "url('/images/web/bedloop-nofoto.png')", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "500px" }}>
                                </a>
                        }
                    </div>
                ))
            }
        </div>
    );
};
export default GridApartamentos;
