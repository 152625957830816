import React, { useState } from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';
import axios from 'axios';

const Newsletter = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = "/enviamailchimp";

        var mensajeerror = "";

        if (email === "") {
            mensajeerror += `${t('Email')}<br/>`;
            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensajeerror,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            let elmail = email;

            let token = document.getElementById('meta_token').getAttribute('content');
            const result = axios.post(url, {
                email: elmail
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    console.log(response);
                    if (response.data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Dirección no enviada')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Dirección enviada')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }


    }

    return (
        <section className="container pt80 mb80">
            <div
                className="row cta-box cta-negative js-scroll-show"
                style={{ opacity: 1, transform: "matrix(1, 0, 0, 1, 0, 0)" }}
            >
                <div className="col-12 col-lg-6">
                    <h2 className="title title--h2">
                        {t("Suscríbete a nuestro newsletter sin compromiso.")}
                    </h2>
                    <p className="paragraph">
                        {t("Sé el primero en enterarte de las novedades, ofertas y curiosidades.")}
                    </p>
                </div>
                <div className="col-12 col-lg-6 text-lg-right">
                    <form id="form-mailchimp" style={{ width: "100%", display: "flex" }} onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="email-mailchimp"
                            className="email-input"
                            placeholder={t("Escribe tu correo aquí")} style={{ width: "60%", outline: "none" }}
                            value={email} onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            className="btn btn--white black-text"
                            style={{
                                backgroundColor: "#fff",
                                boxShadow: "0px 16px 48px rgba(201, 169, 106, 0.16)",
                                color: "#fff",
                                fontWeight: "600",
                                display: "inline-flex",
                                border: "0",
                                borderRadius: "5rem",
                                cursor: "pointer",
                                msFlexAlign: "center",
                                alignItems: "center",
                                msFlexWrap: "nowrap",
                                flexWrap: "nowrap",
                                justifyContent: "center",
                                lineHeight: 0,
                                height: "3rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                padding: "0 2rem!important",
                                position: "relative",
                                textAlign: "center",
                                zIndex: 1,
                                transition: "box-shadow .4s ease-in-out",
                                fontSize: "1rem"
                            }}
                            type="submit"
                            value={t("Subscribirse")}
                        />
                    </form>
                </div>
            </div>
        </section>


    );
};
export default Newsletter;
