import { React } from "react";

import { useTranslation } from 'react-i18next';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import "./../../../../../css/magno/fichablog.css";

const TextoNoticia = ({noticia, populares, ruta, lang}) => {
    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    var imagen = "/images/web/bedloop-nofoto.png";

    if(noticia.imagen)
    {
        imagen = ruta+"/blog/"+noticia.id+"/"+noticia.imagen;
    }

    let lateral = [];

    populares.forEach((popular, index) => {
        lateral = lateral.concat(
            <article className={'elementor-post elementor-grid-item post-'+popular.id+" post type-post status-publish format-standard has-post-thumbnail hentry category-blog"} key={index} style={{ marginBottom: "4em"}}>
                <a className="elementor-post__thumbnail__link lateralnoticia" href={"/"+preidioma+t("blog")+popular.laurl}>
                    <div className="elementor-post__thumbnail">
                        <img decoding="async" width="212" height="131" src={ruta + "/blog/" + popular.id + "/" + popular.imagen}  className="attachment-medium size-medium wp-image-9971 fotolateral" alt="" loading="lazy"/>
                    </div>
                </a>
                <div className="elementor-post__text" style={{ paddingLeft: "0",  textAlign: "center", maxWidth: "80%",  marginLeft: "auto", marginRight: "auto" }}>
                    <h5 className="elementor-post__title" style={{ marginBottom: "13px", fontWeight: "300",fontSize: "18px" }}>
                        <a href={"/"+preidioma+t("blog")+"/"+popular.laurl} style={{ fontFamily: "Lato, sans-serif" , textAlign: "center"}}>{popular.eltitulo}</a>
                    </h5>
                </div>
            </article>
        )
    })

    const results = [];
    return (
        <div className="container">
            <div className="row nd_options_section nd_options_box_sizing_border_box nd_options_padding_15">
                <div className="col-md-7 esquerrablog">
                    <h1 className="" style={{ color:"#1c1c1c", fontFamily: "'Lato', sans-serif",fontSize: "30px",   lineHeight: "30px", margin: "0px", padding: "0px", margin: "50px 0 40px 0" }}>
                        {noticia.eltitulo}
                    </h1>
                    <div className="nd_options_section nd_options_height_20"></div>

                    <div data-elementor-type="wp-post" data-elementor-id="6459" className="elementor elementor-6459">
                        <section className="elementor-section elementor-top-section elementor-element elementor-element-bff7457 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default" data-id="bff7457" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cd19e98" data-id="cd19e98" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div className="elementor-widget-wrap"></div>
                                </div>
                            </div>
                        </section>

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-7a7a5886 elementor-section-stretched elementor-section-boxed elementor-section-height-default" data-id="7a7a5886" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;,&quot;background_background&quot;:&quot;classic&quot;}">
                            <div className="elementor-background-overlay"></div>
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-792a9483" data-id="792a9483" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-1ce6fa35 elementor-widget elementor-widget-text-editor" data-id="1ce6fa35" data-element_type="widget" data-widget_type="text-editor.default" style={{marginBottom:"20px" }}>
                                            <div className="elementor-widget-container">
                                                <p style={{ fontFamily: "'Roboto', sans-serif", paddingTop:"0px", color: "#5c5c5c", fontSize: "14px", lineHeight: "27px"  }}>{ noticia.elsubtitulo}</p>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-5477d44 elementor-arrows-position-inside elementor-widget elementor-widget-image-carousel" data-id="5477d44" data-element_type="widget" data-settings="{&quot;slides_to_show&quot;:&quot;1&quot;,&quot;navigation&quot;:&quot;arrows&quot;,&quot;autoplay&quot;:&quot;yes&quot;,&quot;pause_on_hover&quot;:&quot;yes&quot;,&quot;pause_on_interaction&quot;:&quot;yes&quot;,&quot;autoplay_speed&quot;:5000,&quot;infinite&quot;:&quot;yes&quot;,&quot;effect&quot;:&quot;slide&quot;,&quot;speed&quot;:500}" data-widget_type="image-carousel.default" style={{ marginBottom:"20px" }}>
                                            <div className="elementor-widget-container">
                                                <div className="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                                                    <div className="elementor-image-carousel swiper-wrapper">
                                                        <div className="swiper-slide">
                                                            <figure className="swiper-slide-inner">
                                                                <img decoding="async" className="swiper-slide-image img-fluid" src={imagen} alt="001" style={{ maxwidth: "90%" }}/>
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-2c59d77f elementor-widget elementor-widget-text-editor" data-id="2c59d77f" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="mb-6 elementor-widget-container" style={{ margin: "5px 0px 0px 0px", color: "#5C5C5C", fontFamily: "'Roboto', Sans-serif", fontSize: "14px", fontWeight: "300", lineHeight: "2em", letterSpacing: "1px" }}>
                                                { ReactHtmlParser(noticia.ladesclarga) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="col-md-4" style={{ paddingTop: "60px", textAlign:"center"}}>
                    { lateral }
                </div>
            </div>
        </div>
    );
}

export default TextoNoticia;
























