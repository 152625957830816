import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Scripts = () => {

    const jqueryScript = "https://code.jquery.com/jquery-3.4.1.min.js";

    const scriptSources = [
        "https://cdnjs.cloudflare.com/ajax/libs/sticky-kit/1.1.3/sticky-kit.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/gsap/1.20.2/TweenMax.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/ScrollMagic.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/plugins/animation.gsap.js",
        "https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/plugins/debug.addIndicators.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/plugins/animation.velocity.min.js",
        "/vendor/hhc/plugins.js",
        "https://cdnjs.cloudflare.com/ajax/libs/Swiper/5.1.0/js/swiper.min.js",
        "https://unpkg.com/imagesloaded@4.1.4/imagesloaded.pkgd.min.js",
        "https://unpkg.com/masonry-layout@4.2.2/dist/masonry.pkgd.min.js",
        "https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js",
        "https://cdn.jsdelivr.net/npm/jarallax@1.12.0/dist/jarallax.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/object-fit-images/3.2.4/ofi.min.js",
        "https://cdnjs.cloudflare.com/ajax/libs/1000hz-bootstrap-validator/0.11.9/validator.min.js",
        "/vendor/hhc/common.js",
        "https://cdn.jsdelivr.net/npm/gsap@3.12/dist/gsap.min.js"
    ];

    useEffect(() => {
        const jqueryScriptElement = document.createElement("script");
        jqueryScriptElement.src = jqueryScript;
        document.body.appendChild(jqueryScriptElement);

        jqueryScriptElement.onload = () => {
            const scripts = scriptSources.map((src) => {
                const script = document.createElement("script");
                script.src = src;
                document.body.appendChild(script);
                return script;
            });

            return () => {
                scripts.forEach((script) => document.body.removeChild(script));
            };
        }
    }, []);

    return null;
};


export default Scripts;
