
import Menu from './Compartido/Menu.jsx';
import Cabecera from './Inicio/Cabecera.jsx';
import Destacados from './Inicio/Destacados.jsx';
import ServiciosPremium from './Inicio/ServiciosPremium.jsx';
import Conocenos from './Inicio/Conocenos.jsx';
import Experiencias from './Inicio/Experiencias.jsx';
import Blog from './Inicio/Blog.jsx';
import Investments from './Inicio/Investments.jsx';
import Footer from './Compartido/Footer.jsx';

const Inicio = (props) => {
    return (
        <main className='bg-white'>
            <Menu
                idiomas={props.idiomas}
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                ruta={props.ruta}
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
            />
            <Cabecera
                lang={props.lang}
                config={props.config}
                websgrupo={props.websgrupo}
                elementosweb={props.elementosweb}
            />
            <Destacados
                lang={props.lang}
                config={props.config}
                elementosweb={props.elementosweb}
                ruta={props.ruta}
                destacadas={props.destacadasmagno}
            />
            <ServiciosPremium
                lang={props.lang}
                config={props.config}
                ruta={props.ruta}
                servicios={props.ventajasMagno}
            />
            <Conocenos
                lang={props.lang}
                config={props.config}
                websgrupo={props.elementosweb}
                sobrenosotros={props.textosobrenosotros}
            />
            <Experiencias
                lang={props.lang}
                config={props.config}
                websgrupo={props.elementosweb}
                textoclientes={props.textoclientes}
            />
            <Blog
                lang={props.lang}
                config={props.config}
                websgrupo={props.websgrupo}
                noticias={props.noticias}
                ruta={props.ruta}
            />
            <Investments
                lang={props.lang}
                config={props.config}
                websgrupo={props.websgrupo}
                textopropiedades={props.textopropiedades}
            />
            <Footer
                lang={props.lang}
                config={props.websgrupo}
                config2={props.config}
                idiomas={props.idiomas}
                ruta={props.ruta}
            />

        </main>
    );
}

export default Inicio;
