import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Servicios = ({ lang }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section className="container section nuestros-servicios">
            <div className="row align-items-end">
                <div className="col-12 col-md-12 col-lg-8">
                    <span className="title title--overhead js-lines">
                        {t("Nuestros servicios para ti")}
                    </span>
                    <h1 className="title title--h1 js-lines">{t("Lo que te espera")}.</h1>
                </div>
            </div>
            <div className="row mb-20">
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/house.png" />
                    <h4 className="mb-20">{t("Villas con encanto")}</h4>
                </div>
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/atencion.png" />
                    <h4 className="mb-20">{t("Trato personalizado")}</h4>
                </div>
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/bbq.png" />
                    <h4 className="mb-20">{t("Barbacoa")}</h4>
                </div>
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/wifi.png" />
                    <h4 className="mb-20">{t("WiFi")}</h4>
                </div>
            </div>
            <div className="row mb-20">
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/ninos.png" />
                    <h4 className="mb-20">{t("Niños bienvenidos")}</h4>
                </div>
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/animales.png" />
                    <h4 className="mb-20">{t("Mascotas bienvenidas")}</h4>
                </div>
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/piscinas.png" />
                    <h4 className="mb-20">{t("Piscinas")}</h4>
                </div>
                <div className="col-6 col-lg-3">
                    <img src="/images/web/hhc/recomendaciones.png" />
                    <h4 className="mb-20">{t("Experiencia local")}</h4>
                </div>
            </div>
        </section>

    );
};
export default Servicios;
