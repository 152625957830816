import React from "react";
import Options from "../Compartido/options";
import NoticiasPortada from "./noticiasportada";
import FormularioPortada from "./FormularioPortada";
import { useTranslation } from 'react-i18next';

const Content = ({ noticias, ruta, preidioma, lang }) => {

    const { t } = useTranslation();

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma={preidioma}
                        />

                        <div className="sectionBg section mcb-section animate zoomIn" data-anim-type="zoomIn" >
                            <div className="section_wrapper mcb-section-inner">
                                <div className="clearfix wrap mcb-wrap one valign-top">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix column_attr align_center">
                                                <a href={"/" + preidioma + t("servicios")} className="button button_size_2 button_theme button_js">
                                                    <span className="button_label">{t("Servicios")}</span>
                                                </a>
                                                <h2>{t("Descubre los beneficios de formar parte de Apartur")}</h2>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_blog_teaser">
                                            <div className="destacats" style={{ padding: "0px" }}>
                                                <ul className="teaser-wrapper">
                                                    <li className="post format-standard has-post-thumbnail hentry category-news">
                                                        <div className="photo-wrapper scale-with-grid">
                                                            <img src="/images/apartur/events.jpg" className="scale-with-grid wp-post-image" alt="" />
                                                        </div>

                                                        <div className="desc-wrapper">
                                                            <div className="desc">
                                                                <div className="post-title">
                                                                    <h4 className="blanc">{t("Asesoramiento legal y personalizado")}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="post format-standard has-post-thumbnail hentry category-news">
                                                        <div className="photo-wrapper scale-with-grid">
                                                            <img src="/images/apartur/assessorament-legal.jpg" className="scale-with-grid wp-post-image" alt="" />
                                                        </div>

                                                        <div className="desc-wrapper">
                                                            <div className="desc">
                                                                <div className="post-title">
                                                                    <h4 className="blanc">{t("Convenios exclusivos con empresas del sector")}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li className="post format-standard has-post-thumbnail hentry category-news">
                                                        <div className="photo-wrapper scale-with-grid">
                                                            <img src="/images/apartur/convenis-exclusius.jpg" className="scale-with-grid wp-post-image" alt="" />
                                                        </div>

                                                        <div className="desc-wrapper">
                                                            <div className="desc">
                                                                <div className="post-title">
                                                                    <h4 className="blanc">{t("Representación institucional")}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section mcb-section animate fadeInUpLarge P120T80" data-anim-type="fadeInUpLarge" >
                            <div className="section_wrapper mcb-section-inner">
                                <div className="clearfix wrap mcb-wrap one valign-top">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix column_attr align_center darckPadding">
                                                <a href={"/" + preidioma + t("mision_vision")} className="button button_size_2 button_theme button_js">
                                                    <span className="button_label">{t("LA ASOCIACIÓN")}</span>
                                                </a>
                                                <h2>{t("Aspectos importantes de Apartur")}</h2>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line m20" />
                                        </div>
                                    </div>
                                </div>

                                <div className="clearfix column_attr p10">
                                    <div className="clearfix wrap mcb-wrap one-second valign-top">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr mbcaracteristiques arrowOrange" >
                                                    <h3>{t("Representación de los asociados en las organizaciones")}</h3>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr mbcaracteristiques arrowOrange">
                                                    <h3>{t("Cohesión del sector y lucha contra el intrusismo")}</h3>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr mbcaracteristiques arrowOrange">
                                                    <h3>{t("Protección y defensa de los intereses de los asociados")}</h3>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="clearfix wrap mcb-wrap one-second valign-top">
                                        <div className="mcb-wrap-inner">
                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr mbcaracteristiques arrowOrange">
                                                    <h3>{t("Creación de sinergias entre los asociacos")}</h3>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr mbcaracteristiques arrowOrange">
                                                    <h3>{t("Promoción de todos nuesros asociados")}</h3>
                                                </div>
                                            </div>

                                            <div className="column mcb-column one column_column">
                                                <div className="clearfix column_attr mbcaracteristiques arrowOrange">
                                                    <h3>{t("Coordinación con las entirdades del sector")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section mcb-section equal-height-wrap full-width animate zoomIn" data-anim-type="zoomIn">
                            <div className="section_wrapper mcb-section-inner">
                                <div className="clearfix bg-cover wrap mcb-wrap one-second valign-top bgPic1" >
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line m500" />
                                        </div>
                                    </div>
                                </div>

                                <div className="clearfix customBackgroundContainer wrap mcb-wrap one-second valign-top" >
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_image">
                                            <div className="image_frame image_item no_link scale-with-grid no_border mTop30">
                                                <div className="image_wrapper">
                                                    <img className="scale-with-grid" src="/images/apartur/apartur-icon1.png" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column mcb-column one column_divider">
                                            <hr className="no_line m70" />
                                        </div>

                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix mt-5 column_attr">
                                                <h6 className="themecolor">{t("Club Colaborador")}</h6>
                                                <h3 className="black">{t("Apartur cuenta con un Club Colaborador formado por empresas profesionales del sector.")}</h3>
                                                <hr className="no_line m10" />
                                                <p className="black">{t("Trabajamos con empresas de diferentes sectores de interés para la gestión de las viviendas y apartamentos turísticos, con el fin de velar la calidad y profesionalidad del sector. Ellos es uno de los pilares más importantes de la asociación.")}</p>
                                                <hr className="no_line m50" />
                                                <a className="button button_size_2 button_theme button_js" href={"/" + preidioma + t("club-colaborador")}>
                                                    <span className="button_label">{t("Más informacion")}</span>
                                                </a>
                                                <a className="button button_size_2 button_js color" href={"/" + preidioma + t("contacto")}>
                                                    <span className="button_label">{t("Contacto")}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section mcb-section animate fadeInRightLarge p50CentredContainer" data-anim-type="fadeInRightLarge" >
                            <div className="section_wrapper mcb-section-inner">
                                <div className="clearfix wrap mcb-wrap one valign-top">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one-sixth column_placeholder">
                                            <div className="placeholder"></div>
                                        </div>

                                        <div className="column mcb-column two-third column_column">
                                            <div className="clearfix column_attr align_center">
                                                <h6 className="cityhall-heading2">{t("Objetivos")}</h6>
                                                <h2>APARTUR</h2>
                                                <hr className="no_line m10" />
                                                {t("Desde APARTUR queremos consolidar esta actividad, mejorando la calidad de los servicios de los apartamentos turísticos y asegurando su integración y coordinación con el resto del sector turístico y los poderes públicos.")}
                                                <hr className="no_line m20" />
                                                <a className="button button_size_2 button_js color" href={"/" + preidioma + t("mision_vision")} >
                                                    <span className="button_label">{t("Más informacion")}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <NoticiasPortada
                            noticias={noticias}
                            ruta={ruta}
                            preidioma={preidioma}
                        />

                        <FormularioPortada
                            lang={lang}
                            preidioma={preidioma}
                        />

                        <div className="section" id="contentlink flv_sections_16 lightBg">
                            <div className="clearfix section_wrapper">
                                <div className="clearfix items_group">

                                    <div className="column one column_column pTop30">
                                        <div className="column_attr">
                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="cityhall-heading">{t("Información")} </h6>
                                                <h2>{t("Censo alojamientos")}</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="column one column_column pBottom30">
                                        <div className="column_attr">
                                            <div className="aligncenter">
                                                <a className="content_link pc25ombra" href="http://w121.bcn.cat/APPS/censactivitats/cceatDef.do?reqCode=search" target="_blank" style={{ textDecoration: "none" }}>
                                                    <span className="icon">
                                                        <i className="fa-solid fa-house"></i>
                                                    </span>
                                                    <span className="title negre">{t("Censo alojamientos turísticos Ayuntamiento Barcelona")}</span>
                                                </a>
                                                <a className="content_link pc25ombra" href="https://empresa.gencat.cat/ca/treb_ambits_actuacio/turisme/professionals_turisme/emo_empreses_establiments_turistics/registre-turisme-catalunya/llistat-establiments/" target="_blank" style={{ textDecoration: "none" }}>
                                                    <span className="icon">
                                                        <i className="fa-solid fa-house"></i>
                                                    </span>
                                                    <span className="title negre">{t("Censo alojamientos turísticos Catalunya")}</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section T50B30">
                            <div className="clearfix section_wrapper">
                                <div className="column one column_column aligncenter">
                                    <div className="clearfix column_attr mobile_align_center">
                                        <h2>{t("Redes sociales")}</h2>
                                        <h3>{t("Mira todo lo que compartimos y síguenos")}</h3>
                                    </div>
                                </div>

                                <div className="column one column_column aligncenter">
                                    <div className="clearfix column_attr mobile_align_center">
                                        <a href="https://www.facebook.com" target="_blank" className="icon_bar icon_bar_facebook icon_bar_small">
                                            <span className="t">
                                                <i className="fa-brands fa-facebook"></i>
                                            </span>

                                            <span className="b">
                                                <i className="fa-brands fa-facebook"></i>
                                            </span>
                                        </a>
                                        <a href="https://twitter.com/apartur" target="_blank" className="icon_bar icon_bar_twitter icon_bar_small">
                                            <span className="t">
                                                <i className="fa-brands fa-twitter"></i>
                                            </span>

                                            <span className="b">
                                                <i className="fa-brands fa-twitter"></i>
                                            </span>
                                        </a>

                                        <a href="https://www.instragram.com" target="_blank" className="icon_bar icon_bar_instagram icon_bar_small">
                                            <span className="t">
                                                <i className="fa-brands fa-instagram"></i>
                                            </span>

                                            <span className="b">
                                                <i className="fa-brands fa-instagram"></i>
                                            </span>
                                        </a>

                                        <a href="https://www.linkedin.com/company/apartur" target="_blank" className="icon_bar icon_bar_linkedin icon_bar_small">
                                            <span className="t">
                                                <i className="fa-brands fa-linkedin"></i>
                                            </span>

                                            <span className="b">
                                                <i className="fa-brands fa-linkedin"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
