import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Slider = ({ lang, destinos, d_ale, ruta }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    const [imagenActual, setImagenActual] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setImagenActual((prevImagenActual) => {
                return (prevImagenActual + 1) % destinos[d_ale].imagenes.length;
            });
        }, 5000);
        return () => clearInterval(intervalId);
    }, [destinos[d_ale].imagenes]);

    var imagenfondo = "";
    imagenfondo = ruta + '/destinos/' + destinos[d_ale].id + '/' + destinos[d_ale].imagenes[imagenActual].imagen;

    return (
        <>
            <div className="p-0 m-0 container-fluid box-slider">
                <div className="slider-text">
                    <div className="text-center slider-content w-100">
                        <h1 className="white-text">{t("Descubre Tenerife")}</h1>
                        <p className="white-text">{t("Y sorpréndete con una estancia inolvidable")}</p>
                        <a href={preidioma + t("sobre-nosotros")} className="black-text btn btn__large btn-slider">{t("Más información")}</a>
                    </div>
                </div>
                <div className="slider position-absolute overlay" style={{ backgroundImage: "url('" + imagenfondo + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover", top: "0", height: "100%", width: "100%" }}>
                </div>
            </div>


            {/*<section className="intro-slider ltr section-rounding">
                <div className="container intro__container">
                    <div className="text-center row h-100 align-items-center">
                        <div className="col-12 intro-slider__caption swiper-container">
                            <div className="swiper-wrapper">

                                <div className='swiper-slide'>
                                    <h1 className='title js-text-wave'>{t("Descubre Tenerife")}</h1>
                                    <p className='description down-up'>
                                        <span>{t("Y sorpréndete con una estancia inolvidable")}</span>
                                        <span>
                                            <a className='btn btn__large btn--white black-text mt40' href={preidioma + t("sobre-nosotros")}>{t("Más información")}</a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="intro-slider__image swiper-container overlay">
                    <div className="swiper-wrapper">

                        <div className='swiper-slide'>
                            <div className='coverSlider js-parallax js-image' data-image={imagenfondo}></div>
                        </div>
                    </div>
                </div>


                <div className="container slider-navigation">
                    <div className="slider-prev icon-chevron-left"></div>
                    <div className="slider-next icon-chevron-right"></div>
                </div>

            </section>*/}
        </>
    );
};
export default Slider;
