import React from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';

const Content = ({preidioma, personas, ruta}) => {

    const { t } = useTranslation();

    let laspersonas = [];

    return (
        <div id="Content">
            <div className="content_wrapper clearfix">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma = {preidioma}
                        />

                        <div className="section mcb-section no-margin-h" style={{ paddingTop:"60px", paddingBottom:"80px",backgroundColor:"#ededed"}}>
                            <div className="section_wrapper mcb-section-inner">
                                <div className="wrap mcb-wrap one valign-top clearfix">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one-sixth column_placeholder">
                                            <div className="placeholder"></div>
                                        </div>
                                        <div className="column mcb-column two-third column_column">
                                            <div className="column_attr clearfix align_center">
                                                <h6 className="cityhall-heading">{ t("Quiénes somos en Apartur") }</h6>
                                                <div className="column_attr clearfix align_center" style={{padding: "0 8%"}}>
                                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "3px solid  #E87C0C", display: "inline-block" , borderColor: "#E87C0C" }}>{ t("NUESTRO EQUIPO") }</h6>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    personas.map((persona, index) => (
                                        <div className="wrap mcb-wrap one-fourth valign-top clearfix"  key={index}>
                                            <div className="mcb-wrap-inner">
                                                <div className="column mcb-column one column_image">
                                                    <div className="image_frame image_item no_link scale-with-grid no_border">
                                                        <div className="image_wrapper border-radius foto-equip">
                                                            <img className="scale-with-grid" src={ ruta+"/quienesSomos/"+persona.id+"/"+persona.imagen } width="135" height="203"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column mcb-column one column_column column-margin-20px">
                                                    <div className="column_attr clearfix">   
                                                        <h3 className="centratexto equip">{ persona.nombre}</h3>
                                                        <div dangerouslySetInnerHTML={{__html: persona.ladesc}}></div>                     
                                                    </div>
                                                </div>
                                                <div className="column mcb-column one column_image">
                                                    <div className="image_frame image_item scale-with-grid no_border hover-disable">
                                                        <div className="image_wrapper">
                                                            <a href="#">
                                                                <div className="mask"></div></a>
                                                            <div className="image_links">
                                                                <a href="#" className="link"><i className="icon-link"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;