import Header from './Compartido/Header.jsx';
import Footer from './Compartido/Footer.jsx';
import Content from './Faqs/Content.jsx';
import BtnFloating from './Compartido/BtnFloating.jsx';
import Scripts from './Compartido/Scripts.jsx';

import '../../../../../resources/css/web/holidayhomescanarias/header.css'
import '../../../../../resources/css/web/holidayhomescanarias/general.css';
import '../../../../../resources/css/web/holidayhomescanarias/footer.css'
import '../../../../../resources/css/web/holidayhomescanarias/intro.css'
import '../../../../../resources/css/web/holidayhomescanarias/buttons.css'
import '../../../../../resources/css/web/holidayhomescanarias/faqses.css'

const Faqs = (props) => {
    return (
        <main>
            <Header
                datos={props.websgrupo}
                lang={props.lang}
                idiomas={props.idiomas}
                tipo="faqs"
                meta_titulo={props.meta_titulo}
                meta_descripcion={props.meta_descripcion}
                meta_keywords={props.meta_keywords}
                ruta={props.ruta}
            />
            <Content
                lang={props.lang}
                categorias={props.categoriasfaq}
                faqs={props.faqs}
            />
            <Footer
                lang={props.lang}
                config={props.config}
            />
            <BtnFloating />

            <Scripts />
        </main>
    );
}

export default Faqs;

