import { React, useState } from "react";
import { Modal, Button, Dropdown, Carousel } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ImageGallery from "react-image-gallery";

const ImagePopupGallery = ({ imageSrc, urlprop, gallery }) => {

    const { t } = useTranslation();

    const [showI, setShowI] = useState(false);
    const [imagenActual, setImagenActual] = useState(null);
    const [zoom, setZoom] = useState(1);

    const buttonStyle = { background: "none", border: "none" };
    const zoomButtonStyle = { ...buttonStyle, fontSize: 24 };

    const handleZoom = () => {
        setZoom(zoom + (zoom > 1 ? -1 : 1));
    };

    return (
        <>
            <img
                src={imageSrc}
                className="d-block w-100"
                style={{ maxHeight: "440px", cursor: "pointer" }}
                onClick={() => setShowI(true)}
            />
            <Modal
                show={showI}
                onHide={() => setShowI(false)}
                dialogClassName="modalpropietat modal-fullscreen"
            >
                <Modal.Header>
                    <Button
                        id="zoom"
                        variant="secondary"
                        style={zoomButtonStyle}
                        onClick={handleZoom}
                        aria-label={zoom > 1 ? "Zoom out" : "Zoom in"}
                    >
                        {zoom > 1 ? "-" : "+"}
                    </Button>

                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={buttonStyle}>
                            <i className='fa-solid fa-share'></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                href={"https://www.facebook.com/sharer/sharer.php?u=" + urlprop + "%2F&t=Alojamiento"}
                                target="_blank"
                            >
                                <i className="fa-brands fa-facebook"></i> {t("Compartir en") + " Facebook"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={"https://twitter.com/intent/tweet?text=" + urlprop}
                                target="_blank"
                            >
                                <i className="fa-brands fa-twitter"></i> {t("Compartir en") + " Twitter"}
                            </Dropdown.Item>
                            <Dropdown.Item
                                href={"https://www.pinterest.com/pin/create/button/?url=&media=" + imageSrc}
                                target="_blank"
                            >
                                <i className="fa-brands fa-pinterest"></i> {t("Compartir en") + " Pinterest"}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="secondary" onClick={() => setShowI(false)} style={buttonStyle}>
                        <i className="fa-solid fa-xmark"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
                    <ImageGallery
                        items={gallery}
                        showNav={true}
                        showThumbnails={false}
                        lazyLoad={true}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        additionalClass="background_black"
                        onSlide={(slide) => setImagenActual(slide)}
                    />
                    {imagenActual && (
                        <img
                            src={imagenActual.original}
                            style={{
                                transform: `scale(${zoom})`,
                                transition: "transform 0.3s ease-in-out",
                            }}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ImagePopupGallery;
