import React from 'react';
//import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/inertia-react';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import spanishContent from "./Pages/Traducciones/lang/es.json";
import englishContent from "./Pages/Traducciones/lang/en.json";
import frenchContent from "./Pages/Traducciones/lang/fr.json";
import italianContent from "./Pages/Traducciones/lang/it.json";
import portuguesContent from "./Pages/Traducciones/lang/pt.json";
import russianContent from "./Pages/Traducciones/lang/ru.json";
import deutchContent from "./Pages/Traducciones/lang/de.json";
import swedishContent from "./Pages/Traducciones/lang/sv.json";
import catalanContent from "./Pages/Traducciones/lang/ca.json";

i18n.use(initReactI18next).init({
  resources: {
      es: spanishContent,
      en: englishContent,
      fr: frenchContent,
      it: italianContent,
      pt: portuguesContent,
      ru: russianContent,
      de: deutchContent,
      sv: swedishContent,
      ca: catalanContent,
  },

  fallbackLng: "es",

  keySeparator: false,

  interpolation: {
      escapeValue: false,
  },
});


createInertiaApp({
  resolve: name => require(`./Pages/${name}.jsx`),
  setup({ el, App, props }) {
    const root = createRoot(el); // createRoot(container!) if you use TypeScript
    root.render(<App {...props} />);
    //render(<App {...props} />, el);
  },
})
