import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { useTranslation } from 'react-i18next';

const PanelInferior = ({ lang, capacidad_maxima }) => {

    const { t } = useTranslation();

    registerLocale('es', es);

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    const numpersonas = useRef(null);

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [show, setShow] = useState('none');

    const handleShow = () => {
        if (show === 'none') setShow('block');
        else setShow('none');
    };

    const handleClose = () => {
        handleEtiqueta(countAdults, countNinos, countBebes, countMascotas);
    };

    {/* ******************* */ }

    const [countAdults, setCountAdults] = useState(0);
    const [countNinos, setCountNinos] = useState(0);
    const [countBebes, setCountBebes] = useState(0);
    const [countMascotas, setCountMascotas] = useState(0);

    const handleSumaAdultos = () => {
        if (countAdults + countNinos < capacidad_maxima) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(countAdults + 1, countNinos, countBebes, countMascotas);
        }
    }

    const handleRestaAdultos = () => {
        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(countAdults - 1, countNinos, countBebes, countMascotas);
        }
    }

    const handleSumaNinos = () => {

        if (countNinos + countAdults < capacidad_maxima) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(countAdults, countNinos + 1, countBebes, countMascotas);
        }
    }

    const handleRestaNinos = () => {

        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(countAdults, countNinos - 1, countBebes, countMascotas);
        }
    }

    const handleSumaBebes = () => {

        if (countBebes < capacidad_maxima) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(countAdults, countNinos, countBebes + 1, countMascotas);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(countAdults, countNinos, countBebes - 1, countMascotas);
        }
    }

    const handleSumaMascotas = () => {

        if (countMascotas < capacidad_maxima) {
            setCountMascotas(cm => cm + 1);
            handleEtiqueta(countAdults, countNinos, countBebes, countMascotas + 1);
        }
    }

    const handleRestaMascotas = () => {
        if (countMascotas > 0) {
            setCountMascotas(cm => cm - 1);
            handleEtiqueta(countAdults, countNinos, countBebes, countMascotas - 1);
        }
    }

    const handleEtiqueta = (adults, ninos, bebes, mascotas) => {

        let frase = t("Personas");
        if (adults > 0) frase = " " + (adults + ninos + bebes + mascotas) + " " + t("Huéspedes");

        numpersonas.current.textContent = frase;

    }

    const handleBorrar = () => {
        setCountAdults(1);
        setCountNinos(0);
        setCountBebes(0);
        setCountMascotas(0);

    }


    return (
        <div className="bottom-panel booking-panel" style={{
            zIndex: "10"
        }}>
            <form
                className="bottom-panel__wrap d-flex"
                method="get"
                id="busqueda0"
                action={"/" + preidioma + t("buscar")}
            >

                <div className="row bottom-panel__form-wrap" style={{ width: "50%" }}>
                    <div className="form-group col-date-to col-12 col-sm-12 col-md-7 slash">
                        <label className="labelFeature" htmlFor="fechas">
                            {t("Check in") + " " + t("Check out")}
                        </label>
                        <DatePicker
                            isClearable={true}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            className="inputFeature input-arrow readonly js-datepicker flatpickr-input w250"
                            autoComplete="off"
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            monthsShown={2}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText={t("Entrada - Salida")}
                            locale={lang}
                            name="daterange"
                            id="fechas"
                        >
                        </DatePicker>
                    </div>
                    <div className="form-group col-12 col-md-5 dropdown">
                        <div
                            className="closeDropdown"
                            id="dropdownPersonsAction"
                            data-toggle="dropdown"
                            data-display="static"
                        >
                            <label className="labelFeature" htmlFor="person-total">
                                {t("Personas")}
                            </label>
                            <div onClick={handleShow} className="inputFeature input-arrow readonly" id="person-total" ref={numpersonas}>{t("N. De Personas")}</div>
                        </div>
                        {/* Dropdown person */}
                        <div
                            className="dropdown-menu dropdown-menu-lg-left dropdown-menu-right"
                            id="dropdownPersons"
                            aria-labelledby="dropdownPersonsAction"
                            style={{
                                boxShadow: "rgba(44, 63, 88, 0.1) 4px -48px 64px",
                                display: show,
                                width: "350px"
                            }}
                            onBlur={handleShow}
                            tabIndex="0"
                            onMouseLeave={handleShow}
                        >
                            <div className="row">
                                <div className="form-group col-6 col__left">
                                    <label className="label" htmlFor="person-adult" style={{ marginLeft: "17px" }}>
                                        {t("Adultos")} ({t("Años 12+")})
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleRestaAdultos} >
                                            <span>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <input
                                            type="number"
                                            className="inputText js-quantity-input col-4"
                                            id="person-adult"
                                            name="adultos"
                                            min={0}
                                            max={capacidad_maxima}
                                            readOnly="readonly"
                                            value={countAdults}
                                            style={{ border: "0" }}
                                        />
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleSumaAdultos}>
                                            <span>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group col-6 col__right">
                                    <label className="label" htmlFor="person-kids" style={{ marginLeft: "17px" }}>
                                        {t("Niños")} ({t("2 - 12 años")})
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleRestaNinos}>
                                            <span >
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <input
                                            type="number"
                                            className="inputText js-quantity-input col-4"
                                            id="person-kids"
                                            name="ninos"
                                            min={0}
                                            max={capacidad_maxima}
                                            readOnly="readonly"
                                            value={countNinos}
                                            style={{ border: "0" }}
                                        />
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleSumaNinos}>
                                            <span >
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6 col__left">
                                    <label className="label" htmlFor="person-baby" style={{ marginLeft: "17px" }}>
                                        {t("Bebés")} ({t("0 - 2 años")})
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleRestaBebes}>
                                            <span>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <input
                                            type="number"
                                            className="inputText js-quantity-input col-4"
                                            id="person-baby"
                                            name="bebes"
                                            min={0}
                                            max={capacidad_maxima}
                                            readOnly="readonly"
                                            value={countBebes}
                                            style={{ border: "0" }}
                                        />
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleSumaBebes}>
                                            <span >
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group col-6 col__right">
                                    <label className="label" htmlFor="person-mascot" style={{ marginLeft: "17px" }}>
                                        {t("Mascotas")}
                                    </label>
                                    <div className="js-quantity row">
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleRestaMascotas}>
                                            <span>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginLeft: "auto", background: "rgba(114, 128, 157, 0.1)" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                            </span>
                                        </span>
                                        <input
                                            type="number"
                                            className="inputText js-quantity-input col-4"
                                            id="person-mascot"
                                            name="mascotas"
                                            min={0}
                                            max={capacidad_maxima}
                                            readOnly="readonly"
                                            value={countMascotas}
                                            style={{ border: "0" }}
                                        />
                                        <span className="input-group-btn col-4" style={{ paddingTop: "10px", cursor: "pointer" }} onMouseDown={handleSumaMascotas}>
                                            <span>
                                                <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor", marginRight: "auto", background: "rgba(114, 128, 157, 0.1)" }} >
                                                    <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-footer">
                                <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn__small btn__second btn-reset-persons"
                                        onClick={handleBorrar}
                                        style={{ marginLeft: "18px", width: "78%" }}
                                    >
                                        {t("Vaciar")}
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        type="button"
                                        className="btn btn__small btn-close-dropdown black-text"
                                        onClick={handleClose}
                                        style={{ marginLeft: "18px", width: "78%" }}
                                    >
                                        {t("Añadir")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* /Dropdown person */}
                    </div>
                </div>
                <button
                    type="submit"
                    className="btn-booking black-text"
                    style={{ minWidth: 100 }}
                >
                    <img
                        width={30}
                        src="/images/web/hhc/magnifier.png"
                    />
                </button>
            </form>
        </div >

    );
};
export default PanelInferior;
