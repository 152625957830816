import {React } from "react";
import {Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const TextosMarca = ({eltexto, ruta, lang}) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (eltexto.imagen)
	{
		imagen = ruta+"/marcas/"+eltexto.id+"/imagen/"+eltexto.imagen;
	}

    return (
        <Container>
            <div className="row">
                <div className="col-md-12">
                    <div className="imatge-banner" title={ eltexto.nombre } style={{  backgroundImage: "url('"+imagen+"')" , backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundSize: "cover"} }></div>

                    <h1 className="noticia-individual mb-5">{ eltexto.nombre }</h1>
                    <div className="noticia-individual mt-5 mb-5">
                        <div className="noticia-individual-descripcio-llarga" dangerouslySetInnerHTML={{__html: eltexto.texto[lang]}}></div>
                    </div>
                </div>
            </div>
        </Container>
    );
}


export default TextosMarca;
