import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import CustomAlert from "../Compartido/CustomAlert";
import axios from 'axios';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import "./../../../../../css/magno/propietarios.css";
import "./../../../../../css/magno/elementor.css";
import "./../../../../../css/magno/gform.css";

const TextosPropietarios = ({ propietarios, lang }) => {

    const { t } = useTranslation();

    const [alert, setAlert] = useState({ show: false, type: '', message: '' });

    const enviaContactoPropietario = (e) => {
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/enviarcontactopropietario', {
            yourname: e.target.input_1.value,
            Phone: e.target.input_2.value,
            contactEmail: e.target.input_3.value,
            tipoPropiedad: e.target.input_9.value,
            numeroPropiedades: e.target.input_6.value,
            ubicacion: e.target.input_7.value

        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                if (response.data.type == 'success') {
                    setAlert({ show: true, type: 'ok', message: t("Contacto enviado. En breve nos pondremos en contacto con Ud.") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);

                    location.reload();
                }
                else {
                    setAlert({ show: true, type: 'ko', message: t("Contacto no enviado") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);
                }
            });
    }

    const handleCloseAlert = () => {
        setAlert({ show: false, type: '', message: '' });
    };

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    let pos1 = propietarios.ladesc.indexOf("<p>*</p>");
    let pos2 = propietarios.ladesc.indexOf("<p>+</p>");
    let texto1 = propietarios.ladesc.substr(0, pos1)
    let texto2 = propietarios.ladesc.substr(pos1 + 8, pos2 - (pos1 + 8));
    let texto3 = propietarios.ladesc.substr(pos2 + 8);



    return (
        <div>
            <div className="">
                <div id="post-14595">
                    <div data-elementor-type="wp-page" data-elementor-id="14595" className="elementor elementor-14595">
                        <section id="imagenpropietarios">
                            <div style={{ width: "100%", overflow: "hidden" }} className="fondopropietarios">
                                <div className="imagenpropietarios" style={{ backgroundImage: 'url(/images/web/magno/ser-propietario-nuevo.jpg)', backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", transformOrigin: "center" }}
                                ></div>
                            </div>
                            <div className="container caixa-props" style={{ maxWidth: "960px" }}>
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-456ec61" data-id="456ec61" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-cef10ba elementor-section-content-middle elementor-section-boxed elementor-section-height-default" data-id="cef10ba" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-abb8f4a" data-id="abb8f4a" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-475425c pp-gravity-form-button-custom pp-gravity-form-pagination-buttons-auto elementor-widget elementor-widget-pp-gravity-forms" data-id="475425c" data-element_type="widget" data-widget_type="pp-gravity-forms.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="pp-contact-form pp-gravity-form">
                                                                    <div className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework gf_stylespro_wrapper sp_inherit_wrapper gf_footer_spread_wrapper" data-form-theme="gravity-theme" data-form-index="0" id="gform_wrapper_1">
                                                                        <div id="gf_1" className="gform_anchor" tabIndex="-1"></div>
                                                                        <div className="gform_heading">
                                                                            <p className="gform_description"></p>
                                                                        </div>

                                                                        <form method="post" id="gform_1" className="positionForm sp_inherit gf_footer_spread" action="#" autoComplete="off" style={{ marginBottom: "5%" }} onSubmit={enviaContactoPropietario}>
                                                                            <div className="gform-body gform_body">
                                                                                <div id="gform_fields_1" className="formFilelsPosition top_label form_sublabel_below description_below" style={{ padding: "25px", width: "130%", marginLeft: "-15%" }}>
                                                                                    <div id="field_1_11" className="gfield gfield--type-html gfield--width-full TITULAR gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_1_11" style={{ textAlign: "center" }}>
                                                                                        <span className="TITULAR textStyle" style={{ fontSize: "20px" }}>{t('Gestionamos tu inmueble de manera profesional, garantizando la máxima rentabilidad, seguridad y transparencia')}</span>
                                                                                    </div>
                                                                                    <br></br>
                                                                                    <div id="field_1_12" className="gfield gfield--type-html gfield--width-full TITULAR gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_1_12">
                                                                                        <span className="textStyle TITULAR" style={{ fontSize: "14px", marginBottom: 0 }}>{t('Facilítanos tus datos en este formulario y te informaremos')}</span>
                                                                                    </div>
                                                                                    <br />
                                                                                    <div className="row" style={{ marginBottom: 0 }}>
                                                                                        <div id="field_1_1" className="col-md-12">
                                                                                            <div className="ginput_container ginput_container_text">
                                                                                                <input name="input_1" id="input_1_1" type="text" defaultValue="" className="large" placeholder={t('Nombre').toUpperCase() + "*"} aria-required="true" aria-invalid="false" required style={{ marginBottom: "10px", color: "initial" }} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div id="field_1_2" className="col-md-6">
                                                                                            <div className="ginput_container ginput_container_phone">
                                                                                                <input name="input_2" id="input_1_2" type="text" defaultValue="" className="large" placeholder={t("Teléfono").toUpperCase() + "*"} aria-required="true" aria-invalid="false" required style={{ marginBottom: "10px", color: "initial" }} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div id="field_1_3" className="col-md-6">
                                                                                            <div className="ginput_container ginput_container_email">
                                                                                                <input name="input_3" id="input_1_3" type="text" defaultValue="" className="large" placeholder={t('Email').toUpperCase() + "*"} aria-required="true" aria-invalid="false" required style={{ marginBottom: "10px", color: "initial" }} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div id="field_1_9" className="col-md-6">
                                                                                            <div className="ginput_container ginput_container_select">
                                                                                                <select name="input_9" id="input_1_9" className="large gfield_select" aria-invalid="false" style={{ marginBottom: "10px", color: "initial", padding: "10px", backgroundColor: "#fff", border: "none", fontFamily: 'Lato', paddingBottom: "15px" }}>
                                                                                                    <option defaultValue="" className="gf_placeholder">{t('TIPO DE PROPIEDAD')}</option>
                                                                                                    <option defaultValue={t('VIVIENDA HABITUAL')}>{t('VIVIENDA HABITUAL')}</option>
                                                                                                    <option defaultValue={t('EDIFICIO COMPLETO')}>{t('EDIFICIO COMPLETO')}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div id="field_1_6" className="col-md-6">
                                                                                            <div className="ginput_container ginput_container_number">
                                                                                                <input name="input_6" id="input_1_6" type="text" defaultValue="" className="large" placeholder={t('NÚMERO DE APARTAMENTOS') + "*"} aria-required="true" aria-invalid="false" required style={{ marginBottom: "10px", color: "initial" }} />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div id="field_1_7" className="col-md-12">
                                                                                            <div className="ginput_container ginput_container_text">
                                                                                                <input name="input_7" id="input_1_7" type="text" defaultValue="" className="large" placeholder={t('UBICACIÓN DE LA PROPIEDAD') + "*"} aria-required="true" aria-invalid="false" required style={{ marginBottom: "10px", color: "initial" }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <fieldset id="field_1_8" className="gfield gfield--type-consent gfield--type-choice gfield--input-type-consent gfield--width-full field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_1_8">
                                                                                        <legend className="gfield_label gform-field-label screen-reader-text gfield_label_before_complex"></legend>
                                                                                        <div className="ginput_container ginput_container_consent" style={{ marginBottom: 0 }}>
                                                                                            <input name="input_8.1" id="input_1_8_1" type="checkbox" defaultValue="1" aria-invalid="false" required className="privacity" style={{ width: "auto" }} />
                                                                                            <label className="gform-field-label gform-field-label--type-inline gfield_consent_label" htmlFor="input_1_8_1">{t('Estoy de acuerdo con la')} <a href={"/" + preidioma + t('legal')} style={{ color: "white" }}>{t('Política de privacidad') + " " + t("y") + " " + t('Política de Cookies')}</a></label>
                                                                                        </div>
                                                                                    </fieldset>
                                                                                    <br></br>
                                                                                    <div id="field_1_10" style={{ marginBottom: 0 }} className="gfield gfield--type-html gfield--width-full form_contacta gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_1_10">{t('Responsable: Magno Apartments. · Finalidad: Solicitud de contacto. · Legitimación: Consentimiento del interesado. · Destinatarios: No se ceden o comunican datos a terceros para prestar este servicio. · Derechos: A acceder rectificar y suprimir los datos.')}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="gform_footer top_label" style={{ textAlign: "center", paddingBottom: "15px" }}>
                                                                                <input type="submit" id="gform_submit_button_1" className="submitButton button bgcolorcorporativo" value={t('Enviar')} style={{ textTransform: "uppercase" }} />
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section style={{ textAlign: "center", backgroundColor: "#F4F4F4", paddingTop: "20px", paddingBottom: "20px", marginTop: "20px", marginBottom: "20px" }}>
                            <div className="container elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-30fbf26" data-id="30fbf26" data-element_type="column">
                                <div className="elementor-widget-wrap elementor-element-populated" style={{ width: "84%", marginLeft: "auto", marginRight: "auto" }}>
                                    <div className="elementor-element elementor-element-0b4d4da elementor-widget elementor-widget-text-editor" data-id="0b4d4da" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div className="elementor-widget-container2">
                                            <div style={{ fontFamily: "Lato, sans-serif", fontWeight: "300", textAlign: "center", fontSize: "50px", lineHeight: "45px", marginTop: "20px" }}>
                                                <span id="title">{t('Gestión integral')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-element-50ecba1">
                                        {ReactHtmlParser(texto1)}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="container" style={{ width: "84%", marginLeft: "auto", marginRight: "auto" }}>
                            <div>
                                {ReactHtmlParser(texto2)}
                            </div>
                            <div>
                                {ReactHtmlParser(texto3)}
                            </div>
                        </section>

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-5ad158d elementor-section-boxed elementor-section-height-default" data-id="5ad158d" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7706d93" data-id="7706d93" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-cccef1b elementor-widget elementor-widget-spacer" data-id="cccef1b" data-element_type="widget" data-widget_type="spacer.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-spacer">
                                                    <div className="elementor-spacer-inner"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            {alert.show && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: "50"
                    }}>
                        <CustomAlert type={alert.type} message={alert.message} onClose={handleCloseAlert} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default TextosPropietarios;
