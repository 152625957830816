import React from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Content = ({ lang, asociados, ruta }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const handleSearch = () => {
        let palabra = document.getElementById("paraula").value;
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/busca-asociado', {
            palabra: palabra,
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                let respuesta = "";
                let socis = response.data.socis;
                if (socis.length > 0) {
                    for (var i = 0; i < socis.length; i++) {
                        var unsoci = socis[i];
                        if (unsoci['web'] != "") var laweb = unsoci['web'];
                        else var laweb = "#"

                        respuesta += "        <div class='post-item post-item2 isotope-item clearfix author-admin post format-standard has-post-thumbnail hentry' id='elem" + i + "'>";
                        respuesta += "           <a class='nounderline' href='" + laweb + "' target='_blank'>";
                        respuesta += "                <div class='image_frame post-photo-wrapper scale-with-grid image' style='height:100px; background-image: url(\"" + ruta + "/propietarios/" + unsoci['id'] + "/logo/" + unsoci['logo'] + "\");background-repeat: no-repeat; background-position: center; background-size: contain;'>";
                        respuesta += "                    &nbsp;";
                        respuesta += "                </div>";
                        respuesta += "            </a>";
                        respuesta += "            <div class='post-desc-wrapper bg-'>";
                        respuesta += "                <div class='post-desc'>";
                        respuesta += "                    <div class='post-head'></div>";
                        respuesta += "                    <div class='post-title'>";
                        respuesta += "                        <h2 class='entry-title associats'>";
                        respuesta += "                            <a href='" + laweb + "' target='_blank'>" + unsoci['nombre'].toUpperCase() + "</a>";
                        respuesta += "                        </h2>";
                        respuesta += "                    </div>";
                        respuesta += "                    <div class='post-footer post-footer2'>";
                        respuesta += "                        <h5 class='num-socis'>";
                        respuesta += t("Socio") + ":";
                        respuesta += "                            <a href='" + laweb + "'>" + unsoci['direccion'] + "</a>";
                        respuesta += "                        </h5>";
                        respuesta += "                    </div>";
                        respuesta += "                </div>";
                        respuesta += "         </div>"
                        respuesta += "      </div>";
                    }
                }
                document.querySelectorAll(".lm_wrapper")[0].innerHTML = respuesta;

                var alturas = [];
                var highlightedItems = document.querySelectorAll(".post-item");

                highlightedItems.forEach(function () {
                    alturas.push(this.innerHeight());
                });

                var j = 0;
                while (j < alturas.length) {
                    if (alturas[j] > alturas[j + 1] && alturas[j] > alturas[j + 2] && alturas[j] > alturas[j + 3] && alturas[j] > alturas[j + 4]) {
                        document.getElementById("elem" + j).height(alturas[j]);
                        document.getElementById("elem" + (j + 1)).height(alturas[j]);
                        document.getElementById("elem" + (j + 2)).height(alturas[j]);
                        document.getElementById("elem" + (j + 3)).height(alturas[j]);
                        document.getElementById("elem" + (j + 4)).height(alturas[j]);
                    }
                    else if (alturas[j + 1] > alturas[j] && alturas[j + 1] > alturas[j + 2] && alturas[j + 1] > alturas[j + 3] && alturas[j + 1] > alturas[j + 4]) {
                        document.getElementById("#elem" + j).height(alturas[j + 1]);
                        document.getElementById("elem" + (j + 2)).height(alturas[j + 1]);
                        document.getElementById("elem" + (j + 1)).height(alturas[j + 1]);
                        document.getElementById("#elem" + (j + 3)).height(alturas[j + 1]);
                        document.getElementById("elem" + (j + 4)).height(alturas[j + 1]);
                    }
                    else if (alturas[j + 2] > alturas[j] && alturas[j + 2] > alturas[j + 1] && alturas[j + 2] > alturas[j + 3] && alturas[j + 2] > alturas[j + 4]) {
                        document.getElementById("elem" + j).height(alturas[j + 2]);
                        document.getElementById("elem" + (j + 1)).height(alturas[j + 2]);
                        document.getElementById("elem" + (j + 2)).height(alturas[j + 2]);
                        document.getElementById("elem" + (j + 3)).height(alturas[j + 2]);
                        document.getElementById("elem" + (j + 4)).height(alturas[j + 2]);
                    }
                    else if (alturas[j + 3] > alturas[j] && alturas[j + 3] > alturas[j + 1] && alturas[j + 3] > alturas[j + 2] && alturas[j + 4] > alturas[j + 2]) {
                        document.getElementById("elem" + j).height(alturas[j + 3]);
                        document.getElementById("elem" + (j + 1)).height(alturas[j + 3]);
                        document.getElementById("elem" + (j + 2)).height(alturas[j + 3]);
                        document.getElementById("elem" + (j + 3)).height(alturas[j + 3]);
                        document.getElementById("elem" + (j + 4)).height(alturas[j + 3]);
                    }
                    else if (alturas[j + 4] > alturas[j] && alturas[j + 4] > alturas[j + 1] && alturas[j + 4] > alturas[j + 2] && alturas[j + 4] > alturas[j + 2]) {
                        document.getElementById("elem" + j).height(alturas[j + 4]);
                        document.getElementById("elem" + (j + 1)).height(alturas[j + 4]);
                        document.getElementById("elem" + (j + 2)).height(alturas[j + 4]);
                        document.getElementById("elem" + (j + 3)).height(alturas[j + 4]);
                        document.getElementById("elem" + (j + 4)).height(alturas[j + 4]);
                    }
                    else {
                        if (alturas[j + 4]) {
                            document.getElementById("elem" + j).height(alturas[j]);
                            document.getElementById("elem" + (j + 1)).height(alturas[j]);
                            document.getElementById("elem" + (j + 2)).height(alturas[j]);
                            document.getElementById("elem" + (j + 3)).height(alturas[j]);
                            document.getElementById("elem" + (j + 4)).height(alturas[j]);
                        }
                        else if (alturas[j + 3]) {
                            if (alturas[j] > alturas[j + 2]) {
                                document.getElementById("elem" + j).height(alturas[j]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j]);
                                document.getElementById("elem" + (j + 2)).height(alturas[j]);
                                document.getElementById("elem" + (j + 3)).height(alturas[j]);
                            }
                            else if (alturas[j + 1] > alturas[j + 2]) {
                                document.getElementById("elem" + j).height(alturas[j + 1]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j + 1]);
                                document.getElementById("elem" + (j + 2)).height(alturas[j + 1]);
                                document.getElementById("elem" + (j + 3)).height(alturas[j + 1]);
                            }
                            else {
                                document.getElementById("elem" + j).height(alturas[j + 2]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j + 2]);
                                document.getElementById("elem" + (j + 2)).height(alturas[j + 2]);
                                document.getElementById("elem" + (j + 3)).height(alturas[j + 2]);
                            }
                        }
                        else if (alturas[j + 2]) {
                            if (alturas[j] > alturas[j + 2]) {
                                document.getElementById("elem" + j).height(alturas[j]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j]);
                                document.getElementById("elem" + (j + 2)).height(alturas[j]);
                            }
                            else if (alturas[j + 1] > alturas[j + 2]) {
                                document.getElementById("elem" + j).height(alturas[j + 1]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j + 1]);
                                document.getElementById("elem" + (j + 2)).height(alturas[j + 1]);
                            }
                            else {
                                document.getElementById("elem" + j).height(alturas[j + 2]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j + 2]);
                                document.getElementById("elem" + (j + 2)).height(alturas[j + 2]);
                            }
                        }
                        else if (alturas[j + 1]) {
                            if (alturas[j] > alturas[j + 1]) {
                                document.getElementById("elem" + j).height(alturas[j]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j]);
                            }
                            else {
                                document.getElementById("elem" + j).height(alturas[j + 1]);
                                document.getElementById("elem" + (j + 1)).height(alturas[j + 1]);
                            }
                        }
                        else {
                            document.getElementById("elem" + j).height(alturas[j]);
                        }
                    }
                    j = j + 5;
                }
            });
    }

    const pressEnter = (e) => {
        if (e.code === "Enter") {
            e.preventDefault();
            handleSearch();
        }
    }

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma={preidioma}
                        />

                        <div className="section mcb-section no-margin-h" style={{ paddingTop: "60px", paddingBottom: "80px", backgroundColor: "#ededed" }}>
                            <div className="section_wrapper mcb-section-inner">
                                <div className="clearfix wrap mcb-wrap one valign-top">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one-sixth column_placeholder">
                                            <div className="placeholder"></div>
                                        </div>
                                        <div className="column mcb-column two-third column_column">
                                            <div className="clearfix column_attr align_center">
                                                <div className="clearfix column_attr align_center" style={{ padding: "0 8%" }}>
                                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "3px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C" }}>{t("LOS ASOCIADOS")}</h6>
                                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* *********** */}

                                <div className="clearfix section_wrapper">
                                    <div className="clearfix items_group">
                                        <div className="column one column_column">
                                            <div className="column_attr">
                                                <div id="contactWrapper">
                                                    <form id="contactform">
                                                        <div className="column one-third">
                                                            <label className="labelsearch" style={{ width: "100%" }}>
                                                                <input type="text" name="paraula" id="paraula" aria-required="true" aria-invalid="false" placeholder={t("Busca por nombre o número de socio")} onKeyPress={(e) => pressEnter(e)} />
                                                            </label>
                                                        </div>
                                                        <div className="column one-fourth">
                                                            <input type="button" value={t("Buscar")} id="submit_popup" onClick={handleSearch} />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* *********** */}

                                <div className="section">
                                    <div className="clearfix section_wrapper">
                                        <div className="column one column_blog">
                                            <div className="blog_wrapper isotope_wrapper">
                                                <div className="grid posts_group lm_wrapper">

                                                    {
                                                        asociados.map((unasociado, index) => (
                                                            <div className="clearfix post-item post-item2 isotope-item author-admin post format-standard has-post-thumbnail hentry " id={"elem" + index} style={{ height: "254px" }} key={index}>
                                                                <a className="nounderline" href={unasociado.web ? unasociado.web : "#"} target="_blank">
                                                                    {unasociado.logo ?
                                                                        <div className="image_frame post-photo-wrapper scale-with-grid image" style={{ height: "100px", backgroundImage: "url('" + ruta + "/propietarios/" + unasociado.id + "/logo/" + unasociado.logo + "')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}>
                                                                            &nbsp;
                                                                        </div>
                                                                        :
                                                                        <div className="image_frame post-photo-wrapper scale-with-grid image" style={{ height: "100px", backgroundImage: "url('')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "contain" }}>
                                                                            &nbsp;
                                                                        </div>
                                                                    }
                                                                </a>
                                                                <div className="post-desc-wrapper bg-">
                                                                    <div className="post-desc">
                                                                        <div className="post-head"></div>
                                                                        <div className="post-title">
                                                                            <h2 className="entry-title associats">
                                                                                <a href={unasociado.web} target="_blank">{unasociado.nombre.toUpperCase()} </a>
                                                                            </h2>
                                                                        </div>
                                                                        <div className="post-footer post-footer2">
                                                                            <h5 className="num-socis">{t("Socio")}: <a href={unasociado.web} target="_blank">{unasociado.direccion}</a></h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* *********** */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
