import {React} from "react";
import { useTranslation } from 'react-i18next';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


import "./../../../../../css/magno/conocenos.css";


const Experiencias = ({lang, config, websgrupo, textoclientes }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div className="no-padd-mb" style={{ background:"white", paddingBottom: "10px" }}>
            <section className="container no-padd-mb" style={{ marginTop: "27px", marginBottom: "17px", padding: "33px 0px 17px 0px" }}>
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-382398d" data-id="382398d" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-3fe104f elementor-widget elementor-widget-heading" data-id="3fe104f" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                                <div className="elementor-widget-container">
                                    <h3 className="elementor-heading-title elementor-size-default titolexperiencies" style={{ color: "#5C5C5C", fontFamily: "Roboto, Sans-serif", fontSize: "12px", fontWeight: "400", lineHeight: "1em", letterSpacing: "4px", marginBottom:"20px" }}>
                                        { websgrupo.titulo_mapa[lang] }
                                    </h3>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-d320c70 elementor-widget elementor-widget-heading" data-id="d320c70" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                                <div className="elementor-widget-container">
                                    <h3 className="elementor-heading-title elementor-size-medium tamtitulo titolexperiencies2" style={{ color: "#2E2E2E", fontFamily: 'Lato, Sans-serif', fontWeight: "300", lineHeight: "1.2em" , letterSpacing: "1px" }}>
                                        { websgrupo.subtitulo_mapa[lang] }
                                    </h3>
                                </div>
                            </div>

                            <div className="solodesktop elementor-element elementor-element-1e9a156 elementor-widget elementor-widget-heading box-experiencias" data-id="1e9a156" data-element_type="widget" data-widget_type="heading.default">
                                <div className="elementor-widget-container p-experiencias">
                                    { ReactHtmlParser(textoclientes.ladesc) }
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-e46157a elementor-align-center elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="e46157a" data-element_type="widget" data-widget_type="button.default" style={{ textAlign:"center" }}>
                                <div className="elementor-widget-container">
                                    <div className="elementor-button-wrapper">
                                        <a href={"/"+preidioma+t("experiencias-sevilla")} className="elementor-button-link elementor-button elementor-size-lg elementor-animation-shrink" role="button"  style={{ fontFamily: "Roboto, Sans-serif", fontSize: "12px", fontWeight: "normal", lineHeight: "1px", letterSpacing: "2px", fill: "#FFFFFF" , color: "#FFFFFF", borderRadius: "0px 0px 0px 0px", padding: "19px 19px 19px 19px" }}>
                                            <span className="elementor-button-content-wrapper" style={{margin: "0px 10%"}}>
                                                <span className="experiencias m-auto-mb">{ t('RESERVA EXPERIENCIA') }</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Experiencias;
