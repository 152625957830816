import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Content = ({ lang, politicas }) => {

    const { t } = useTranslation();

    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <main className="mt-5 main mb80">
            <section className="container section section-first">
                <div className="row justify-content-center">
                    {/* Content */}
                    <div className="col-12 col-lg-10 col-xl-8">
                        <h1
                            className="title title--h2 js-lines"
                            style={{ overflow: "inherit" }}
                        >
                            <div style={{ overflow: "inherit" }}>
                                <div
                                    className="tl-line"
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        position: "relative",
                                        transform: "matrix(1, 0, 0, 1, 0, 0)",
                                        opacity: 1,
                                        overflow: "inherit"
                                    }}
                                >
                                    {t("Política De Cancelación")}
                                </div>
                            </div>
                        </h1>
                        {politicas.map((unapolitica) => (
                            <div key={unapolitica.id}>
                                <h2>{unapolitica.elnombre}</h2>
                                <h2>
                                    <p class="paragraph paragraph--large" dangerouslySetInnerHTML={{ __html: unapolitica.ladesc }}></p>{" "}
                                </h2>
                            </div>
                        ))}


                        {/* <h2>Estándar</h2>
                        <h2>
                            <p className="paragraph paragraph--large">
                                Los huéspedes pueden cancelar hasta 60 días antes de la llegada y
                                recibir un reembolso del 50% del total de la reserva (precio por
                                noche, gastos de limpieza, gestos de administración). Para obtener
                                un reembolso del 50% del precio por noche, la cancelación la debes
                                realizar 60 días completos antes de la llegada. Si cancelas con
                                menos de 60 días de antelación, no se te reembolsará el importe
                                total de la reserva. Si llegas y decides dejar tu vivienda
                                vacacional antes de tiempo, las noches no disfrutadas no serán
                                reembolsadas. Tampoco se reembolsará la tarifa de limpieza ni la de
                                administración. Las cancelaciones siempre deben notificarse por
                                escrito mediante correo electrónico a hello@holidayhomescanarias.com
                            </p>
                        </h2>
                        <h2>.</h2>
                        <h2>
                            <p className="paragraph paragraph--large">.</p>{" "}
                        </h2>*/}
                    </div>
                </div>
            </section>
        </main>
    );
};
export default Content;
