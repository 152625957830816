import { React, useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Modal } from 'react-bootstrap';
import es from 'date-fns/locale/es';
import axios from 'axios';
import { parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';

const DatosPropiedad = ({ propiedad, servicios, politicas, diasbloqueados, lang, config, extras, llegada, salida, adultos, ninos, bebes, configweb }) => {

    const { t } = useTranslation();

    registerLocale('es', es)

    let results = [];
    let results2 = [];

    let entr = llegada != null ? parseISO(llegada) : null;
    let sali = salida != null ? parseISO(salida) : null;

    const [showprop, setShowprop] = useState('none');
    const handleShowprop = () => {
        if (showprop == "none") setShowprop("block");
        else setShowprop("none");
    }
    const [dateRange, setDateRange] = useState([entr, sali])
    const [startDate, endDate] = dateRange;


    let textopersonas = t("Personas");

    if (adultos > 0 || ninos > 0 || bebes > 0) {
        textopersonas = "";
        if (adultos > 0)
            textopersonas += " " + adultos + " " + t("Adultos");
        if (ninos > 0)
            textopersonas += " " + ninos + " " + t("Niños");
        if (bebes > 0)
            textopersonas += " " + bebes + " " + t("Bebés");
    }

    const numpersonas = useRef(null);

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    let dias = [];

    {
        diasbloqueados.forEach((undia, index) => {
            dias.push(new Date(undia))
        })
    }

    {/* *************** */ }

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const handleSumaAdultos = () => {
        if (countAdults < 10) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(parseInt(countAdults) + 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleRestaAdultos = () => {
        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(parseInt(countAdults) - 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleSumaNinos = () => {
        if (countNinos < 10) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) + 1, parseInt(countBebes));
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) - 1, parseInt(countBebes));
        }

    }

    const handleSumaBebes = () => {
        if (countBebes < 10) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) - 1);
        }
    }

    const handleEtiqueta = (adults, ninos, bebes) => {

        let frase = t("Personas");
        if (adults > 0) frase = " " + adults + " " + t("Adultos");
        if (ninos > 0) frase += " " + ninos + " " + t("Niños");
        if (bebes > 0) frase += " " + bebes + " " + t("Bebés");

        numpersonas.current.textContent = frase;
    }

    {/* *************** */ }

    const [dataRes, setDataRes] = useState("<p></p>");

    const handleSubmit = (e) => {
        e.preventDefault()

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/api/v1/rates', {
            id: propiedad.id,
            range: e.target.daterange.value,
            personas: Number(countAdults) + Number(countNinos),
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                const solucion = response.data;
                if (solucion.Error == "OK") {
                    const precios = solucion.precios[0];

                    let estancia = 0;
                    let eltotal = 0;
                    let noches = 0;
                    for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                        estancia += precios.calendario_sin_otas[j].precio;
                        noches++;
                    }
                    estancia = estancia * 0.01;

                    eltotal = eltotal + estancia;

                    const extras = solucion.extras.relacion_extras;

                    var textoextras = "";

                    for (let j = 0; j < extras.length; j++) {
                        if (extras[j].obligatorio == 1) {
                            textoextras += "  <div class='row mb-3'>" +
                                "<div class='col-8'>" +
                                extras[j].nombre +
                                "</div>" +
                                "<div class='col-4 text-end'>" +
                                extras[j].precio * 0.01 + '€' +
                                "</div>" +
                                "</div>";
                            eltotal = eltotal + (extras[j].precio * 0.01);
                        }

                    }

                    if (propiedad.by_request == 1) {
                        var formreserva =
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            "</div>" +
                            "</div>" +
                            "<div>" +
                            "<a href='/" + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Petición") + "</a>";
                        "</div>"
                    }
                    else {
                        var formreserva =
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +
                            "<div>" +
                            "<a href='/" + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Reservar") + "</a>";
                        "</div>"
                    }

                    setDataRes(
                        formreserva
                    );
                }
                else {
                    setDataRes("<p>" + solucion.Error + "</p>");
                }
            });
    }

    useEffect(() => {
        if (adultos > 0 && entr != null && sali != null) {
            let token = document.getElementById('meta_token').getAttribute('content');

            const fecha = new Date(llegada);
            const fecha2 = new Date(salida);
            const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const llegadaFormateada = fecha.toLocaleDateString('es-ES', opciones);
            const salidaformateada = fecha2.toLocaleDateString('es-ES', opciones);

            const result = axios.post('/api/v1/rates', {
                id: propiedad.id,
                range: llegadaFormateada + " - " + salidaformateada,
                personas: Number(countAdults) + Number(countNinos),
            }, {
                headers: {
                    'content-type': 'text/json',
                    'X-CSRF-TOKEN': token
                }
            })
                .then((response) => {
                    const solucion = response.data;
                    if (solucion.Error == "OK") {
                        const precios = solucion.precios[0];

                        let estancia = 0;
                        let eltotal = 0;
                        let noches = 0;
                        for (let j = 0; j < precios.calendario_sin_otas.length; j++) {
                            estancia += precios.calendario_sin_otas[j].precio;
                            noches++;
                        }
                        estancia = estancia * 0.01;

                        eltotal = eltotal + estancia;

                        const extras = solucion.extras.relacion_extras;

                        var textoextras = "";

                        for (let j = 0; j < extras.length; j++) {
                            if (extras[j].obligatorio == 1) {
                                textoextras += "  <div class='row mb-3'>" +
                                    "<div class='col-8'>" +
                                    extras[j].nombre +
                                    "</div>" +
                                    "<div class='col-4 text-end'>" +
                                    extras[j].precio * 0.01 + '€' +
                                    "</div>" +
                                    "</div>";
                                eltotal = eltotal + (extras[j].precio * 0.01);
                            }

                        }

                        if (propiedad.by_request == 1) {
                            var urlreserva = "<a href='/" + t("peticion") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=1' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Petición") + "</a>";
                        }
                        else {
                            var urlreserva = "<a href='/" + t("reserva") + "?idpropiedad=" + propiedad.id + "&amp;daterange=" + e.target.daterange.value + "&amp;adultos=" + Number(countAdults) + "&amp;ninos=" + Number(countNinos) + "&amp;bebes=" + Number(countBebes) + "&amp;solorequest=0&amp;tarifa=" + precios.id + "' class='btn btn-primary btn-icon mt-2 w100' style='background:" + configweb.color_corporativo_web + "; border: 1px solid " + configweb.color_corporativo_web + "'>" + t("Reservar") + "</a>";
                        }

                        setDataRes(
                            "<div class='row mb-3'>" +
                            "<div class='col-8'>" +
                            noches + " " + t("Noches") + " / " + (Number(countAdults) + Number(countNinos)) + " " + t("Personas") +
                            "</div>" +
                            "<div class='col-4 text-end'>" +
                            estancia.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +
                            textoextras +
                            "<div class='linia mt-3 mb-3'>" +
                            "</div>" +
                            "<div class='row'>" +
                            "<div class='col-8 fw-bolder'>" +
                            t("Total") +
                            "</div>" +
                            "<div class='col-4 text-end fw-bolder'>" +
                            eltotal.toFixed(2) + '€' +
                            "</div>" +
                            "</div>" +
                            urlreserva +
                            "</div>"
                        );
                    }
                    else {
                        setDataRes("<p>" + solucion.Error + "</p>");
                    }
                });
        }
    }
        , [])

    {/* *************** */ }

    {
        servicios.forEach((unservicio, index) => {
            results = results.concat(
                <div className="col-md-4 col-sm-4 col-xs-6 altura30" title={unservicio.elnombre} data-original-title={unservicio.elnombre} key={"s" + index}>
                    <i className="fa fa-check fa-1-5x"></i> <span className="with-icon">{unservicio.elnombre}</span>
                </div>
            )
        })
    }

    {/* *************** */ }

    return (
        <div className="container">
            <div className="row">
                <div className="order-last mb-5 col-md-12 col-lg-12 order-sm-first">
                    <div className="detail-header">
                        <h2>{propiedad.nombre_original} - {propiedad.nombre}</h2>
                        <p className="mb-0">{propiedad.ciudad},  - <a href="#detail-content-sticky-nav-04" className="font13 anchor" data-toggle="tab"><i className="fa-duotone fa-map-marker"></i> {t("Mostrar mapa")}</a></p>
                        <div className="mt-2 linia"></div>
                        <div className="mt-2 row icones-propietat">
                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-home font30"></i>
                                    </div>
                                </center>
                                <br />
                                <span className="dobletam">
                                    {propiedad.metros_cuadrados} M2
                                </span>
                            </div>

                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-bed font30"></i>
                                    </div>
                                </center>
                                <br />

                                <span className="dobletam">
                                    {propiedad.num_habitaciones} {t("Dormitorios")}
                                </span>
                            </div>

                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-users font30"></i>
                                    </div>
                                </center>

                                <br />

                                <span className="dobletam">
                                    {propiedad.capacidad_maxima} {t("Adultos")}
                                </span>
                            </div>

                            <div className="col-md-2 col-6 centradoalineado mb-15">
                                <center>
                                    <div className="iconoprop">
                                        <i className="fa-duotone fa-bath font30"></i>
                                    </div>
                                </center>

                                <br />

                                <span className="dobletam">
                                    {propiedad.num_banyos} {t("Baños")}
                                </span>
                            </div>

                            {propiedad.num_sofas_cama > 0 ?
                                <div className="col-md-2 col-6 centradoalineado mb-15">
                                    <center>
                                        <div className="iconoprop op08">
                                            <i className="fa-duotone fa-couch font30"></i>
                                        </div>
                                    </center>

                                    <br />

                                    <span className="dobletam">
                                        {propiedad.num_sofas_cama} {t("Sofá Cama")}</span>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>

                    <div id="detail-content-sticky-nav-03" className="pt-3">
                        {
                            servicios.length > 0
                                ?
                                <>
                                    <button className="mt-3 mb-3 btn btn-serveis" onClick={() => handleShowModal()}>{t("Muestra los") + " " + servicios.length + " " + t("servicios")}</button>

                                    <Modal
                                        show={showModal} onHide={() => setShowModal(false)}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        size="lg"
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>{t("¿Qué ofrece este espacio?")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="clearfix mb-20 ml-20 row">
                                                {results}
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </>
                                :
                                ""
                        }
                    </div>

                    <div className="mt-4 multiple-sticky hidden-sm hidden-xs stickyminheight caixa-seccions sticky-top">
                        <div className="multiple-sticky-inner">
                            <div className="multiple-sticky-container">
                                <div className="clearfix multiple-sticky-item">
                                    <ul id="multiple-sticky-menu" className="clearfix multiple-sticky-nav">
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-03">{t("Habitaciones & comodidades")}</a>
                                        </li>
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-02">{t("Descripción")}</a>
                                        </li>
                                        <li className="">
                                            <a href="#detail-content-sticky-nav-04">{t("Mapa & vecindario")}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-5 detail-content-for-sticky-menu">
                        <div className="clear"></div>

                        <div id="detail-content-sticky-nav-02" className="mb-5">
                            <div className="mb-40 section-title-3">
                                <h3 style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }} className="p-2">{t("Bienvenidos a") + " " + propiedad.destino.nombre[lang]}</h3>

                            </div>

                            <div id="descriptiontext">
                                <div className="justificar">
                                    {propiedad.ladesc ?
                                        <div dangerouslySetInnerHTML={{ __html: propiedad.ladesc }}></div>

                                        :
                                        null
                                    }
                                    {/* <hr style={{borderTop: '2px solid #0096db'}}/>
                                    <div>
                                        <h3 style={{color: '#0096db'}}>{propiedad.ciudad}</h3>
                                        <div>
                                            {/* text descrp
                                        </div>
                                    </div> */}
                                    <hr style={{ borderTop: '2px solid #0096db' }} />
                                    {/* Horarios de oficina */}
                                    {propiedad.propietarios[0].informacion_adicional_checkin[lang] ?
                                        <div>
                                            <h3 className="p-2 fw-bolder" style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }} ><i className="fa-solid fa-business-time"></i> {t("Horarios de oficina")}</h3>
                                            <div id="horarios" className="paddingtitulos">
                                                <div dangerouslySetInnerHTML={{ __html: propiedad.propietarios[0].informacion_adicional_checkin[lang] }}></div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {
                                        propiedad.hora_checkin && propiedad.hora_checkout ?
                                            <div>
                                                <h5 style={{ color: '#0096db' }} className="fw-bolder"><i className="fa-solid fa-timer"></i> {t("Horas de entrada/salida")}</h5>
                                                <div id="horariotext" className="paddingtitulos">
                                                    <p className="justificar">
                                                        {t("Check-in: a partir de las") + " " + propiedad.hora_checkin + "h"}
                                                        <br />
                                                        {t("Check out: antes de las") + " " + propiedad.hora_checkout + "h."}
                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <h5 style={{ color: '#0096db' }} className="fw-bolder"><i className="fa-solid fa-timer"></i> {t("Horas de entrada/salida")}</h5>
                                                <div id="horariotext" className="paddingtitulos">
                                                    <p className="justificar">
                                                        {t("Check-in: a partir de las") + " " + propiedad.propietarios[0].hora_checkin + "h"}
                                                        <br />
                                                        {t("Check out: antes de las") + " " + propiedad.propietarios[0].hora_checkout + "h."}
                                                    </p>
                                                </div>
                                            </div>
                                    }

                                    < hr style={{ borderTop: '2px solid #0096db' }} />
                                    {
                                        propiedad.propietarios && propiedad.propietarios[0] && propiedad.propietarios[0].notas ?
                                            <>
                                                <h3 style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }} className="p-2">{t("Contactos importantes")}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: propiedad.propietarios[0].notas }}></div>
                                            </>
                                            :
                                            null
                                    }
                                    <hr style={{ borderTop: '2px solid #0096db' }} />
                                    {/* aqui esta el contacte de la oficina */}
                                    {propiedad.propietarios[0].instrucciones_checkin[lang] ?
                                        <>
                                            <h3 className="p-2 mb-3" style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }} >{t("Contactos útiles")}</h3>
                                            <div dangerouslySetInnerHTML={{ __html: propiedad.propietarios[0].instrucciones_checkin[lang] }}></div>
                                        </>
                                        :
                                        null
                                    }
                                </div>
                            </div>

                            {/* <div className="mt-4 linia"></div> */}

                        </div>

                        {/* <div className="mt-4 linia"></div> */}
                        <hr className="" style={{ borderTop: '2px solid #0096db' }} />
                        <div id="detail-content-sticky-nav-04" className="pt-4">
                            <div className="section-title-3">
                                <h3 style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }} className="p-2">{t("Mapa & vecindario")}</h3>
                            </div>

                            <div className="map-and-friends">
                                <MapContainer center={[propiedad.latitud, propiedad.longitud]} zoom={15} scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                    />
                                    <Marker position={[propiedad.latitud, propiedad.longitud]}></Marker>
                                </MapContainer>
                            </div>

                            {
                                politicas.length > 0 || propiedad.atcliente || propiedad.normat || propiedad.hutb || propiedad.descEspacio || propiedad.descZonaCom ?

                                    <div>
                                        <h3 className="p-2 mt-4 mb-4" style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }}>{t("Información")}</h3>

                                        <div className="descripcions-propietat">
                                            {politicas.length > 0 ?
                                                <div>
                                                    <h5 style={{ color: '#0096db' }} className="fw-bolder">{t("Cancelaciones")}</h5>
                                                    {politicas.forEach((unapolitica, index) => {
                                                        results2 = results2.concat(
                                                            <div key={"p" + index}>
                                                                <strong>{unapolitica.elnombre}</strong><br></br>
                                                                <span dangerouslySetInnerHTML={{ __html: unapolitica.ladesc }}></span>
                                                            </div>
                                                        )
                                                    })}
                                                    {results2}
                                                </div>
                                                :
                                                null
                                            }

                                            {
                                                propiedad.atcliente && propiedad.atcliente != "" ?
                                                    <div>
                                                        <h5 style={{ color: '#0096db' }} className="fw-bolder"><i className="fa-solid fa-sensor-on"></i> {t("Alarma")}</h5>
                                                        <div id="alarma" className="paddingtitulos">
                                                            <div dangerouslySetInnerHTML={{ __html: propiedad.atcliente }}></div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                // falta valor als props
                                                propiedad.normat ?
                                                    <div>
                                                        <h5 style={{ color: '#0096db' }} className="fw-bolder"><i className="fa-solid fa-square-parking"></i> {t("Parking")}</h5>
                                                        <div id="parking" className="paddingtitulos">
                                                            <div dangerouslySetInnerHTML={{ __html: propiedad.normat }}></div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {propiedad.hutb ?
                                                <div>
                                                    <h5 style={{ color: '#0096db' }} className="fw-bolder">{t("Número de licencia")}</h5>
                                                    <div id="licence" className="paddingtitulos">
                                                        <p className="justificar ms-5">{propiedad.hutb}</p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {propiedad.descEspacio ?
                                                <div>
                                                    <h5 style={{ color: '#0096db' }} className="fw-bolder">{t("Descripcion Espacio")}</h5>
                                                    <div id="espacio" className="paddingtitulos">
                                                        <p className="justificar ms-5">{propiedad.descEspacio}</p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {propiedad.descZonaCom ?
                                                <div>
                                                    <h5 style={{ color: '#0096db' }} className="fw-bolder">{t("Descripcion Zonas Comunes")}</h5>
                                                    <div id="zonacomun" className="paddingtitulos">
                                                        <p className="justificar ms-5">{propiedad.descZonaCom}</p>
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                            {/* <div className="mt-3 bb"></div> */}
                        </div>
                        <hr className="mt-2 mb-2" style={{ borderTop: '2px solid #0096db' }} />
                        {propiedad.lainfo ?
                            <>
                                <h3 style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }} className="p-2">{t("Particularidades")}</h3>
                                <div dangerouslySetInnerHTML={{ __html: propiedad.lainfo }}></div>
                            </>
                            :
                            null
                        }
                        {propiedad.textras ?
                            <>
                                <div dangerouslySetInnerHTML={{ __html: propiedad.textras }}></div>
                            </>
                            :
                            null
                        }

                        {propiedad.comomov ?
                            <div>
                                <h5 style={{ color: '#0096db' }} className="fw-bolder"><i className="fa-solid fa-trash"></i> {t("Basura")}</h5>
                                <div id="basura" className="paddingtitulos">
                                    <div dangerouslySetInnerHTML={{ __html: propiedad.comomov }}></div>
                                </div>
                            </div>
                            :
                            null
                        }

                        {
                            propiedad.vecind ?
                                <div>
                                    <h5 style={{ color: '#0096db' }} className="fw-bolder"><i className="fa-solid fa-vault"></i> {t("Caja fuerte")}</h5>
                                    <div id="cajafuerte" className="paddingtitulos">
                                        <div dangerouslySetInnerHTML={{ __html: propiedad.vecind }}></div>
                                    </div>
                                </div>
                                :
                                null
                        }


                        {/* recomendaciones */}
                        <hr className="mt-2 mb-2" style={{ borderTop: '2px solid #0096db' }} />
                        {/* sostenibilidadd */}
                        {propiedad.propietarios[0].servicios_especiales_checkin[lang] ?
                            <div>
                                <h3 className="p-2 fw-bolder" style={{ color: '#ffff', borderRadius: '6px', background: '#0096db' }}><i className="fa-solid fa-solar-panel"></i> {t("Sostenibilidad")}</h3>
                                <div id="ecosostenibilidad" className="paddingtitulos">
                                    <div dangerouslySetInnerHTML={{ __html: propiedad.propietarios[0].servicios_especiales_checkin[lang] }}></div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                    <div className="mb-40"></div>
                </div>
            </div>
        </div>
    );
}

export default DatosPropiedad;
