import { React, useState} from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const Content = ({lang, estudios}) => {

    const { t } = useTranslation();

    var preidioma = ""
    if(lang!='es') preidioma = lang+"/";

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">


                        <div className="section" style={{paddingTop:"60px"}}>
                            <div className="clearfix section_wrapper">

                               {/* **** ESTUDIOS ***** */}
                               <div className="sections_group normas">
                                    <div className="section">
                                        <div className="clearfix section_wrapper">
                                            <div className="clearfix mt-20 column_attr align_center">
                                                <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid  #E87C0C", display: "inline-block", borderColor: "#E87C0C"}}>{t("ESTUDIOS")}</h6>
                                                <hr className="no_line" style={{margin: "0 auto 15px auto"}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="entry_content">
                                        <div className="section">
                                            <div className="clearfix section_wrapper">
                                                <div className="column one column_blog">
                                                    {
                                                        estudios.map((unestudio, index) =>(
                                                            <div className="column mcb-column one-third column_column minh-200" style={{borderBottom: "1px solid  #E87C0C"}}  key={index}>
                                                                <h3 className="fs-18 lh-25">
                                                                    <img src="/images/apartur/corporation2-relations-pic2.png" width="30" height="36" className="imgestudios"/>
                                                                    {unestudio.nombre}
                                                                </h3>
                                                                <h6 style={{fontWeight:"400"}}> {new Date(unestudio.created_at).toLocaleDateString()}</h6>
                                                                <div style={{ width:"100%", paddingBottom: "3px", display: "inline-block", marginBottom:"20px", lineHeight:"21px"}} className="fs-13" dangerouslySetInnerHTML={{__html: unestudio.respuesta}}>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ****FIN ESTUDIOS ***** */}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
