import React from 'react';
import Section from './Compartido/section.jsx';
import Footer from './Compartido/footer.jsx'
import Header from './Contacto/Header.jsx';
import Content from './Contacto/Content.jsx';

import "./../../../../css/apartur/rs6.css";
import "./../../../../css/apartur/structure.css";
import "./../../../../css/apartur/global.css";
import "./../../../../css/apartur/sweetaltert2.min.css";
import "./../../../../css/apartur/custom.css";
import './../../../../css/apartur/partners.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./../../../../css/apartur/styles.css";
import "./../../../../css/apartur/apartur.css";

const Contacto = (props) => {
    return ( 
        <body className="page template-slider button-round layout-full-width if-border-hide no-content-padding header-classic header-fw minimalist-header-no sticky-header sticky-tb-color ab-hide subheader-both-left menu-link-color menuo-right menuo-no-borders mobile-tb-hide mobile-side-slide mobile-mini-mr-ll tablet-sticky mobile-sticky scroll" >                    
            <div id="Wrapper">
                <Header
                    lang = {props.lang}
                    ruta = {props.ruta}
                    config= {props.websgrupo}
                    textocontacto = {props.textocontacto}
                    meta_titulo = {props.meta_titulo}
                    meta_descripcion = {props.meta_descripcion} 
                    meta_keywords = {props.meta_keywords}
                />
                <Content
                    lang = {props.lang}
                    contacto = {props.textocontacto}
                />
            </div>
            
            <div className="section pTop75pB30" >
                <Section/>
            </div>

            <footer id="Footer" className="clearfix">
                <Footer 
                    lang = {props.lang}
                />
            </footer>

            <div id="Side_slide" className="right dark" data-width="250">
                <div className="close-wrapper">
                    <a href="#" className="close"><i className="icon-cancel-fine"></i></a>
                </div>
                
                <div className="menu_wrapper"></div>
            </div>

            <div id="body_overlay"></div>
        </body>
    );
}

export default Contacto;

