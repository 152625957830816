
import { React } from "react";

import { useTranslation } from 'react-i18next';

const TextosPersonalizados = ({ paginapersonalizada, ruta }) => {
    const { t } = useTranslation();
    return (
        <div className="container mt-5">
            {paginapersonalizada.logo_pagina ?
                <div className="imatge-banner" title={t("Página")} style={{ backgroundImage: "url('" + ruta + "/paginapersonalizada/" + paginapersonalizada.id + "/" + paginapersonalizada.logo_pagina + "')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover" }} ></div>
                :
                ""
            }
            <h3 className="centra">{paginapersonalizada.eltitulo}</h3>
            <div dangerouslySetInnerHTML={{ __html: paginapersonalizada.eltexto }}></div>
        </div>
    );
}

export default TextosPersonalizados;
