import { right } from "@popperjs/core";
import { root } from "postcss";
import { React } from "react";
import { useTranslation } from 'react-i18next';



const ListaServicios = ({ ruta, servicios, preidioma }) => {
    const { t } = useTranslation();

    let results = [];

    const handleSearchAmenity = (amenity) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        var url = "/" + preidioma + t("busca");

        let div = document.createElement('div');
        div.innerHTML = amenity;
        console.log(div);

        var unfiltro = div.textContent || div.innerText || '';
        console.log(unfiltro);
        var filtros = [];
        filtros.push(unfiltro);

        var form = document.createElement('form');

        form.method = 'GET';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros);
        form.appendChild(input);

        document.body.append(form);

        form.submit();
    }

    {/* ************* */ }

    return (
        <>
            {servicios.forEach((servicio, index) => {
                results = results.concat(
                    <div className="p-0 col-md-3 col-6" data-id="45bf8974" data-element_type="column" key={index}>
                        <div className="elementor-widget-wrap elementor-element-populated" style={{ padding: "10px" }}>
                            <div className="elementor-element elementor-element-4283c6d7 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="4283c6d7" data-element_type="widget" data-widget_type="image-box.default">
                                <div className='elementor-widget-container'>
                                    <div className='elementor-image-box-wrapper' style={{ textAlign: "center" }}>
                                        <figure className='elementor-image-box-img' style={{ marginRight: "auto", marginLeft: "auto", float: "left" }}>
                                            <a className='topropiedadf mayusculascss widgets-box' data-url={"/" + preidioma + t("busca")} data-amenity={servicio.ventaja_texto} style={{ cursor: "pointer" }} onClick={() => handleSearchAmenity(servicio.ventaja_texto)}>
                                                <img decoding='async' width='62' height='62' src={ruta + "/ventajas/" + servicio.idv + "/" + servicio.imagen} className='attachment-full size-full wp-image-16006 bk-tamany-img' alt='' loading='lazy' />
                                                <h6 className='elementor-image-box-title' style={{ marginBottom: "0px", color: "#1C1C1C", fontFamily: "'Gilda Display', Sans-serif", fontSize: "14px", fontWeight: "300", letterSpacing: "1px", stroke: "#000", display: "inline-block", paddingLeft: "20px" }}>
                                                    {servicio.ventaja_nombre.toUpperCase()}
                                                </h6>
                                            </a>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {results}
        </>
    )
};

const ListaServiciosMobile1 = ({ ruta, servicios, preidioma }) => {
    const { t } = useTranslation();

    let results1 = [];

    const handleSearchAmenityM1 = (amenity) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        var url = "/" + preidioma + t("busca");

        let div = document.createElement('div');
        div.innerHTML = amenity;
        console.log(div);

        var unfiltro = div.textContent || div.innerText || '';
        console.log(unfiltro);
        var filtros = [];
        filtros.push(unfiltro);

        var form = document.createElement('form');

        form.method = 'GET';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros);
        form.appendChild(input);

        document.body.append(form);

        form.submit();
    }

    {/* ************* */ }

    return (
        <>
            {servicios.forEach((servicio, index) => {
                if (index < 4) {
                    results1 = results1.concat(
                        <div className="servicio" key={index} style={{ textAlign: "center", margin: "0" }}>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-4a99cf02 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="4a99cf02" data-element_type="widget" data-widget_type="image-box.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-image-box-wrapper">
                                            <figure className="elementor-image-box-img">
                                                <a className='topropiedadf mayusculascss widgets-box' data-url={"/" + preidioma + t("busca")} data-amenity={servicio.ventaja_texto} style={{ cursor: "pointer" }} onClick={() => handleSearchAmenityM1(servicio.ventaja_texto)}>
                                                    <img decoding='async' width='54px' height='54px' src={ruta + "/ventajas/" + servicio.idv + "/" + servicio.imagen} className='attachment-full size-full wp-image-16006 bk-tamany-img-m' alt='' loading='lazy' />
                                                </a>
                                            </figure>
                                            <div className="elementor-image-box-content">
                                                <h5 className="elementor-image-box-title">
                                                    <a className='topropiedadf mayusculacss' data-url={"/" + preidioma + t("buscar")} data-amenity={servicio.ventaja_texto} onClick={() => handleSearchAmenityM1(servicio.ventaja_texto)} style={{ cursor: "pointer", fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "15px", letterSpacing: "1px", lineHeight: "15px", fontWeight: "300" }}> {servicio.ventaja_nombre.toUpperCase()} </a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
            {results1}
        </>
    )
};

const ListaServiciosMobile2 = ({ ruta, servicios, preidioma }) => {
    const { t } = useTranslation();

    let results2 = [];

    const handleSearchAmenityM2 = (amenity) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        var url = "/" + preidioma + t("busca");

        let div = document.createElement('div');
        div.innerHTML = amenity;
        console.log(div);

        var unfiltro = div.textContent || div.innerText || '';
        console.log(unfiltro);
        var filtros = [];
        filtros.push(unfiltro);

        var form = document.createElement('form');

        form.method = 'GET';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros);
        form.appendChild(input);

        document.body.append(form);

        form.submit();
    }

    {/* ************* */ }

    return (
        <>
            {servicios.forEach((servicio, index) => {
                if (index > 3) {
                    results2 = results2.concat(
                        <div className="servicio" key={index} style={{ textAlign: "center", margin: "0" }}>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-4a99cf02 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="4a99cf02" data-element_type="widget" data-widget_type="image-box.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-image-box-wrapper">
                                            <figure className="elementor-image-box-img">
                                                <a className='topropiedadf mayusculascss widgets-box' data-url={"/" + preidioma + t("busca")} data-amenity={servicio.ventaja_texto} style={{ cursor: "pointer" }} onClick={() => handleSearchAmenityM2(servicio.ventaja_texto)}>
                                                    <img decoding='async' width='54px' height='54px' src={ruta + "/ventajas/" + servicio.idv + "/" + servicio.imagen} className='attachment-full size-full wp-image-16006 bk-tamany-img-m' alt='' loading='lazy' />
                                                </a>
                                            </figure>
                                            <div className="elementor-image-box-content">
                                                <h5 className="elementor-image-box-title">
                                                    <a className='topropiedadf mayusculacss' data-url={"/" + preidioma + t("buscar")} data-amenity={servicio.ventaja_texto} onClick={() => handleSearchAmenityM2(servicio.ventaja_texto)} style={{ cursor: "pointer", fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "15px", letterSpacing: "1px", lineHeight: "15px", fontWeight: "300" }}> {servicio.ventaja_nombre.toUpperCase()} </a>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
            {results2}
        </>
    )
};

const ListaServiciosTablet1 = ({ ruta, servicios, preidioma }) => {
    const { t } = useTranslation();

    let results1 = [];

    const handleSearchAmenityT1 = (amenity) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        var url = "/" + preidioma + t("busca");

        let div = document.createElement('div');
        div.innerHTML = amenity;
        console.log(div);

        var unfiltro = div.textContent || div.innerText || '';
        console.log(unfiltro);
        var filtros = [];
        filtros.push(unfiltro);

        var form = document.createElement('form');

        form.method = 'GET';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros);
        form.appendChild(input);

        document.body.append(form);

        form.submit();
    }

    {/* ************* */ }

    return (
        <>
            {servicios.forEach((servicio, index) => {
                if (index < 4) {
                    results1 = results1.concat(
                        <div className="servicio" key={index} style={{ textAlign: "left", margin: "5px 0px", width: '250px' }}>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-4a99cf02 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="4a99cf02" data-element_type="widget" data-widget_type="image-box.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-image-box-wrapper">
                                            <figure className="elementor-image-box-img">
                                                <a className='topropiedadf mayusculascss widgets-box' data-url={"/" + preidioma + t("busca")} data-amenity={servicio.ventaja_texto} style={{ cursor: "pointer" }} onClick={() => handleSearchAmenityT1(servicio.ventaja_texto)}>
                                                    <img decoding='async' width='54px' height='54px' src={ruta + "/ventajas/" + servicio.idv + "/" + servicio.imagen} className='attachment-full size-full wp-image-16006 bk-tamany-img-t' alt='' loading='lazy' />
                                                    <h5 className="elementor-image-box-title topropiedadf mayusculacss" style={{ cursor: "pointer", fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "15px", letterSpacing: "1px", lineHeight: "15px", fontWeight: "300", display: "inline-block", paddingLeft: "10px" }}> {servicio.ventaja_nombre.toUpperCase()} </h5>
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
            {results1}
        </>
    )
};

const ListaServiciosTablet2 = ({ ruta, servicios, preidioma }) => {
    const { t } = useTranslation();

    let results2 = [];

    const handleSearchAmenityT2 = (amenity) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        var url = "/" + preidioma + t("busca");

        let div = document.createElement('div');
        div.innerHTML = amenity;
        console.log(div);

        var unfiltro = div.textContent || div.innerText || '';
        console.log(unfiltro);
        var filtros = [];
        filtros.push(unfiltro);

        var form = document.createElement('form');

        form.method = 'GET';
        form.action = url;

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros);
        form.appendChild(input);

        document.body.append(form);

        form.submit();
    }

    {/* ************* */ }

    return (
        <>
            {servicios.forEach((servicio, index) => {
                if (index > 3) {
                    results2 = results2.concat(
                        <div className="servicio" key={index} style={{ textAlign: "left", margin: "5px 0px", width: '285px' }}>
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-4a99cf02 elementor-position-left elementor-vertical-align-middle elementor-widget elementor-widget-image-box" data-id="4a99cf02" data-element_type="widget" data-widget_type="image-box.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-image-box-wrapper">
                                            <figure className="elementor-image-box-img">
                                                <a className='topropiedadf mayusculascss widgets-box' data-url={"/" + preidioma + t("busca")} data-amenity={servicio.ventaja_texto} style={{ cursor: "pointer" }} onClick={() => handleSearchAmenityT2(servicio.ventaja_texto)}>
                                                    <img decoding='async' width='54px' height='54px' src={ruta + "/ventajas/" + servicio.idv + "/" + servicio.imagen} className='attachment-full size-full wp-image-16006 bk-tamany-img-t' alt='' loading='lazy' />
                                                    <h5 className="elementor-image-box-title topropiedadf mayusculacss" style={{ cursor: "pointer", fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "15px", letterSpacing: "1px", lineHeight: "15px", fontWeight: "300", display: "inline-block", paddingLeft: "10px" }}> {servicio.ventaja_nombre.toUpperCase()} </h5>
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            })}
            {results2}
        </>
    )
};


const ServiciosPremium = ({ lang, config, ruta, servicios }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    return (
        <>
            <section className="container">
                <div className="elementor-container elementor-column-gap-default" style={{ display: "flex", marginRight: "auto", marginLeft: "auto", position: "relative" }}>
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1f3cc358 w100-600" data-id="1f3cc358" data-element_type="column" style={{ marginLeft: "auto", marginRgiht: "auto" }}>
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-119a4a0 elementor-widget elementor-widget-heading" data-id="119a4a0" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                                <div className="elementor-widget-container" style={{ margin: "50px 22px 20px 22px" }}>
                                    <h3 className="elementor-heading-title elementor-size-default" style={{ color: "#5C5C5C", fontFamily: "Roboto, Sans-serif", fontSize: "12px", fontWeight: "400", lineHeight: "1em", letterSpacing: "4px" }}>{t("DESCUBRE LO QUE NOS CARACTERIZA")}</h3>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-1831fd9 elementor-widget elementor-widget-heading" data-id="1831fd9" data-element_type="widget" data-widget_type="heading.default" style={{ textAlign: "center" }}>
                                <div className="elementor-widget-container">
                                    <h2 className="mb-4 elementor-heading-title tituloserpremium elementor-size-default" style={{ color: "#1C1C1C", fontFamily: "'Lato', Sans-serif", fontWeight: "300", stroke: "#000" }}>{t('Servicios premium')}</h2>
                                </div>
                            </div>
                            <div className="solodesktop elementor-element elementor-element-637af38 elementor-widget elementor-widget-text-editor" data-id="637af38" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container">
                                    <p style={{ textAlign: "center", margin: "0px 0px 11px 0px", padding: "0px 150px 0px 150px", fontFamily: "'Roboto', sans-serif", color: "rgb(92,92,92)", fontWeight: "300", fontSize: "14px", lineHeight: "28px" }} >{t('Nuestros alojamientos están diseñados desde el concepto del lujo, cuidando esos detalles exclusivos que hacen que nuestros huéspedes disfruten de una experiencia inolvidable y única.')}<br />{t('Descubre qué apartamentos cuentan con estos servicios premium.')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mt-3 mb-5 solodesktopserprem max-widthServicios" style={{ position: "relative", }}>
                <div className="row">
                    <ListaServicios
                        ruta={ruta}
                        servicios={servicios}
                        preidioma={preidioma}
                    />
                </div>
            </section>

            <section className="container mt-3 mb-5 solomobileserprem justify-600">
                <div className="row w-80-600" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div className="col-6" style={{ padding: "0" }}>
                        <ListaServiciosMobile1
                            ruta={ruta}
                            servicios={servicios}
                            preidioma={preidioma}
                        />
                    </div>
                    <div className="col-6" style={{ padding: "0" }}>
                        <ListaServiciosMobile2
                            ruta={ruta}
                            servicios={servicios}
                            preidioma={preidioma}
                        />
                    </div>
                </div>
            </section>

            <section className="container mt-3 mb-4 solotabletserprem justify-600">
                <div className="row w-80-600" style={{ marginLeft: "auto", marginRight: "auto", paddingLeft: "40px" }}>
                    <div className="col-6 listatablet1-servicios" style={{}}>
                        <ListaServiciosTablet1
                            ruta={ruta}
                            servicios={servicios}
                            preidioma={preidioma}
                        />
                    </div>
                    <div className="col-6 listatablet2-servicios">
                        <ListaServiciosTablet2
                            ruta={ruta}
                            servicios={servicios}
                            preidioma={preidioma}
                        />
                    </div>
                </div>
            </section>

        </>
    );

};
export default ServiciosPremium;
