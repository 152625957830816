import {React } from "react";
import {Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const Equipo = ({ personas, ruta, sobrenosotros }) => {

    const { t } = useTranslation();

    return (
        <>
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                        <div className="section-title-2 alt text-center">
                            <h2>{ t("Equipo") }</h2>
                            <p>{ t("Conozca a los artífices de esta iniciativa") }</p>
                        </div>
                    </div>
                </div>
            
                <div className="destacats">

                    {personas.map((equipo, index) => (
                        <div className="destacats-equip" key={index} >

                            <img className = "destacats-imatge-propietat" src = '/images/web/bedloop-nofoto.png' alt='persona{index} ' title='persona {index}'/>
                            <div className="destacats-text">
                                <h5 className="destacats-titol-propietat">{equipo.nombre}</h5>
                            </div>

                        </div>
                    ))}

                </div>
            </div>
            
        </>
    );
}

const TextosSobreNosotros = ({sobrenosotros, personas, ruta}) => {

    const { t } = useTranslation();

    var imagen = "/images/web/bedloop-nofoto.png"; 

	if (sobrenosotros.imagen_cabecera)
	{
		imagen = ruta+"/"+sobrenosotros.imagen_cabecera;
	}

    return (
        <>
            <div className="container">
				<div className="row">
                    <div className="col-md-12">
                        <div className="imatge-banner" title={ t("Sobre_Nosotros") } style={{  backgroundImage: "url('"+imagen+"')" , backgroundRepeat: 'no-repeat',backgroundPosition: 'center', backgroundSize: "cover"} }></div>

                        <h1 className="noticia-individual mb-5">{ t("Sobre_Nosotros") }</h1>
                        <div className="noticia-individual mt-5 mb-5">
                            <div className="noticia-individual-descripcio-llarga" dangerouslySetInnerHTML={{__html: sobrenosotros.ladesc}}></div>
                        </div>
                    </div>
				</div>
			</div>

            { 
                personas.length>0 ?
                    <div className="container pt-5 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title-2 alt text-center">
                                    <h2>{ t("Equipo") }</h2>
                                    <p>{ t("Conozca a los artífices de esta iniciativa") }</p>
                                </div>
                            </div>
                        </div>
                        <div className="destacats">

                            {personas.map((equipo, index) => (
                                <div className="destacats-equip" key={index} >

                                    <img className = "destacats-imatge-propietat" src = '/images/web/bedloop-nofoto.png' alt='persona{index} ' title='persona {index}'/>
                                    <div className="destacats-text">
                                        <h5 className="destacats-titol-propietat">{equipo.nombre} {equipo.apellidos}</h5>
                                        <p className="destacats-ciutat-propietat">{equipo.apellidos}</p>
                                    </div>

                                </div>
                            ))}

                        </div>
                    </div>
                :
                null
            }
        </>
    );
}


export default TextosSobreNosotros;
