import {useEffect, useState } from 'react';
import ReactFlagsSelect  from 'react-flags-select'; 
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function LangSelector({idiomas, langactual}){
    const { i18n } = useTranslation();
    const[ langu, setLangu ] = useState({langactual});
    
    const { t } = useTranslation();

    let countriesarr=[];
    let customLabelsarr={};

    idiomas.forEach((unidioma,index) => {
        if(unidioma.codigo=="en")
            countriesarr = countriesarr.concat("US");
        else
            countriesarr = countriesarr.concat(unidioma.codigo.toUpperCase());

        if(unidioma.codigo=="en")
            customLabelsarr['US'] =unidioma.nombre;
        else 
            customLabelsarr[unidioma.codigo.toUpperCase()] = unidioma.nombre;
    })

    const getUserBrowserLanguage = (langu) =>{
        let lang ="";
       
        if(langu!="")
            lang = langu.langactual.toLowerCase();
        else
            lang = window.navigator.language;

        if(lang.includes("es")) return "es";
        if(lang.includes("en")) return "en";
        if(lang.includes("fr")) return "fr";
        if(lang.includes("it")) return "it";
        if(lang.includes("pt")) return "pt";
        if(lang.includes("ru")) return "ru";
        if(lang.includes("de")) return "de";
        if(lang.includes("sv")) return "sv";
        if(lang.includes("ca")) return "ca";

        return "es";
    };

    const countryCodeToLangCode = (countryCode) => {
        const country = countryCode.toLowerCase();
        if(country.includes("us")) return "en";
        if(country.includes("es")) return "es";
        if(country.includes("fr")) return "fr";
        if(country.includes("it")) return "it";
        if(country.includes("pt")) return "pt";
        if(country.includes("ru")) return "ru";
        if(country.includes("de")) return "de";
        if(country.includes("sv")) return "sv";
        if(country.includes("ca")) return "ca";

        return "es";
    }

    const handleLangSelect = (countryCode) => {
        const currentLang = countryCodeToLangCode(countryCode);
        i18n.changeLanguage(currentLang);

        setLangu(countryCode);

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: currentLang
        },{
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
        .then((response) => {
            console.log(response.data);
            console.log("cambiado voy a recargar");
            window.location.reload(); 
        })
        .catch((error) => {
            console.log("Error");
            console.log(error);
        });
    }

    useEffect(() => {
        const userBrowserLang = getUserBrowserLanguage(langu);
        
        i18n.changeLanguage(userBrowserLang);
        setLangu(userBrowserLang);
    }, []);

    return (
        <ReactFlagsSelect
            countries={ countriesarr }
            customLabels={ customLabelsarr }
            selected={langu.toString().toUpperCase()}
            onSelect={ handleLangSelect }
            placeholder= {t("Seleccione_Idioma")}
        />
    );
}

export default LangSelector;