import React from "react";
import { Container } from "react-bootstrap";
// import '../../../../../../resources/css/web/reysabio/styles.css';
import { useTranslation } from 'react-i18next';

const Img = () => {

    const { t } = useTranslation();

    return (
        <div className="container-fluid img-box w-100">
            <div style={{ backgroundImage: 'url("/images/web/reysabio/CASA-REYSABIO-52904.jpg")', backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            </div>
        </div>
    );
};
export default Img;
