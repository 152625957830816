import React from "react";
import { Container } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

const Content = ({ lang, clientes }) => {

    const { t } = useTranslation();
    var preidioma = "";
    if (lang != 'es') preidioma = lang + "/";

    return (
        <section id="about" className="container section">
            <div className="row">
                <div className="col-12 col-lg-5">
                    <span
                        className="title title--overhead js-lines"
                        style={{ overflow: "inherit" }}
                    >
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Lo que piensan de nosotros")}
                            </div>
                        </div>
                    </span>
                    <h2 className="title title--h2 js-lines" style={{ overflow: "inherit" }}>
                        <div style={{ overflow: "inherit" }}>
                            <div
                                className="tl-line"
                                style={{
                                    display: "block",
                                    textAlign: "left",
                                    position: "relative",
                                    transform: "matrix(1, 0, 0, 1, 0, 0)",
                                    opacity: 1,
                                    overflow: "inherit"
                                }}
                            >
                                {t("Recomendaciones")}
                            </div>
                        </div>
                    </h2>
                </div>
                <div className="col-12 col-lg-6 offset-lg-1 offset-top" dangerouslySetInnerHTML={{ __html: clientes.ladesc }}></div>
            </div>
        </section>

    );
};
export default Content;
